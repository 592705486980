import 'diff2html/bundles/css/diff2html.min.css';
import React, { Component } from 'react';
import * as Diff2Html from 'diff2html';

export default class Diff extends Component {
  renderDiff() {
    const diffHtml = Diff2Html.html(this.props.diff, {
      inputFormat: 'diff',
      showFiles: false,
      matching: 'lines',
      outputFormat: 'side-by-side',
      synchronisedScroll: true
    });

    return <div dangerouslySetInnerHTML={{ __html: diffHtml }} />;
  }

  render() {
    return <div>{this.renderDiff()}</div>;
  }
}
