import React, { Component } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import styles from './keywordSuggestionsPage.module.scss';
import LiveDemoWarning from 'components/demo/liveDemoWarning';
import KeywordSuggestionTable from 'components/keywordSuggestion/keywordSuggestionTable';

class KeywordSuggestionsPage extends Component {
  componentDidMount() {
    const { profile } = this.props;
    this.props.fetchKeywordSuggestionsForDomainIfRequired(
      profile.normalisedDomain
    );
  }

  getTabTitle = profile => {
    return (
      <span className={styles.tabTitle}>
        <img src={profile.faviconUrl} alt="" /> {profile.name}
      </span>
    );
  };

  onSelectTab = key => {
    this.props.fetchKeywordSuggestionsForDomainIfRequired(key);
  };

  renderTab = profile => {
    const { data } = this.props;

    const title = this.getTabTitle(profile);
    const dataForDomain = data.get(profile.normalisedDomain);

    return (
      <Tab
        key={profile.normalisedDomain}
        eventKey={profile.normalisedDomain}
        title={title}
      >
        <div className={styles.tabWrapper}>
          <KeywordSuggestionTable dataForDomain={dataForDomain} />
        </div>
      </Tab>
    );
  };

  renderTabs = () => {
    const { profile, competitors } = this.props;

    const tabs = [];
    tabs.push(this.renderTab(profile));

    Object.values(competitors).forEach(competitor => {
      tabs.push(this.renderTab(competitor));
    });
    return tabs;
  };

  render() {
    const { profile } = this.props;

    return (
      <div>
        <div className="row" style={{ marginBottom: '30px' }}>
          <div className="col-xl-12" style={{ textAlign: 'center' }}>
            <h1 className={'h4'} style={{ marginBottom: 0 }}>
              Keyword suggestions
            </h1>
            <h2 className={'h5 textLighter thin'}>
              Discover keyword suggestions to target for your own site and those
              of your competitors
            </h2>
          </div>
        </div>

        <LiveDemoWarning style={{ marginBottom: '60px' }} />

        <Tabs
          defaultActiveKey={profile.normalisedDomain}
          id="suggestion-tabs"
          onSelect={k => this.onSelectTab(k)}
        >
          {this.renderTabs()}
        </Tabs>
      </div>
    );
  }
}

export default KeywordSuggestionsPage;
