import React, { Component } from 'react';
import styles from './lineProgress.module.scss';

export default class LineProgress extends Component {
  render() {
    const { percentage, showText } = this.props;
    return (
      <div className={styles.progress}>
        <div className={styles.progressBar} style={{ width: percentage + '%' }}>
          {showText && <span>{percentage}%</span>}
        </div>
      </div>
    );
  }
}
