import { call, put, takeLatest } from 'redux-saga/effects';
import {
  UPDATE_USER_CHANNEL,
  receiveNotificationChannel
} from 'actions/authActions';
import { createAxios } from 'utils/axios/axiosUtils';
import Swal from 'sweetalert2';

export default function* updateUserChannelSaga() {
  yield takeLatest(UPDATE_USER_CHANNEL, updateUserChannel);
}

function* updateUserChannel({ data }) {
  try {
    const response = yield call(attemptUpdateResponse, data);
    if (response.data && response.data.channel) {
      yield put(receiveNotificationChannel(response.data.channel));

      Swal.fire({
        type: 'success',
        title: 'Saved!',
        showConfirmButton: false,
        timer: 1500
      });
    }
  } catch (e) {
    console.warn(e);

    Swal.fire({
      type: 'error',
      title: 'Oops...',
      text:
        'Something went wrong trying to update this channel. Try again later.'
    });
  }
}

async function attemptUpdateResponse(data) {
  return await createAxios().put('self-notification-channel', { fields: data });
}
