import React, { useState, useContext, createContext } from 'react';
import Pusher from 'components/pusher/pusher';
import { createAxios } from 'utils/axios/axiosUtils';
import { useAuthUser } from './appProvider';

export const CommentsContext = createContext();

async function loadComments(setComments, eventId) {
  try {
    const response = await createAxios().get('event/' + eventId + '/comments');
    if (response.data && response.data.comments) {
      setComments(response.data.comments);
    }
  } catch (e) {
    console.warn('Something went wrong trying to comments.', e);
  }
}

export default function CommentsProvider(props) {
  const [comments, setComments] = useState(props.defaultComments || []);
  const authUser = useAuthUser();

  return (
    <CommentsContext.Provider value={comments}>
      <Pusher
        channel={authUser.accountId + `-channel-timeline-events`}
        event={`commentsUpdated-${props.eventId}`}
        onUpdate={() => loadComments(setComments, props.eventId)}
      />

      {props.children}
    </CommentsContext.Provider>
  );
}

export const useGetComments = () => {
  const comments = useContext(CommentsContext);
  return comments;
};
