import { createSelector } from 'reselect';
import { Map } from 'immutable';
import { scoutUrlInformationSelector } from 'selectors/scoutUrlSelectors';
import { currentProfileSelector } from 'selectors/profileSelectors';
import {
  competitorConfigSelector,
  competitorConfigLoadingSelector
} from 'selectors/competitorConfigSelectors';
import { competitorEmailSelector } from 'selectors/competitorEmailSelector';

export const competitorsForProfileSelector = createSelector(
  state => state.competitor,
  state => state.profile,
  (competitors, profilesState) => {
    const currentProfileId = profilesState.get('currentProfileId');
    if (!currentProfileId) {
      // All logged in accounts should already have a profileId set.
      // If it doesn't, then we have a problem.
      return Map({});
    }

    return competitors
      .get('data')
      .filter(
        competitor =>
          competitor.get('profileId').toString() === currentProfileId
      );
  }
);

export const simpleCompetitorSelector = createSelector(
  competitorsForProfileSelector,
  competitors => {
    if (!competitors || !competitors.size) {
      return Map({});
    }

    return competitors.map(competitor => {
      return {
        competitorId: competitor.get('competitorId'),
        profileId: competitor.get('profileId'),
        name: competitor.get('name'),
        domain: competitor.get('domain'),
        addedOn: competitor.get('addedOn'),
        normalisedDomain: competitor.get('normalisedDomain'),
        faviconUrl: competitor.get('faviconUrl')
      };
    });
  }
);

export const competitorsSelector = createSelector(
  state => state.competitor,
  competitors => {
    const competitorData = competitors.get('data');
    if (!competitorData || !competitorData.size) {
      return Map({});
    }

    return competitorData;
  }
);

export const competitorLoadingStateSelector = createSelector(
  state => state.competitor,
  competitors => {
    return competitors.get('loading');
  }
);

export const competitorAddPageSelector = createSelector(
  state => state.competitor,
  scoutUrlInformationSelector,
  currentProfileSelector,
  (competitorData, scoutData, currentProfile) => {
    return {
      ...scoutData,
      addingCompetitor: competitorData.get('addingCompetitor'),
      currentProfile
    };
  }
);

export const manageCompetitorsPageSelector = createSelector(
  state => state.competitor,
  competitorsForProfileSelector,
  currentProfileSelector,
  (competitorState, competitors, profile) => {
    return {
      competitors: competitors,
      profile: profile ? profile.toJS() : null
    };
  }
);

export const editCompetitorPageSelector = createSelector(
  competitorsForProfileSelector,
  currentProfileSelector,
  competitorConfigSelector,
  competitorConfigLoadingSelector,
  competitorEmailSelector,
  (competitors, currentProfile, competitorConfig, loadingConfig, { email }) => {
    return {
      competitors: competitors,
      profile: currentProfile,
      competitorConfig,
      loadingConfig,
      email
    };
  }
);
