import React from 'react';
import Gatekeeper from 'components/permissions/gatekeeper';
import { ROLE_ACCOUNT_ADMIN } from 'utils/permissions/permissions';
import styles from './shareButton.module.scss';
import Swal from 'sweetalert2';
import Icon from 'components/fontAwesome/icon';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { createAxios } from 'utils/axios/axiosUtils';
import { useHistory } from 'react-router-dom';

export default function DeleteEventButton({ eventId }) {
  let history = useHistory();

  const deleteEvent = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This will peranently delete this event.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, I am sure!'
    }).then(async result => {
      if (result.value) {
        try {
          const deleteResponse = await createAxios().delete(
            'timeline-events/' + eventId
          );
          if (deleteResponse.data && deleteResponse.data.success) {
            history.push('/timeline');
            return;
          }
        } catch (e) {
          console.warn(e);
        }

        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'Something went wrong trying to delete this event.'
        });
      }
    });
  };

  return (
    <Gatekeeper noDemoAccount role={ROLE_ACCOUNT_ADMIN}>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id={`tooltip-overlay`}>Delete event</Tooltip>}
      >
        <span onClick={deleteEvent} className={styles.buttonBlock}>
          <Icon name="fal fa-trash" />
        </span>
      </OverlayTrigger>
    </Gatekeeper>
  );
}
