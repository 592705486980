import React, { Component } from 'react';
import SideContentWithGraphics from 'components/loggedOutWrappers/sideContentWithGraphics';
import Button from 'react-bootstrap/Button';
import { createAxios } from 'utils/axios/axiosUtils';
import cx from 'classnames';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import Icon from 'components/fontAwesome/icon';
import styles from 'components/loggedOutWrappers/sidebarContentWithGraphics.module.scss';
import { notOnMobile } from 'utils/appUtils';

class ForgottenPasswordContainer extends Component {
  state = { emailSent: false };

  submitForm = async event => {
    event.preventDefault();

    const email = event.currentTarget.email.value;

    if (!email || email.length < 3) {
      return;
    }

    try {
      await createAxios(false).post('reset-password', {
        email
      });
    } catch (e) {}

    this.setState({ emailSent: true });
  };

  renderPasswordResetForm = () => {
    return (
      <div>
        <h1 className={cx('h3', 'fw700', styles.title)}>
          Oops! Don't worry, we can help get it back
        </h1>
        <h2 className={cx('h5', 'fw400', 'textLighter')}>
          Please enter your email below
        </h2>

        <Form onSubmit={this.submitForm}>
          <Form.Group controlId="email">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              autoFocus={notOnMobile(true)}
            />
          </Form.Group>

          <Button variant="btnAlt" type="submit" className="btnFlat">
            <Icon name="fal fa-unlock-alt" className="mgr10" /> Send reset
            instructions
          </Button>
        </Form>

        <div className={'mrgTop30'}>
          <Link to="/" className={cx('linkEffect', 'textLighter', 'mgr10')}>
            <Icon name="fal fa-user-plus" /> Go back
          </Link>
        </div>
      </div>
    );
  };

  renderConfirmationSent = () => {
    return (
      <div>
        <h1 className={cx('h3', 'fw700', styles.title)}>
          If your email exists in the system, an email should have been sent.
        </h1>
        <h2 className={cx('h5', 'fw400', 'textLighter')}>
          Please check your emails and follow the instructions presented to
          reset your password.
        </h2>

        <div className={'mrgTop30'}>
          <Link to="/" className={cx('linkEffect', 'textLighter', 'mgr10')}>
            <Icon name="fal fa-user-plus" /> Go back
          </Link>
        </div>
      </div>
    );
  };

  render() {
    const { emailSent } = this.state;

    return (
      <SideContentWithGraphics>
        {emailSent && this.renderConfirmationSent()}
        {!emailSent && this.renderPasswordResetForm()}
      </SideContentWithGraphics>
    );
  }
}

export default ForgottenPasswordContainer;
