import { put, call, takeLatest } from 'redux-saga/effects';
import {
  ATTEMPT_REMOVE_COMPETITOR,
  removeCompetitor
} from 'actions/competitorActions';
import { createAxios } from 'utils/axios/axiosUtils';
import Swal from 'sweetalert2';

export default function* deleteCompetitorSaga() {
  yield takeLatest(ATTEMPT_REMOVE_COMPETITOR, deleteCompetitor);
}

function* deleteCompetitor({ competitorId, cb }) {
  let isOK = false;
  try {
    const response = yield call(attemptDeleteResponse, competitorId);
    if (response.data && response.data.success) {
      isOK = true;
      yield put(removeCompetitor(competitorId));
    }
  } catch (e) {
    console.warn(e);
  }

  if (!isOK) {
    Swal.fire({
      type: 'error',
      title: 'Oops...',
      text:
        'Something went wrong trying to delete this competitor. Try again later.'
    });
  }

  cb();
}

async function attemptDeleteResponse(competitorId) {
  return await createAxios().delete('competitors/' + competitorId);
}
