export const SCOUT_URL = 'app:SCOUT_URL';
export function scoutUrl(url, isCompetitorCheck = false) {
  return {
    type: SCOUT_URL,
    url,
    isCompetitorCheck
  };
}

export const HANDLE_SCOUT_RESPONSE = 'app:HANDLE_SCOUT_RESPONSE';
export function handleScoutResponse(response) {
  return {
    type: HANDLE_SCOUT_RESPONSE,
    response
  };
}

export const HANDLE_BAD_SCOUT_RESPONSE = 'app:HANDLE_BAD_SCOUT_RESPONSE';
export function handleBadScoutResponse(errorMessage) {
  return {
    type: HANDLE_BAD_SCOUT_RESPONSE,
    errorMessage
  };
}

export const CLEAR_SCOUT_STATE = 'app:CLEAR_SCOUT_STATE';
export function clearScoutState() {
  return {
    type: CLEAR_SCOUT_STATE
  };
}
