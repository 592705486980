import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { loggedInSelector } from 'selectors/loggedInSelectors';
import { connect } from 'react-redux';
import { setCurrentProfileId } from 'actions/profileActions';
import LoggedInProfileContainer from './loggedInProfileContainer';
import { switchAccount, setExampleMode } from 'actions/authActions';
import ProfileAddContainer from './profileAddContainer';
import { getQueryStringParams } from 'utils/urlUtils';
import InterfaceWrapper from 'components/presentation/interfaceWrapper';
import PlanContainer from 'containers/planContainer';
import EventNotificationSettingsContainer from 'containers/eventNotificationSettingsContainer';
import AccountUserManagementContainer from 'containers/accountUserManagementContainer';
import AccountIntegrationsContainer from 'containers/accountIntegrationsContainer';
import EditUserContainer from 'containers/editUserContainer';
import AuthUserSettingsContainer from 'containers/authUserSettingsContainer';
import MyNotificationsPage from 'components/page/myNotificationsPage';
import LoggedInLaunchTriggers from 'components/app/loggedInLaunchTriggers';
import AppProvider from 'providers/appProvider';

const PATHS_NOT_REQUIRING_PROFILE = [
  '/event-notifications',
  '/account-user-management',
  '/account-integrations',
  '/user-settings',
  '/plan',
  '/my-notifications'
];

class LoggedInContainer extends Component {
  componentDidMount() {
    this.redirectIfRequired(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.redirectIfRequired(nextProps);
  }

  redirectIfRequired = props => {
    const {
      history,
      profiles,
      currentProfileId,
      setCurrentProfileId,
      location,
      account,
      switchAccount
    } = props;

    const queryParams = getQueryStringParams();
    if (queryParams['switch-account'] !== undefined) {
      switchAccount(queryParams['switch-account']);
      return;
    }

    const currentHref = window.location.href;
    if (this.doesNotRequireProfile(currentHref)) {
      return;
    }

    const firstProfile = profiles.first();
    const firstProfileId = firstProfile && firstProfile.profileId;

    // If we are trying to go to the Profile add, just allow it.
    if (currentHref.indexOf('/profile/add') !== -1) {
      return;
    }

    // If we have no Profiles at all, force them to the setup wizard.
    if (!firstProfile) {
      if (account.get('setupComplete')) {
        history.replace('/profile/add');
        return;
      }
      history.replace('/setup-wizard');
      return;
    }

    // If we are redirecting to a different plan (after purchase?) then set the new Profile.
    if (currentHref.indexOf('/redirect-plan') !== -1) {
      setCurrentProfileId(firstProfileId.toString());
      history.replace('/plan?c=1');
      return;
    }

    const forcedProfileId = queryParams.profileId
      ? queryParams.profileId
      : null;

    const defaultPath = account.get('setupComplete')
      ? '/timeline'
      : '/onboarding';

    // Check the currently given profileId matches one that exists. If not, redirect to the first.
    if (!profiles.get(forcedProfileId || currentProfileId)) {
      setCurrentProfileId(firstProfileId.toString());
      history.replace(defaultPath);
      return;
    }

    if (forcedProfileId) {
      // If we are forcing the Profile, let's set it now.
      setCurrentProfileId(forcedProfileId);
    }

    // If we have an accountId and a profileId but haven't chosen a path, send them to the timeline.
    if (!location || location.pathname === '/') {
      history.replace(defaultPath);
      return;
    }
  };

  doesNotRequireProfile(currentHref) {
    return PATHS_NOT_REQUIRING_PROFILE.some(
      path => currentHref.indexOf(path) !== -1
    );
  }

  render() {
    const {
      user,
      exampleMode,
      currentProfileId,
      unreadNotificationsCount
    } = this.props;

    if (window.location.href.indexOf('/profile/add') !== -1) {
      return <Route component={ProfileAddContainer} />;
    }

    if (window.location.href.indexOf('?switch-account=') !== -1) {
      return null;
    }

    return (
      <AppProvider
        user={user}
        exampleMode={exampleMode}
        currentProfileId={currentProfileId}
        unreadNotificationsCount={unreadNotificationsCount}
      >
        <InterfaceWrapper>
          <Switch>
            <Route
              exact
              path={PATHS_NOT_REQUIRING_PROFILE[0]}
              component={EventNotificationSettingsContainer}
            />
            <Route
              exact
              path={PATHS_NOT_REQUIRING_PROFILE[1]}
              component={AccountUserManagementContainer}
            />
            <Route
              exact
              path={PATHS_NOT_REQUIRING_PROFILE[2]}
              component={AccountIntegrationsContainer}
            />
            <Route
              exact
              path={PATHS_NOT_REQUIRING_PROFILE[3] + '/:userId'}
              component={EditUserContainer}
            />
            <Route
              exact
              path={PATHS_NOT_REQUIRING_PROFILE[3]}
              component={AuthUserSettingsContainer}
            />
            <Route
              exact
              path={PATHS_NOT_REQUIRING_PROFILE[4]}
              component={PlanContainer}
            />
            <Route
              exact
              path={PATHS_NOT_REQUIRING_PROFILE[5]}
              component={MyNotificationsPage}
            />
            <Route component={LoggedInProfileContainer} />
          </Switch>
          <LoggedInLaunchTriggers />
        </InterfaceWrapper>
      </AppProvider>
    );
  }
}

const mapStateToProps = state => loggedInSelector(state);
export default connect(mapStateToProps, {
  setCurrentProfileId,
  switchAccount,
  setExampleMode
})(LoggedInContainer);
