import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  UPDATE_KEYWORDS,
  handleUpdateKeywordsResponse
} from 'actions/keywordActions';
import { createAxios } from 'utils/axios/axiosUtils';
import Swal from 'sweetalert2';
import { currentProfileSelector } from 'selectors/profileSelectors';

export default function* updateKeywordsSaga() {
  yield takeLatest(UPDATE_KEYWORDS, updateKeywords);
}

function* updateKeywords({ keywords, successCb, failCb }) {
  const profile = yield select(currentProfileSelector);
  if (!profile || !profile.size) {
    return;
  }

  try {
    const response = yield call(
      attemptUpdateResponse,
      profile.get('profileId'),
      keywords
    );

    if (response.data && response.data.keywords) {
      yield put(handleUpdateKeywordsResponse(keywords));
      successCb();
      return;
    }
  } catch (e) {
    console.warn(e);
  }

  failCb();

  Swal.fire({
    type: 'error',
    title: 'Oops...',
    text:
      'You are trying to add more keywords than your plan allows, please keep within your account limits.'
  });
}

async function attemptUpdateResponse(profileId, keywords) {
  return await createAxios().put('keywords/' + profileId, {
    keywords: keywords
  });
}
