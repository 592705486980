import { createSelector } from 'reselect';
import {
  simpleProfilesSelector,
  currentProfileSelector,
  currentProfileIdSelector
} from 'selectors/profileSelectors';
import {
  competitorLoadingStateSelector,
  competitorsForProfileSelector
} from 'selectors/competitorSelectors';
import { hasExpiredSelector, activeServiceSelector } from './accountSelectors';

export const loggedInSelector = createSelector(
  state => state.auth,
  simpleProfilesSelector,
  currentProfileIdSelector,
  (auth, simpleProfiles, currentProfileId) => {
    return {
      isLoggedIn: auth.get('isLoggedIn'),
      exampleMode: auth.get('user').get('exampleMode'),
      user: auth.get('user').toJS(),
      account: auth.get('account'),
      profiles: simpleProfiles,
      currentProfileId: currentProfileId,
      unreadNotificationsCount: auth.get('unreadNotificationsCount')
    };
  }
);

export const loggedInProfileSelector = createSelector(
  simpleProfilesSelector,
  currentProfileSelector,
  currentProfileIdSelector,
  competitorLoadingStateSelector,
  (simpleProfiles, currentProfile, currentProfileId, isLoadingCompetitors) => {
    return {
      profiles: simpleProfiles,
      currentProfile: currentProfile && currentProfile.toJS(),
      currentProfileId: currentProfileId,
      isLoadingCompetitors
    };
  }
);

export const mainSidebarSelector = createSelector(
  state => state.auth,
  simpleProfilesSelector,
  currentProfileSelector,
  hasExpiredSelector,
  (auth, simpleProfiles, currentProfile, hasExpired) => {
    return {
      user: auth.get('user').toJS(),
      paymentStatus: auth.get('account').get('paymentStatus'),
      servicesSummary: auth.get('servicesSummary').toJS(),
      profiles: simpleProfiles.toJS(),
      currentProfile: currentProfile && currentProfile.toJS(),
      hasExpired,
      account: auth.get('account')
    };
  }
);

export const topBarSelector = createSelector(
  state => state.auth,
  competitorsForProfileSelector,
  currentProfileSelector,
  hasExpiredSelector,
  activeServiceSelector,
  (auth, competitors, currentProfile, hasExpired, activeService) => {
    return {
      user: auth.get('user'),
      competitors: competitors && competitors.toJS(),
      paymentStatus: auth.get('account').get('paymentStatus'),
      currentProfile,
      isExampleMode: auth.get('user').get('exampleMode'),
      hasExpired,
      activeService
    };
  }
);
