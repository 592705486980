import React from 'react';
import styles from './inspectorEventComments.module.scss';
import Icon from 'components/fontAwesome/icon';
import EventCommentBox from './eventCommentBox';
import EventComment from './eventComment';
import UsersProvider, { useGetUsers } from 'providers/usersProvider';
import CommentsProvider, { useGetComments } from 'providers/commentsProvider';
import { useGetInspectorEventData } from 'providers/inspectorEventProvider';

function CommentsTitleBox() {
  const comments = useGetComments();

  return (
    <div className={styles.titleSegment}>
      <div>
        <Icon name="far fa-comment" /> Comments ({comments.length})
      </div>
    </div>
  );
}

function Comments() {
  const users = useGetUsers();
  const comments = useGetComments();

  let renderComments = [];
  comments.forEach(comment => {
    let userId = comment.userId.toString();
    let user = users && users[userId];

    let userName = user && user.name;
    let userEmail = user && user.email;

    if (!user && userId === '2') {
      userName = 'Richard Hendricks';
      userEmail = 'lee+livedemo@jessle.com';
    } else if (!user && userId === '3') {
      userName = 'Bertram Gilfoyle';
      userEmail = 'lee+livedemo2@jessle.com';
    } else if (!user) {
      userName = 'Deleted user';
      userEmail = 'deleteduser@competito.app';
    }

    renderComments.push(
      <EventComment
        {...comment}
        user={userName}
        email={userEmail}
        key={comment.commentId}
      />
    );
  });

  return <div className={styles.comments}>{renderComments}</div>;
}

export default function InspectorEventComments(props) {
  const { eventData } = useGetInspectorEventData();

  return (
    <UsersProvider>
      <CommentsProvider
        defaultComments={eventData.comments}
        eventId={eventData.eventId}
      >
        <div className="row">
          <div className="col-md-6">
            <div className={styles.wrapper}>
              <div className={styles.commentsContainer}>
                <CommentsTitleBox />
                <Comments />
                <EventCommentBox eventId={eventData.eventId} />
              </div>
            </div>
          </div>
        </div>
      </CommentsProvider>
    </UsersProvider>
  );
}
