import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { profileAddPageSelector } from 'selectors/profileSelectors';
import { scoutUrl, clearScoutState } from 'actions/scoutUrlActions';
import { addProfile } from 'actions/profileActions';
import styles from './loggedInContainer.module.scss';
import Block from 'components/presentation/block';
import Alert from 'components/alert/alert';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'components/spinner/spinner';
import { withScoutResponse } from 'utils/scout/scoutResponseFormBuilder';
import { notOnMobile } from 'utils/appUtils';
import { timelinePath } from 'utils/pathUtils';

const DEFAULT_FORM_DATA_STATE = {
  name: '',
  domain: '',
  facebookUrl: '',
  twitterUrl: '',
  youtubePageUrl: '',
  keywords: '',
  faviconUrl: ''
};

class ProfileAddContainer extends Component {
  state = {
    formData: Object.assign({}, DEFAULT_FORM_DATA_STATE)
  };

  componentWillUnmount() {
    this.props.clearScoutState();
  }

  clearState = () => {
    this.setState({ formData: Object.assign({}, DEFAULT_FORM_DATA_STATE) });
    this.props.clearScoutState();

    this.props.history.push('/');
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.verifiedDomain && !this.props.verifiedDomain) {
      this.setState({
        formData: withScoutResponse(
          this.state.formData,
          nextProps.scoutingResponse
        )
      });
    }
  }

  submitForm = e => {
    e.preventDefault();
    e.stopPropagation();

    if (this.isSubmitDisabled()) {
      return;
    }

    if (this.props.verifiedDomain) {
      this.props.addProfile(this.state.formData, responseData => {
        this.props.history.push(
          timelinePath() + '?profileId=' + responseData.profile.profileId
        );
      });
    } else {
      this.props.scoutUrl(this.state.formData.domain);
    }
  };

  setField = fieldName => e => {
    let newData = this.state;
    newData.formData[fieldName] = e.target.value;
    this.setState(newData);
  };

  isSubmitDisabled = () => {
    const { formData } = this.state;
    const { scoutingDomain, addingProfile } = this.props;

    return (
      formData.name.length < 1 ||
      formData.domain.length < 4 ||
      formData.domain.indexOf('.') === -1 ||
      scoutingDomain ||
      addingProfile
    );
  };

  renderVerifiedFormFields = () => {
    const { scoutingResponse } = this.props;

    const keywordsUsed = scoutingResponse.get('keywordsUsed');
    const keywordsLeft = scoutingResponse.get('keywordsLeft');

    return (
      <div>
        <Form.Group controlId="facebookPageUrl" className={'mrgBottom30'}>
          <Form.Label>
            <strong>Facebook page</strong>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="e.g. https://facebook.com/competito"
            onChange={this.setField('facebookUrl')}
            value={this.state.formData.facebookUrl}
          />

          <Form.Text className="text-muted">
            We use this to analyze the Facebook page given and compare against
            your competitors performance.
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="twitterPageUrl" className={'mrgBottom30'}>
          <Form.Label>
            <strong>Twitter page</strong>
          </Form.Label>

          <Form.Control
            type="text"
            placeholder="e.g. https://twitter.com/competito"
            onChange={this.setField('twitterUrl')}
            value={this.state.formData.twitterUrl}
          />

          <Form.Text className="text-muted">
            We use this to analyze the Twitter page given and compare against
            your competitors performance.
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="youtubePageUrl" className={'mrgBottom30'}>
          <Form.Label>
            <strong>YouTube page</strong>
          </Form.Label>

          <Form.Control
            type="text"
            placeholder="e.g. https://youtube.com/channel/dfsdfs"
            onChange={this.setField('youtubePageUrl')}
            value={this.state.formData.youtubePageUrl}
          />

          <Form.Text className="text-muted">
            We use this to analyze the YouTube page given and compare against
            your competitors YouTube performance.
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="keywords" className={'mrgBottom30'}>
          <Form.Label>
            <strong>Keywords</strong>
          </Form.Label>
          <Form.Control
            type="textarea"
            rows={10}
            as="textarea"
            placeholder="e.g. competitor monitoring, watch my competition"
            onChange={this.setField('keywords')}
            value={this.state.formData.keywords}
          />

          <Form.Text className="text-muted">
            Keywords are seperated by a new line or a comma. <br />
            You are able to monitor {keywordsLeft} keywords for this domain (
            {keywordsUsed} already used as part of your service plan). <br />{' '}
            <br />
            We use keywords to help monitor your competitors performance for the
            words given. Additionally, we use this to find new competitors and
            Ads that are targeting the words you are trying to rank for. <br />
          </Form.Text>
        </Form.Group>
      </div>
    );
  };

  renderEnterProfile = () => {
    const { verifiedDomain, scoutingError } = this.props;

    if (verifiedDomain) {
      return (
        <div>
          <div className={'mrgBottom30'}>
            <p>
              <strong>Company name</strong>
            </p>
            <p>{this.state.formData.name}</p>
          </div>
          <div className={'mrgBottom30'}>
            <p>
              <strong>Website address</strong>
            </p>
            <p>{this.state.formData.domain}</p>
          </div>
        </div>
      );
    }

    return (
      <div>
        <Form.Group controlId="name" className={'mrgBottom30'}>
          <Form.Label>
            <strong>Enter company name</strong>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="e.g. Competitor"
            onChange={this.setField('name')}
            value={this.state.formData.name}
            disabled={verifiedDomain}
            autoFocus={notOnMobile(true)}
          />
        </Form.Group>

        <Form.Group controlId="domain" className={'mrgBottom30'}>
          <Form.Label>
            <strong>Enter website address</strong>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="e.g. https://competito.app or competito.app"
            onChange={this.setField('domain')}
            value={this.state.formData.domain}
            disabled={verifiedDomain}
          />

          {scoutingError && (
            <Form.Text className="text-danger">{scoutingError}</Form.Text>
          )}
        </Form.Group>
      </div>
    );
  };

  render() {
    const { scoutingDomain, verifiedDomain, addingProfile } = this.props;
    const buttonDisabled = this.isSubmitDisabled();

    return (
      <div className={cx(styles.page, styles.pageNoSidebar)}>
        <div className={styles.centerModal}>
          <div className="row">
            <Block title={'Add your company'}>
              <Alert variant="info">
                <strong>Add the details for your business</strong>. We will use
                the data we collect for your business to compare against your
                competitors. You can add your competitors afterwards.
              </Alert>
              <br />

              <Form onSubmit={e => this.submitForm(e)}>
                {this.renderEnterProfile()}

                {verifiedDomain && this.renderVerifiedFormFields()}

                <Button
                  variant={verifiedDomain ? 'success' : 'info'}
                  size="sm"
                  type="submit"
                  disabled={buttonDisabled}
                  className="btnFlatSmall"
                >
                  {!verifiedDomain && 'Next'}
                  {verifiedDomain && 'Confirm'}
                </Button>

                <Button
                  onClick={this.clearState}
                  variant="light"
                  style={{ marginLeft: '10px' }}
                  size="sm"
                  type="button"
                  disabled={addingProfile}
                  className="btnFlatSmall"
                >
                  Cancel
                </Button>

                {scoutingDomain && (
                  <span style={{ marginLeft: '10px' }}>
                    <Spinner
                      style={{ height: '20px', width: '20px', fontSize: '8px' }}
                    />{' '}
                    verifying domain
                  </span>
                )}

                {addingProfile && (
                  <span style={{ marginLeft: '10px' }}>
                    <Spinner
                      style={{ height: '20px', width: '20px', fontSize: '8px' }}
                    />{' '}
                    adding profile
                  </span>
                )}
              </Form>
            </Block>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => profileAddPageSelector(state);
export default connect(mapStateToProps, {
  scoutUrl,
  clearScoutState,
  addProfile
})(ProfileAddContainer);
