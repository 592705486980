import ConfirmKeywordsModal from 'components/modal/confirmKeywordsModal';
import NewsletterSignupGuidanceModal from 'components/modal/newsletterSignupGuidanceModal';

const tasks = [
  {
    key: 'hasAddedCompetitor',
    title: 'Add your first competitor',
    description:
      "Let's start monitoring your most important competitor so we know all the moves they are making online.",
    button: {
      to: '/competitors/add',
      label: 'Add competitor'
    }
  },
  {
    key: 'hasViewedTimeline',
    title: 'View the demo timeline',
    description:
      'Whilst you wait for your competitor results to be populated, we suggest you view the demo timeline to see how Competito can help your business.',
    button: {
      action: 'viewDemoTimeline',
      label: 'View timeline'
    }
  },
  {
    key: 'hasVerifiedEmail',
    title: 'Verify your email',
    description:
      'You can dop this by clicking on the link in the email we have sent you.',
    button: {
      action: 'sendVerificationEmail',
      label: 'Re-send email'
    },
    skippable: false
  },
  {
    key: 'hasReviewedKeywords',
    title: 'Confirm your keywords',
    description:
      'We monitor your keywords to show you how you stack up in search against your competition, and notify you of any movements.',
    button: {
      modal: ConfirmKeywordsModal,
      label: 'View keywords'
    }
  },
  {
    key: 'hasSetupNewsletter',
    title: 'Set up newsletter monitoring',
    description:
      'Sign up to your competitors newsletters. This will allow Competito to receive all updates your competitors send out.',
    button: {
      modal: NewsletterSignupGuidanceModal,
      label: 'Begin setup'
    }
  },
  {
    key: 'hasInvitedTeamMember',
    title: 'Invite your team',
    description:
      "Competito helps keep your entire team informed of your competitor movements. Now your setup is near complete, let's start inviting your team.",
    button: {
      to: '/account-user-management',
      label: 'Invite'
    }
  }
];

export default tasks;
