import React, { Component } from 'react';
import styles from './mainSidebar.module.scss';
import { Link, withRouter } from 'react-router-dom';
import Gravatar from 'components/avatar/gravatarWrapper';
import cx from 'classnames';
import { mainSidebarSelector } from 'selectors/loggedInSelectors';
import Icon from 'components/fontAwesome/icon';
import { logout, setExampleMode } from 'actions/authActions';
import { connect } from 'react-redux';
import OutstandingAppNotificationsPill from 'components/notification/outstandingAppNotificationsPill';
import { ROLE_ACCOUNT_ADMIN, ROLE_USER } from 'utils/permissions/permissions';
import Gatekeeper from 'components/permissions/gatekeeper';
import OnboardingRadialProgress from 'components/accountSetup/onboardingRadialProgress';
import { setAccountSetupComplete } from 'actions/accountSetupActions';

const MENU_MAIN = 'main';
const MENU_SUBMENU = 'submenu';
const MENU_PROFILES = 'profiles';

class MainSidebar extends Component {
  state = { menu: MENU_MAIN };

  toggleSidebarIfRequired() {
    if (window.innerWidth <= 991) {
      this.props.toggleSidebar();
    }
  }

  toggleExampleTimeline = () => {
    this.props.setExampleMode(!this.props.user.exampleMode);
  };

  renderMainMenuItems = () => {
    const {
      user,
      profiles,
      account,
      setAccountSetupComplete,
      history: { location }
    } = this.props;

    const exampleModeText =
      (user.exampleMode ? 'Disable' : 'Enable') + ' example timeline';

    let MENU_MAP = [
      { type: 'heading', text: 'General', style: { paddingTop: 0 } },
      {
        type: 'item',
        text: 'First setup',
        icon: 'fal fa-hammer',
        to: '/onboarding',
        isSettingUp: true,
        sideComponent: OnboardingRadialProgress,
        gatekeeper: { role: ROLE_ACCOUNT_ADMIN } // find a way to include the progress component??
      },
      {
        type: 'item',
        text: 'Timeline',
        icon: 'fal fa-exchange-alt',
        to: '/timeline'
      },
      {
        type: 'item',
        text: 'Dashboard',
        icon: 'fal fa-tachometer-alt',
        to: '/overview'
      },
      { type: 'heading', text: 'Keywords' },
      {
        type: 'item',
        text: 'Overview',
        icon: 'fal fa-trophy',
        to: '/keywords'
      },
      {
        type: 'item',
        text: 'Suggestions',
        icon: 'fal fa-search-dollar',
        to: '/keyword-suggestions'
      },
      { type: 'heading', text: 'Inventory' },
      {
        type: 'item',
        text: 'Web pages',
        icon: 'fal fa-map-signs',
        to: '/inventory/pages'
      },
      {
        type: 'item',
        text: 'Technologies',
        icon: 'fal fa-list',
        to: '/inventory/technologies'
      },
      {
        type: 'heading',
        text: 'Competitors',
        gatekeeper: { role: ROLE_ACCOUNT_ADMIN }
      },
      {
        type: 'item',
        text: 'Create competitor',
        icon: 'fal fa-plus',
        to: '/competitors/add',
        gatekeeper: { role: ROLE_ACCOUNT_ADMIN },
        isNotExpired: true
      },
      {
        type: 'item',
        text: 'Manage competitors',
        icon: 'fal fa-cog',
        to: '/competitors/manage',
        gatekeeper: { role: ROLE_ACCOUNT_ADMIN }
      }
    ];

    if (this.state.menu === MENU_SUBMENU) {
      MENU_MAP = [
        {
          type: 'heading',
          text: 'Demo',
          style: { paddingTop: 0 },
          gatekeeper: { role: ROLE_USER }
        },
        {
          type: 'item',
          key: 'ex',
          text: exampleModeText,
          gatekeeper: { role: ROLE_USER },
          icon: 'fal fa-chalkboard',
          onClick: this.toggleExampleTimeline,
          to: '/timeline'
        },
        { type: 'heading', text: 'User' },
        {
          type: 'item',
          key: 'notif',
          text: (
            <span>
              My notifications <OutstandingAppNotificationsPill />
            </span>
          ),
          icon: 'fal fa-bell',
          to: '/my-notifications'
        },
        {
          type: 'item',
          text: 'Settings',
          icon: 'fal fa-user',
          to: '/user-settings',
          gatekeeper: { role: ROLE_USER }
        },
        {
          type: 'item',
          text: 'Event notifications',
          icon: 'fal fa-envelope',
          to: '/event-notifications',
          gatekeeper: { role: ROLE_USER }
        },
        {
          type: 'heading',
          text: 'Account',
          gatekeeper: { role: ROLE_ACCOUNT_ADMIN }
        },
        {
          type: 'item',
          text: 'Users',
          icon: 'fal fa-wrench',
          to: '/account-user-management',
          gatekeeper: { role: ROLE_ACCOUNT_ADMIN }
        },
        {
          type: 'item',
          text: 'Integrations',
          icon: 'fal fa-cubes',
          to: '/account-integrations',
          gatekeeper: { role: ROLE_ACCOUNT_ADMIN }
        },
        {
          type: 'item',
          text: 'Plans',
          icon: 'fal fa-dollar-sign',
          to: '/plan',
          gatekeeper: { role: ROLE_ACCOUNT_ADMIN }
        },
        { type: 'heading', text: 'Other' },
        {
          type: 'item',
          text: 'Sign out',
          icon: 'fal fa-sign-out-alt',
          to: '/login',
          onClick: this.props.logout
        }
      ];
    }

    if (this.state.menu === MENU_PROFILES) {
      MENU_MAP = [
        {
          type: 'heading',
          text: 'Options',
          style: { paddingTop: 0 },
          gatekeeper: { role: ROLE_USER }
        },
        {
          type: 'item',
          text: 'Add profile',
          to: '/profile/add',
          gatekeeper: { role: ROLE_USER },
          icon: 'fal fa-plus'
        },
        { type: 'heading', text: 'Available profiles' }
      ];

      Object.values(profiles).forEach(profile => {
        MENU_MAP.push({
          type: 'item',
          text: profile.profileName,
          href: '/timeline?profileId=' + profile.profileId,
          icon: 'fal fa-globe'
        });
      });
    }

    let menu = [];

    MENU_MAP.forEach(item => {
      if (item.type === 'heading') {
        menu.push(
          <Gatekeeper
            {...item.gatekeeper}
            key={item.key ? item.key : item.text}
          >
            <li className={styles.navMainHeader} style={item.style}>
              <span>{item.text}</span>
            </li>
          </Gatekeeper>
        );
        return;
      }

      if (item.isNotExpired && this.hasExpired) {
        return;
      }

      if (item.isSettingUp && account.get('setupComplete')) {
        return;
      }

      const linkContents = (
        <span key={item.text}>
          <Icon name={item.icon} className={styles.navMainItemsIcon} />
          <span className={styles.navItemText}>{item.text}</span>
        </span>
      );

      const link = item.href ? (
        <a href={item.href}>{linkContents}</a>
      ) : (
        <Link
          to={item.to}
          onClick={
            item.onClick ? item.onClick : () => this.toggleSidebarIfRequired()
          }
        >
          {linkContents}
        </Link>
      );

      menu.push(
        <Gatekeeper {...item.gatekeeper} key={item.key ? item.key : item.text}>
          <li
            style={{ position: 'relative' }}
            className={cx({
              [styles.selected]:
                !item.onClick &&
                location.pathname.toLowerCase().indexOf(item.to) !== -1
            })}
          >
            {link}

            {item.sideComponent && (
              <div
                style={{
                  position: 'absolute',
                  right: '20px',
                  top: '17px',
                  width: '40px'
                }}
              >
                <item.sideComponent
                  accountId={user.accountId}
                  onTopMarks={setAccountSetupComplete}
                />
              </div>
            )}
          </li>
        </Gatekeeper>
      );
    });

    return menu;
  };

  renderSideHeader = () => {
    return (
      <div className={styles.contentHeader}>
        <Link to="/" onClick={() => this.toggleSidebarIfRequired()}>
          <img className={styles.logo} src={'/wordmark-dark.svg'} alt="" />
        </Link>

        <button className={styles.closeBtn} onClick={this.props.toggleSidebar}>
          <Icon name="fa fa-bars" />
        </button>
      </div>
    );
  };

  renderProfile = () => {
    const { user } = this.props;

    const userName = user.name;

    const lastNameParts = userName.split(' ');
    let userShortName =
      lastNameParts.length > 1
        ? userName.charAt(0) + '. ' + lastNameParts[lastNameParts.length - 1]
        : userName;

    if (userShortName.length > 20) {
      userShortName = userShortName.substr(0, 20) + '...';
    }

    return (
      <div
        className={styles.sidebarUserProfile}
        onClick={() => this.setState({ menu: MENU_SUBMENU })}
      >
        <Icon name="fas fa-cog" className={styles.chevron} />

        <div className={styles.notificationsPill}>
          <OutstandingAppNotificationsPill />
        </div>

        <Gravatar
          email={user.email}
          default="mm"
          protocol="https://"
          className={styles.gravatarIcon}
          fakeClassName={styles.gravatarIcon}
          size={35}
        />

        <div className={styles.profileRight}>
          <p className={styles.profileName}>{userShortName}</p>
        </div>
      </div>
    );
  };

  renderBackButton = () => {
    return (
      <div
        className={styles.back}
        onClick={() => this.setState({ menu: MENU_MAIN })}
      >
        <Icon name="fal fa-chevron-left" style={{ marginRight: '10px' }} />
        Main menu
      </div>
    );
  };

  renderProfileSwitcher = () => {
    const { currentProfile } = this.props;

    return (
      <div
        className={styles.back}
        onClick={() => this.setState({ menu: MENU_PROFILES })}
        style={{ marginTop: '5px' }}
      >
        <p
          style={{
            margin: 0,
            textTransform: 'uppercase',
            fontWeight: 600,
            fontSize: '12px',
            letterSpacing: '1px',
            opacity: 0.55
          }}
        >
          Current profile
        </p>
        <p style={{ margin: 0 }}>
          {currentProfile ? currentProfile.name : 'No profile selected.'}
          <Icon
            name="fal fa-chevron-right"
            style={{ marginLeft: '10px', fontSize: '12px' }}
          />
        </p>
      </div>
    );
  };

  render() {
    const { menu } = this.state;
    const isMainMenu = menu === MENU_MAIN;
    const isSubMenu = menu === MENU_SUBMENU;
    const isProfileMenu = menu === MENU_PROFILES;

    return (
      <nav className={styles.wrapper}>
        <div className={styles.sidebarContent}>
          {this.renderSideHeader()}

          {isMainMenu && this.renderProfileSwitcher()}

          <div className={styles.itemsWrapper}>
            {(isProfileMenu || isSubMenu) && this.renderBackButton(MENU_MAIN)}

            <ul className={styles.navMain}>{this.renderMainMenuItems()}</ul>
          </div>

          {isMainMenu && this.renderProfile()}
        </div>
      </nav>
    );
  }
}

const mapStateToProps = state => mainSidebarSelector(state);
export default withRouter(
  connect(mapStateToProps, { logout, setExampleMode, setAccountSetupComplete })(
    MainSidebar
  )
);
