import React, { Component } from 'react';
import Block from 'components/presentation/block';
import Button from 'components/button/button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import { getRoleRank } from 'utils/permissions/permissions';
import Spinner from 'components/spinner/spinner';

class UserSettingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      name: props.user.get('name'),
      email: props.user.get('email'),
      role: props.user.get('role'),
      password: '',
      confirmPassword: ''
    };
  }

  moanValidationFailure = reason => {
    Swal.fire({
      title: 'These details are invalid',
      text: reason,
      type: 'error'
    });
  };

  saveForm = () => {
    const { user } = this.props;

    const data = {
      name: this.state.name,
      email: this.state.email,
      role: this.state.role
    };

    if (this.state.name.length < 3) {
      this.moanValidationFailure('You must provide a name for this user.');
      return;
    }

    if (
      this.state.email.length < 4 ||
      this.state.email.indexOf('.') === -1 ||
      this.state.email.indexOf('@') === -1
    ) {
      this.moanValidationFailure('You must provide a valid email address.');
      return;
    }

    if (this.state.password.length > 0) {
      const password = this.state.password;
      const confirmPassword = this.state.confirmPassword;

      if (password.length < 6) {
        this.moanValidationFailure(
          'Password length should be at-least 6 characters long.'
        );
        return;
      } else if (password !== confirmPassword) {
        this.moanValidationFailure('Your passwords do not match.');
        return;
      }

      data.password = this.state.password;
    }

    this.setState({ saving: true });
    this.props.attemptUpdateUser(user.get('userId'), data, () => {
      this.setState({ saving: false });
    });
  };

  setField = fieldName => e => {
    this.setState({ [fieldName]: e.target.value });
  };

  renderAvailableRoleOptions = () => {
    const { authUserRole } = this.props;

    const authUserRoleRank = getRoleRank(authUserRole);

    const options = [
      <option
        key="ao"
        value="ROLE_ACCOUNT_OWNER"
        disabled={authUserRoleRank < 3}
      >
        Account owner
      </option>,
      <option
        key="aa"
        value="ROLE_ACCOUNT_ADMIN"
        disabled={authUserRoleRank < 2}
      >
        Account admin
      </option>,
      <option key="user" value="ROLE_USER">
        User
      </option>
    ];

    if (authUserRole === 'ROLE_JESSLE') {
      return [
        <option key="su" value="ROLE_JESSLE" disabled={authUserRoleRank < 999}>
          Superuser
        </option>,
        ...options
      ];
    }

    return options;
  };

  render() {
    const { editingSelf } = this.props;

    return (
      <div>
        <div className="row" style={{ marginBottom: '30px' }}>
          <div className="col-xl-12" style={{ textAlign: 'center' }}>
            <h1 className={'h4'} style={{ marginBottom: 0 }}>
              Manage settings
            </h1>
            <h2 className={'h5 textLighter thin'}>
              Update settings for {this.state.name}
            </h2>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <Block title={'General settings'}>
              <Form.Group controlId="name">
                <Form.Label>
                  <strong>Full name</strong>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="e.g. Fred Bloggs"
                  onChange={this.setField('name')}
                  value={this.state.name}
                />
              </Form.Group>

              <Form.Group controlId="email">
                <Form.Label>
                  <strong>Email</strong>
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="e.g. fred@bloggs.com"
                  onChange={this.setField('email')}
                  value={this.state.email}
                />
              </Form.Group>

              {!editingSelf && (
                <Form.Group controlId="role">
                  <Form.Label>
                    <strong>Role</strong>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    onChange={this.setField('role')}
                    value={this.state.role}
                  >
                    {this.renderAvailableRoleOptions()}
                  </Form.Control>
                </Form.Group>
              )}
            </Block>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <Block title={'Change your password'}>
              <Form.Group controlId="password">
                <Form.Label>
                  <strong>Password</strong>
                </Form.Label>
                <Form.Control
                  type="password"
                  autoComplete={'new-password'}
                  placeholder=""
                  onChange={this.setField('password')}
                  value={this.state.password}
                />
              </Form.Group>

              <Form.Group controlId="confirm-password">
                <Form.Label>
                  <strong>Confirm password</strong>
                </Form.Label>
                <Form.Control
                  type="password"
                  autoComplete={'new-password'}
                  onChange={this.setField('confirmPassword')}
                  value={this.state.confirmPassword}
                />
              </Form.Group>
            </Block>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <Button
              size="sm"
              primary
              type="submit"
              style={{ marginBottom: '20px' }}
              disabled={this.state.saving}
              onClick={this.saveForm}
            >
              {this.state.saving ? <Spinner center /> : 'Update'}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default UserSettingsPage;
