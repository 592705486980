import { call, select, takeLatest } from 'redux-saga/effects';
import { createAxios } from 'utils/axios/axiosUtils';
import { ADD_PAGE_FOR_MONITORING } from 'actions/inventoryActions';
import Swal from 'sweetalert2';
import { currentProfileIdSelector } from 'selectors/profileSelectors';

export default function* addPageForMonitoringSaga() {
  yield takeLatest(ADD_PAGE_FOR_MONITORING, addPageForMonitoring);
}

function* addPageForMonitoring({ competitorId, url, successCallbackFn }) {
  const currentProfileId = yield select(currentProfileIdSelector);

  try {
    const response = yield call(apiCall, competitorId, url, currentProfileId);

    if (response && response.data.success) {
      successCallbackFn && successCallbackFn();
      return;
    }

    Swal.fire({
      type: 'error',
      title: 'Oops...',
      text: response.data.errorMessage
    });
  } catch (e) {
    Swal.fire({
      type: 'error',
      title: 'Oops...',
      text: 'Something went wrong trying to add this page. Try again later.'
    });
  }
}

async function apiCall(competitorId, url, profileId) {
  return await createAxios().post('inventory/pages/' + competitorId + '/add', {
    profileId,
    competitorId,
    url
  });
}
