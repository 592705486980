export const FETCH_KEYWORD_TABLE_DATA = 'app:FETCH_KEYWORD_TABLE_DATA';
export function fetchKeywordTableData(
  profileId,
  chosenCompetitorId,
  sortOrder,
  page = 1,
  searchTerm = ''
) {
  return {
    type: FETCH_KEYWORD_TABLE_DATA,
    profileId,
    chosenCompetitorId,
    sortOrder,
    page,
    searchTerm
  };
}

export const RECEIVE_KEYWORD_TABLE_DATA = 'app:RECEIVE_KEYWORD_TABLE_DATA';
export function receiveKeywordTableData(data) {
  return {
    type: RECEIVE_KEYWORD_TABLE_DATA,
    data
  };
}

export const HANDLE_KEYWORD_TABLE_DATA_ERROR =
  'app:HANDLE_KEYWORD_TABLE_DATA_ERROR';
export function handleKeywordTableDataError() {
  return {
    type: HANDLE_KEYWORD_TABLE_DATA_ERROR
  };
}

export const UPDATE_KEYWORDS = 'app:UPDATE_KEYWORDS';
export function updateKeywords(keywords, successCb, failCb) {
  return {
    type: UPDATE_KEYWORDS,
    keywords,
    successCb,
    failCb
  };
}

export const HANDLE_UPDATE_KEYWORD_RESPONSE =
  'app:HANDLE_UPDATE_KEYWORD_RESPONSE';
export function handleUpdateKeywordsResponse(keywords) {
  return {
    type: HANDLE_UPDATE_KEYWORD_RESPONSE,
    keywords
  };
}
