export const REQUEST_COMPETITORS = 'app:REQUEST_COMPETITORS';
export function requestCompetitors() {
  return {
    type: REQUEST_COMPETITORS
  };
}

export const RECEIVE_COMPETITORS = 'app:RECEIVE_COMPETITORS';
export function storeCompetitors(competitors) {
  return {
    type: RECEIVE_COMPETITORS,
    competitors
  };
}

export const ADD_COMPETITOR = 'app:ADD_COMPETITOR';
export function addCompetitor(data, successCb) {
  return {
    type: ADD_COMPETITOR,
    data,
    successCb
  };
}

export const HANDLE_COMPETITOR_ADD_RESPONSE =
  'app:HANDLE_COMPETITOR_ADD_RESPONSE';
export function handleAddCompetitorResponse(response) {
  return {
    type: HANDLE_COMPETITOR_ADD_RESPONSE,
    response
  };
}

export const HANDLE_ADD_COMPETITOR_FAILURE = 'app:ADD_COMPETITOR_FAILURE';
export function handleAddCompetitorFailure() {
  return {
    type: HANDLE_ADD_COMPETITOR_FAILURE
  };
}

export const ATTEMPT_REMOVE_COMPETITOR = 'app:ATTEMPT_REMOVE_COMPETITOR';
export function attemptRemoveCompetitor(competitorId, cb) {
  return {
    type: ATTEMPT_REMOVE_COMPETITOR,
    competitorId,
    cb
  };
}

export const REMOVE_COMPETITOR = 'app:REMOVE_COMPETITOR';
export function removeCompetitor(competitorId) {
  return {
    type: REMOVE_COMPETITOR,
    competitorId
  };
}

export const VERIFY_CAN_ADD_LIMIT_TYPE = 'app:VERIFY_CAN_ADD_LIMIT_TYPE';
export function verifyCanAddLimitType(successCb, failCb, limitType) {
  return {
    type: VERIFY_CAN_ADD_LIMIT_TYPE,
    successCb,
    failCb,
    limitType
  };
}
