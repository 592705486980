import { call, put, takeLatest } from 'redux-saga/effects';
import { ATTEMPT_DELETE_USER, deleteUser } from 'actions/userActions';
import { createAxios } from 'utils/axios/axiosUtils';
import Swal from 'sweetalert2';

export default function* deleteUserSaga() {
  yield takeLatest(ATTEMPT_DELETE_USER, deleteUserFn);
}

function* deleteUserFn({ userId }) {
  try {
    const response = yield call(attemptDeleteResponse, userId);
    if (response.data && response.data.success) {
      yield put(deleteUser(userId));
      return;
    }
  } catch (e) {
    console.warn(e);
  }

  Swal.fire({
    type: 'error',
    title: 'Oops...',
    text: 'Something went wrong trying to delete this user.'
  });
}

async function attemptDeleteResponse(userId) {
  return await createAxios().delete('users/' + userId);
}
