import { delay, put, call } from 'redux-saga/effects';
import { triggerAppIsReady, storeReAuth } from 'actions/authActions';
import { createAxios } from 'utils/axios/axiosUtils';
import { storeProfiles } from 'actions/profileActions';
import { storeCompetitors } from 'actions/competitorActions';
import { storeCompetitorsConfig } from 'actions/competitorConfigActions';
import { storeProfilesConfig } from 'actions/profileConfigActions';
import { fetchAllUsers } from 'actions/userActions';
import { getAuthToken, removeAuthToken } from 'utils/localStorage/localStorage';

export default function* reauthenticate() {
  yield delay(200); // Make sure all other sagas and shit are setup before performing shit

  const token = getAuthToken();
  if (!token) {
    yield put(triggerAppIsReady());
    return;
  }

  // If we have a token, we need to verify it is legit and get the user info associated. Store it and fire ready
  try {
    const response = yield call(getSelfResponse);
    if (response.data && response.data.user) {
      yield put(
        storeReAuth({
          token,
          ...response.data
        })
      );

      yield put(fetchAllUsers());
    }

    if (response.data && response.data.profiles) {
      yield put(storeProfiles(response.data.profiles));
    }

    if (response.data && response.data.competitors) {
      yield put(storeCompetitors(response.data.competitors));
    }

    if (response.data && response.data.competitorsConfig) {
      yield put(storeCompetitorsConfig(response.data.competitorsConfig));
    }

    if (response.data && response.data.profilesConfig) {
      yield put(storeProfilesConfig(response.data.profilesConfig));
    }
  } catch (e) {
    removeAuthToken();
    // just trigger ready if this is the case. We clearly aren't logged in.
  }

  yield put(triggerAppIsReady());
}

async function getSelfResponse() {
  return await createAxios().get('self');
}
