import { Map, fromJS } from 'immutable';
import {
  REQUEST_COMPETITOR_CONFIG,
  RECEIVE_COMPETITOR_CONFIG,
  STORE_COMPETITORS_CONFIG,
  HANDLE_BAD_COMPETITOR_CONFIG_RESPONSE
} from 'actions/competitorConfigActions';
import { STORE_LOGIN } from 'actions/authActions';

const initialState = fromJS({
  data: Map(),
  loading: false
});

export default function competitorConfigReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_COMPETITOR_CONFIG:
      return state.set('loading', true);
    case RECEIVE_COMPETITOR_CONFIG:
      return state
        .setIn(['data', action.competitorId], fromJS(action.competitorConfig))
        .set('loading', false);
    case STORE_COMPETITORS_CONFIG:
      return state
        .set('data', fromJS(action.competitorsConfig))
        .set('loading', false);
    case HANDLE_BAD_COMPETITOR_CONFIG_RESPONSE:
      return state.set('loading', false);
    case STORE_LOGIN:
      return state.set('data', fromJS(action.competitorsConfig));
    default:
      return state;
  }
}
