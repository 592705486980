import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import LoginContainer from 'containers/loginContainer.js';
import ForgottenPasswordContainer from 'containers/forgottenPasswordContainer';
import ResetPasswordContainer from 'containers/resetPasswordContainer';
import SignupConfirmContainer from 'containers/signupConfirmContainer';
import CheckoutConfirmContainer from 'containers/checkoutConfirmContainer';
import LoggedInContainer from 'containers/loggedInContainer.js';
import { connect } from 'react-redux';
import { appSelector } from 'selectors/authSelectors';
import FullPageSpinner from 'components/spinner/fullPageSpinner';
import WelcomeEmailContainer from 'containers/welcomeEmailContainer';
import LiveDemoContainer from 'containers/liveDemoContainer';
import FullStory, { FullStoryAPI } from 'react-fullstory';
import Drift from 'react-driftjs';
import Gatekeeper from 'components/permissions/gatekeeper';
import AccountSetupWizardContainer from 'containers/accountSetupWizardContainer';
import { clearGuestToken } from 'utils/localStorage/localStorage';

class App extends Component {
  constructor() {
    super();

    if (window.location.href.indexOf('setup-wizard') !== -1) {
      clearGuestToken();
    }
  }

  getChatProps = () => {
    const { user } = this.props;

    let props = {
      appId: '886xxc6myvgu'
    };

    if (user && user.get) {
      const nameParts = user.get('name').split(' ');

      props.userId = user.get('userId');
      props.attributes = {
        email: user.get('email'),
        firstName: nameParts[0],
        lastName: nameParts[1]
      };
    }

    return props;
  };

  pushFullStoryIdentity = () => {
    const { user } = this.props;

    if (user && user.get) {
      FullStoryAPI('identify', user.get('userId'), {
        displayName: user.get('name'),
        email: user.get('email')
      });
    }
  };

  getReturnToUrlIfAny = () => {
    if (window.location.pathname.length < 2) {
      return '';
    }

    return '?returnTo=' + encodeURIComponent(window.location.href);
  };

  render() {
    const { isLoggedIn, isAppReady, user } = this.props;
    if (!isAppReady) {
      return <FullPageSpinner useColors={true} larger={true} />;
    }

    this.pushFullStoryIdentity();

    const returnTo = this.getReturnToUrlIfAny();
    return (
      <div>
        <Switch>
          <Route exact path="/login" component={LoginContainer} />
          <Route
            exact
            path="/forgotten-password"
            component={ForgottenPasswordContainer}
          />
          <Route
            exact
            path="/reset-password-confirm"
            component={ResetPasswordContainer}
          />
          <Route
            exact
            path="/signup/confirm"
            component={SignupConfirmContainer}
          />
          <Route
            exact
            path="/checkout/confirm/:ref"
            component={CheckoutConfirmContainer}
          />
          <Route
            exact
            path="/welcome-email"
            component={WelcomeEmailContainer}
          />
          <Route exact path="/live-demo" component={LiveDemoContainer} />
          <Route path="/setup-wizard" component={AccountSetupWizardContainer} />
          {isLoggedIn && <Route path="/" component={LoggedInContainer} />}
          {!isLoggedIn && <Redirect from="*" to={'/login' + returnTo} />}
        </Switch>

        {process.env.NODE_ENV === 'production' && <FullStory org={'Y6D4R'} />}
        {process.env.NODE_ENV === 'production' && user && user.get && (
          <Gatekeeper noDemoAccount>
            <Drift {...this.getChatProps()} />
          </Gatekeeper>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => appSelector(state);

export default connect(mapStateToProps)(App);
