import { put, call, select, takeLatest } from 'redux-saga/effects';
import {
  storeTimelineEvents,
  FETCH_TIMELINE_EVENTS
} from 'actions/timelineActions';
import { createAxios } from 'utils/axios/axiosUtils';
import { currentFiltersSelector } from 'selectors/timelineSelectors';
import { currentProfileIdSelector } from 'selectors/profileSelectors';
import { authUserExampleModeSelector } from 'selectors/authSelectors';
import Swal from 'sweetalert2';

export default function* fetchTimelineEventsSaga() {
  yield takeLatest(FETCH_TIMELINE_EVENTS, fetchTimelineEvents);
}

function* fetchTimelineEvents({ clearEvents }) {
  const filters = yield select(currentFiltersSelector);
  const profileId = yield select(currentProfileIdSelector);
  const isExampleMode = yield select(authUserExampleModeSelector);

  try {
    const response = yield call(
      getTimelineResponse,
      filters,
      profileId,
      isExampleMode
    );
    if (response.data && response.data.events) {
      yield put(storeTimelineEvents(response.data.events, clearEvents));
    }
  } catch (e) {
    Swal.fire({
      type: 'error',
      title: 'Oops...',
      text: 'Something went wrong trying to load the timeline. Try again later.'
      // footer: '<a href>Why do I have this issue?</a>'
    });
  }
}

async function getTimelineResponse(filters, profileId, isExampleMode) {
  let url = 'timeline-events?page=' + filters.page + '&profileId=' + profileId;

  if (isExampleMode) {
    url += '&example=1';
  }
  if (filters.competitorId) {
    url += '&competitorId=' + filters.competitorId;
  }
  if (filters.eventTypes) {
    url += '&eventTypes=' + JSON.stringify(filters.eventTypes);
  }

  return await createAxios().get(url);
}
