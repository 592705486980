import Spinner from 'components/spinner/spinner';
import React, { useState, useContext, createContext, useEffect } from 'react';
import { createAxios } from 'utils/axios/axiosUtils';

export const UsersContext = createContext();

export default function UsersProvider(props) {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    async function loadUsers() {
      try {
        const response = await createAxios().get('users');
        if (response.data && response.data.users) {
          setUserData(response.data.users);
        }
      } catch (e) {
        console.warn('Something went wrong trying to load users.', e);
      }
    }
    loadUsers();
  }, []);

  return (
    <UsersContext.Provider value={userData}>
      {userData === null && <Spinner center />}
      {userData !== null && props.children}
    </UsersContext.Provider>
  );
}

export const useGetUsers = () => {
  const users = useContext(UsersContext);
  return users;
};
