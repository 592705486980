import React from 'react';
import styles from './inspectorEventComments.module.scss';
import moment from 'moment';
import GravatarWrapper from 'components/avatar/gravatarWrapper';
import { useAuthUser } from 'providers/appProvider';
import Swal from 'sweetalert2';
import { createAxios } from 'utils/axios/axiosUtils';

function RenderTime(props) {
  const { commentId, eventId, added, userId } = props;

  const authUser = useAuthUser();

  const onTriggerDelete = async () => {
    try {
      const response = await createAxios().delete(
        'event/' + eventId + '/comment/' + commentId
      );
      if (response.data && response.data.success) {
        console.log('Deleted');
      }
    } catch (e) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Something went wrong trying to delete this comment.'
      });
    }
  };

  return (
    <div className={styles.commentTime}>
      {moment.unix(added).fromNow()}{' '}
      {authUser.userId === userId && (
        <span>
          {' '}
          -{' '}
          <button
            className="btn btn-link"
            style={{ fontSize: '11px', lineHeight: '1px', padding: 0 }}
            onClick={onTriggerDelete}
          >
            Delete
          </button>
        </span>
      )}
    </div>
  );
}

export function RenderBox(props) {
  const { content, metadata, user } = props;

  let friendlyContent = content;

  metadata.mentions.forEach(mention => {
    friendlyContent = friendlyContent.replace(
      mention.display,
      `<strong>${mention.display.replace('@', '')}</strong>`
    );
  });

  return (
    <div className={styles.comment}>
      <strong>{user}</strong>{' '}
      <span dangerouslySetInnerHTML={{ __html: friendlyContent }} />
    </div>
  );
}

export default function EventComment(props) {
  return (
    <div className={styles.comentWrapper}>
      <div style={{ marginRight: '10px' }}>
        <GravatarWrapper
          email={props.email}
          default="mm"
          protocol="https://"
          className={styles.profileGraphicBox}
          size={25}
        />
      </div>
      <div>
        <RenderBox {...props} />
        <RenderTime {...props} />
      </div>
    </div>
  );
}
