export const FETCH_OVERVIEW_DATA = 'app:FETCH_OVERVIEW_DATA';
export function fetchOverviewData(timeFromStr) {
  return {
    type: FETCH_OVERVIEW_DATA,
    timeFromStr
  };
}

export const RECEIVE_OVERVIEW_DATA = 'app:RECEIVE_OVERVIEW_DATA';
export function receiveOverviewData(data) {
  return {
    type: RECEIVE_OVERVIEW_DATA,
    data
  };
}

export const HANDLE_OVERVIEW_LOADING_ERROR =
  'app:HANDLE_OVERVIEW_LOADING_ERROR';
export function handleOverviewLoadingError(error) {
  return {
    type: HANDLE_OVERVIEW_LOADING_ERROR,
    error
  };
}
