import { call, put, takeLatest } from 'redux-saga/effects';
import { createAxios } from 'utils/axios/axiosUtils';
import {
  ADD_PROFILE,
  handleAddProfileFailure,
  handleAddProfileResponse
} from 'actions/profileActions';
import Swal from 'sweetalert2';

export default function* addProfileSaga() {
  yield takeLatest(ADD_PROFILE, addProfile);
}

function* addProfile({ data, successCb }) {
  try {
    const response = yield call(apiCall, data);

    if (response && response.data.profile) {
      yield put(handleAddProfileResponse(response.data.profile));

      successCb && successCb(response.data);
      return;
    }

    yield put(handleAddProfileFailure());
  } catch (e) {
    Swal.fire({
      type: 'error',
      title: 'Oops...',
      text: 'Something went wrong trying to add this Profile. Try again later.'
    });

    yield put(handleAddProfileFailure());
  }
}

async function apiCall(data) {
  return await createAxios().post('profiles', data);
}
