import { List, Map, fromJS } from 'immutable';
import {
  STORE_TIMELINE_EVENTS,
  RESET_TIMELINE_STATE,
  APPLY_FILTERS_TO_TIMELINE,
  INCREMENT_TIMELINE_PAGE,
  STORE_HAS_UPDATES_COUNT
} from 'actions/timelineActions';

const initialState = fromJS({
  events: List(),
  filters: Map({
    competitorId: '',
    eventTypes: [],
    page: 1
  }),
  canLoadMore: false,
  showSpinner: true,
  lastFetchTime: 0,
  hasUpdatesCount: 0
});

export default function timelineReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_TIMELINE_STATE:
      return initialState;
    case STORE_HAS_UPDATES_COUNT:
      return state.set('hasUpdatesCount', action.count || 0);
    case INCREMENT_TIMELINE_PAGE:
      return state.setIn(['filters', 'page'], action.newPage);
    case APPLY_FILTERS_TO_TIMELINE:
      return state
        .set('showSpinner', true)
        .set('events', List())
        .setIn(['filters', action.filterName], action.filterValue)
        .setIn(['filters', 'page'], 1);
    case STORE_TIMELINE_EVENTS:
      return state
        .set('showSpinner', false)
        .set('hasUpdatesCount', 0)
        .set('canLoadMore', action.events.length >= 50)
        .set('lastFetchTime', Math.floor(Date.now() / 1000))
        .update('events', events => {
          if (action.clearEvents) {
            return fromJS(action.events);
          }
          return events.concat(fromJS(action.events));
        });
    default:
      return state;
  }
}
