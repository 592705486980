import { put, call, takeLatest } from 'redux-saga/effects';
import {
  receiveKeywordTableData,
  handleKeywordTableDataError,
  FETCH_KEYWORD_TABLE_DATA
} from 'actions/keywordActions';
import { createAxios } from 'utils/axios/axiosUtils';

export default function* fetchKeywordTableDataSaga() {
  yield takeLatest(FETCH_KEYWORD_TABLE_DATA, fetchKeywordTableData);
}

function* fetchKeywordTableData({
  profileId,
  chosenCompetitorId,
  page,
  searchTerm,
  sortOrder
}) {
  try {
    const response = yield call(
      getKeywordsResponse,
      profileId,
      chosenCompetitorId,
      page,
      searchTerm,
      sortOrder
    );
    if (response.data && response.data.keywordTable) {
      const dataToPass = {
        keywordTable: response.data.keywordTable,
        keywordStats: response.data.keywordStats
      };
      yield put(receiveKeywordTableData(dataToPass));
    }
  } catch (e) {
    console.warn(e);
    yield put(handleKeywordTableDataError());
  }
}

async function getKeywordsResponse(
  profileId,
  chosenCompetitorId,
  page,
  searchTerm,
  sortBy
) {
  return await createAxios().get(
    'keywords?profileId=' +
      profileId +
      '&chosenCompetitorId=' +
      chosenCompetitorId +
      '&page=' +
      page +
      '&searchTerm=' +
      searchTerm +
      '&sortBy=' +
      sortBy
  );
}
