import { call, takeLatest } from 'redux-saga/effects';
import { createAxios } from 'utils/axios/axiosUtils';
import Swal from 'sweetalert2';
import { SET_EXAMPLE_MODE } from 'actions/authActions';

export default function* setExampleModeSaga() {
  yield takeLatest(SET_EXAMPLE_MODE, setExampleMode);
}

function* setExampleMode({ isEnabled }) {
  try {
    yield call(attemptUpdateResponse, isEnabled);
  } catch (e) {
    console.warn(e);

    Swal.fire({
      type: 'error',
      title: 'Oops...',
      text:
        'Something went wrong trying to enable/disable the example mode. Try again later.'
    });
  }
}

async function attemptUpdateResponse(isEnabled) {
  return await createAxios().post(
    isEnabled ? 'user/example-mode/enable' : 'user/example-mode/disable'
  );
}
