import { fromJS, List } from 'immutable';
import { LOGOUT } from 'actions/authActions';
import { RECEIVE_INTEGRATIONS_DATA } from 'actions/integrationActions';

const initialState = fromJS({
  data: List()
});

export default function integrationReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_INTEGRATIONS_DATA:
      return state.set('data', fromJS(action.integrations));
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
