import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './accountSetupWizard.module.scss';
import { notOnMobile } from 'utils/appUtils';
import Form from 'react-bootstrap/Form';
import Button from 'components/button/button';
import { scoutUrl, clearScoutState } from 'actions/scoutUrlActions';
import {
  addCompetitor,
  verifyCanAddLimitType
} from 'actions/competitorActions';
import cx from 'classnames';
import { competitorAddPageSelector } from 'selectors/competitorSelectors';
import Spinner from 'components/spinner/spinner';
import { withScoutResponse } from 'utils/scout/scoutResponseFormBuilder';
import Alert from 'components/alert/alert';
import { Link } from 'react-router-dom';

const DEFAULT_FORM_DATA_STATE = {
  name: '',
  domain: '',
  facebookUrl: '',
  twitterUrl: '',
  faviconUrl: '',
  youtubePageUrl: '',
  deniedPaths: ''
};

class AccountSetupWizardCompetitorStep extends Component {
  state = {
    formData: Object.assign({}, DEFAULT_FORM_DATA_STATE),
    step: 1,
    hasCheckedAdd: false,
    outOfCredits: false,
    isSameAsDomainError: false,
    competitorEmail: null
  };

  componentDidMount() {
    this.props.verifyCanAddLimitType(
      () => this.setState({ outOfCredits: false, hasCheckedAdd: true }),
      () => this.setState({ outOfCredits: true, hasCheckedAdd: true }),
      'MaxCompetitors'
    );
  }

  componentWillUnmount() {
    this.props.clearScoutState();
  }

  clearState = () => {
    this.setState({
      step: 1,
      isSameAsDomainError: false,
      formData: Object.assign({}, DEFAULT_FORM_DATA_STATE)
    });
    this.props.clearScoutState();
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.verifiedDomain && !this.props.verifiedDomain) {
      this.setState({
        step: 2,
        formData: withScoutResponse(
          this.state.formData,
          nextProps.scoutingResponse
        )
      });
    }
  }

  submitForm = e => {
    const { step } = this.state;
    e.preventDefault();
    e.stopPropagation();

    if (this.isSameAsDomainError()) {
      this.setState({ isSameAsDomainError: true });
      return;
    }

    if (step === 1) {
      this.props.scoutUrl(this.state.formData.domain);
    }

    if (step === 2) {
      this.props.addCompetitor(this.state.formData, () => {
        this.props.loadNextStep();
      });
    }
  };

  isSameAsDomainError = () => {
    return (
      this.props.currentProfile
        .get('url')
        .indexOf(this.state.formData.domain) !== -1
    );
  };

  setField = fieldName => e => {
    let newData = this.state;
    newData.isSameAsDomainError = false;
    newData.formData[fieldName] = e.target.value;
    this.setState(newData);
  };

  renderTitleForStep = () => {
    const { step } = this.state;

    if (step === 2) {
      return (
        <div>
          <h1>
            Great. Now let's learn about the social profiles of your competitor{' '}
            <span role="img" aria-label="">
              💬
            </span>
          </h1>
          <h2>
            These are optional. If your competitor doesn't have one, leave it
            blank
          </h2>
        </div>
      );
    }

    return (
      <div>
        <h1>
          Great. Tell us about your most important competitor{' '}
          <span role="img" aria-label="">
            🔥
          </span>
        </h1>
        <h2>We can set up other competitors later</h2>
      </div>
    );
  };

  renderStepOne = () => {
    const { formData, isSameAsDomainError } = this.state;
    const { verifiedDomain, scoutingDomain, scoutingError } = this.props;

    const isSubmitDisabled =
      formData.domain.length < 4 ||
      formData.domain.indexOf('.') === -1 ||
      formData.name.length < 3;

    return (
      <div>
        <div className="row">
          <div className={'col-md-12'}>
            <div className={styles.websiteFields}>
              <Form.Group controlId="name" className={'mrgBottom50'}>
                <Form.Label>
                  <strong>What's the name of your competitor?</strong>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="e.g. My Competitor Co"
                  onChange={this.setField('name')}
                  autoFocus={notOnMobile(true)}
                  value={this.state.formData.name}
                  disabled={verifiedDomain}
                  className={styles.formControl}
                />
              </Form.Group>

              <Form.Group controlId="domain" className={'mrgBottom50'}>
                <Form.Label>
                  <strong>
                    What's the website address of your competitor?
                  </strong>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="e.g. https://my-competitor.com or my-competitor.com"
                  onChange={this.setField('domain')}
                  value={this.state.formData.domain}
                  disabled={verifiedDomain}
                  className={styles.formControl}
                />

                {isSameAsDomainError && (
                  <Form.Text className="text-danger">
                    You cannot have a competitor that's the same as your own
                    website.
                  </Form.Text>
                )}

                {scoutingError && (
                  <Form.Text
                    className={cx('text-danger', styles.multipleMessage)}
                  >
                    {scoutingError}
                  </Form.Text>
                )}
              </Form.Group>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="col-md-12"
            style={{
              textAlign: 'center',
              marginTop: '30px',
              marginBottom: '30px'
            }}
          >
            {!scoutingDomain && (
              <Button type="submit" rounded primary disabled={isSubmitDisabled}>
                Continue
              </Button>
            )}

            {scoutingDomain && (
              <span style={{ marginLeft: '10px' }}>
                <Spinner
                  style={{
                    height: '20px',
                    width: '20px',
                    fontSize: '8px',
                    marginRight: '10px'
                  }}
                />{' '}
                Verifying
              </span>
            )}
          </div>
        </div>
      </div>
    );
  };

  renderStepTwo = () => {
    const { formData } = this.state;
    const { addingCompetitor } = this.props;

    return (
      <div>
        <div className="row">
          <div className={'col-md-12'}>
            <div className={styles.websiteFields}>
              <Form.Group controlId="facebookPageUrl" className={'mrgBottom50'}>
                <Form.Label>
                  <strong>What's {formData.name}'s Facebook page?</strong>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="e.g. https://facebook.com/competito"
                  onChange={this.setField('facebookUrl')}
                  value={this.state.formData.facebookUrl}
                  className={styles.formControl}
                />
              </Form.Group>

              <Form.Group controlId="twitterPageUrl" className={'mrgBottom50'}>
                <Form.Label>
                  <strong>What's {formData.name}'s Twitter page?</strong>
                </Form.Label>

                <Form.Control
                  type="text"
                  placeholder="e.g. https://twitter.com/competito"
                  onChange={this.setField('twitterUrl')}
                  value={this.state.formData.twitterUrl}
                  className={styles.formControl}
                />
              </Form.Group>

              <Form.Group controlId="youtubePageUrl" className={'mrgBottom50'}>
                <Form.Label>
                  <strong>What's {formData.name}'s YouTube page?</strong>
                </Form.Label>

                <Form.Control
                  type="text"
                  placeholder="e.g. https://youtube.com/channel/dfsdfs"
                  onChange={this.setField('youtubePageUrl')}
                  value={this.state.formData.youtubePageUrl}
                  className={styles.formControl}
                />
              </Form.Group>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="col-md-12"
            style={{
              textAlign: 'center',
              marginTop: '30px',
              marginBottom: '30px'
            }}
          >
            {!addingCompetitor && (
              <Button type="submit" rounded primary>
                Add competitor
              </Button>
            )}

            {!addingCompetitor && (
              <Button
                style={{ marginLeft: '10px' }}
                rounded
                secondary
                onClick={this.clearState}
              >
                Go back
              </Button>
            )}

            {addingCompetitor && (
              <Spinner
                style={{
                  height: '20px',
                  width: '20px',
                  fontSize: '8px',
                  marginRight: '10px'
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  renderStepData = () => {
    const { step, hasCheckedAdd } = this.state;

    if (!hasCheckedAdd) {
      return (
        <div className="row">
          <div className="col-md-12">
            <Spinner center />
          </div>
        </div>
      );
    }

    if (this.state.outOfCredits) {
      return (
        <div className="row">
          <div className="col-md-12">
            <Alert variant="danger">
              <strong>Out of credits</strong>. Please remove a competitor or{' '}
              <Link to={'/plan'}>upgrade your plan</Link> to add another
              competitor.
            </Alert>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="row">
          <div className={cx('col-md-12', styles.titleText)}>
            {this.renderTitleForStep()}
          </div>
        </div>

        <Form onSubmit={e => this.submitForm(e)}>
          {step === 1 && this.renderStepOne()}
          {step === 2 && this.renderStepTwo()}
        </Form>
      </div>
    );
  };

  render() {
    return <div>{this.renderStepData()}</div>;
  }
}

const mapStateToProps = state => competitorAddPageSelector(state);
export default connect(mapStateToProps, {
  scoutUrl,
  clearScoutState,
  addCompetitor,
  verifyCanAddLimitType
})(AccountSetupWizardCompetitorStep);
