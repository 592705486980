import { put, call, takeLatest } from 'redux-saga/effects';
import {
  REQUEST_COMPETITOR_CONFIG,
  receiveCompetitorConfig,
  handleBadCompetitorConfigResponse
} from 'actions/competitorConfigActions';
import { createAxios } from 'utils/axios/axiosUtils';

export default function* fetchCompetitorConfigSaga() {
  yield takeLatest(REQUEST_COMPETITOR_CONFIG, fetchCompetitorConfig);
}

function* fetchCompetitorConfig({ competitorId }) {
  try {
    const competitorResponse = yield call(getCompetitorsResponse, competitorId);
    if (competitorResponse.data && competitorResponse.data.config) {
      yield put(
        receiveCompetitorConfig(
          competitorId.toString(),
          competitorResponse.data.config
        )
      );
    }
  } catch (e) {
    console.warn(e);

    yield put(handleBadCompetitorConfigResponse());
  }
}

async function getCompetitorsResponse(competitorId) {
  return await createAxios().get('competitors-config/' + competitorId);
}
