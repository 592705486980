import { Map, fromJS } from 'immutable';
import {
  FETCH_OVERVIEW_DATA,
  RECEIVE_OVERVIEW_DATA,
  HANDLE_OVERVIEW_LOADING_ERROR
} from '../actions/overviewActions';

const initialState = fromJS({
  data: Map({}),
  isLoading: false,
  loadingError: false
});

export default function overviewReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_OVERVIEW_DATA:
      return state
        .set('isLoading', true)
        .set('loadingError', null)
        .set('data', Map({}));
    case RECEIVE_OVERVIEW_DATA:
      return state
        .set('isLoading', false)
        .set('loadingError', null)
        .set('data', fromJS(action.data));
    case HANDLE_OVERVIEW_LOADING_ERROR:
      return state
        .set('isLoading', false)
        .set('loadingError', action.error)
        .set('data', Map({}));
    default:
      return state;
  }
}
