export const exampleCompetitorData = {
  32: {
    competitorId: 32,
    normalisedDomain: 'aviato.com',
    name: 'Aviato',
    faviconUrl: 'https://www.google.com/s2/favicons?domain=aviato.com'
  },
  33: {
    competitorId: 33,
    normalisedDomain: 'hooli.com',
    name: 'Hooli',
    faviconUrl: 'https://www.google.com/s2/favicons?domain=hooli.com'
  }
};

export const possibleUpdates = [
  {
    eventTypeId: '',
    text: 'All updates',
    eventTypes: [],
    defaultChecked: true
  },
  {
    eventTypeId: 'website-updates',
    text: 'Website updates',
    eventTypes: [
      'webPageInit',
      'webPageAdded',
      'webPageUpdated',
      'webPageRemoved'
    ]
  },
  {
    eventTypeId: 'new-webpages',
    text: 'New webpages',
    eventTypes: ['webPageAdded'],
    isChild: true
  },
  {
    eventTypeId: 'updated-webpages',
    text: 'Updated webpages',
    eventTypes: ['webPageUpdated'],
    isChild: true
  },
  {
    eventTypeId: 'removed-webpages',
    text: 'Removed webpages',
    eventTypes: ['webPageRemoved'],
    isChild: true
  },
  {
    eventTypeId: 'social-updates',
    text: 'Social updates',
    eventTypes: ['tweetAdded', 'facebookPostAdded', 'youTubeVideoAdded']
  },
  {
    eventTypeId: 'facebook-updates',
    text: 'Facebook updates',
    eventTypes: ['facebookPostAdded'],
    isChild: true
  },
  {
    eventTypeId: 'twitter-updates',
    text: 'Twitter updates',
    eventTypes: ['tweetAdded'],
    isChild: true
  },
  {
    eventTypeId: 'youtube-updates',
    text: 'YouTube updates',
    eventTypes: ['youTubeVideoAdded'],
    isChild: true
  },
  {
    eventTypeId: 'keyword-updates',
    text: 'Keyword updates',
    eventTypes: [
      'keywordNewRanking',
      'keywordRankingUpdate',
      'serpAdFound',
      'serpCompetitorFound',
      'competitorFound'
    ]
  },
  {
    eventTypeId: 'email-updates',
    text: 'Email updates',
    eventTypes: ['newsletterUpdate']
  },
  {
    eventTypeId: 'advert-updates',
    text: 'Advertisement updates',
    eventTypes: ['facebookAdAdded', 'serpAdFound']
  }
];
