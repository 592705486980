import React, { Component } from 'react';
import Block from 'components/presentation/block';
import Icon from 'components/fontAwesome/icon';

export default class UnrankedKeywordsWidget extends Component {
  renderKeywordRows = () => {
    const { keywords } = this.props;

    return keywords.map(keyword => {
      return (
        <tr key={keyword}>
          <td>
            {keyword}
            {keyword.host && <div>{keyword.host}</div>}
          </td>
        </tr>
      );
    });
  };

  renderContent = () => {
    const { keywords } = this.props;

    if (!keywords.length) {
      return (
        <div>
          <Icon
            name="fas fa-exclamation-triangle"
            style={{ color: 'orange', marginRight: '5px' }}
          />{' '}
          No unranked keywords detected.
        </div>
      );
    }

    return (
      <table className="table table-striped">
        <tbody>{this.renderKeywordRows()}</tbody>
      </table>
    );
  };

  render() {
    const title = this.props.title;

    return (
      <Block
        title={title || 'Unranked keywords'}
        help="View keywords you have specified that neither you or your competitors are currently ranking for. These may be keywords that are no use, or keywords that are easy to target without competition."
      >
        {this.renderContent()}
      </Block>
    );
  }
}
