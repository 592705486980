import React, { Component } from 'react';
import MainSidebar from 'components/sidebar/mainSidebar';
import cx from 'classnames';
import styles from 'containers/loggedInContainer.module.scss';
import NoticeBar from 'components/sidebar/noticeBar';
import GlobalModal from 'components/modal/globalModal';

class InterfaceWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = { showSidebar: this.shouldShowSidebar() };
  }

  shouldShowSidebar = () => {
    return window.innerWidth >= 992;
  };

  determineSidebarState = () => {
    if (this.shouldShowSidebar()) {
      this.setState({ showSidebar: true });
    } else {
      this.setState({ showSidebar: false });
    }
  };

  componentDidMount() {
    window.addEventListener('resize', this.determineSidebarState);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.determineSidebarState);
  }

  toggleSidebar = () => {
    this.setState({ showSidebar: this.state.showSidebar ? false : true });
  };

  render() {
    const { showSidebar } = this.state;
    const { history, children } = this.props;

    return (
      <div
        className={cx(styles.page, {
          [styles.pageNoSidebar]: !showSidebar,
          [styles.pageFixed]: showSidebar
        })}
      >
        {showSidebar && (
          <MainSidebar history={history} toggleSidebar={this.toggleSidebar} />
        )}

        <GlobalModal />

        <div className={cx('animated fadeIn')}>
          <NoticeBar
            history={history}
            showSidebar={showSidebar}
            toggleSidebar={this.toggleSidebar}
          />

          {children}
        </div>
      </div>
    );
  }
}

export default InterfaceWrapper;
