import React, { Component } from 'react';
import Block from 'components/presentation/block';
import Spinner from 'components/spinner/spinner';
import Alert from 'components/alert/alert';
import Form from 'react-bootstrap/Form';
import moment from 'moment';
import Badge from 'react-bootstrap/Badge';
import { getRoleRank } from 'utils/permissions/permissions';
import Button from 'components/button/button';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

const DEFAULT_INVITE_FIELDS = { name: '', email: '', role: 'ROLE_USER' };

class AccountUserManagementPage extends Component {
  state = { inviteFields: Object.assign({}, DEFAULT_INVITE_FIELDS) };

  triggerDeleteForUser = userId => () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, I am sure!'
    }).then(result => {
      if (result.value) {
        this.props.attemptDeleteUser(userId);
      }
    });
  };

  showSwalError = reason => {
    Swal.fire({
      title: 'These details are invalid',
      text: reason,
      type: 'error'
    });
  };

  sendInviteEmail = () => {
    const inviteFields = this.state.inviteFields;

    if (inviteFields.name.length < 3) {
      this.showSwalError('You must provide a valid name.');
      return;
    } else if (
      inviteFields.email.length < 3 ||
      inviteFields.email.indexOf('@') === -1 ||
      inviteFields.email.indexOf('.') === -1
    ) {
      this.showSwalError('You must provide a valid email address.');
      return;
    } else if (!inviteFields.role.length) {
      this.showSwalError('You must provide a valid role.');
      return;
    }

    this.props.attemptCreateUser(inviteFields, () => {
      this.setState({ inviteFields: DEFAULT_INVITE_FIELDS });
    });
  };

  setInviteField = field => e => {
    const inviteFields = this.state.inviteFields;
    inviteFields[field] = e.target.value;
    this.setState({ inviteFields });
  };

  renderUserRow = user => {
    const { authUserId } = this.props;

    const lastActive =
      user.get('lastActive') > 0
        ? moment.unix(user.get('lastActive')).format('MMMM Do YYYY, h:mm a')
        : 'Never logged in';

    return (
      <tr key={user.get('userId')}>
        <td style={{ wordBreak: 'break-all' }}>
          <div>{user.get('name')}</div>
          <div style={{ color: '#999', fontSize: '0.9em' }}>
            {user.get('email')}
          </div>
        </td>
        <td>{this.renderRoleTd(user.get('role'))}</td>
        <td className="d-none d-lg-table-cell">{lastActive}</td>
        <td style={{ textAlign: 'right' }}>
          <div>
            <Link to={'/user-settings/' + user.get('userId')}>
              <Button thin primary>
                Edit
              </Button>
            </Link>{' '}
            {user.get('userId') !== authUserId && (
              <Button
                onClick={this.triggerDeleteForUser(user.get('userId'))}
                thin
                danger
              >
                Delete
              </Button>
            )}
          </div>
        </td>
      </tr>
    );
  };

  renderRoleTd = role => {
    switch (role) {
      case 'ROLE_JESSLE':
        return <Badge variant="danger">Superuser</Badge>;
      case 'ROLE_ACCOUNT_ADMIN':
        return <Badge variant="warning">Admin</Badge>;
      case 'ROLE_ACCOUNT_OWNER':
        return <Badge variant="primary">Owner</Badge>;
      default:
        return <Badge variant="info">User</Badge>;
    }
  };

  renderAvailableRoleOptions = () => {
    const { authUserRole } = this.props;

    const authUserRoleRank = getRoleRank(authUserRole);

    const options = [
      <option
        key="ao"
        value="ROLE_ACCOUNT_OWNER"
        disabled={authUserRoleRank < 3}
      >
        Account owner
      </option>,
      <option
        key="aa"
        value="ROLE_ACCOUNT_ADMIN"
        disabled={authUserRoleRank < 2}
      >
        Account admin
      </option>,
      <option key="user" value="ROLE_USER">
        User
      </option>
    ];

    if (authUserRole === 'ROLE_JESSLE') {
      return [
        <option key="su" value="ROLE_JESSLE" disabled={authUserRoleRank < 999}>
          Superuser
        </option>,
        ...options
      ];
    }

    return options;
  };

  renderInviteAUser = () => {
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <Form.Group controlId="name">
              <Form.Label>
                <strong>Full name</strong>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="e.g. Fred Bloggs"
                onChange={this.setInviteField('name')}
                value={this.state.inviteFields.name}
              />
            </Form.Group>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <Form.Group controlId="email">
              <Form.Label>
                <strong>Email address</strong>
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="e.g. support@competito.app"
                onChange={this.setInviteField('email')}
                value={this.state.inviteFields.email}
              />
            </Form.Group>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <Form.Group controlId="name">
              <Form.Label>
                <strong>Role</strong>
              </Form.Label>
              <Form.Control
                as="select"
                onChange={this.setInviteField('role')}
                value={this.state.inviteFields.role}
              >
                {this.renderAvailableRoleOptions()}
              </Form.Control>
            </Form.Group>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <Button
              size="sm"
              primary
              type="submit"
              style={{ marginBottom: '20px' }}
              onClick={this.sendInviteEmail}
            >
              Send invite
            </Button>
          </div>
        </div>
      </div>
    );
  };

  renderManageUsers = () => {
    const { users, usersLoading, usersLoadingError } = this.props;

    if (usersLoadingError) {
      return (
        <Alert variant="danger">
          Their was an issue loading users, please try again shortly.
        </Alert>
      );
    }

    if (usersLoading) {
      return <Spinner center />;
    }

    if (!users.size) {
      return (
        <Alert varianet="info">
          You do not have any users for this account.
        </Alert>
      );
    }

    const rows = [];
    users.forEach(user => {
      rows.push(this.renderUserRow(user));
    });

    return (
      <table className="table table-striped">
        <thead>
          <tr>
            <th>User</th>
            <th>Role</th>
            <th className="d-none d-lg-table-cell">Last active</th>
            <th style={{ textAlign: 'right' }}>Actions</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  };

  render() {
    return (
      <div>
        <div className="row" style={{ marginBottom: '30px' }}>
          <div className="col-xl-12" style={{ textAlign: 'center' }}>
            <h1 className={'h4'} style={{ marginBottom: 0 }}>
              User management
            </h1>
            <h2 className={'h5 textLighter thin'}>
              This area allows you to invite, edit and remove users.
            </h2>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <Block title={'Manage my users'}>{this.renderManageUsers()}</Block>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <Block title={'Invite a user'}>{this.renderInviteAUser()}</Block>
          </div>
        </div>
      </div>
    );
  }
}

export default AccountUserManagementPage;
