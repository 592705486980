import { createSelector } from 'reselect';
import { authUserSelector, authTokenSelector } from 'selectors/authSelectors';
import { currentProfileSelector } from 'selectors/profileSelectors';
import { integrationDataSelector } from 'selectors/integrationSelectors';

export const accountUserManagementPageSelector = createSelector(
  state => state.account,
  state => state.user,
  authUserSelector,
  currentProfileSelector,
  (accountState, userState, authUser, currentProfile) => {
    return {
      users: userState.get('data'),
      usersLoading: userState.get('loadingAll'),
      usersLoadingError: userState.get('loadingAllError'),
      authUserId: authUser.get('userId'),
      authUserRole: authUser.get('role'),
      currentProfileId: currentProfile && currentProfile.get('profileId')
    };
  }
);

export const accountIntegrationsPageSelector = createSelector(
  currentProfileSelector,
  integrationDataSelector,
  authTokenSelector,
  (currentProfile, integrations, authToken) => {
    return {
      currentProfileId: currentProfile && currentProfile.get('profileId'),
      integrations,
      authToken
    };
  }
);

export const hasExpiredSelector = createSelector(
  state => state.auth,
  authState => {
    const servicesSummary = authState.get('servicesSummary');
    return (
      !servicesSummary ||
      !servicesSummary.find(service => service.get('isActive'))
    );
  }
);

export const activeServiceSelector = createSelector(
  state => state.auth,
  authState => {
    const servicesSummary = authState.get('servicesSummary');
    const activeService = servicesSummary.find(service =>
      service.get('isActive')
    );
    return typeof activeService === 'object' ? activeService : null;
  }
);
