import { createSelector } from 'reselect';

export const scoutUrlInformationSelector = createSelector(
  state => state.scoutUrl,
  scoutUrlData => {
    return {
      scoutingDomain: scoutUrlData.get('verifying'),
      scoutingError: scoutUrlData.get('error'),
      scoutingResponse: scoutUrlData.get('data'),
      verifiedDomain: scoutUrlData.get('verified')
    };
  }
);
