export const FETCH_KEYWORD_SUGGESTIONS_FOR_DOMAIN_IF_REQUIRED =
  'app:FETCH_KEYWORD_SUGGESTIONS_FOR_DOMAIN_IF_REQUIRED';
export function fetchKeywordSuggestionsForDomainIfRequired(domain) {
  return {
    type: FETCH_KEYWORD_SUGGESTIONS_FOR_DOMAIN_IF_REQUIRED,
    domain
  };
}

export const FETCH_KEYWORD_SUGGESTIONS_FOR_DOMAIN =
  'app:FETCH_KEYWORD_SUGGESTIONS_FOR_DOMAIN';
export function fetchKeywordSuggestionsForDomain(domain) {
  return {
    type: FETCH_KEYWORD_SUGGESTIONS_FOR_DOMAIN,
    domain
  };
}

export const RECEIVE_KEYWORD_SUGGESTIONS_FOR_DOMAIN =
  'app:RECEIVE_KEYWORD_SUGGESTIONS_FOR_DOMAIN';
export function receiveKeywordSuggestionsForDomain(domain, suggestions) {
  return {
    type: RECEIVE_KEYWORD_SUGGESTIONS_FOR_DOMAIN,
    domain,
    suggestions
  };
}

export const HANDLE_KEYWORD_SUGGESTIONS_FOR_DOMAIN_ERROR =
  'app:HANDLE_KEYWORD_SUGGESTIONS_FOR_DOMAIN_ERROR';
export function handleKeywordSuggestionsForDomainError(domain) {
  return {
    type: HANDLE_KEYWORD_SUGGESTIONS_FOR_DOMAIN_ERROR,
    domain
  };
}
