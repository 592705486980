import React, { Component } from 'react';
import CentralWrapper from 'components/loginWrappers/centralWrapper';
import { eventNotificationSettingsPageSelector } from 'selectors/authSelectors';
import { connect } from 'react-redux';
import { fetchUserChannelData, updateUserChannel } from 'actions/authActions';
import { toggleNotificationSubscriptionsForProfile } from 'actions/eventNotificationSubscriptionActions';
import EventNotificationsSettingsPage from 'components/page/eventNotificationsSettingsPage';
import Spinner from 'components/spinner/spinner';

const TITLE_DEFAULT = 'My notification settings | Competito';

class EventNotificationSettingsContainer extends Component {
  UNSAFE_componentWillMount() {
    document.title = TITLE_DEFAULT;

    this.props.fetchUserChannelData();
  }

  render() {
    const { notificationChannel } = this.props;

    return (
      <CentralWrapper>
        {!notificationChannel && <Spinner center />}
        {notificationChannel && (
          <EventNotificationsSettingsPage {...this.props} />
        )}
      </CentralWrapper>
    );
  }
}

const mapStateToProps = state => eventNotificationSettingsPageSelector(state);
export default connect(mapStateToProps, {
  fetchUserChannelData,
  updateUserChannel,
  toggleNotificationSubscriptionsForProfile
})(EventNotificationSettingsContainer);
