export const LAUNCH_MODAL = 'app:LAUNCH_MODAL';
export function launchModal(modalComponent) {
  return {
    type: LAUNCH_MODAL,
    modalComponent
  };
}

export const CLEAR_MODAL = 'app:CLEAR_MODAL';
export function clearModal() {
  return {
    type: CLEAR_MODAL
  };
}
