import React from 'react';
import TimelineEventWrapper from '../timelineEventWrapper';
import { pluralise } from 'utils/numberUtils';
import Icon from 'components/fontAwesome/icon';

export function getEventTitle(name, positionsChange) {
  const title =
    name +
    pluralise(
      positionsChange,
      ` ${positionsChange > 0 ? 'gained' : 'lost'} a keyword position`,
      ` ${
        positionsChange > 0 ? 'gained' : 'lost'
      } ${positionsChange.toString().replace('-', '')} keyword positions`
    );

  return title;
}

function RenderDelta(delta, showOld) {
  if (delta === 0) {
    return null;
  }

  if (delta > 0) {
    return (
      <span>
        <Icon
          style={{ marginLeft: '3px', color: 'green' }}
          name="fas fa-caret-up"
        />
        {showOld && (
          <span style={{ marginLeft: '5px', color: '#bbb' }}>{delta}</span>
        )}
      </span>
    );
  }

  return (
    <span>
      <Icon
        style={{ marginLeft: '3px', color: 'red' }}
        name="fas fa-caret-down"
      />
      {showOld && (
        <span style={{ marginLeft: '5px', color: '#bbb' }}>
          {delta.toString().replace('-', '')}
        </span>
      )}
    </span>
  );
}

function RenderKeyword({ keyword }) {
  return (
    <tr key={keyword.keyword}>
      <td>{keyword.keyword}</td>
      <td style={{ textAlign: 'center' }}>
        <a href={keyword.link} target="_blank" rel="noopener noreferrer">
          View page
        </a>
      </td>
      <td style={{ textAlign: 'right' }}>{keyword.rank}</td>
      <td style={{ textAlign: 'right' }}>{RenderDelta(keyword.delta, true)}</td>
    </tr>
  );
}

export default function KeywordRankingUpdateEvent(props) {
  const keywords = props.event.props.items;
  const positionsChange = props.event.props.positionsChange;
  const name = props.event.referenceName;

  let displayKeywords = [];
  let displayCounter = 1;

  const filteredKeywords = keywords
    .sort((a, b) => (a.delta < b.delta ? 1 : -1))
    .slice(0, props.isInspector ? 500 : 10);

  filteredKeywords.forEach(keyword => {
    displayKeywords.push(
      <RenderKeyword
        key={displayCounter}
        keyword={keyword}
        name={name}
        isSingleEvent={keywords.length === 1}
      />
    );
    displayCounter++;
  });

  return (
    <TimelineEventWrapper
      title={getEventTitle(name, positionsChange)}
      buttonText={keywords.length > 10 ? 'Show all changes' : 'View details'}
      {...props}
    >
      <div className="row">
        <div className="col-md-12">
          <div style={{ padding: '10px 20px' }}>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Keyword</th>
                  <th style={{ textAlign: 'center' }}> </th>
                  <th style={{ textAlign: 'right' }}>Position</th>
                  <th style={{ textAlign: 'right' }}>Change</th>
                </tr>
              </thead>
              <tbody>{displayKeywords}</tbody>
            </table>
          </div>
        </div>
      </div>
    </TimelineEventWrapper>
  );
}
