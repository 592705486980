import { Map, fromJS } from 'immutable';
import {
  STORE_RPOFILES,
  SET_CURRENT_PROFILE_ID,
  ADD_PROFILE,
  HANDLE_ADD_PROFILE_FAILURE,
  HANDLE_PROFILE_ADD_RESPONSE
} from 'actions/profileActions';
import { STORE_LOGIN } from 'actions/authActions';
import {
  getCurrentProfileId,
  setCurrentProfileId
} from 'utils/localStorage/localStorage';

const initialState = fromJS({
  data: Map({}),
  currentProfileId: getCurrentProfileId(),
  loading: true,
  addingProfile: false
});

export default function profileReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_PROFILE_ID:
      setCurrentProfileId(action.profileId);
      return state.set('currentProfileId', action.profileId);
    case STORE_LOGIN:
      return state
        .set('loading', false)
        .set('data', fromJS(action.data.profiles));
    case STORE_RPOFILES:
      return state.set('loading', false).set('data', fromJS(action.profiles));
    case ADD_PROFILE:
      return state.set('addingProfile', true);
    case HANDLE_ADD_PROFILE_FAILURE:
      return state.set('addingProfile', false);
    case HANDLE_PROFILE_ADD_RESPONSE:
      const currentProfileId = action.response.profileId.toString();

      setCurrentProfileId(action.response.profileId);

      if (state.get('data').size === 0) {
        return state
          .set('currentProfileId', currentProfileId)
          .set('addingProfile', false)
          .set('data', Map({ [currentProfileId]: fromJS(action.response) }));
      }

      return state
        .set('currentProfileId', currentProfileId)
        .set('addingProfile', false)
        .setIn(['data', currentProfileId], fromJS(action.response));
    default:
      return state;
  }
}
