import { List } from 'immutable';
import { createSelector } from 'reselect';
import {
  simpleProfilesSelector,
  currentProfileIdSelector
} from 'selectors/profileSelectors';
import { notificationSubscriptionDatapSelector } from 'selectors/eventNotificationSubscriptionSelectors';

export const authSelector = state => state.auth;

export const appSelector = createSelector(authSelector, auth => {
  return {
    isLoggedIn: auth.get('isLoggedIn'),
    isAppReady: auth.get('appIsReady'),
    user: auth.get('user')
  };
});

export const isLoggedInSelector = createSelector(authSelector, auth =>
  auth.get('isLoggedIn')
);

export const authUserSelector = createSelector(authSelector, auth => {
  return auth.get('user');
});

export const authUserRoleSelector = createSelector(
  authUserSelector,
  authUser => {
    return authUser.get('role');
  }
);

export const authUserExampleModeSelector = createSelector(
  authUserSelector,
  user => {
    return user.get('exampleMode');
  }
);

export const authTokenSelector = createSelector(authSelector, auth => {
  return auth.get('authToken');
});

export const authAccountSelector = createSelector(authSelector, auth => {
  return auth.get('account');
});

export const authAccountIdSelector = createSelector(authSelector, auth => {
  return auth.get('account').get('accountId');
});

export const authUserSettingsPageSelector = createSelector(
  authSelector,
  auth => {
    return {
      user: auth.get('user')
    };
  }
);

export const accountProfileSelector = createSelector(
  authSelector,
  currentProfileIdSelector,
  (auth, currentProfileId) => {
    return {
      accountId: auth.get('user').get('accountId'),
      profileId: currentProfileId
    };
  }
);

export const eventNotificationSettingsPageSelector = createSelector(
  authSelector,
  simpleProfilesSelector,
  notificationSubscriptionDatapSelector,
  (auth, simpleProfiles, { notificationSubscriptionData }) => {
    const notificationChannel = auth.get('notificationChannel');

    return {
      user: auth.get('user'),
      profiles: simpleProfiles,
      notificationChannel: notificationChannel,
      channelSubscriptions: notificationChannel
        ? notificationSubscriptionData.get(
            notificationChannel.get('channelId').toString()
          )
        : List()
    };
  }
);

export const authSubscriptionSelector = createSelector(authSelector, auth => {
  return auth.get('subscription');
});

export const gatekeeperSelector = createSelector(authSelector, auth => {
  const user = auth.get('user');
  return {
    user,
    isDemoAccount: user && user.get ? user.get('accountId') === 17 : false,
    isExampleMode: user && user.get && user.get('exampleMode')
  };
});

export const loggedInLaunchTriggersSelector = createSelector(
  authUserRoleSelector,
  role => {
    return {
      role: role
    };
  }
);
