import React, { Component } from 'react';
import sparkline from '@fnando/sparkline';

export default class Sparkline extends Component {
  componentDidMount() {
    this.redraw();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.redraw();
  }

  redraw = () => {
    const { data } = this.props;

    const that = this;

    sparkline(this.sparkline, data, {
      interactive: true,
      onmousemove(event, datapoint) {
        const tooltip = that.tooltip;
        tooltip.hidden = false;
        tooltip.textContent = `${datapoint.value}`;
        tooltip.style.top = `${event.offsetY}px`;
        tooltip.style.left = `${event.offsetX + 20}px`;
      },

      onmouseout() {
        const tooltip = that.tooltip;
        tooltip.hidden = true;
      }
    });
  };

  render() {
    return (
      <div style={{ position: 'relative' }}>
        <svg
          ref={input => (this.sparkline = input)}
          className="sparkline"
          width="100"
          height="30"
          strokeWidth="3"
        ></svg>
        <span
          ref={input => (this.tooltip = input)}
          style={{
            position: 'absolute',
            background: 'rgba(0, 0, 0, .7)',
            color: '#fff',
            padding: '2px 5px',
            fontSize: '12px',
            whiteSpace: 'nowrap',
            zIndex: 9999
          }}
          hidden={true}
        ></span>
      </div>
    );
  }
}
