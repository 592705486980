var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as Hogan from 'hogan.js';
import { defaultTemplates } from './diff2html-templates';
var HoganJsUtils = (function () {
    function HoganJsUtils(_a) {
        var _b = _a.compiledTemplates, compiledTemplates = _b === void 0 ? {} : _b, _c = _a.rawTemplates, rawTemplates = _c === void 0 ? {} : _c;
        var compiledRawTemplates = Object.entries(rawTemplates).reduce(function (previousTemplates, _a) {
            var _b;
            var name = _a[0], templateString = _a[1];
            var compiledTemplate = Hogan.compile(templateString, { asString: false });
            return __assign(__assign({}, previousTemplates), (_b = {}, _b[name] = compiledTemplate, _b));
        }, {});
        this.preCompiledTemplates = __assign(__assign(__assign({}, defaultTemplates), compiledTemplates), compiledRawTemplates);
    }
    HoganJsUtils.compile = function (templateString) {
        return Hogan.compile(templateString, { asString: false });
    };
    HoganJsUtils.prototype.render = function (namespace, view, params, partials, indent) {
        var templateKey = this.templateKey(namespace, view);
        try {
            var template = this.preCompiledTemplates[templateKey];
            return template.render(params, partials, indent);
        }
        catch (e) {
            throw new Error("Could not find template to render '" + templateKey + "'");
        }
    };
    HoganJsUtils.prototype.template = function (namespace, view) {
        return this.preCompiledTemplates[this.templateKey(namespace, view)];
    };
    HoganJsUtils.prototype.templateKey = function (namespace, view) {
        return namespace + "-" + view;
    };
    return HoganJsUtils;
}());
export default HoganJsUtils;
