import React, { Component } from 'react';
import CentralWrapper from 'components/loginWrappers/centralWrapper';
import { editUserPageSelector } from 'selectors/userSelectors';
import { connect } from 'react-redux';
import UserSettingsPage from 'components/page/userSettingsPage';
import { attemptUpdateUser, fetchAllUsers } from 'actions/userActions';
import Spinner from 'components/spinner/spinner';
import Alert from 'components/alert/alert';

const TITLE_DEFAULT = 'Edit user settings | Competito';

class EditUserContainer extends Component {
  UNSAFE_componentWillMount() {
    document.title = TITLE_DEFAULT;
  }

  render() {
    const { usersLoading, usersLoadingError } = this.props;

    if (usersLoading || !this.props.user) {
      return (
        <CentralWrapper>
          <Spinner center />
        </CentralWrapper>
      );
    }

    if (usersLoadingError) {
      return (
        <CentralWrapper>
          <Alert variant="danger">
            Failed to load this user, please try again shortly.
          </Alert>
        </CentralWrapper>
      );
    }

    return (
      <CentralWrapper>
        <UserSettingsPage {...this.props} />
      </CentralWrapper>
    );
  }
}

const mapStateToProps = (state, props) => editUserPageSelector(state, props);
export default connect(mapStateToProps, { attemptUpdateUser, fetchAllUsers })(
  EditUserContainer
);
