import React, { Component } from 'react';
import throttle from 'lodash/throttle';

class InfiniteScroll extends Component {
  componentDidMount() {
    this.scrollHandler = throttle(
      this.checkWindowScroll,
      this.props.throttle || 64
    );
    this.resizeHandler = throttle(
      this.checkWindowScroll,
      this.props.throttle || 64
    );

    window.addEventListener('scroll', this.scrollHandler);
    window.addEventListener('resize', this.resizeHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollHandler);
    window.removeEventListener('resize', this.resizeHandler);
  }

  componentDidUpdate() {
    // This fixes edge case where initial content is not enough to enable scrolling on a large screen.
    this.checkWindowScroll();
  }

  checkWindowScroll = () => {
    if (this.props.isLoading) {
      return;
    }

    let threshold = this.props.threshold || 100;

    if (
      this.props.hasMore &&
      this.sentinel.getBoundingClientRect().top - window.innerHeight < threshold
    ) {
      this.props.onLoadMore();
    }
  };

  render() {
    return (
      <div>
        {this.props.children}
        <div ref={i => (this.sentinel = i)}></div>
      </div>
    );
  }
}

export default InfiniteScroll;
