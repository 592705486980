import React from 'react';
import TimelineEventWrapper from '../timelineEventWrapper';
import { pluralise } from 'utils/numberUtils';
import WebsiteItem from 'components/website/websiteItem';
import styles from './webPageAddedEvent.module.scss';
import cx from 'classnames';

export function getEventTitle(name, pages) {
  const title =
    name +
    ' added ' +
    pluralise(pages.length, 'a web page', pages.length + ' web pages');

  return title;
}

export default function WebPageAddedEvent(props) {
  const pages = props.event.props.pages;
  const name = props.event.referenceName;

  const slicedPages = props.isInspector ? pages : pages.slice(0, 5);

  return (
    <TimelineEventWrapper
      title={getEventTitle(name, pages)}
      buttonText={pages.length > 5 ? 'Show all pages' : 'View details'}
      {...props}
    >
      <div className="row">
        <div className="col-md-12">
          <div className={cx({ [styles.scroll]: props.isInspector })}>
            <ul className={styles.addedList}>
              {slicedPages.map(page => (
                <li key={page.url}>
                  <WebsiteItem
                    screenshotUrl={page.viewportPageScreenshotLocation}
                    url={page.url}
                    large
                    pageName={page.pageName}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </TimelineEventWrapper>
  );
}
