import { call, select, takeLatest } from 'redux-saga/effects';
import { ATTEMPT_UPDATE_CURRENT_PROFILE } from 'actions/profileActions';
import { createAxios } from 'utils/axios/axiosUtils';
import Swal from 'sweetalert2';
import { currentProfileSelector } from 'selectors/profileSelectors';

export default function* updateCurrentProfileSaga() {
  yield takeLatest(ATTEMPT_UPDATE_CURRENT_PROFILE, updateCurrentProfile);
}

function* updateCurrentProfile({ data, cb }) {
  const profile = yield select(currentProfileSelector);
  if (!profile || !profile.size) {
    return;
  }

  try {
    const response = yield call(
      attemptUpdateResponse,
      profile.get('profileId'),
      data
    );
    if (response.data && response.data.success) {
      window.location.href = '/';
    }
  } catch (e) {
    console.warn(e);
    if (
      e.response &&
      e.response.data &&
      !e.response.data.success &&
      e.response.data.errorMessage
    ) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: e.response.data.errorMessage
      });
    } else {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text:
          'Something went wrong trying to update this Profile. Try again later.'
      });
    }
  }
  cb();
}

async function attemptUpdateResponse(profileId, data) {
  return await createAxios().put('profiles/' + profileId, data);
}
