import { Component } from 'react';
import { connect } from 'react-redux';
import { launchModal } from 'actions/globalModalActions';
import { ROLE_LIVE_DEMO } from 'utils/permissions/permissions';
import { loggedInLaunchTriggersSelector } from 'selectors/authSelectors';
import LiveDemoIntroModal from 'components/modal/liveDemoIntroModal';

class LoggedInLaunchTriggers extends Component {
  componentDidMount() {
    this.launchDemoModalIfRequired(this.props);
  }

  launchDemoModalIfRequired = props => {
    const { role, launchModal } = props;
    if (role === ROLE_LIVE_DEMO) {
      launchModal(LiveDemoIntroModal);
    }
  };

  render() {
    return null;
  }
}

const mapStateToProps = state => loggedInLaunchTriggersSelector(state);
export default connect(
  mapStateToProps,
  { launchModal }
)(LoggedInLaunchTriggers);
