export function withScoutResponse(formData, response) {
  const effectiveUrlScoutResponse = response.get('effectiveUrl');
  formData.domain = effectiveUrlScoutResponse;

  const twitterScoutResponse = response.get('twitterUrl');
  if (twitterScoutResponse && twitterScoutResponse.length) {
    formData.twitterUrl = twitterScoutResponse;
  }

  const facebookScoutResponse = response.get('facebookUrl');
  if (facebookScoutResponse && facebookScoutResponse.length) {
    formData.facebookUrl = facebookScoutResponse;
  }

  const youtubeScoutResponse = response.get('youtubePageUrl');
  if (youtubeScoutResponse && youtubeScoutResponse.length) {
    formData.youtubePageUrl = youtubeScoutResponse;
  }

  const faviconScoutResponse = response.get('faviconUrl');
  if (faviconScoutResponse && faviconScoutResponse.length) {
    formData.faviconUrl = faviconScoutResponse;
  }

  const keywordsScoutResponse = response.get('keywords');
  if (keywordsScoutResponse && keywordsScoutResponse.size) {
    formData.keywords = keywordsScoutResponse.toJS().join('\n');
  }

  return formData;
}
