import React, { Component } from 'react';
import Block from 'components/presentation/block';
import { friendlyUrl } from 'utils/urlUtils';
import Icon from 'components/fontAwesome/icon';

export default class WebsiteStatsForName extends Component {
  renderAddedPages = () => {
    const recentPages = this.props.pageChanges.recentAddedPages;
    return recentPages.map(page => {
      let url = friendlyUrl(page.url);
      return (
        <tr key={'r' + page.pageId}>
          <td>
            <a href={page.url} target="_blank" rel="noopener noreferrer">
              {url.length > 1 ? url : page.url}
            </a>
          </td>
        </tr>
      );
    });
  };

  renderEditedPages = () => {
    const recentPages = this.props.pageChanges.recentEditedPages;
    return recentPages.map(page => {
      return (
        <tr key={'e' + page.pageId}>
          <td>
            <a href={page.url} target="_blank" rel="noopener noreferrer">
              {friendlyUrl(page.url)}
            </a>
          </td>
        </tr>
      );
    });
  };

  render() {
    const { pageChanges } = this.props;
    if (!pageChanges) {
      return null;
    }

    return (
      <div className="row">
        <div className="col-md-6">
          <Block
            title={'Recently added pages'}
            help="View the pages that have been added in the last 30 days by your competitor."
          >
            {pageChanges.recentAddedPages.length !== 0 && (
              <table className="table table-striped">
                <thead>
                  <tr>
                    <td>URL</td>
                  </tr>
                </thead>
                <tbody>{this.renderAddedPages()}</tbody>
              </table>
            )}
            {pageChanges.recentAddedPages.length === 0 && (
              <div>
                <Icon
                  name="fas fa-exclamation-triangle"
                  style={{ color: 'orange', marginRight: '5px' }}
                />{' '}
                No pages have been added recently.
              </div>
            )}
          </Block>
        </div>
        <div className="col-md-6">
          <Block
            title={'Recently edited pages'}
            help="View the pages that have been edited in the last 30 days by your competitor."
          >
            {pageChanges.recentEditedPages.length !== 0 && (
              <table className="table table-striped">
                <thead>
                  <tr>
                    <td>URL</td>
                  </tr>
                </thead>
                <tbody>{this.renderEditedPages()}</tbody>
              </table>
            )}
            {pageChanges.recentEditedPages.length === 0 && (
              <div>
                <Icon
                  name="fas fa-exclamation-triangle"
                  style={{ color: 'orange', marginRight: '5px' }}
                />{' '}
                No pages have been modified recently.
              </div>
            )}
          </Block>
        </div>
      </div>
    );
  }
}
