import { Map, fromJS } from 'immutable';
import { STORE_LOGIN } from 'actions/authActions';
import { STORE_PROFILES_CONFIG } from 'actions/profileConfigActions';
import { HANDLE_UPDATE_KEYWORD_RESPONSE } from 'actions/keywordActions';

const initialState = fromJS({
  data: Map({})
});

export default function profileConfigReducer(state = initialState, action) {
  switch (action.type) {
    case STORE_LOGIN:
      return state.set('data', fromJS(action.data.profilesConfig));
    case STORE_PROFILES_CONFIG:
      return state.set('data', fromJS(action.profilesConfig));
    case HANDLE_UPDATE_KEYWORD_RESPONSE:
      return state.setIn(
        ['data', action.profileId, 'keywords', 'value'],
        action.keywords
      );
    default:
      return state;
  }
}
