import React, { Component } from 'react';
import styles from './simpleStat.module.scss';
import Tooltip from 'react-bootstrap/Tooltip';
import Icon from 'components/fontAwesome/icon';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

class SimpleStat extends Component {
  getIconForDelta = delta => {
    if (delta > 0) {
      return 'fas fa-caret-up';
    }

    if (delta < 0) {
      return 'fas fa-caret-down';
    }

    return 'fas fa-caret-left';
  };

  getColorForDelta = delta => {
    if (delta > 0) {
      return 'lightgreen';
    }

    if (delta < 0) {
      return 'lightred';
    }

    return '#999';
  };

  renderStat = () => {
    const {
      iconName,
      iconSize,
      iconColor,
      stat,
      label,
      delta,
      deltaMessage
    } = this.props;
    return (
      <div className={styles.statBoxContent}>
        <div className={styles.statBoxIcon}>
          <Icon
            style={{ color: iconColor || '#e4e7ed' }}
            name={iconName}
            size={iconSize || '3x'}
          />
        </div>
        <div className={styles.statBoxStat}>{stat}</div>
        <div className={styles.statBoxLabel}>{label}</div>
        {delta !== undefined && (
          <div className={styles.deltaMessage}>
            {delta === '0' && 'No change'}
            {delta !== '0' && delta} {deltaMessage}{' '}
            {delta !== '0' && (
              <Icon
                name={this.getIconForDelta(delta)}
                style={{ color: this.getColorForDelta(delta) }}
              />
            )}
          </div>
        )}
      </div>
    );
  };

  render() {
    const { tooltip, label } = this.props;

    if (!tooltip) {
      return <div className={styles.statBoxWrapper}>{this.renderStat()}</div>;
    }

    return (
      <div className={styles.statBoxWrapper}>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`tooltip-` + label}>{tooltip}</Tooltip>}
        >
          {this.renderStat()}
        </OverlayTrigger>
      </div>
    );
  }
}

export default SimpleStat;
