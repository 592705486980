import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { competitorAddPageSelector } from 'selectors/competitorSelectors';
import { scoutUrl, clearScoutState } from 'actions/scoutUrlActions';
import {
  addCompetitor,
  verifyCanAddLimitType
} from 'actions/competitorActions';
import styles from './loggedInContainer.module.scss';
import Block from 'components/presentation/block';
import Alert from 'components/alert/alert';
import Form from 'react-bootstrap/Form';
import Icon from 'components/fontAwesome/icon';
import { timelinePath } from 'utils/pathUtils';
import Button from 'react-bootstrap/Button';
import Spinner from 'components/spinner/spinner';
import { withScoutResponse } from 'utils/scout/scoutResponseFormBuilder';
import { notOnMobile } from 'utils/appUtils';
import { Link } from 'react-router-dom';

const DEFAULT_FORM_DATA_STATE = {
  name: '',
  domain: '',
  facebookUrl: '',
  twitterUrl: '',
  faviconUrl: '',
  youtubePageUrl: '',
  deniedPaths: '',
  shouldSkipQueryParams: true
};

class CompetitorAddContainer extends Component {
  state = {
    formData: Object.assign({}, DEFAULT_FORM_DATA_STATE),
    showAdvanced: false,
    hasCheckedAdd: false,
    outOfCredits: false,
    isSameAsDomainError: false
  };

  componentDidMount() {
    this.props.verifyCanAddLimitType(
      () => this.setState({ outOfCredits: false, hasCheckedAdd: true }),
      () => this.setState({ outOfCredits: true, hasCheckedAdd: true }),
      'MaxCompetitors'
    );
  }

  componentWillUnmount() {
    this.props.clearScoutState();
  }

  clearState = () => {
    this.setState({
      isSameAsDomainError: false,
      formData: Object.assign({}, DEFAULT_FORM_DATA_STATE)
    });
    this.props.clearScoutState();
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.verifiedDomain && !this.props.verifiedDomain) {
      this.setState({
        formData: withScoutResponse(
          this.state.formData,
          nextProps.scoutingResponse
        )
      });
    }
  }

  submitForm = e => {
    e.preventDefault();
    e.stopPropagation();

    if (this.isSubmitDisabled()) {
      return;
    }

    if (this.isSameAsDomainError()) {
      this.setState({ isSameAsDomainError: true });
      return;
    }

    if (this.props.verifiedDomain) {
      this.props.addCompetitor(this.state.formData, () => {
        this.props.history.push(timelinePath());
      });
    } else {
      this.props.scoutUrl(this.state.formData.domain, true);
    }
  };

  isSameAsDomainError = () => {
    return (
      this.props.currentProfile
        .get('url')
        .indexOf(this.state.formData.domain) !== -1
    );
  };

  setField = fieldName => e => {
    let newData = this.state;
    newData.formData[fieldName] = e.target.value;
    newData.isSameAsDomainError = false;
    this.setState(newData);
  };

  setCheckboxField = fieldName => e => {
    let newData = this.state;
    newData.formData[fieldName] = e.target.checked;
    newData.isSameAsDomainError = false;
    this.setState(newData);
  };

  isSubmitDisabled = () => {
    const { formData, isSameAsDomainError } = this.state;
    const { scoutingDomain, addingCompetitor } = this.props;

    return (
      formData.name.length < 2 ||
      formData.domain.length < 4 ||
      formData.domain.indexOf('.') === -1 ||
      scoutingDomain ||
      addingCompetitor ||
      isSameAsDomainError
    );
  };

  toggleAdvancedFields = () => {
    this.setState({ showAdvanced: !this.state.showAdvanced });
  };

  renderAdvancedFormFields = () => {
    if (!this.state.showAdvanced) {
      return (
        <p
          className={cx('link', 'mrgBottom30')}
          onClick={this.toggleAdvancedFields}
        >
          <Icon name={'fas fa-chevron-right'} /> Show advanced options
        </p>
      );
    }

    return (
      <div>
        <p
          className={cx('link', 'mrgBottom30')}
          onClick={this.toggleAdvancedFields}
        >
          <Icon name={'fas fa-chevron-down'} /> Hide advanced options
        </p>

        <Form.Group controlId="deniedPaths" className={'mrgBottom30'}>
          <Form.Label>
            <strong>Denied URL paths</strong>
          </Form.Label>
          <Form.Control
            type="textarea"
            rows={10}
            as="textarea"
            placeholder="e.g. https://example.com/path/to/ignore"
            onChange={this.setField('deniedPaths')}
            value={this.state.formData.deniedPaths}
          />

          <Form.Text className="text-muted">
            Place each path to ignore on a new line. Each path added here will
            ignore all pages that fall under that path in the URL. This is
            useful for ignoring blogs, search pages and more.
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="shouldSkipQueryParams" className={'mrgBottom30'}>
          <Form.Label>
            <strong>Exclude query parameters</strong>
          </Form.Label>

          <Form.Check
            type="checkbox"
            label="Ignore for new pages"
            onChange={this.setCheckboxField('shouldSkipQueryParams')}
            checked={this.state.formData.shouldSkipQueryParams}
          />
        </Form.Group>
      </div>
    );
  };

  renderVerifiedFormFields = () => {
    return (
      <div>
        <Form.Group controlId="facebookPageUrl" className={'mrgBottom30'}>
          <Form.Label>
            <strong>Facebook page</strong>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="e.g. https://facebook.com/competito"
            onChange={this.setField('facebookUrl')}
            value={this.state.formData.facebookUrl}
          />

          <Form.Text className="text-muted">
            We use this to analyze the Facebook page given to keep you informed
            of any changes and to compare against your own Facebook performance.
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="twitterPageUrl" className={'mrgBottom30'}>
          <Form.Label>
            <strong>Twitter page</strong>
          </Form.Label>

          <Form.Control
            type="text"
            placeholder="e.g. https://twitter.com/competito"
            onChange={this.setField('twitterUrl')}
            value={this.state.formData.twitterUrl}
          />

          <Form.Text className="text-muted">
            We use this to analyze the Twitter page given to keep you informed
            of any changes and to compare against your own Twitter performance.
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="youtubePageUrl" className={'mrgBottom30'}>
          <Form.Label>
            <strong>YouTube page</strong>
          </Form.Label>

          <Form.Control
            type="text"
            placeholder="e.g. https://youtube.com/channel/dfdsfsdf"
            onChange={this.setField('youtubePageUrl')}
            value={this.state.formData.youtubePageUrl}
          />

          <Form.Text className="text-muted">
            We use this to analyze the YouTube page given to keep you informed
            of any changes and to compare against your own YouTube video
            performance.
          </Form.Text>
        </Form.Group>

        {this.renderAdvancedFormFields()}
      </div>
    );
  };

  renderEnterDomain = () => {
    const { verifiedDomain, scoutingError } = this.props;
    const { isSameAsDomainError } = this.state;

    if (verifiedDomain) {
      return (
        <div>
          <div className={'mrgBottom30'}>
            <p>
              <strong>Competitor name</strong>
            </p>
            <p>{this.state.formData.name}</p>
          </div>

          <div className={'mrgBottom30'}>
            <p>
              <strong>Website address</strong>
            </p>
            <p>{this.state.formData.domain}</p>
          </div>
        </div>
      );
    }

    return (
      <div>
        <Form.Group controlId="name" className={'mrgBottom30'}>
          <Form.Label>
            <strong>Competitor name</strong>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="e.g. Competito"
            onChange={this.setField('name')}
            value={this.state.formData.name}
            autoFocus={notOnMobile(true)}
          />
        </Form.Group>

        <Form.Group controlId="domain" className={'mrgBottom30'}>
          <Form.Label>
            <strong>Enter website address</strong>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="e.g. https://competito.app or competito.app"
            onChange={this.setField('domain')}
            value={this.state.formData.domain}
          />

          {isSameAsDomainError && (
            <Form.Text className="text-danger">
              This is the same as your own website. This should be your
              competitors website instead.
            </Form.Text>
          )}

          {!isSameAsDomainError && scoutingError && (
            <Form.Text className="text-danger">{scoutingError}</Form.Text>
          )}
        </Form.Group>
      </div>
    );
  };

  render() {
    const { scoutingDomain, verifiedDomain, addingDomain } = this.props;
    const buttonDisabled = this.isSubmitDisabled();

    if (!this.state.hasCheckedAdd) {
      return (
        <div className={cx(styles.page, styles.pageNoSidebar)}>
          <div className={styles.centerModal}>
            <div className="col-md-12">
              <Spinner center />
            </div>
          </div>
        </div>
      );
    }

    if (this.state.outOfCredits) {
      return (
        <div className={cx(styles.page, styles.pageNoSidebar)}>
          <div className={styles.centerModal}>
            <div className="row">
              <div className="col-md-12">
                <Alert variant="danger">
                  <strong>Out of credits</strong>. Please remove a competitor or{' '}
                  <Link to={'/plan'}>upgrade your plan</Link> to add another
                  competitor.
                </Alert>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={cx(styles.page, styles.pageNoSidebar)}>
        <div className={styles.centerModal}>
          <div className="row">
            <Alert variant="info">
              <strong>Add the details for your competitor</strong>. We will
              periodically scan their online presence and let you know what
              changes are being made.
            </Alert>
          </div>
          <div className="row">
            <Block title={'Add competitor'}>
              <Form onSubmit={e => this.submitForm(e)}>
                {this.renderEnterDomain()}

                {verifiedDomain && this.renderVerifiedFormFields()}

                <Button
                  variant={verifiedDomain ? 'success' : 'info'}
                  size="sm"
                  type="submit"
                  disabled={buttonDisabled}
                  className="btnFlatSmall"
                >
                  {!verifiedDomain && 'Next'}
                  {verifiedDomain && 'Confirm'}
                </Button>

                <Button
                  onClick={this.clearState}
                  variant="light"
                  style={{ marginLeft: '10px' }}
                  size="sm"
                  type="button"
                  disabled={addingDomain}
                  className="btnFlatSmall"
                >
                  Reset
                </Button>

                {scoutingDomain && (
                  <span style={{ marginLeft: '10px' }}>
                    <Spinner
                      style={{ height: '20px', width: '20px', fontSize: '8px' }}
                    />{' '}
                    verifying competitor
                  </span>
                )}

                {addingDomain && (
                  <span style={{ marginLeft: '10px' }}>
                    <Spinner
                      style={{ height: '20px', width: '20px', fontSize: '8px' }}
                    />{' '}
                    adding competitor
                  </span>
                )}
              </Form>
            </Block>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => competitorAddPageSelector(state);
export default connect(mapStateToProps, {
  scoutUrl,
  clearScoutState,
  addCompetitor,
  verifyCanAddLimitType
})(CompetitorAddContainer);
