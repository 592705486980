import LineProgress from 'components/progress/lineProgress';
import React, { Component } from 'react';
import { createAxios } from 'utils/axios/axiosUtils';
import Pusher from 'components/pusher/pusher';

export default class OnboardingRadialProgress extends Component {
  state = { progress: -1 };

  componentDidMount() {
    this.fetchLatestProgress();
  }

  async fetchLatestProgress() {
    const { onTopMarks } = this.props;

    try {
      let response = await createAxios().get('account-onboarding');
      this.setState({ progress: response.data.progress });

      if (response.data.progress === 100 && onTopMarks !== undefined) {
        onTopMarks();
      }
    } catch (e) {}
  }

  render() {
    const { progress } = this.state;
    const { accountId } = this.props;

    if (progress === -1) {
      return null;
    }

    return (
      <div>
        <LineProgress percentage={progress} />

        <Pusher
          channel={accountId + `-channel-onboarding`}
          event={`progressUpdate`}
          onUpdate={() => this.fetchLatestProgress()}
        />
      </div>
    );
  }
}
