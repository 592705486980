import React, { Component } from 'react';
import Alert from 'components/alert/alert';
import styles from './pagesViewer.module.scss';
import Icon from 'components/fontAwesome/icon';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Dropdown from 'react-bootstrap/Dropdown';
import Swal from 'sweetalert2';
import DropdownButton from 'react-bootstrap/DropdownButton';
import TablePagination from 'components/table/tablePagination';
import Badge from 'react-bootstrap/Badge';
import cx from 'classnames';
import { ROLE_ACCOUNT_ADMIN } from 'utils/permissions/permissions';
import Gatekeeper from 'components/permissions/gatekeeper';

class PagesViewer extends Component {
  renderMonitorStatus = row => {
    let icon = (
      <Badge pill variant="warning">
        Unchecked
      </Badge>
    );
    let message = 'This page is not currently being analyzed by Competito.';

    if (row.get('status') !== 200 && row.get('specialStatus') === 'f') {
      icon = (
        <Badge pill variant="info">
          Forced - queued
        </Badge>
      );
      message =
        'This page has been forced and will be tested shortly for the first time by Competito.';
    } else if (row.get('status') === -1) {
      icon = (
        <Badge pill variant="danger">
          Excluded
        </Badge>
      );
      message =
        'This page has been excluded manually from being analyzed by Competito.';
    } else if (row.get('status') === 200) {
      icon = (
        <Badge pill variant="success">
          Analyzing
        </Badge>
      );
      message = 'This page is being analyzed by Competito.';
    }

    return (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={`tooltip-overlay`}>{message}</Tooltip>}
      >
        {icon}
      </OverlayTrigger>
    );
  };

  hasPageAllowanceRemaining = () => {
    const { allowedToMonitorCount, monitoredPageCount } = this.props;
    return allowedToMonitorCount > monitoredPageCount;
  };

  triggerConfirmation = (pageId, operation) => {
    const { monitoredPageCount } = this.props;

    if (operation === 'f' && !this.hasPageAllowanceRemaining()) {
      Swal.fire({
        title: 'No allowance remaining!',
        text:
          'You are already monitoring ' +
          monitoredPageCount +
          ' pages. Please exclude a page before forcing another page to be monitored.',
        type: 'error',
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      });
      return;
    }

    if (operation === 'f' || operation === 'u') {
      this.props.setPageMonitoringState(pageId, operation);
      return;
    }

    Swal.fire({
      title: 'Are you sure?',
      text:
        'Excluding this page will remove all historical results from Competito. This page will never be tested again until you undo this exclusion.',
      type: 'error',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, I am sure!'
    }).then(result => {
      if (result.value) {
        this.props.setPageMonitoringState(pageId, operation);
      }
    });
  };

  renderDropdownForAction = row => {
    let excludeMessage =
      'Exclude this page from ever being monitored by Competito';

    let button = 'Include page';
    let btnOneMessage = 'Force monitor this page';
    let operation = 'f';

    let buttonTwo = 'Exclude page';
    let btnTwoMessage = excludeMessage;
    let operationTwo = 'e';

    if (row.get('status') === -1) {
      button = 'Undo exclusion';
      btnOneMessage = 'Stop excluding this page from being monitored';
      operation = 'u';
      buttonTwo = null;
    } else if (row.get('specialStatus') === 'f') {
      button = 'Undo force';
      btnOneMessage = 'Stop forcing this page to be monitored';
      operation = 'u';
      buttonTwo = null;
    } else if (row.get('status') === 200) {
      button = 'Exclude page';
      btnOneMessage = excludeMessage;
      operation = 'e';
      buttonTwo = null;
    }

    return (
      <span>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={`tooltip-overlay` + row.get('pageId')}>
              {btnOneMessage}
            </Tooltip>
          }
        >
          <Dropdown.Item
            onClick={() =>
              this.triggerConfirmation(row.get('pageId'), operation)
            }
          >
            {button}
          </Dropdown.Item>
        </OverlayTrigger>
        {buttonTwo && (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-overlay` + row.get('pageId')}>
                {btnTwoMessage}
              </Tooltip>
            }
          >
            <Dropdown.Item
              onClick={() =>
                this.triggerConfirmation(row.get('pageId'), operationTwo)
              }
            >
              {buttonTwo}
            </Dropdown.Item>
          </OverlayTrigger>
        )}
      </span>
    );
  };

  renderRow = row => {
    return (
      <tr key={row.get('pageId')}>
        <td className="align-middle" align="center">
          {row.get('fullPageScreenshotLocation') && (
            <a
              href={row.get('fullPageScreenshotLocation')}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={row.get('viewportPageScreenshotLocation')}
                alt=""
                className={styles.pageScreenshot}
              />
            </a>
          )}
          <div>{this.renderMonitorStatus(row)}</div>
        </td>
        <td className="align-middle">
          <div className={styles.pageName}>{row.get('pageName')}</div>
          <a
            href={row.get('url')}
            target="_blank"
            rel="noopener noreferrer"
            style={{ wordBreak: 'break-all' }}
          >
            {row.get('url')} <Icon name="far fa-external-link-alt" />
          </a>
        </td>
        <td align="right" className={cx('align-middle', styles.mobileHide)}>
          <Gatekeeper noDemoAccount role={ROLE_ACCOUNT_ADMIN} key="buttons">
            <DropdownButton
              className={styles.actionBtn}
              title=""
              id={'dropdown-filter-button' + row.get('pageId')}
            >
              {this.renderDropdownForAction(row)}
            </DropdownButton>
          </Gatekeeper>
        </td>
      </tr>
    );
  };

  renderRows() {
    const { pages } = this.props;

    return pages.map(row => {
      return this.renderRow(row);
    });
  }

  render() {
    const { pages, pagination } = this.props;

    if (!pages.size) {
      return (
        <Alert variant="danger">No pages found for this competitor.</Alert>
      );
    }

    return (
      <div>
        <table className="table table-striped table-vcenter">
          <tbody>{this.renderRows()}</tbody>
        </table>

        <TablePagination
          style={{ marginTop: '20px' }}
          page={pagination.page}
          numRows={pagination.totalRows}
          rowsPerPage={pagination.rowsPerPage}
          onClickPage={pagination.selectPage}
        />
      </div>
    );
  }
}

export default PagesViewer;
