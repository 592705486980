import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import styles from './loggedInContainer.module.scss';
import { setupWizardSelector } from 'selectors/accountSetupSelectors';
import AccountSetupWizardBreadcrumb from 'components/accountSetup/accountSetupWizardBreadcrumb';
import AccountSetupWizardProfileStep from 'components/accountSetup/step/accountSetupWizardProfileStep';
import AccountSetupWizardCompetitorStep from 'components/accountSetup/step/accountSetupWizardCompetitorStep';
import AccountSetupWizardRegisterStep from 'components/accountSetup/step/accountSetupWizardRegisterStep';
import {
  REGISTER_STEP,
  PROFILE_STEP,
  COMPETITOR_STEP
} from 'components/accountSetup/steps';

const MESSAGES = {
  1: {
    heading: 'Effortless intelligence',
    subheading:
      'Designed to keep you informed, with hundreds of data points checked daily.',
    image:
      'https://competito.app/wp-content/themes/jessle/assets/images/svg/websites.svg'
  },
  2: {
    heading: 'Make informed decisions',
    subheading:
      'Designed to increase the knowledge of your team about your competition, customers, and industry.',
    image:
      'https://competito.app/wp-content/themes/jessle/assets/images/svg/slack-integration.svg'
  },
  3: {
    heading: 'Everything in one place',
    subheading:
      'Designed so you can get the birds-eye view of how you stack up against your competition.',
    image:
      'https://competito.app/wp-content/themes/jessle/assets/images/svg/competitors.svg'
  }
};

class AccountSetupWizardContainer extends Component {
  state = { step: REGISTER_STEP };

  componentDidMount() {
    const { profiles, competitors, isLoggedIn, setupComplete } = this.props;
    if (setupComplete || competitors.size > 0) {
      window.location.href = '/';
      return;
    }

    if (profiles.size > 0) {
      this.setState({ step: COMPETITOR_STEP });
      return;
    }

    if (isLoggedIn) {
      this.setState({ step: PROFILE_STEP });
      return;
    }
  }

  loadNextStep = () => {
    const { step } = this.state;

    if (step === COMPETITOR_STEP) {
      window.location.href = '/onboarding';
      return;
    }

    this.setState({ step: step + 1 });
  };

  renderStep = () => {
    const { step } = this.state;

    if (step === COMPETITOR_STEP) {
      return (
        <AccountSetupWizardCompetitorStep loadNextStep={this.loadNextStep} />
      );
    }

    if (step === PROFILE_STEP) {
      return <AccountSetupWizardProfileStep loadNextStep={this.loadNextStep} />;
    }

    return (
      <AccountSetupWizardRegisterStep
        loadNextStep={this.loadNextStep}
        location={this.props.location}
      />
    );
  };

  render() {
    const { step } = this.state;

    const message = MESSAGES[step];

    return (
      <div className={cx(styles.page, styles.pageNoSidebar)}>
        <div className="row" style={{ margin: 0 }}>
          <div className={cx('col-md-7', styles.wizardColRight)}>
            <AccountSetupWizardBreadcrumb step={this.state.step} />

            <div className={styles.centerWizard}>{this.renderStep()}</div>
          </div>
          <div className={cx('col-md-5', styles.wizardColLeft)}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                justifyItems: 'center',
                alignItems: 'center',
                height: '100vh'
              }}
            >
              <img src={message.image} alt="" style={{ maxWidth: '450px' }} />
              <h2
                style={{
                  color: '#fff',
                  fontSize: '26px',
                  fontWeight: 'bold',
                  marginTop: '40px',
                  marginBottom: '20px'
                }}
              >
                {message.heading}
              </h2>
              <p
                style={{
                  color: '#ddd',
                  fontSize: '14px',
                  fontWeight: 'bold',
                  width: '400px',
                  margin: '0 auto'
                }}
              >
                {message.subheading}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => setupWizardSelector(state);
export default connect(
  mapStateToProps,
  {}
)(AccountSetupWizardContainer);
