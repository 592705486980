import { call, put, select, takeLatest } from 'redux-saga/effects';
import { createAxios } from 'utils/axios/axiosUtils';
import {
  ADD_COMPETITOR,
  handleAddCompetitorFailure,
  handleAddCompetitorResponse
} from 'actions/competitorActions';
import { receiveCompetitorEmail } from 'actions/competitorEmailActions';
import Swal from 'sweetalert2';
import { currentProfileIdSelector } from 'selectors/profileSelectors';

export default function* addCompetitorSaga() {
  yield takeLatest(ADD_COMPETITOR, addCompetitor);
}

function* addCompetitor({ data, successCb }) {
  const currentProfileId = yield select(currentProfileIdSelector);

  try {
    const response = yield call(apiCall, data, currentProfileId);

    if (response && response.data.competitor) {
      const competitor = response.data.competitor;
      const competitorEmail = response.data.competitorEmail;

      yield put(handleAddCompetitorResponse(competitor));
      yield put(
        receiveCompetitorEmail(competitor.competitorId, competitorEmail)
      );

      successCb && successCb();
      return;
    }

    yield put(handleAddCompetitorFailure());
  } catch (e) {
    Swal.fire({
      type: 'error',
      title: 'Oops...',
      text:
        'Something went wrong trying to add this competitor. Try again later.'
    });

    yield put(handleAddCompetitorFailure());
  }
}

async function apiCall(data, profileId) {
  return await createAxios().post('competitors', {
    ...data,
    profileId
  });
}
