import { takeLatest } from 'redux-saga/effects';
import Swal from 'sweetalert2';
import { SET_ACCOUNT_SETUP_COMPLETE } from 'actions/accountSetupActions';

export default function* setAccountSetupCompleteSaga() {
  yield takeLatest(SET_ACCOUNT_SETUP_COMPLETE, setAccountSetupComplete);
}

function setAccountSetupComplete() {
  Swal.fire({
    title: 'Good job!',
    html: 'You have completed the setup for this account.',
    timer: 4000,
    type: 'success',
    timerProgressBar: true
  });
}
