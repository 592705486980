export const RECEIVE_NOTIFICATION_SUBSCRIPTIONS_FOR_CHANNEL =
  'app:RECEIVE_NOTIFICATION_SUBSCRIPTIONS_FOR_CHANNEL';
export function receiveNotificationSubscriptionsForChannel(
  channelId,
  subscriptions
) {
  return {
    type: RECEIVE_NOTIFICATION_SUBSCRIPTIONS_FOR_CHANNEL,
    channelId,
    subscriptions
  };
}

export const TOGGLE_NOTIFICATION_SUBS_FOR_PROFILE =
  'app:TOGGLE_NOTIFICATION_SUBS_FOR_PROFILE';
export function toggleNotificationSubscriptionsForProfile(profileId, status) {
  return {
    type: TOGGLE_NOTIFICATION_SUBS_FOR_PROFILE,
    profileId,
    status
  };
}
