import React, { Component } from 'react';
import SimpleStat from 'components/stat/simpleStat';
import TopIconStat from 'components/stat/topIconStat';

export default class KeyStats extends Component {
  render() {
    const { name, stats, self } = this.props;

    if (!stats) {
      return null;
    }

    const facebookPostsDelta = stats.FacebookPosts.delta;
    const tweetDelta = stats.TweetCount.delta;
    const facebookLikesDelta = stats.FacebookLikes.delta;
    const twitterFollowersDelta = stats.TwitterFollowers.delta;
    const postsDelta = facebookPostsDelta + tweetDelta;

    const followersDelta = facebookLikesDelta + twitterFollowersDelta;
    const followers = stats.FacebookLikes.value + stats.TwitterFollowers.value;

    const facebookEngagementDelta = stats.FacebookPostEngagement.delta;
    const twitterEngagementDelta = stats.TweetEngagement.delta;
    const socialEngagementDelta =
      facebookEngagementDelta + twitterEngagementDelta;

    const mdClass = self ? 'col-xl-4' : 'col-xl-3';

    return (
      <div>
        <h2 className="heading">
          {name}
          {self ? (
            <small>Your profile summary</small>
          ) : (
            <small>Competitor summary</small>
          )}
        </h2>

        <div className="row">
          <div className={mdClass}>
            <SimpleStat
              iconName="fab fa-google"
              iconColor="#be4bdb"
              iconSize="3x"
              label={
                stats.RankedKeywordsCount.value === 1
                  ? 'Keyword ranking'
                  : 'Keywords ranking'
              }
              stat={stats.RankedKeywordsCount.value}
              delta={
                (stats.RankedKeywordsCount.delta > 0 ? '+' : '') +
                stats.RankedKeywordsCount.delta
              }
              deltaMessage="this period"
              tooltip="How many keywords you are currently ranking for."
            />
          </div>
          {!self && typeof stats.WebsiteChangesCount !== 'undefined' && (
            <div className={mdClass}>
              <SimpleStat
                iconName="fas fa-browser"
                iconColor="lightgreen"
                iconSize="3x"
                label={
                  stats.WebsiteChangesCount.value === 1
                    ? 'Website change'
                    : 'Website changes'
                }
                stat={stats.WebsiteChangesCount.value}
                delta={
                  (stats.WebsiteChangesCount.delta > 0 ? '+' : '') +
                  stats.WebsiteChangesCount.delta
                }
                deltaMessage="this period"
                tooltip="How many times you have added, edited or removed page on the website in the last 30 days."
              />
            </div>
          )}
          <div className={mdClass}>
            <SimpleStat
              iconName="fas fa-user-friends"
              iconColor="#26c6da"
              iconSize="3x"
              label={followers === 1 ? ' Follower' : 'Followers'}
              stat={followers}
              delta={(followersDelta > 0 ? '+' : '') + followersDelta}
              deltaMessage="this period"
              tooltip="How many Facebook and Twitter followers you have gained or lost in the last 30 days."
            />
          </div>
          <div className={mdClass}>
            <SimpleStat
              iconName="fas fa-share-alt-square"
              iconColor="#4c6ef5"
              iconSize="3x"
              label={postsDelta === 1 ? 'New post' : 'New posts'}
              stat={postsDelta > 0 ? postsDelta : 0}
              delta={(postsDelta > 0 ? '+' : '') + postsDelta}
              deltaMessage="this period"
              tooltip="How many new Facebook and Twitter posts you have made in the last 30 days."
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xl-4">
            <TopIconStat
              iconName="far fa-comments"
              iconColor="#4c6ef5"
              iconSize="4x"
              label={'social interactions'}
              stat={
                (socialEngagementDelta < 0 ? '' : '+') + socialEngagementDelta
              }
              tooltip="How many Facebook and Twitter likes, comments, favorites and retweets you have gained or lost in the last 30 days."
            />
          </div>
          <div className="col-xl-4">
            <TopIconStat
              iconName="fab fa-twitter"
              iconColor="#26c6da"
              iconSize="4x"
              label={'followers'}
              stat={
                (twitterFollowersDelta < 0 ? '' : '+') + twitterFollowersDelta
              }
              tooltip="How many Twitter followers you have gained or lost in the last 30 days."
            />
          </div>
          <div className="col-xl-4">
            <TopIconStat
              iconName="fab fa-facebook"
              iconColor="#4c6ef5"
              iconSize="4x"
              label={'likes'}
              stat={(facebookLikesDelta < 0 ? '' : '+') + facebookLikesDelta}
              tooltip="How many Facebook likes you have gained or lost in the last 30 days."
            />
          </div>
        </div>
      </div>
    );
  }
}
