import React, { Component } from 'react';
import Block from 'components/presentation/block';
import Icon from 'components/fontAwesome/icon';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import moment from 'moment';

export default class TopFacebookPostsWidget extends Component {
  renderPostRows = () => {
    const { posts } = this.props;

    return posts.map(post => {
      return (
        <tr key={post.postId}>
          <td>
            <Icon
              name="fab fa-facebook"
              style={{ color: 'rgb(76, 110, 245)' }}
            />
          </td>
          <td>
            <div style={{ marginBottom: '5px', wordBreak: 'break-all' }}>
              <a href={post.postUrl} target="_blank" rel="noreferrer noopener">
                {post.plainMessage.length > 180
                  ? post.plainMessage.substr(0, 180) + '...'
                  : post.plainMessage}
              </a>
            </div>
            <span style={{ marginRight: '20px', color: '#999' }}>
              <Icon name="far fa-thumbs-up" /> {post.likes}
            </span>{' '}
            <span style={{ marginRight: '20px', color: '#999' }}>
              <Icon name="far fa-comments" /> {post.comments}
            </span>{' '}
            <span style={{ marginRight: '20px', color: '#999' }}>
              <Icon name="far fa-share" /> {post.shares}
            </span>
          </td>
          <td style={{ width: '100px' }}>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-` + post.postId}>
                  {post.likes}, {post.comments} comments and {post.shares}{' '}
                  shares.
                </Tooltip>
              }
            >
              <span style={{ fontSize: '0.8rem' }}>
                {moment.unix(post.postedTimestamp).fromNow()}
              </span>
            </OverlayTrigger>
          </td>
        </tr>
      );
    });
  };

  renderContent = () => {
    const { posts } = this.props;

    if (!posts.length) {
      return (
        <div>
          <Icon
            name="fas fa-exclamation-triangle"
            style={{ color: 'orange', marginRight: '5px' }}
          />{' '}
          No posts detected.
        </div>
      );
    }

    return (
      <table className="table table-striped">
        <tbody>{this.renderPostRows()}</tbody>
      </table>
    );
  };

  render() {
    return (
      <Block
        title={'Top Facebook posts'}
        help="Review the top Facebook posts for this domain. A top post is defined as the post that got the most comments, shares and likes in the last 30 days."
      >
        {this.renderContent()}
      </Block>
    );
  }
}
