import React, { Component } from 'react';
import { HorizontalBar } from 'react-chartjs-2';

export default class BarChart extends Component {
  render() {
    const { data } = this.props;

    const legendOptions = {
      display: true,
      position: 'bottom',
      fullWidth: true,
      reverse: false,
      labels: {
        fontColor: '#333'
      }
    };

    const options = {};

    return (
      <div>
        <HorizontalBar data={data} legend={legendOptions} options={options} />
      </div>
    );
  }
}
