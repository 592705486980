import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import { createAxios } from 'utils/axios/axiosUtils';
import Form from 'react-bootstrap/Form';
import Alert from 'components/alert/alert';
import { parse } from 'query-string';
import SideContentWithGraphics from 'components/loggedOutWrappers/sideContentWithGraphics';
import cx from 'classnames';
import Icon from 'components/fontAwesome/icon';
import styles from 'components/loggedOutWrappers/sidebarContentWithGraphics.module.scss';
import { notOnMobile } from 'utils/appUtils';

class WelcomeEmailContainer extends Component {
  state = {
    passwordsMatch: false,
    hasValidated: false,
    failed: false,
    missingRequiredFields: false,
    tokenExpired: false
  };

  submitForm = async event => {
    const {
      location: { search }
    } = this.props;
    const params = parse(search);

    event.preventDefault();

    this.setState({
      hasValidated: false,
      failed: false,
      missingRequiredFields: false,
      tokenExpired: false
    });

    const password = event.currentTarget.password.value;
    const confirmPassword = event.currentTarget.confirmPassword.value;

    if (!password || !password.length) {
      this.setState({
        hasValidated: true,
        passwordsMatch: false,
        failed: true,
        missingRequiredFields: true
      });
      return;
    }

    if (password !== confirmPassword) {
      this.setState({
        hasValidated: true,
        passwordsMatch: false,
        failed: true
      });
      return;
    }

    this.setState({ passwordsMatch: true, hasValidated: true, failed: false });

    try {
      await createAxios(false).post('confirm-reset-password', {
        password,
        token: params['invite-token'],
        email: params.email
      });
    } catch (e) {
      this.setState({ failed: true, tokenExpired: true });
      return;
    }

    window.location.href = '/login';
  };

  renderFailureMessage = () => {
    const { passwordsMatch, missingRequiredFields, tokenExpired } = this.state;

    if (tokenExpired) {
      return (
        <Alert variant={'danger'}>
          Token has expired, please request a new token.
        </Alert>
      );
    }

    if (missingRequiredFields) {
      return <Alert variant={'danger'}>Missing required fields.</Alert>;
    }

    if (!passwordsMatch) {
      return (
        <Alert variant={'danger'}>
          Passwords do not match. Please try again.
        </Alert>
      );
    }

    return (
      <Alert variant={'danger'}>
        There was a problem setting this password. Please try again later.
      </Alert>
    );
  };

  render() {
    const { hasValidated, failed } = this.state;

    return (
      <SideContentWithGraphics>
        <h1 className={cx('h3', 'fw700', styles.title)}>
          Welcome to Competito
        </h1>
        <h2 className={cx('h5', 'fw400', 'textLighter')}>
          Let's setup a password for your account
        </h2>

        {hasValidated && failed && this.renderFailureMessage()}

        <Form onSubmit={this.submitForm}>
          <Form.Group controlId="password">
            <Form.Label>New password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Choose password"
              autoFocus={notOnMobile(true)}
            />
          </Form.Group>
          <Form.Group controlId="confirmPassword">
            <Form.Label>Confirm password</Form.Label>
            <Form.Control type="password" placeholder="Confirm password" />
          </Form.Group>

          <Button variant="btnAlt" type="submit" className="btnFlat">
            <Icon name="fal fa-unlock-alt" className="mgr10" /> Set password
          </Button>
        </Form>
      </SideContentWithGraphics>
    );
  }
}

export default WelcomeEmailContainer;
