import numeral from 'numeral';

export function pluralise(value, singularString, pluralString) {
  return value === 1
    ? singularString.replace('{value}', value)
    : pluralString.replace('{value}', value);
}

export function getOrdinal(n) {
  var s = ['th', 'st', 'nd', 'rd'],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

// Returns the number of decimal places, including scientific e.g. 3.2e+41
export function countDecimalPlaces(num) {
  var match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
  if (!match) {
    return 0;
  }
  return Math.max(
    0,
    // Number of digits right of decimal point.
    (match[1] ? match[1].length : 0) -
      // Adjust for scientific notation.
      (match[2] ? +match[2] : 0)
  );
}

export function formatGivenNumber(number, decimals = 0) {
  // if decimal, find out how many places.
  let givenFormat = '000,000,000';
  let decimalPlaces = decimals ? decimals : countDecimalPlaces(number);

  if (decimalPlaces) {
    givenFormat += '.';
    for (let i = 0; i < decimalPlaces; i++) {
      givenFormat += '0';
    }
  }

  return numeral(number).format(givenFormat);
}
