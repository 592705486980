import React, { Component } from 'react';
import BootstrapSpinner from 'react-bootstrap/Spinner';

const colors = ['#EA1D61', '#42B255', '#0871FE'];

class Spinner extends Component {
  constructor(props) {
    super(props);
    this.state = { iteration: 0 };
  }

  componentDidMount() {
    const { useColors } = this.props;

    if (useColors) {
      this.timer = setInterval(() => {
        if (this.state.iteration >= 2) {
          this.setState({ iteration: 0 });
        } else {
          this.setState({ iteration: this.state.iteration + 1 });
        }
      }, 2000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    const { iteration } = this.state;
    const { larger, center, style: propStyle } = this.props;

    const color = colors[iteration];

    const style = { color: color, ...propStyle };

    if (larger) {
      style.height = '3rem';
      style.width = '3rem';
    }

    if (center) {
      return (
        <div style={{ textAlign: 'center', width: '100%' }}>
          <BootstrapSpinner animation="border" style={style} />
        </div>
      );
    }

    return <BootstrapSpinner animation="border" style={style} />;
  }
}

export default Spinner;
