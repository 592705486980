import React, { Component } from 'react';
import Block from 'components/presentation/block';
import Icon from 'components/fontAwesome/icon';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import moment from 'moment';

export default class TopTweetsWidget extends Component {
  renderTweetRows = () => {
    const { tweets } = this.props;

    return tweets.map(tweet => {
      return (
        <tr key={tweet.twitterTweetId}>
          <td>
            <Icon
              name="fab fa-twitter"
              style={{ color: 'rgb(38, 198, 218)' }}
            />
          </td>
          <td>
            <div style={{ marginBottom: '5px', wordBreak: 'break-all' }}>
              <a
                href={tweet.tweetUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {tweet.text}
              </a>
            </div>{' '}
            <span style={{ marginRight: '20px', color: '#999' }}>
              <Icon name="fal fa-heart-circle" /> {tweet.favorites}
            </span>{' '}
            <span style={{ marginRight: '20px', color: '#999' }}>
              <Icon name="fal fa-retweet" /> {tweet.retweets}
            </span>
          </td>
          <td>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-` + tweet.twitterTweetId}>
                  {tweet.favorites} favorites and {tweet.retweets} retweets.
                </Tooltip>
              }
            >
              <span style={{ fontSize: '0.8rem' }}>
                {moment.unix(tweet.createdAt).fromNow()}
              </span>
            </OverlayTrigger>
          </td>
        </tr>
      );
    });
  };

  renderContent = () => {
    const { tweets } = this.props;
    if (!tweets.length) {
      return (
        <div>
          <Icon
            name="fas fa-exclamation-triangle"
            style={{ color: 'orange', marginRight: '5px' }}
          />{' '}
          No tweets detected.
        </div>
      );
    }

    return (
      <table className="table table-striped">
        <tbody>{this.renderTweetRows()}</tbody>
      </table>
    );
  };

  render() {
    return (
      <Block
        title={'Top Tweets'}
        help="Review the top Tweets for this domain. A top Tweet is defined as the Tweet that received the most favorites and retweets in the last 30 days."
      >
        {this.renderContent()}
      </Block>
    );
  }
}
