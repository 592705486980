import React from 'react';
import InspectorEvent from 'components/inspector/inspectorEvent';
import InspectorEventComments from 'components/timeline/inspectorEventComments';
import { useGetInspectorEventData } from 'providers/inspectorEventProvider';

export default function EventInspectorPage(props) {
  const { eventData, eventMeta } = useGetInspectorEventData();

  return (
    <div>
      <InspectorEvent {...props} eventData={eventData} eventMeta={eventMeta} />
      <InspectorEventComments limit={99999999} />
    </div>
  );
}
