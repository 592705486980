import React, { Component } from 'react';
import Spinner from 'components/spinner/spinner';
import KeyStats from 'components/dashboard/keyStats';
import WebsiteStatsForName from 'components/dashboard/websiteStatsForName';
import WebsiteChangeFrequency from 'components/dashboard/websiteChangeFrequency';
import KeywordsBreakdownWidget from 'components/dashboard/keywordsBreakdownWidget';
import FacebookAdsWidget from 'components/dashboard/facebookAdsWidget';
import TopFacebookPostsWidget from 'components/dashboard/topFacebookPostsWidget';
import TopTweetsWidget from 'components/dashboard/topTweetsWidget';
import TopKeywordsWidget from 'components/dashboard/topKeywordsWidget';
import UnrankedKeywordsWidget from 'components/dashboard/unrankedKeywordsWidget';
import SocialGraphWidgets from 'components/dashboard/socialGraphWidgets';
import Alert from 'components/alert/alert';
import LiveDemoWarning from 'components/demo/liveDemoWarning';

class OverviewPage extends Component {
  renderPageTitle = () => {
    const { profile } = this.props;

    return (
      <div className="row" style={{ marginBottom: '30px' }}>
        <div className="col-xl-12" style={{ textAlign: 'center' }}>
          <h1 className={'h4'} style={{ marginBottom: 0 }}>
            Overview for {profile.name}
          </h1>
          <h2 className={'h5 textLighter thin'}>
            Analyze the last{' '}
            <strong style={{ fontWeight: 'bold' }}>30 day performance</strong>{' '}
            of your competitors in comparison to your own against KPIs.
          </h2>
        </div>
      </div>
    );
  };

  renderLoadingOrError = () => {
    const { isLoading, loadingError } = this.props;

    if (isLoading) {
      return <Spinner center />;
    }

    if (loadingError) {
      return (
        <p>
          An issue occured trying to load this page. Please try again later.
        </p>
      );
    }

    return null;
  };

  getStatsForName = (statType, name) => {
    const { keyStatsOverview } = this.props;

    if (statType === 'keyStats' && keyStatsOverview) {
      const statForName = keyStatsOverview.get(name);
      return statForName ? statForName.toJS() : null;
    }

    return null;
  };

  renderCompetitorStats = () => {
    const { competitors, websiteOverview, socialMediaOverview } = this.props;

    const competitorsData = Object.values(competitors);
    return competitorsData.map(competitor => {
      const pageChanges =
        websiteOverview &&
        websiteOverview.get('competitors').get(competitor.name)
          ? websiteOverview
              .get('competitors')
              .get(competitor.name)
              .toJS()
          : null;
      const facebookPosts =
        socialMediaOverview &&
        socialMediaOverview
          .get('competitorsTopFacebookPosts')
          .get(competitor.name)
          ? socialMediaOverview
              .get('competitorsTopFacebookPosts')
              .get(competitor.name)
              .toJS()
          : null;
      const tweets =
        socialMediaOverview &&
        socialMediaOverview.get('competitorsTopTweets').get(competitor.name)
          ? socialMediaOverview
              .get('competitorsTopTweets')
              .get(competitor.name)
              .toJS()
          : null;

      return (
        <div key={competitor.competitorId}>
          <KeyStats
            name={competitor.name}
            stats={this.getStatsForName('keyStats', competitor.name)}
          />

          <WebsiteStatsForName
            name={competitor.name}
            pageChanges={pageChanges}
          />

          <div className="row">
            <div className="col-md-6">
              {tweets && <TopTweetsWidget tweets={tweets} />}
            </div>
            <div className="col-md-6">
              {facebookPosts && (
                <TopFacebookPostsWidget posts={facebookPosts} />
              )}
            </div>
          </div>
        </div>
      );
    });
  };

  renderSelfSummary = () => {
    const { profile, socialMediaOverview, keywordsOverview } = this.props;

    const tweets =
      socialMediaOverview && socialMediaOverview.get('selfTopTweets')
        ? socialMediaOverview && socialMediaOverview.get('selfTopTweets').toJS()
        : [];
    const fbPosts =
      socialMediaOverview && socialMediaOverview.get('selfTopFacebookPosts')
        ? socialMediaOverview &&
          socialMediaOverview.get('selfTopFacebookPosts').toJS()
        : [];
    const topKeywords =
      keywordsOverview && keywordsOverview.get('selfTopPerformingKeywords')
        ? keywordsOverview &&
          keywordsOverview.get('selfTopPerformingKeywords').toJS()
        : [];
    const unrankedKeywords =
      keywordsOverview && keywordsOverview.get('selfUnrankedKeywords')
        ? keywordsOverview &&
          keywordsOverview.get('selfUnrankedKeywords').toJS()
        : [];

    return (
      <div>
        <KeyStats
          name={profile.name}
          self={true}
          stats={this.getStatsForName('keyStats', profile.name)}
        />

        <div className="row">
          <div className="col-md-6">
            <TopTweetsWidget tweets={tweets} />
          </div>
          <div className="col-md-6">
            <TopFacebookPostsWidget posts={fbPosts} />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <TopKeywordsWidget keywords={topKeywords} />
          </div>
          <div className="col-md-6">
            <UnrankedKeywordsWidget keywords={unrankedKeywords} />
          </div>
        </div>
      </div>
    );
  };

  renderRankingWidgetsForAllCompetitors = () => {
    const { keywordsOverview } = this.props;

    const topKeywords =
      keywordsOverview &&
      keywordsOverview.get('competitorsTopPerformingKeywords')
        ? keywordsOverview &&
          keywordsOverview.get('competitorsTopPerformingKeywords').toJS()
        : [];
    const unrankedKeywords =
      keywordsOverview && keywordsOverview.get('competitorsUnrankedKeywords')
        ? keywordsOverview &&
          keywordsOverview.get('competitorsUnrankedKeywords').toJS()
        : [];

    return (
      <div className="row">
        <div className="col-md-6">
          <TopKeywordsWidget
            keywords={topKeywords}
            title="Top ranking keywords for competitors"
          />
        </div>
        <div className="col-md-6">
          <UnrankedKeywordsWidget
            keywords={unrankedKeywords}
            title="Unranked keywords for competitors"
          />
        </div>
      </div>
    );
  };

  renderDemoWarning = () => {
    return <LiveDemoWarning style={{ marginBottom: '60px' }} />;
  };

  render() {
    const {
      websiteOverview,
      keywordsOverview,
      socialMediaOverview,
      competitors
    } = this.props;

    if (!Object.values(competitors).length) {
      return (
        <div>
          <Alert variant="info">
            You must add some competitors before viewing the overview data.
          </Alert>
        </div>
      );
    }

    return (
      <div>
        {this.renderPageTitle()}
        {this.renderLoadingOrError()}

        {this.renderDemoWarning()}

        <h2 className="heading">
          All competitors
          <small>Summary for all competitors</small>
        </h2>

        <SocialGraphWidgets
          socialMediaOverview={
            socialMediaOverview ? socialMediaOverview.toJS() : null
          }
        />

        <WebsiteChangeFrequency
          changeFrequencyData={
            websiteOverview
              ? websiteOverview.get('changeFrequencyTable').toJS()
              : null
          }
        />

        <KeywordsBreakdownWidget
          keywordsBreakdown={
            keywordsOverview
              ? keywordsOverview.get('googleRankingsSummaryTable').toJS()
              : null
          }
        />

        {this.renderRankingWidgetsForAllCompetitors()}

        {socialMediaOverview && socialMediaOverview.get('facebookAds') && (
          <FacebookAdsWidget
            data={socialMediaOverview.get('facebookAds').toJS()}
          />
        )}

        <br />

        {this.renderSelfSummary()}
        <br />
        {this.renderCompetitorStats()}
      </div>
    );
  }
}

export default OverviewPage;
