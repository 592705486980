import { put, call, takeLatest } from 'redux-saga/effects';
import {
  REQUEST_COMPETITOR_EMAIL,
  receiveCompetitorEmail,
  handleBadCompetitorEmailResponse
} from 'actions/competitorEmailActions';
import { createAxios } from 'utils/axios/axiosUtils';

export default function* fetchCompetitorEmailSaga() {
  yield takeLatest(REQUEST_COMPETITOR_EMAIL, fetchCompetitorEmail);
}

function* fetchCompetitorEmail({ competitorId }) {
  try {
    const response = yield call(getResponse, competitorId);
    if (response.data && response.data.email) {
      yield put(
        receiveCompetitorEmail(competitorId.toString(), response.data.email)
      );
    }
  } catch (e) {
    console.warn(e);

    yield put(handleBadCompetitorEmailResponse());
  }
}

async function getResponse(competitorId) {
  return await createAxios().get('account-email/' + competitorId);
}
