export const FETCH_INTEGRATIONS_DATA = 'app:FETCH_INTEGRATIONS_DATA';
export function fetchIntegrationsData() {
  return {
    type: FETCH_INTEGRATIONS_DATA
  };
}

export const RECEIVE_INTEGRATIONS_DATA = 'app:RECEIVE_INTEGRATIONS_DATA';
export function receiveIntegrationsData(integrations) {
  return {
    type: RECEIVE_INTEGRATIONS_DATA,
    integrations
  };
}

export const REMOVE_INTEGRATION = 'app:REMOVE_INTEGRATION';
export function removeIntegration(type) {
  return {
    type: REMOVE_INTEGRATION,
    integrationType: type
  };
}
