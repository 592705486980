import React, { Component } from 'react';
import config from 'config';
import Block from 'components/presentation/block';
import Spinner from 'components/spinner/spinner';
import CompetitorSelectInput from 'components/competitor/competitorSelectInput';
import SimpleStat from 'components/stat/simpleStat';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { ROLE_ACCOUNT_ADMIN } from 'utils/permissions/permissions';
import Gatekeeper from 'components/permissions/gatekeeper';
import LiveDemoWarning from 'components/demo/liveDemoWarning';

class InventoryTechPage extends Component {
  selectCompetitor = competitorId => {
    if (competitorId && competitorId.length > 0) {
      this.props.onSelectCompetitor(competitorId);
    }
  };

  renderTableRows = () => {
    const { technologies } = this.props;

    if (!technologies.size) {
      return (
        <tr>
          <td colSpan={3}>No technologies found for this competitor</td>
        </tr>
      );
    }

    return (
      technologies.size &&
      technologies.map(row => {
        return (
          <tr key={row.get('name')}>
            <td>
              <img
                src={config.IMG_ROOT + 'tech-icons/' + row.get('icon')}
                alt=""
                style={{ maxWidth: '14px', marginRight: '5px' }}
              />{' '}
              {row.get('name')}
            </td>
            <td>
              {!!row.get('website') && (
                <a
                  href={row.get('website')}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {row.get('website')}
                </a>
              )}
            </td>
            <td className={'d-sm-none d-md-block d-none'}>
              {row.get('categories').join(', ')}
            </td>
          </tr>
        );
      })
    );
  };

  getCompetitorName = competitor => {
    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id={`tooltip-2`}>
            {competitor.name} - {competitor.normalisedDomain}
          </Tooltip>
        }
      >
        <span>
          <img
            src={competitor.faviconUrl}
            alt=""
            style={{
              height: '14px',
              width: '14px',
              marginRight: '3px'
            }}
          />

          {competitor.name.length > 30
            ? competitor.name.substr(0, 30) + '...'
            : competitor.name}
        </span>
      </OverlayTrigger>
    );
  };

  renderTableData = () => {
    const { technologies } = this.props;

    return (
      <div className="row" key="table-data">
        <Block
          title={'Technologies'}
          blockItems={[
            {
              key: 'bi_1',
              value: '<span>' + technologies.size + ' rows</span>'
            }
          ]}
        >
          <table className="table table-striped table-vcenter">
            <thead>
              <tr>
                <th>Technology</th>
                <th>Website</th>
                <th className={'d-sm-none d-md-block d-none'}>Category</th>
              </tr>
            </thead>
            <tbody>{this.renderTableRows()}</tbody>
          </table>
        </Block>
      </div>
    );
  };

  renderStats = () => {
    const { stats } = this.props;

    return (
      <div className="row" key="stats">
        {stats.get('AnalyticsSolution') && (
          <div className="col-md-6">
            <SimpleStat
              iconName="fal fa-analytics"
              iconColor="red"
              iconSize="3x"
              label="Analytics solution"
              stat={stats.get('AnalyticsSolution')}
            />
          </div>
        )}

        {stats.get('CmsSolution') && (
          <div className="col-md-6">
            <SimpleStat
              iconName="fal fa-columns"
              iconColor="purple"
              iconSize="3x"
              label="CMS platform"
              stat={stats.get('CmsSolution')}
            />
          </div>
        )}
      </div>
    );
  };

  renderCompetitorInfo() {
    const { competitorId, competitors } = this.props;
    const competitor = competitors[competitorId];

    return (
      <Gatekeeper noDemoAccount role={ROLE_ACCOUNT_ADMIN} key="comp-info">
        <div
          className="d-flex justify-content-between"
          style={{ marginBottom: '20px' }}
        >
          <div className="col-md-6" style={{ paddingTop: '5px' }}>
            {this.getCompetitorName(competitor)}
          </div>

          <div className="col-md-6">
            <CompetitorSelectInput
              onChange={this.selectCompetitor}
              competitors={competitors}
              style={{ color: '#777', maxWidth: '250px', float: 'right' }}
            />
            <div className="clear"></div>
          </div>
        </div>
      </Gatekeeper>
    );
  }

  renderLoadedData() {
    const { isLoadingTech, techLoadingError } = this.props;
    if (isLoadingTech || techLoadingError) {
      return null;
    }

    return [
      this.renderCompetitorInfo(),
      this.renderStats(),
      this.renderTableData()
    ];
  }

  render() {
    const {
      isLoadingTech,
      techLoadingError,
      competitorId,
      competitors
    } = this.props;

    const competitor = competitors[competitorId];

    const nameStr = competitor ? ' for ' + competitor.name : '';

    return (
      <div>
        <div className="row" style={{ marginBottom: '30px' }}>
          <div className="col-xl-12" style={{ textAlign: 'center' }}>
            <h1 className={'h4'} style={{ marginBottom: 0 }}>
              Technology profile {nameStr}
            </h1>
            <h2 className={'h5 textLighter thin'}>
              Understand the technology that empowers your competitors website
            </h2>
          </div>
        </div>

        <LiveDemoWarning style={{ marginBottom: '60px' }} />

        {isLoadingTech && <Spinner center />}
        {!isLoadingTech && techLoadingError && (
          <p>
            An issue occured trying to load this page. Please try again later.
          </p>
        )}

        {this.renderLoadedData()}
      </div>
    );
  }
}

export default InventoryTechPage;
