import React, { Component } from 'react';
import CentralWrapper from 'components/loginWrappers/centralWrapper';
import { keywordsPageSelector } from 'selectors/keywordSelectors';
import { connect } from 'react-redux';
import Spinner from 'components/spinner/spinner';
import KeywordsPage from '../components/page/keywordsPage';
import { fetchKeywordTableData } from 'actions/keywordActions';

const TITLE_DEFAULT = 'Keywords | Competito';

class KeywordsContainer extends Component {
  constructor(props) {
    super(props);

    const competitorValues = Object.values(props.competitors);
    const chosenCompetitorId = competitorValues.length
      ? competitorValues[0].competitorId
      : null;

    this.state = {
      chosenCompetitorId: chosenCompetitorId,
      page: 1,
      searchTerm: '',
      sortOrder: 'keyword:asc'
    };
  }

  UNSAFE_componentWillMount() {
    document.title = TITLE_DEFAULT;

    this.fetchKeywordData(
      this.props.profile,
      this.props.competitors,
      this.state.chosenCompetitorId,
      this.state.sortOrder
    );
  }

  changeCompetitor = competitorId => {
    const { profile, competitors } = this.props;

    if (competitorId === this.state.chosenCompetitorId.toString()) {
      return;
    }

    this.setState({ chosenCompetitorId: competitorId });
    this.fetchKeywordData(
      profile,
      competitors,
      competitorId,
      this.state.sortOrder,
      this.state.page,
      this.state.searchTerm
    );
  };

  changePage = page => {
    const { profile, competitors } = this.props;

    this.setState({ page });
    this.fetchKeywordData(
      profile,
      competitors,
      this.state.chosenCompetitorId,
      this.state.sortOrder,
      page,
      this.state.searchTerm
    );
  };

  fetchKeywordData = (
    profile,
    competitors,
    chosenCompetitorId,
    sortOrder,
    page = 1,
    searchTerm = ''
  ) => {
    if (!Object.keys(competitors).length) {
      return;
    }

    this.props.fetchKeywordTableData(
      profile.profileId,
      chosenCompetitorId,
      sortOrder,
      page,
      searchTerm
    );
  };

  onSearchTermChange = searchTerm => {
    const { profile, competitors } = this.props;

    this.setState({ searchTerm: searchTerm, page: 1 });
    this.fetchKeywordData(
      profile,
      competitors,
      this.state.chosenCompetitorId,
      this.state.sortOrder,
      1,
      searchTerm
    );
  };

  onSortBy = field => {
    const { profile, competitors } = this.props;
    const currentState = this.state.sortOrder;

    let sortOrder = field + ':asc';
    if (
      currentState.indexOf(field) !== -1 &&
      currentState.indexOf(':asc') !== -1
    ) {
      sortOrder = field + ':desc';
    }

    this.setState({ sortOrder });
    this.fetchKeywordData(
      profile,
      competitors,
      this.state.chosenCompetitorId,
      sortOrder,
      this.state.page,
      this.state.searchTerm
    );
  };

  render() {
    const { showSpinner } = this.props;

    return (
      <CentralWrapper>
        {showSpinner ? (
          <Spinner center useColors />
        ) : (
          <KeywordsPage
            {...this.props}
            chosenCompetitorId={
              this.state.chosenCompetitorId
                ? this.state.chosenCompetitorId.toString()
                : ''
            }
            onSelectCompetitor={this.changeCompetitor}
            onSelectPage={this.changePage}
            page={this.state.page}
            searchTerm={this.state.searchTerm}
            sortBy={this.onSortBy}
            onSearchTermChange={this.onSearchTermChange}
          />
        )}
      </CentralWrapper>
    );
  }
}

const mapStateToProps = state => keywordsPageSelector(state);
export default connect(mapStateToProps, { fetchKeywordTableData })(
  KeywordsContainer
);
