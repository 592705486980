import { put, call, select, takeLatest } from 'redux-saga/effects';
import {
  handleKeywordSuggestionsForDomainError,
  receiveKeywordSuggestionsForDomain,
  fetchKeywordSuggestionsForDomain,
  FETCH_KEYWORD_SUGGESTIONS_FOR_DOMAIN_IF_REQUIRED
} from 'actions/keywordSuggestionActions';
import { keywordSuggestionsForDomainSelector } from 'selectors/keywordSuggestionSelectors';
import { createAxios } from 'utils/axios/axiosUtils';

export default function* fetchKeywordSuggestionsSaga() {
  yield takeLatest(
    FETCH_KEYWORD_SUGGESTIONS_FOR_DOMAIN_IF_REQUIRED,
    fetchKeywordSuggestions
  );
}

function* fetchKeywordSuggestions({ domain }) {
  const suggestionsForDomain = yield select(
    keywordSuggestionsForDomainSelector,
    { domain }
  );
  if (
    suggestionsForDomain &&
    suggestionsForDomain.size &&
    !suggestionsForDomain.get('error')
  ) {
    // We already have data for this, let's not fetch again.
    return;
  }

  // Notify to start spinners and put default data into place.
  yield put(fetchKeywordSuggestionsForDomain(domain));

  try {
    const response = yield call(getKeywordSuggestions, domain);
    if (response.data) {
      yield put(receiveKeywordSuggestionsForDomain(domain, response.data));
      return;
    }

    yield put(handleKeywordSuggestionsForDomainError(domain));
  } catch (e) {
    console.warn(e);
    yield put(handleKeywordSuggestionsForDomainError(domain));
  }
}

async function getKeywordSuggestions(domain) {
  return await createAxios().get('suggested-keywords?domain=' + domain);
}
