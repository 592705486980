import React, { Component } from 'react';
import CentralWrapper from 'components/loginWrappers/centralWrapper';
import styles from './errorPage.module.scss';
import Icon from 'components/fontAwesome/icon';
import Button from 'components/button/button';
import { withRouter } from 'react-router-dom';

const CODE_MAP = {
  401: 'We are sorry but you are not authorized to access this page..',
  403: 'We are sorry but you do not have permission to access this page..',
  404: 'We are sorry but the page you are looking for was not found..',
  500: 'We are sorry but your request failed, please try again shortly..',
  503: 'We are sorry but our service is currently not available..'
};

class ErrorPage extends Component {
  onClickGoback = () => {
    this.props.history.goBack();
  };

  render() {
    const { code } = this.props;
    const errorMsg = CODE_MAP[code];

    return (
      <CentralWrapper>
        <div className={styles.centralWrapper}>
          <div className={styles.central}>
            <div className={styles.content}>
              <div
                className={styles.errorCodeBlock}
                style={{ color: '#ef5350' }}
              >
                <Icon
                  name={'fas fa-exclamation-triangle'}
                  style={{ marginRight: '10px' }}
                />
                {code}
              </div>
              <h1 className="h2 fw700 mrgTop30">
                Oops.. There seems to have been a problem.
              </h1>
              {errorMsg && <h2 className="h3 textLighter fw400">{errorMsg}</h2>}

              <Button
                rounded
                secondary
                icon="fa fa-arrow-left"
                style={{ marginTop: '30px' }}
                onClick={this.onClickGoback}
              >
                Go back
              </Button>
            </div>
          </div>
        </div>
      </CentralWrapper>
    );
  }
}

export default withRouter(ErrorPage);
