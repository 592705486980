export function friendlyUrl(url, noTrailingSlash, maxLength = 100) {
  url = url.replace(/^https?:\/\//, '');

  const firstSlashIndex = url.indexOf('/');
  url = url.substr(firstSlashIndex, url.length);

  if (noTrailingSlash) {
    if (url.substr(-1) === '/') {
      url = url.substr(0, url.length - 1);
    }
  }

  if (maxLength && url.length > maxLength) {
    url = url.substr(0, maxLength - 4) + ' ...';
  }

  return url;
}

export function getQueryStringParams() {
  const href = window.location.href;
  const hrefParts = href.split('?');
  const queryString = hrefParts[1] === undefined ? '' : hrefParts[1];

  if (!queryString.length) {
    return {};
  }

  const queryStringSplit = queryString.split('&');
  const queryStringObj = queryStringSplit.reduce((params, param) => {
    let [key, value] = param.split('=');
    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
    return params;
  }, {});

  return queryStringObj;
}
