import { fromJS } from 'immutable';
import {
  STORE_LOGIN,
  APP_IS_READY,
  STORE_REAUTH,
  LOGOUT,
  RECEIVE_NOTIFICATION_CHANNEL,
  SET_EXAMPLE_MODE
} from 'actions/authActions';
import {
  SET_ACCOUNT_SETUP_COMPLETE,
  SKIP_ONBOARDING
} from 'actions/accountSetupActions';
import { UPDATE_USER_RESPONSE } from 'actions/userActions';
import {
  CANCEL_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION
} from 'actions/subscriptionActions';

const initialState = fromJS({
  isLoggedIn: false,
  authToken: null,
  user: null,
  account: null,
  unreadNotificationsCount: 0,
  appIsReady: false,
  notificationChannel: null
});

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case APP_IS_READY:
      return state.set('appIsReady', true);
    case SET_EXAMPLE_MODE:
      return state.setIn(['user', 'exampleMode'], action.isEnabled ? 1 : 0);
    case UPDATE_USER_RESPONSE:
      if (state.get('user').get('userId') === action.userId) {
        // We cannot put this in directly as this brings back a user object, where as auth stores an AuthUser object.
        // Slightly different data sometimes.
        const oldUser = state.get('user');
        const newUser = oldUser
          .set('name', action.data.name)
          .set('email', action.data.email)
          .set('role', action.data.role);
        return state.set('user', newUser);
      }
      return state;
    case CANCEL_SUBSCRIPTION:
      return state.set('subscription', null);
    case UPDATE_SUBSCRIPTION:
      return state.set('subscription', fromJS(action.subscription));
    case STORE_LOGIN:
    case STORE_REAUTH:
      return state
        .set('isLoggedIn', true)
        .set('authToken', action.data.token)
        .set('unreadNotificationsCount', action.data.unreadNotificationsCount)
        .set('account', fromJS(action.data.account))
        .set('servicesSummary', fromJS(action.data.servicesSummary))
        .set('subscription', fromJS(action.data.subscription))
        .set('user', fromJS(action.data.user));
    case SET_ACCOUNT_SETUP_COMPLETE:
    case SKIP_ONBOARDING:
      return state.setIn(['account', 'setupComplete'], true);
    case LOGOUT:
      return initialState.set('appIsReady', true);
    case RECEIVE_NOTIFICATION_CHANNEL:
      return state.set(
        'notificationChannel',
        fromJS(action.notificationChannel)
      );
    default:
      return state;
  }
}
