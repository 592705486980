export const RESET_TIMELINE_STATE = 'app:RESET_TIMELINE_STATE';
export function resetTimelineState() {
  return {
    type: RESET_TIMELINE_STATE
  };
}

export const FETCH_TIMELINE_EVENTS = 'app:FETCH_TIMELINE_EVENTS';
export function fetchTimelineEvents(clearEvents = false) {
  return {
    type: FETCH_TIMELINE_EVENTS,
    clearEvents
  };
}

export const SET_TIMELINE_PAGE = 'app:SET_TIMELINE_PAGE';
export function setTimelinePage(page = 1) {
  return {
    type: SET_TIMELINE_PAGE,
    page
  };
}

export const APPLY_FILTERS_TO_TIMELINE = 'app:APPLY_FILTERS_TO_TIMELINE';
export function applyFilterToTimeline(filterName, filterValue) {
  return {
    type: APPLY_FILTERS_TO_TIMELINE,
    filterName,
    filterValue
  };
}

export const INCREMENT_TIMELINE_PAGE = 'app:INCREMENT_TIMELINE_PAGE';
export function incrementTimelinePage(newPage) {
  return {
    type: INCREMENT_TIMELINE_PAGE,
    newPage
  };
}

export const STORE_TIMELINE_EVENTS = 'app:STORE_TIMELINE_EVENTS';
export function storeTimelineEvents(events, clearEvents) {
  return {
    type: STORE_TIMELINE_EVENTS,
    events,
    clearEvents
  };
}

export const STORE_TIMELINE_EVENT = 'app:STORE_TIMELINE_EVENT';
export function storeTimelineEvent(event) {
  return {
    type: STORE_TIMELINE_EVENT,
    event
  };
}

export const CHECK_FOR_TIMELINE_UPDATES = 'app:CHECK_FOR_TIMELINE_UPDATES';
export function checkForTimelineUpdates() {
  return {
    type: CHECK_FOR_TIMELINE_UPDATES
  };
}

export const STORE_HAS_UPDATES_COUNT = 'app:STORE_HAS_UPDATES_COUNT';
export function storeHasUpdateCount(count) {
  return {
    type: STORE_HAS_UPDATES_COUNT,
    count
  };
}
