import { createSelector } from 'reselect';

export const integrationSelector = state => state.integration;

export const integrationDataSelector = createSelector(
  integrationSelector,
  state => {
    return state.get('data');
  }
);
