import React, { Component } from 'react';
import styles from './sidebarContentWithGraphics.module.scss';
import cx from 'classnames';

class SideContentWithGraphics extends Component {
  render() {
    const { children, hideLogo } = this.props;

    return (
      <div className={styles.bgImage}>
        <div className={styles.bgOverlay}>
          <div className={styles.left}>
            <div className={cx(styles.msg, 'animatedSlow', 'fadeIn')}>
              <p className={styles.inspireMsg}>
                Competitor research shouldn't be time consuming.
              </p>
              <p className={styles.copyright}>
                Copyright &copy; 2021 Competito
              </p>
            </div>
          </div>
          <div className={cx(styles.right, 'animated', 'fadeIn')}>
            <div className={styles.content}>
              <div className={styles.para}>
                {!hideLogo && (
                  <a
                    href="https://my.competito.app/"
                    className={cx(styles.logo, 'linkEffect', 'fw700')}
                  >
                    <img src={'/wordmark.svg'} alt="" />
                  </a>
                )}

                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SideContentWithGraphics;
