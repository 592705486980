import React, { Component } from 'react';
import Icon from 'components/fontAwesome/icon';
import { connect } from 'react-redux';
import Tooltip from 'react-bootstrap/Tooltip';
import styles from './shareButton.module.scss';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { accountProfileSelector } from 'selectors/authSelectors';

class ShareButton extends Component {
  state = {
    copied: false
  };

  generateUrl = () => {
    const { profileId, accountId } = this.props;
    return (
      window.location.href +
      (window.location.href.indexOf('?') === -1 ? '?' : '&') +
      'profileId=' +
      profileId +
      '&accountId=' +
      accountId
    );
  };

  copyUrl = () => {
    const textArea = document.createElement('textarea');

    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em';
    textArea.style.height = '2em';

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;

    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';

    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = 'transparent';

    textArea.value = this.generateUrl();

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
    } catch (err) {}

    document.body.removeChild(textArea);

    this.setState({ copied: true });
  };

  render() {
    const tooltip = this.state.copied ? 'Copied!' : 'Copy Share Link';

    return (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id={`tooltip-overlay`}>{tooltip}</Tooltip>}
      >
        <span onClick={this.copyUrl} className={styles.buttonBlock}>
          <Icon name="fal fa-copy" />
        </span>
      </OverlayTrigger>
    );
  }
}

const mapStateToProps = state => accountProfileSelector(state);
export default connect(
  mapStateToProps,
  {}
)(ShareButton);
