import React from 'react';
import Modal from 'react-bootstrap/Modal';

export default function ScreenshotModal({ url, onClose }) {
  return (
    <Modal dialogClassName="modal-90w" show={url !== null} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Image explorer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img
          src={url}
          alt=""
          style={{ width: '100%', border: '1px solid #eee' }}
        />
      </Modal.Body>
    </Modal>
  );
}
