import { Map, fromJS } from 'immutable';
import { LAUNCH_MODAL, CLEAR_MODAL } from 'actions/globalModalActions';

const initialState = fromJS({
  isShown: false,
  modalComponent: null,
  modalProps: Map({})
});

export default function globalModalReducer(state = initialState, action) {
  switch (action.type) {
    case LAUNCH_MODAL:
      return state
        .set('isShown', true)
        .set('modalComponent', action.modalComponent);
    case CLEAR_MODAL:
      return state.set('isShown', false).set('modalComponent', null);
    default:
      return state;
  }
}
