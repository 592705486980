import React, { Component } from 'react';
import Block from 'components/presentation/block';
import Button from 'components/button/button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import Spinner from 'components/spinner/spinner';

class EditProfilePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deleting: false,
      saving: false,
      name: props.profile.get('name'),
      facebookUrl: props.profileConfig.get('facebookPageUrl')
        ? props.profileConfig.get('facebookPageUrl').get('value')
        : '',
      youtubePageUrl: props.profileConfig.get('youtubePageUrl')
        ? props.profileConfig.get('youtubePageUrl').get('value')
        : '',
      twitterUrl: props.profileConfig.get('twitterPageUrl')
        ? props.profileConfig.get('twitterPageUrl').get('value')
        : '',
      domain: props.profile.get('url'),
      keywords: props.profileConfig.get('keywords')
        ? props.profileConfig.get('keywords').get('value')
        : ''
    };
  }

  triggerConfirm = (text, callback) => {
    Swal.fire({
      title: 'Are you sure?',
      text: text,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, I am sure!'
    }).then(result => {
      if (result.value) {
        callback();
      }
    });
  };

  triggerDeleteProfile = () => {
    const that = this;
    this.triggerConfirm(
      'This will delete this Profile and all competitors for it, this action cannot be recovered afterwards.',
      () => {
        that.setState({ deleting: true });
        this.props.attemptRemoveCurrentProfile(() => {
          that.setState({ deleting: false });
        });
      }
    );
  };

  setField = fieldName => e => {
    this.setState({ [fieldName]: e.target.value });
  };

  saveForm = () => {
    const that = this;
    this.triggerConfirm(
      'Changing profile URLs, social accounts and keywords can cause data to be deleted for the old accounts. This data cannot be restored afterwards.',
      () => {
        that.setState({ saving: true });
        this.props.attemptUpdateCurrentProfile(
          {
            name: that.state.name,
            facebookUrl: that.state.facebookUrl,
            twitterUrl: that.state.twitterUrl,
            youtubePageUrl: that.state.youtubePageUrl,
            domain: that.state.domain,
            keywords: that.state.keywords
          },
          () => {
            that.setState({ saving: false });
          }
        );
      }
    );
  };

  render() {
    const { profile, keywordsLeft, keywordsUsed } = this.props;

    return (
      <div>
        <div className="row" style={{ marginBottom: '10px' }}>
          <div className="col-xl-12" style={{ textAlign: 'center' }}>
            <h1 className={'h4'} style={{ marginBottom: 0 }}>
              Editing {profile.get('name')}
            </h1>
            <h2 className={'h5 textLighter thin'}>
              Update your cofiguration, social accounts and keyword search terms
              for this profile.
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Block
              title={'General'}
              blockItems={[
                {
                  key: 'i_1',
                  isJsx: true,
                  value: (
                    <Button
                      icon="fas fa-trash"
                      danger
                      thin
                      disabled={this.state.deleting || this.state.saving}
                      onClick={this.triggerDeleteProfile}
                    >
                      Delete profile
                    </Button>
                  )
                }
              ]}
            >
              <Form.Group controlId="name" className={'mrgBottom30'}>
                <Form.Label>
                  <strong>Company name</strong>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="e.g. Competito"
                  onChange={this.setField('name')}
                  value={this.state.name}
                />
              </Form.Group>

              <Form.Group controlId="domain">
                <Form.Label>
                  <strong>Website address</strong>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="e.g. https://competito.app or competito.app"
                  onChange={this.setField('domain')}
                  value={this.state.domain}
                />
              </Form.Group>
            </Block>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Block title={'Social accounts'}>
              <Form.Group controlId="facebookPageUrl" className={'mrgBottom30'}>
                <Form.Label>
                  <strong>Facebook page</strong>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="e.g. https://facebook.com/competito"
                  onChange={this.setField('facebookUrl')}
                  value={this.state.facebookUrl}
                />
              </Form.Group>

              <Form.Group controlId="youtubePageUrl" className={'mrgBottom30'}>
                <Form.Label>
                  <strong>YouTube page</strong>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="e.g. https://youtube.com/channel/dfsdf"
                  onChange={this.setField('youtubePageUrl')}
                  value={this.state.youtubePageUrl}
                />
              </Form.Group>

              <Form.Group controlId="twitterPageUrl">
                <Form.Label>
                  <strong>Twitter page</strong>
                </Form.Label>

                <Form.Control
                  type="text"
                  placeholder="e.g. https://twitter.com/competito"
                  onChange={this.setField('twitterUrl')}
                  value={this.state.twitterUrl}
                />
              </Form.Group>
            </Block>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Block title={'Keywords'}>
              <Form.Group controlId="keywords">
                <Form.Label>
                  <strong>Keywords</strong>
                </Form.Label>
                <Form.Control
                  type="textarea"
                  rows={10}
                  as="textarea"
                  placeholder="e.g. competitor monitoring, watch my competition"
                  onChange={this.setField('keywords')}
                  value={this.state.keywords}
                />

                <Form.Text className="text-muted">
                  Keywords are seperated by a new line or a comma. <br />
                  You are able to monitor {keywordsLeft} keywords for this
                  profile ({keywordsUsed} already used as part of your service
                  plan).
                </Form.Text>
              </Form.Group>
            </Block>
          </div>
        </div>

        <div className="row">
          <div className="cold-md-12">
            <Button
              size="sm"
              primary
              type="submit"
              style={{ marginBottom: '20px', marginLeft: '15px' }}
              disabled={this.state.saving || this.state.deleting}
              onClick={this.saveForm}
            >
              {this.state.saving ? <Spinner center /> : 'Update'}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default EditProfilePage;
