import React, { Component } from 'react';
import Block from 'components/presentation/block';
import Button from 'components/button/button';
import moment from 'moment';
import Swal from 'sweetalert2';
import Alert from 'components/alert/alert';
import { Link } from 'react-router-dom';

class ManageCompetitorsPage extends Component {
  state = { deleting: false };

  triggerConfirm = callback => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, I am sure!'
    }).then(result => {
      if (result.value) {
        callback();
      }
    });
  };

  onEditCompetitor = competitorId => {
    console.log('Editing competiror', competitorId);
  };

  triggerDeleteCompetitor = competitorId => {
    const that = this;
    this.triggerConfirm(() => {
      that.setState({ deleting: true });
      this.props.attemptRemoveCompetitor(competitorId, () => {
        that.setState({ deleting: false });
      });
    });
  };

  renderCompetitorRow = competitor => {
    const { deleting } = this.state;

    return (
      <tr key={competitor.normalisedDomain}>
        <td style={{ verticalAlign: 'middle' }}>
          <img
            src={competitor.faviconUrl}
            style={{ maxWidth: '15px', maxHeight: '15px' }}
            alt=""
          />{' '}
          {competitor.normalisedDomain.length > 20
            ? competitor.normalisedDomain.substr(0, 20) + '...'
            : competitor.normalisedDomain}
        </td>
        <td
          style={{ verticalAlign: 'middle' }}
          className="d-none d-lg-table-cell"
        >
          {moment(competitor.addedOn).format('MMMM Do YYYY, h:mm a')}
        </td>
        <td style={{ textAlign: 'right' }}>
          <Link to={'/competitors/' + competitor.competitorId + '/edit'}>
            <Button icon="fas fa-pencil" secondary disabled={deleting} thin>
              Edit
            </Button>
          </Link>{' '}
          <Button
            icon="fas fa-trash"
            onClick={() =>
              this.triggerDeleteCompetitor(competitor.competitorId)
            }
            danger
            disabled={deleting}
            thin
          >
            Delete
          </Button>
        </td>
      </tr>
    );
  };

  renderCompetitorRows = competitors => {
    const competitorsResponse = [];
    competitors.forEach(competitor => {
      competitorsResponse.push(this.renderCompetitorRow(competitor.toJS()));
    });
    return competitorsResponse;
  };

  renderTable = () => {
    const { competitors } = this.props;
    const competitorRows = this.renderCompetitorRows(competitors);

    return (
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Competitor</th>
            <th className="d-none d-lg-table-cell">Added</th>
            <th style={{ textAlign: 'right' }}>Actions</th>
          </tr>
        </thead>
        <tbody>{competitorRows}</tbody>
      </table>
    );
  };

  renderNoCompetitorsWarning = () => {
    return (
      <Alert variant="info">No competitors to view, please add one.</Alert>
    );
  };

  render() {
    const { profile, competitors } = this.props;
    return (
      <div>
        <div className="row" style={{ marginBottom: '30px' }}>
          <div className="col-xl-12" style={{ textAlign: 'center' }}>
            <h1 className={'h4'} style={{ marginBottom: 0 }}>
              Manage competitors for {profile.name}
            </h1>
            <h2 className={'h5 textLighter thin'}>
              Update your competitor settings
            </h2>
          </div>
        </div>

        <div className="row">
          <Block
            title={profile.name}
            blockItems={[
              {
                key: 'i_1',
                isJsx: true,
                value: (
                  <Link to={'/edit-profile'}>
                    <Button
                      icon="fas fa-pencil"
                      secondary
                      thin
                      disabled={this.state.deleting}
                    >
                      Edit profile
                    </Button>
                  </Link>
                )
              }
            ]}
          >
            {competitors.size > 0 && this.renderTable()}
            {competitors.size === 0 && this.renderNoCompetitorsWarning()}
          </Block>
        </div>
      </div>
    );
  }
}

export default ManageCompetitorsPage;
