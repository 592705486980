import React, { Component } from 'react';
import { globalModalSelector } from 'selectors/globalModalSelectors';
import { connect } from 'react-redux';
import { clearModal } from 'actions/globalModalActions';
import styles from './globalModal.module.scss';

class GlobalModal extends Component {
  render() {
    const { modal, clearModal } = this.props;

    if (!modal || !modal.get('isShown')) {
      return null;
    }

    const Component = modal.get('modalComponent');
    return (
      <div className={styles.modalWrapper}>
        <Component {...modal.modalProps} clearModal={clearModal} />
      </div>
    );
  }
}

const mapStateToProps = state => globalModalSelector(state);
export default connect(
  mapStateToProps,
  { clearModal }
)(GlobalModal);
