import React from 'react';
import { Heading } from '../inspectorEventWrapper';

const req = require.context(
  'components/timeline/event/definition',
  true,
  /.*.js/
);

export default function DefaultInspector(props) {
  let Component = req('./' + props.eventData.eventType + 'Event.js').default;

  return (
    <div>
      <Heading eventId={props.eventData.eventId} />
      <Component
        event={props.eventData}
        isInspector={true}
        eventProps={props.eventData.props}
        eventMeta={props.eventMeta}
      />
    </div>
  );
}
