import React from 'react';
import Gatekeeper from 'components/permissions/gatekeeper';
import { ROLE_JESSLE } from 'utils/permissions/permissions';
import styles from './shareButton.module.scss';
import Swal from 'sweetalert2';
import Icon from 'components/fontAwesome/icon';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { createAxios } from 'utils/axios/axiosUtils';

export default function AddToDemoButton({ eventId }) {
  const addToDemo = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This will add this to the example timeline for new customers.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, I am sure!'
    }).then(async result => {
      if (result.value) {
        try {
          await createAxios(true, true).post('example-timeline/' + eventId);
          Swal.fire({
            type: 'success',
            title: 'Added',
            text: 'This has now been added.'
          });
        } catch (e) {
          console.warn(e);
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: 'Something went wrong trying to add this to the demo account.'
          });
        }
      }
    });
  };

  return (
    <Gatekeeper noDemoAccount role={ROLE_JESSLE}>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id={`tooltip-overlay`}>Add to demo timeline</Tooltip>}
      >
        <span onClick={addToDemo} className={styles.buttonBlock}>
          <Icon name="fal fa-plus" />
        </span>
      </OverlayTrigger>
    </Gatekeeper>
  );
}
