import React, { Component } from 'react';
import styles from './timelinePage.module.scss';
import TimelineEventComponent from 'components/timeline/event/timelineEventComponent';
import Spinner from 'components/spinner/spinner';
import InfiniteScroll from 'components/infinite-scroll/infiniteScroll';
import debounce from 'lodash/debounce';
import cx from 'classnames';
import ExampleModeWarning from 'components/timeline/exampleModeWarning';
import LiveDemoWarning from 'components/demo/liveDemoWarning';
import TimelineFilterPanel from 'components/timelineFilters/timelineFilterPanel';

class TimelinePage extends Component {
  state = { isLoadingMore: false };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.state.isLoadingMore &&
      (nextProps.canLoadMore !== this.props.canLoadMore ||
        nextProps.events.length !== this.props.events.length)
    ) {
      this.setState({ isLoadingMore: false });
    }
  }

  renderTimelineEvents = () => {
    const { events, user, exampleMode } = this.props;

    if (!events || !events.length) {
      return (
        <div style={{ marginTop: '30px', textAlign: 'center' }}>
          <p className="h4" style={{ marginTop: '30px', marginBottom: 0 }}>
            No result found for this filter
          </p>
        </div>
      );
    }

    return (
      <ul className={styles.timeline}>
        {events.map(ev => (
          <TimelineEventComponent
            key={ev.eventId}
            event={ev}
            exampleMode={exampleMode}
            user={user}
          />
        ))}
      </ul>
    );
  };

  debouncedLoadMore = debounce(() => this.props.loadMore(), 200);

  loadMore = () => {
    this.setState({ isLoadingMore: true });
    this.debouncedLoadMore();
  };

  renderHasUpdatesButton = () => {
    const { hasUpdatesCount, loadNew } = this.props;

    if (!hasUpdatesCount) {
      return null;
    }

    return (
      <button
        className={cx(
          'btn btn-block btn-lg animated fadeIn',
          styles.loadNewEventsButton
        )}
        onClick={loadNew}
      >
        Load <strong>{hasUpdatesCount}</strong> new{' '}
        {hasUpdatesCount > 1 ? 'events' : 'event'}
      </button>
    );
  };

  render() {
    const {
      canLoadMore,
      competitors,
      exampleMode,
      fetchTimelineEvents,
      applyFilterToTimeline,
      history
    } = this.props;
    const { isLoadingMore } = this.state;

    return (
      <div className={styles.timelineContainer}>
        <TimelineFilterPanel
          competitors={competitors}
          exampleMode={exampleMode}
          history={history}
          fetchTimelineEvents={fetchTimelineEvents}
          applyFilterToTimeline={applyFilterToTimeline}
        />
        {exampleMode === true && <ExampleModeWarning />}

        <LiveDemoWarning style={{ marginBottom: '30px' }} />

        {this.renderHasUpdatesButton()}

        <InfiniteScroll
          throttle={100}
          threshold={300}
          isLoading={isLoadingMore}
          hasMore={canLoadMore}
          onLoadMore={this.loadMore}
        >
          {this.renderTimelineEvents()}
        </InfiniteScroll>

        {isLoadingMore && (
          <div style={{ marginBottom: '20px' }}>
            <Spinner center />
          </div>
        )}
      </div>
    );
  }
}

export default TimelinePage;
