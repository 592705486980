export const REQUEST_COMPETITOR_CONFIG = 'app:REQUEST_COMPETITOR_CONFIG';
export function requestCompetitorConfig(competitorId) {
  return {
    type: REQUEST_COMPETITOR_CONFIG,
    competitorId
  };
}

export const RECEIVE_COMPETITOR_CONFIG = 'app:RECEIVE_COMPETITOR_CONFIG';
export function receiveCompetitorConfig(competitorId, competitorConfig) {
  return {
    type: RECEIVE_COMPETITOR_CONFIG,
    competitorId,
    competitorConfig
  };
}

export const STORE_COMPETITORS_CONFIG = 'app:STORE_COMPETITORS_CONFIG';
export function storeCompetitorsConfig(competitorsConfig) {
  return {
    type: STORE_COMPETITORS_CONFIG,
    competitorsConfig
  };
}

export const HANDLE_BAD_COMPETITOR_CONFIG_RESPONSE =
  'app:HANDLE_BAD_COMPETITOR_CONFIG_RESPONSE';
export function handleBadCompetitorConfigResponse() {
  return {
    type: HANDLE_BAD_COMPETITOR_CONFIG_RESPONSE
  };
}

export const ATTEMPT_UPDATE_COMPETITOR_CONFIG =
  'app:ATTEMPT_UPDATE_COMPETITOR_CONFIG';
export function attemptUpdateCompetitorConfig(competitorId, data, cb) {
  return {
    type: ATTEMPT_UPDATE_COMPETITOR_CONFIG,
    competitorId,
    data,
    cb
  };
}
