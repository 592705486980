import React, { Component } from 'react';
import Icon from 'components/fontAwesome/icon';
import styles from './alert.module.scss';
import AlertComponent from 'react-bootstrap/Alert';
import cx from 'classnames';

const VARIANT_ICONS = {
  danger: 'fas fa-bug',
  warning: 'fas fa-exclamation-triangle',
  success: 'fas fa-check',
  info: 'fas fa-info'
};

class Alert extends Component {
  renderBootstrapAlert = () => {
    const { children, variant, className, style } = this.props;

    return (
      <AlertComponent
        variant={variant}
        className={cx(styles.alert, className)}
        style={style}
      >
        <div style={{ flex: '1 1 auto !important', marginRight: '10px' }}>
          {children}
        </div>
        <div>
          {VARIANT_ICONS[variant] && (
            <Icon name={VARIANT_ICONS[variant]} size="2x" className="mgr10" />
          )}
        </div>
      </AlertComponent>
    );
  };

  renderTw1Alert = () => {
    const { children, variant, className, style } = this.props;

    return (
      <section
        style={style}
        className={cx(styles.alerttw, className, {
          [styles.alerttwInfo]: variant === 'info',
          [styles.alerttwSuccess]: variant === 'success',
          [styles.alerttwDanger]: variant === 'danger',
          [styles.alerttwWarning]: variant === 'warning'
        })}
      >
        {VARIANT_ICONS[variant] && (
          <Icon name={VARIANT_ICONS[variant]} className={styles.twfa} />
        )}
        {children}
      </section>
    );
  };

  render() {
    const { type } = this.props;

    switch (type) {
      case 'tw1':
        return this.renderTw1Alert();
      default:
        return this.renderBootstrapAlert();
    }
  }
}

export default Alert;
