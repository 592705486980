import Button from 'components/button/button';
import React, { useState } from 'react';
import { createAxios } from 'utils/axios/axiosUtils';
import Swal from 'sweetalert2';

export default function MarkAppNotificationButton(props) {
  const [isDisabled, setIsDisabled] = useState(false);

  const { refreshNotifications, notification } = props;

  const isUnread = notification.readTime < 1;
  const btnLabel = isUnread ? 'Mark read' : 'Mark unread';
  const icon = isUnread ? 'fa-check' : 'fa-times';

  const triggerMark = async () => {
    setIsDisabled(true);

    const markAsRead = notification.readTime < 1;

    const response = await createAxios().put(
      'app-notifications/' + notification.notificationId,
      {
        readTime: markAsRead ? Math.floor(Date.now() / 1000) : 0
      }
    );

    if (response.data && response.data.success) {
      refreshNotifications();
      return;
    } else {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text:
          'Something went wrong trying to mark this read/unread. Try again later.'
      });
    }

    setIsDisabled(false);
  };

  return (
    <Button
      icon={'fas ' + icon}
      secondary={!isUnread}
      info={isUnread}
      disabled={isDisabled}
      thin
      onClick={triggerMark}
    >
      {btnLabel}
    </Button>
  );
}
