import { Map, List, fromJS } from 'immutable';
import {
  FETCH_KEYWORD_SUGGESTIONS_FOR_DOMAIN,
  RECEIVE_KEYWORD_SUGGESTIONS_FOR_DOMAIN,
  HANDLE_KEYWORD_SUGGESTIONS_FOR_DOMAIN_ERROR
} from 'actions/keywordSuggestionActions';

const DEFAULT_DOMAIN_DATA = fromJS({
  error: false,
  suggestions: List(),
  isLoading: true
});

const initialState = fromJS({
  data: Map({})
});

export default function keywordSuggestionReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_KEYWORD_SUGGESTIONS_FOR_DOMAIN:
      return state.setIn(['data', action.domain], DEFAULT_DOMAIN_DATA);
    case HANDLE_KEYWORD_SUGGESTIONS_FOR_DOMAIN_ERROR:
      return state
        .setIn(['data', action.domain, 'isLoading'], false)
        .setIn(['data', action.domain, 'error'], true);
    case RECEIVE_KEYWORD_SUGGESTIONS_FOR_DOMAIN:
      return state
        .setIn(['data', action.domain, 'isLoading'], false)
        .setIn(['data', action.domain, 'error'], false)
        .setIn(
          ['data', action.domain, 'suggestions'],
          fromJS(action.suggestions)
        );
    default:
      return state;
  }
}
