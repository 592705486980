import React from 'react';
import TimelineEventTime from 'components/timeline/event/timelineEventTime';
import cx from 'classnames';
import Icon from 'components/fontAwesome/icon';
import { EVENT_TYPE_MAP } from 'utils/timelineUtils';
import styles from 'components/page/eventInspectorPage.module.scss';
import { Link, useLocation } from 'react-router-dom';
import ShareButton from 'components/button/shareButton';
import DeleteEventButton from 'components/button/deleteEventButton';
import AddToDemoButton from 'components/button/addToDemoButton';

function EventTitle(props) {
  const event = props.eventData;
  const eventMap = EVENT_TYPE_MAP[event.eventType];
  return (
    <div className={styles.titleWrapper}>
      <div
        className={cx(styles.eventIcon)}
        style={{ backgroundColor: eventMap.iconBgColor }}
      >
        <Icon name={eventMap.icon} size="2x" />
      </div>
      <div>
        <TimelineEventTime time={event.representativeTime} />
        <h3 className={styles.title}>
          {event.favicon && (
            <img src={event.favicon} alt="" className={styles.favi} />
          )}{' '}
          {props.title}
        </h3>
      </div>
    </div>
  );
}

export function Heading({ eventId, isIndividual }) {
  const { pathname } = useLocation();
  return (
    <div className={styles.topBar}>
      <div>
        &raquo;
        <Link
          to="/timeline"
          className={styles.backLink}
          style={{ marginLeft: '5px' }}
        >
          Timeline
        </Link>{' '}
        {isIndividual === undefined && (
          <span style={{ color: '#999' }}>/ Event inspector</span>
        )}
        {isIndividual !== undefined && (
          <span style={{ color: '#999' }}>
            /{' '}
            <Link
              to={pathname}
              className={styles.backLink}
              style={{ marginRight: '5px' }}
            >
              Event inspector
            </Link>
          </span>
        )}
        {isIndividual !== undefined && (
          <span style={{ color: '#999' }}>/ Viewer</span>
        )}
      </div>
      <div className={styles.buttonHolder}>
        <ShareButton />
        <DeleteEventButton eventId={eventId} />
        <AddToDemoButton eventId={eventId} />
      </div>
    </div>
  );
}

export default function InspectorEventWrapper(props) {
  return (
    <div>
      <Heading {...props} eventId={props.eventData.eventId} />

      <EventTitle {...props} />

      <div className={styles.eventBody}>{props.children}</div>
    </div>
  );
}
