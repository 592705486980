import React, { Component } from 'react';
import SideContentWithGraphics from 'components/loggedOutWrappers/sideContentWithGraphics';
import Spinner from 'components/spinner/spinner';
import Alert from 'components/alert/alert';
import { createAxios } from 'utils/axios/axiosUtils';
import { setAuthToken } from 'utils/localStorage/localStorage';
import { parse } from 'query-string';

class SignupConfirmContainer extends Component {
  state = { errorMessage: '' };

  componentDidMount() {
    this.checkDetails();
  }

  async checkDetails() {
    const {
      location: { search }
    } = this.props;

    const params = parse(search);

    const verifyToken = params['verify-token'];
    const accountId = params['accountId'];
    const email = params.email;
    const planId = params.planId;

    try {
      let response = await createAxios(false, true).post('signup/confirm', {
        email,
        verifyToken,
        accountId,
        planId
      });

      if (response && response.data && response.data.success) {
        setAuthToken(response.data.token);
        window.location.href = '/';
      } else {
        this.setState({
          errorMessage: 'Could not verify email. Please try again.'
        });
      }
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.errorMessage &&
        e.response.data.errorMessage.length
      ) {
        this.setState({ errorMessage: e.response.data.errorMessage });
      } else {
        this.setState({
          errorMessage: 'Could not verify email. Please try again.'
        });
      }
      return;
    }
  }

  render() {
    return (
      <SideContentWithGraphics>
        <br />
        {!this.state.errorMessage.length && <Spinner center />}

        {this.state.errorMessage.length > 0 && (
          <Alert variant="danger">{this.state.errorMessage}</Alert>
        )}
      </SideContentWithGraphics>
    );
  }
}

export default SignupConfirmContainer;
