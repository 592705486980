import React, { Component } from 'react';
import Gravatar from 'components/avatar/gravatarWrapper';
import { Link } from 'react-router-dom';
import styles from './eventCommentNotificationBlock.module.scss';

class EventCommentNotificationBlock extends Component {
  findUser = () => {
    const { users, notification } = this.props;
    const props = notification.props;
    return (
      users &&
      Object.values(users).find(user => user.userId === props.postUserId)
    );
  };

  renderCommentSubheading = (user, props) => {
    const eventId = props.eventId;
    const commentType = props.commentType;
    const profileId = props.profileId;

    const profileInfo = profileId > 0 ? '?profileId=' + profileId : '';

    if (commentType === 'mention') {
      return (
        <span>
          {user ? user.name : 'Unknown'} mentioned you in an{' '}
          <Link to={'/event-inspector/' + eventId + '/default' + profileInfo}>
            event
          </Link>
        </span>
      );
    }

    return (
      <span>
        {user ? user.name : 'Unknown'} commented on an{' '}
        <Link to={'/event-inspector/' + eventId + '/default' + profileInfo}>
          event
        </Link>{' '}
        you are subscribed to
      </span>
    );
  };

  render() {
    const { notification } = this.props;

    const user = this.findUser();

    const props = notification.props;
    const comment = props.comment;

    const avatar =
      user === null || user === undefined ? null : (
        <Gravatar
          email={user.email}
          default="mm"
          protocol="https://"
          className={styles.gravatarIcon}
          size={35}
        />
      );

    return (
      <div>
        {avatar} {this.renderCommentSubheading(user, props)}
        <div className={styles.comment}>
          {comment || 'This message has been deleted.'}
        </div>
      </div>
    );
  }
}

export default EventCommentNotificationBlock;
