import React, { Component } from 'react';
import Block from 'components/presentation/block';
import Button from 'components/button/button';

class AccountIntegrationsPage extends Component {
  findIntegration = type => {
    return (
      this.props.integrations &&
      this.props.integrations.find(item => item.get('type') === type)
    );
  };

  renderSlackIntegration = () => {
    const slackIsSetup = this.findIntegration('slack');

    return (
      <div>
        <p>
          Competito can integrate with Slack to send major updates in real-time
          directly into a channel of your choice.
        </p>
        {!slackIsSetup && (
          <p>
            Status:{' '}
            <strong style={{ color: 'darkred' }}>Not configured.</strong>
          </p>
        )}
        {slackIsSetup && (
          <p>
            Status: <strong style={{ color: 'darkgreen' }}>Configured.</strong>
          </p>
        )}

        {!slackIsSetup && (
          <a
            href={
              'https://slack.com/oauth/authorize?client_id=620281219410.821650787376&scope=incoming-webhook&redirect_uri=https://parliament.competito.app/api/slack/oauth&state=' +
              this.props.authToken
            }
          >
            <img
              alt="Add to Slack"
              height="40"
              width="139"
              src="https://platform.slack-edge.com/img/add_to_slack.png"
              srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
            />
          </a>
        )}

        {slackIsSetup && (
          <Button
            thin
            danger
            onClick={() => this.props.removeIntegration('slack')}
          >
            Unlink from Slack
          </Button>
        )}
      </div>
    );
  };

  render() {
    return (
      <div>
        <div className="row" style={{ marginBottom: '30px' }}>
          <div className="col-xl-12" style={{ textAlign: 'center' }}>
            <h1 className={'h4'} style={{ marginBottom: 0 }}>
              Integration management
            </h1>
            <h2 className={'h5 textLighter thin'}>
              This area allows you to add, edit and remove integrations.
            </h2>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <Block title={'Slack'}>{this.renderSlackIntegration()}</Block>
          </div>
        </div>
      </div>
    );
  }
}

export default AccountIntegrationsPage;
