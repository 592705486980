import { call, takeLatest } from 'redux-saga/effects';
import { CHECKOUT_PLAN } from 'actions/planActions';
import Swal from 'sweetalert2';
import { createAxios } from 'utils/axios/axiosUtils';
import { getStripeKey } from 'utils/stripe/stripeUtils';

export default function* checkoutPlanSaga() {
  yield takeLatest(CHECKOUT_PLAN, checkoutPlan);
}

function* checkoutPlan({ planId, failCb }) {
  try {
    const response = yield call(getResponse, planId);
    if (response.data && response.data.sessionId) {
      const stripe = window.Stripe(getStripeKey());
      if (!stripe) {
        console.warn(
          'You are missing stripe import, this cannot be fulfilled.'
        );

        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text:
            'Something went wrong trying to purchase this plan. Try again shortly.'
        });

        failCb && failCb();
        return;
      }

      yield call(callStripe, stripe, response.data.sessionId);

      // I guess if we got here, we must have a problem, we should have been redirected!
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text:
          'Something went wrong trying to purchase this plan. Try again shortly.'
      });

      failCb && failCb();
    } else {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text:
          'Something went wrong trying to purchase this plan. Try again shortly.'
      });
      failCb && failCb();
    }
  } catch (e) {
    console.warn(e);

    Swal.fire({
      type: 'error',
      title: 'Oops...',
      text:
        'Something went wrong trying to purchase this plan. Try again shortly.'
    });
    failCb && failCb();
  }
}

function* callStripe(stripe, sessionId) {
  return yield call(stripe.redirectToCheckout, {
    sessionId: sessionId
  });
}

async function getResponse(planId) {
  return await createAxios().post('checkout/' + planId);
}
