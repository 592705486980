import React from 'react';
import styles from './timelineFilterPanel.module.scss';
import Select, { components } from 'react-select';
import cx from 'classnames';
import Icon from 'components/fontAwesome/icon';
import { possibleUpdates, exampleCompetitorData } from './filterUtils';

function getUpdatesOptions(queryParams) {
  const updateOptions = possibleUpdates.map(item => {
    return {
      value: item.eventTypeId,
      label: item.text.toLowerCase(),
      isChild: item.isChild
    };
  });

  let defaultUpdatesValue = updateOptions[0];
  const eventTypeId = queryParams.get('eventTypeId');
  if (eventTypeId) {
    defaultUpdatesValue = updateOptions.find(
      item => item.value === eventTypeId
    );
  }

  return { defaultUpdatesValue, updateOptions };
}

function getRivalOptions(queryParams, competitors, exampleMode) {
  const rivalOptions = [{ value: '', label: 'all my rivals' }];

  const filteredCompetitors = exampleMode
    ? exampleCompetitorData
    : competitors.toJS();

  Object.values(filteredCompetitors).forEach(competitor => {
    const label = (
      <span>
        <img src={competitor.faviconUrl} alt="" className={styles.favi} />{' '}
        <span>
          {competitor.name.length > 12
            ? competitor.name.substr(0, 12) + '...'
            : competitor.name}
        </span>
      </span>
    );
    rivalOptions.push({ value: competitor.competitorId, label: label });
  });

  let defaultRivalsValue = rivalOptions[0];
  let competitorId = queryParams.get('competitorId');
  if (competitorId) {
    defaultRivalsValue = rivalOptions.find(
      item => competitorId === item.value.toString()
    );
  }
  return { defaultRivalsValue, rivalOptions };
}

function getDropdownStyles() {
  return {
    menu: provided => ({
      ...provided,
      fontSize: '16px',
      minWidth: '250px',
      color: '#f8485e'
    }),
    option: (provided, state) => {
      return {
        ...provided,
        textAlign: 'left',
        paddingLeft: state.data.isChild ? '40px' : '20px'
      };
    },
    control: provided => ({
      ...provided,
      minWidth: '180px',
      margin: '0 10px',
      backgroundImage:
        'linear-gradient(to right, white 33%, rgba(0, 0, 0, 0) 0%)',
      backgroundPosition: 'bottom',
      backgroundSize: '5px 2px',
      backgroundRepeat: 'repeat-x',
      backgroundColor: 'transparent',
      border: '0 !important',
      boxShadow: '0 !important'
    }),
    indicatorSeparator: () => ({
      border: 0
    }),
    indicatorsContainer: () => ({
      fontSize: '16px',
      lineHeight: '20px'
    }),
    valueContainer: provided => ({
      ...provided,
      paddingTop: 0
    }),
    singleValue: provided => ({
      ...provided,
      color: '#f8485e'
    })
  };
}

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon name="fas fa-chevron-circle-down" style={{ color: '#f8485e' }} />
    </components.DropdownIndicator>
  );
};

export default function TimelineFilterPanel({
  fetchTimelineEvents,
  applyFilterToTimeline,
  competitors,
  exampleMode,
  accountAddedOn,
  history
}) {
  const queryParams = new URLSearchParams(history.location.search);

  const { defaultUpdatesValue, updateOptions } = getUpdatesOptions(queryParams);
  const { defaultRivalsValue, rivalOptions } = getRivalOptions(
    queryParams,
    competitors,
    exampleMode
  );

  const dropdownStyles = getDropdownStyles();

  const onChangeEvent = value => {
    const eventTypeId = value.value;
    const update = possibleUpdates.find(
      item => item.eventTypeId === eventTypeId
    );

    const urlSearchParams = new URLSearchParams(history.location.search);
    if (eventTypeId.length > 0) {
      urlSearchParams.set('eventTypeId', eventTypeId);
    } else {
      urlSearchParams.delete('eventTypeId');
    }

    history.push({
      pathname: history.pathname,
      search: urlSearchParams.toString()
    });
    applyFilterToTimeline('eventTypes', update.eventTypes);
    fetchTimelineEvents(true);
  };

  const onChangeRivals = value => {
    let competitorId = value.value;
    const urlSearchParams = new URLSearchParams(history.location.search);
    if (competitorId) {
      urlSearchParams.set('competitorId', competitorId);
    } else {
      urlSearchParams.delete('competitorId');
    }

    history.push({
      pathname: history.pathname,
      search: urlSearchParams.toString()
    });
    applyFilterToTimeline('competitorId', value.value);
    fetchTimelineEvents(true);
  };

  return (
    <div className={cx(styles.panel)}>
      <div className={cx(styles.primaryLine)}>
        <span className={styles.niceText}>Show me</span>{' '}
        <div className={styles.selectWrapper}>
          <Select
            defaultValue={defaultUpdatesValue}
            onChange={onChangeEvent}
            options={updateOptions}
            components={{ DropdownIndicator }}
            isSearchable={false}
            styles={dropdownStyles}
          />
        </div>{' '}
        from{' '}
        <div className={styles.selectWrapper}>
          <Select
            defaultValue={defaultRivalsValue}
            onChange={onChangeRivals}
            options={rivalOptions}
            components={{ DropdownIndicator }}
            isSearchable={false}
            styles={dropdownStyles}
          />
        </div>
      </div>
      <p className={styles.secondLine}>
        <span title="Quick links" role="img" aria-label="Quick links">
          ⚡
        </span>{' '}
        <button
          onClick={() => onChangeEvent({ value: '' })}
          style={{ marginLeft: '5px' }}
        >
          All updates
        </button>{' '}
        |{' '}
        <button onClick={() => onChangeEvent({ value: 'website-updates' })}>
          Website updates
        </button>{' '}
        |{' '}
        <button onClick={() => onChangeEvent({ value: 'new-webpages' })}>
          New webpages
        </button>{' '}
        |{' '}
        <button onClick={() => onChangeEvent({ value: 'social-updates' })}>
          Social updates
        </button>{' '}
        |{' '}
        <button onClick={() => onChangeEvent({ value: 'keyword-updates' })}>
          Keyword updates
        </button>
      </p>
    </div>
  );
}
