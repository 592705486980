import { takeLatest } from 'redux-saga/effects';
import { LOGOUT } from 'actions/authActions';
import { removeAuthToken } from 'utils/localStorage/localStorage';

export default function* logoutSaga() {
  yield takeLatest(LOGOUT, handleLogout);
}

function* handleLogout() {
  yield removeAuthToken();
  window.location.href = '/login';
}
