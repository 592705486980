import React, { Component } from 'react';
import styles from './tablePagination.module.scss';
import Icon from 'components/fontAwesome/icon';
import cx from 'classnames';

export default class TablePagination extends Component {
  renderGoBack = page => {
    const isDisabled = page === 1;

    return (
      <li
        className={cx(styles.pageLink, { [styles.disabled]: isDisabled })}
        onClick={() => this.handleClickPage(page - 1, isDisabled)}
      >
        <Icon name={'fa fa-angle-left'} />
      </li>
    );
  };

  renderButtons = (page, pages) => {
    const buttons = [];

    const highestPageToShow = Math.min(pages + 1, page + 6);

    for (let i = page; i < highestPageToShow; i++) {
      buttons.push(
        <li
          key={'button_' + i}
          className={cx(styles.pageLink, { [styles.selected]: page === i })}
          onClick={() => this.handleClickPage(i, page === i)}
        >
          {i}
        </li>
      );
    }

    return buttons;
  };

  renderDotDotDotBefore = (page, pages) => {
    if (page < 6) {
      return null;
    }

    return (
      <li
        className={cx(styles.pageLink)}
        onClick={() => this.handleClickPage(page - 5, false)}
      >
        ...
      </li>
    );
  };

  renderDotDotDotAfter = (page, pages) => {
    if (pages - page < 6) {
      return null;
    }

    return (
      <li
        className={cx(styles.pageLink)}
        onClick={() => this.handleClickPage(page + 5, false)}
      >
        ...
      </li>
    );
  };

  renderGoForward = (page, pages) => {
    const isDisabled = page >= pages;

    return (
      <li
        className={cx(styles.pageLink, { [styles.disabled]: isDisabled })}
        onClick={() => this.handleClickPage(page + 1, isDisabled)}
      >
        <Icon name={'fa fa-angle-right'} />
      </li>
    );
  };

  handleClickPage = (page, isDisabled) => {
    if (isDisabled) {
      return;
    }

    this.props.onClickPage && this.props.onClickPage(page);
  };

  render() {
    const { style, page, numRows, rowsPerPage } = this.props;

    const pages = Math.ceil(numRows / rowsPerPage);

    if (!pages || pages < 2) {
      return null;
    }

    return (
      <div className={styles.wrapper} style={style || {}}>
        <div>
          Page <strong>{page}</strong> of <strong>{pages}</strong>
        </div>
        <div>
          <ul>
            {this.renderGoBack(page)}
            {this.renderDotDotDotBefore(page, pages)}
            {this.renderButtons(page, pages)}
            {this.renderDotDotDotAfter(page, pages)}
            {this.renderGoForward(page, pages)}
          </ul>
        </div>
      </div>
    );
  }
}
