import React, { Component } from 'react';
import Spinner from './spinner';

class FullPageSpinner extends Component {
  render() {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%'
        }}
      >
        <Spinner {...this.props} />
      </div>
    );
  }
}

export default FullPageSpinner;
