import { createSelector } from 'reselect';

export const globalModalSelector = createSelector(
  state => state.globalModal,
  globalModal => {
    return {
      modal: globalModal
    };
  }
);

export const isGlobalModalActive = createSelector(
  state => state.globalModal,
  globalModal => {
    return globalModal.get('isShown');
  }
);
