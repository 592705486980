import React, { Component } from 'react';
import Block from 'components/presentation/block';
import Spinner from 'components/spinner/spinner';
import CompetitorSelectInput from 'components/competitor/competitorSelectInput';
import SimpleStat from 'components/stat/simpleStat';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import PagesViewer from 'components/inventory/pagesViewer';
import Button from 'components/button/button';
import Swal from 'sweetalert2';
import { ROLE_ACCOUNT_ADMIN } from 'utils/permissions/permissions';
import Gatekeeper from 'components/permissions/gatekeeper';
import LiveDemoWarning from 'components/demo/liveDemoWarning';

class InventoryPagesPage extends Component {
  selectCompetitor = competitorId => {
    if (competitorId && competitorId.length > 0) {
      this.props.onSelectCompetitor(competitorId);
    }
  };

  getCompetitorName = competitor => {
    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id={`tooltip-2`}>
            {competitor.name} - {competitor.normalisedDomain}
          </Tooltip>
        }
      >
        <span>
          <img
            src={competitor.faviconUrl}
            alt=""
            style={{
              height: '14px',
              width: '14px',
              marginRight: '3px'
            }}
          />

          {competitor.name.length > 30
            ? competitor.name.substr(0, 30) + '...'
            : competitor.name}
        </span>
      </OverlayTrigger>
    );
  };

  submitSearchForm = e => {
    e.preventDefault();

    this.props.pagination.setSearchTerm(
      document.getElementById('search-pages').value
    );
  };

  renderPagesData = () => {
    const { pages, pagination, setPageMonitoringState, stats } = this.props;

    return (
      <div className="row" key="table-data">
        <div className="col-md-12">
          <Block
            title={'Pages'}
            blockItems={[
              {
                key: 'i_1',
                isJsx: true,
                value: (
                  <Gatekeeper
                    noDemoAccount
                    role={ROLE_ACCOUNT_ADMIN}
                    key="buttons"
                  >
                    <form onSubmit={this.submitSearchForm}>
                      <input
                        style={{ maxWidth: '250px' }}
                        className="form-control"
                        type="text"
                        name="search"
                        id="search-pages"
                        defaultValue={pagination.searchTerm}
                        placeholder="Search for a page URL"
                      />
                    </form>
                  </Gatekeeper>
                )
              }
            ]}
          >
            <PagesViewer
              pages={pages}
              allowedToMonitorCount={stats.get('AllowedToMonitorCount')}
              monitoredPageCount={stats.get('MonitoredPageCount')}
              pagination={pagination}
              setPageMonitoringState={setPageMonitoringState}
            />
          </Block>
        </div>
      </div>
    );
  };

  renderStats = () => {
    const { stats } = this.props;

    return (
      <div className="row" key="stats">
        <div className="col-md-4">
          <SimpleStat
            iconName="fal fa-monitor-heart-rate"
            iconColor="#80008040"
            iconSize="3x"
            label="Pages monitoring"
            stat={
              stats.get('MonitoredPageCount') + ' / ' + stats.get('TotalRows')
            }
          />
        </div>

        <div className="col-md-4">
          <SimpleStat
            iconName="fal fa-funnel-dollar"
            iconColor="lightblue"
            iconSize="3x"
            label="Page allowance"
            stat={stats.get('AllowedToMonitorCount')}
          />
        </div>

        <div className="col-md-4">
          <SimpleStat
            iconName="fal fa-do-not-enter"
            iconColor="#f5acb1"
            iconSize="3x"
            label="Monitoring disabled"
            stat={stats.get('ExcludedPageCount')}
          />
        </div>
      </div>
    );
  };

  triggerAddPageModal = () => {
    const { competitorId, addPageForMonitoring } = this.props;

    Swal.fire({
      title: 'Insert page URL you wish to monitor',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Add page'
    }).then(result => {
      if (result.value) {
        addPageForMonitoring(competitorId, result.value);
      }
    });
  };

  renderButtons = () => {
    return (
      <Gatekeeper noDemoAccount role={ROLE_ACCOUNT_ADMIN} key="buttons">
        <div className="row">
          <div className="col-md-12">
            <Button
              primary
              thin
              size="sm"
              style={{ marginBottom: '20px' }}
              onClick={() => this.triggerAddPageModal()}
            >
              Add page
            </Button>
          </div>
        </div>
      </Gatekeeper>
    );
  };

  renderCompetitorInfo() {
    const { competitorId, competitors } = this.props;
    const competitor = competitors[competitorId];

    return (
      <div
        className="d-flex justify-content-between"
        style={{ marginBottom: '20px' }}
        key="comp-info"
      >
        <div style={{ paddingTop: '5px' }}>
          {this.getCompetitorName(competitor)}
        </div>

        <div>
          <Gatekeeper noDemoAccount role={ROLE_ACCOUNT_ADMIN} key="buttons">
            <CompetitorSelectInput
              onChange={this.selectCompetitor}
              competitors={competitors}
              style={{ color: '#777', maxWidth: '250px', float: 'right' }}
            />
          </Gatekeeper>
        </div>
      </div>
    );
  }

  renderLoadedData() {
    const {
      isLoadingPages,
      pagesLoadingError,
      isPerformingDecision
    } = this.props;
    if (isLoadingPages || pagesLoadingError || isPerformingDecision) {
      return null;
    }

    return [
      this.renderCompetitorInfo(),
      this.renderStats(),
      this.renderButtons(),
      this.renderPagesData()
    ];
  }

  render() {
    const {
      isLoadingPages,
      isPerformingDecision,
      pagesLoadingError,
      competitorId,
      competitors
    } = this.props;

    const competitor = competitors[competitorId];

    const nameStr = competitor ? ' for ' + competitor.name : '';

    return (
      <div>
        <div className="row" style={{ marginBottom: '30px' }}>
          <div className="col-xl-12" style={{ textAlign: 'center' }}>
            <h1 className={'h4'} style={{ marginBottom: 0 }}>
              Page inventory {nameStr}
            </h1>
            <h2 className={'h5 textLighter thin'}>
              View and administrate all web pages found by Competito for this
              competitor
            </h2>
          </div>
        </div>

        <LiveDemoWarning style={{ marginBottom: '30px' }} />

        {(isLoadingPages || isPerformingDecision) && <Spinner center />}
        {!isLoadingPages && pagesLoadingError && (
          <p>
            An issue occured trying to load this page. Please try again later.
          </p>
        )}

        {this.renderLoadedData()}
      </div>
    );
  }
}

export default InventoryPagesPage;
