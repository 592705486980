import { Map, fromJS } from 'immutable';
import {
  RECEIVE_COMPETITORS,
  REQUEST_COMPETITORS,
  ADD_COMPETITOR,
  HANDLE_ADD_COMPETITOR_FAILURE,
  HANDLE_COMPETITOR_ADD_RESPONSE,
  REMOVE_COMPETITOR
} from 'actions/competitorActions';
import { STORE_LOGIN } from 'actions/authActions';

const initialState = fromJS({
  data: Map({}),
  loading: false,
  addingCompetitor: false
});

export default function competitorReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_COMPETITORS:
      return state.set('loading', true);
    case STORE_LOGIN:
      return state
        .set('data', fromJS(action.data.competitors))
        .set('loading', false);
    case RECEIVE_COMPETITORS:
      return state
        .set('data', fromJS(action.competitors))
        .set('loading', false);
    case ADD_COMPETITOR:
      return state.set('addingCompetitor', true);
    case HANDLE_ADD_COMPETITOR_FAILURE:
      return state.set('addingCompetitor', false);
    case HANDLE_COMPETITOR_ADD_RESPONSE:
      const currentCompetitorId = action.response.competitorId.toString();

      if (state.get('data').size === 0) {
        return state
          .set('data', Map({ [currentCompetitorId]: fromJS(action.response) }))
          .set('addingCompetitor', false);
      }

      return state
        .setIn(['data', currentCompetitorId], fromJS(action.response))
        .set('addingCompetitor', false);
    case REMOVE_COMPETITOR:
      return state.deleteIn(['data', action.competitorId.toString()]);
    default:
      return state;
  }
}
