import React from 'react';
import PropTypes from 'prop-types';

let pusherClient;

// This was copied from https://github.com/rainforestapp/react-pusher
// as they don't seem to be able to maintain a 1 file npm module
export default class ReactPusher extends React.Component {
  static propTypes = {
    channel: PropTypes.string.isRequired,
    onUpdate: PropTypes.func.isRequired,
    event: PropTypes.string.isRequired
  };

  static channels = {};

  constructor(props) {
    super(props);
    if (!pusherClient) {
      throw new Error('you must set a pusherClient by calling setPusherClient');
    }
  }

  UNSAFE_componentWillMount() {
    this.bindPusherEvent(this.props.channel, this.props.event);
  }

  UNSAFE_componentWillReceiveProps({ channel: newChannel, event: newEvent }) {
    const { channel, event } = this.props;
    if (channel === newChannel && event === newEvent) {
      return;
    }

    this.bindPusherEvent(newChannel, newEvent);
    this.unbindPusherEvent(channel, event);
  }

  componentWillUnmount() {
    this.unbindPusherEvent(this.props.channel, this.props.event);
  }

  unbindPusherEvent(channel, event) {
    const pusherChannel = pusherClient.channels.find(channel);
    if (pusherChannel) {
      pusherChannel.unbind(event, this.props.onUpdate);
    }

    ReactPusher.channels[channel]--;
    if (ReactPusher.channels[channel] <= 0) {
      delete ReactPusher.channels[channel];
      pusherClient.unsubscribe(channel);
    }
  }

  bindPusherEvent(channel, event) {
    const pusherChannel =
      pusherClient.channels.find(channel) || pusherClient.subscribe(channel);
    pusherChannel.bind(event, this.props.onUpdate);

    if (ReactPusher.channels[channel] === undefined)
      ReactPusher.channels[channel] = 0;
    ReactPusher.channels[channel]++;
  }

  render() {
    return null;
  }
}

export const setPusherClient = client => {
  pusherClient = client;
};
