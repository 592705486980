export const STORE_LOGIN = 'app:STORE_LOGIN';
export function storeLogin(data, onSuccess = null) {
  return {
    type: STORE_LOGIN,
    data,
    onSuccess
  };
}

export const STORE_REAUTH = 'app:STORE_REAUTH';
export function storeReAuth(data) {
  return {
    type: STORE_REAUTH,
    data
  };
}

export const APP_IS_READY = 'app:APP_IS_READY';
export function triggerAppIsReady() {
  return {
    type: APP_IS_READY
  };
}

export const LOGOUT = 'app:LOGOUT';
export function logout() {
  return {
    type: LOGOUT
  };
}

export const FETCH_USER_CHANNEL_DATA = 'app:FETCH_USER_CHANNEL_DATA';
export function fetchUserChannelData() {
  return {
    type: FETCH_USER_CHANNEL_DATA
  };
}

export const RECEIVE_NOTIFICATION_CHANNEL = 'app:RECEIVE_NOTIFICATION_CHANNEL';
export function receiveNotificationChannel(notificationChannel) {
  return {
    type: RECEIVE_NOTIFICATION_CHANNEL,
    notificationChannel
  };
}

export const UPDATE_USER_CHANNEL = 'app:UPDATE_USER_CHANNEL';
export function updateUserChannel(data) {
  return {
    type: UPDATE_USER_CHANNEL,
    data
  };
}

export const SWITCH_ACCOUNT = 'app:SWITCH_ACCOUNT';
export function switchAccount(accountId) {
  return {
    type: SWITCH_ACCOUNT,
    accountId
  };
}

export const SET_EXAMPLE_MODE = 'app:SET_EXAMPLE_MODE';
export function setExampleMode(isEnabled) {
  return {
    type: SET_EXAMPLE_MODE,
    isEnabled
  };
}

export const REGISTER_ACCOUNT = 'app:REGISTER_ACCOUNT';
export function registerAccount(accountData, onSuccess, onFailure) {
  return {
    type: REGISTER_ACCOUNT,
    accountData,
    onSuccess,
    onFailure
  };
}

export const SEND_VERIFICATION_EMAIL = 'app:SEND_VERIFICATION_EMAIL';
export function sendVerificationEmail() {
  return {
    type: SEND_VERIFICATION_EMAIL
  };
}
