export const STORE_RPOFILES = 'app:STORE_RPOFILES';
export function storeProfiles(profiles) {
  return {
    type: STORE_RPOFILES,
    profiles
  };
}

export const SET_CURRENT_PROFILE_ID = 'app:SET_CURRENT_PROFILE_ID';
export function setCurrentProfileId(profileId) {
  return {
    type: SET_CURRENT_PROFILE_ID,
    profileId
  };
}

export const ADD_PROFILE = 'app:ADD_PROFILE';
export function addProfile(data, successCb) {
  return {
    type: ADD_PROFILE,
    data,
    successCb
  };
}

export const HANDLE_PROFILE_ADD_RESPONSE = 'app:HANDLE_PROFILE_ADD_RESPONSE';
export function handleAddProfileResponse(response) {
  return {
    type: HANDLE_PROFILE_ADD_RESPONSE,
    response
  };
}

export const HANDLE_ADD_PROFILE_FAILURE = 'app:HANDLE_ADD_PROFILE_FAILURE';
export function handleAddProfileFailure() {
  return {
    type: HANDLE_ADD_PROFILE_FAILURE
  };
}

export const ATTEMPT_DELETE_CURRENT_PROFILE =
  'app:ATTEMPT_DELETE_CURRENT_PROFILE';
export function attemptRemoveCurrentProfile(cb) {
  return {
    type: ATTEMPT_DELETE_CURRENT_PROFILE,
    cb
  };
}

export const ATTEMPT_UPDATE_CURRENT_PROFILE =
  'app:ATTEMPT_UPDATE_CURRENT_PROFILE';
export function attemptUpdateCurrentProfile(data, cb) {
  return {
    type: ATTEMPT_UPDATE_CURRENT_PROFILE,
    data,
    cb
  };
}
