import React from 'react';
import EventInspectorPage from 'components/page/eventInspectorPage';
import CentralWrapper from 'components/loginWrappers/centralWrapper';
import InspectorEventProvider from 'providers/inspectorEventProvider';

export default function EventInspectorContainer(props) {
  return (
    <CentralWrapper wide={true} noTopPadding={true}>
      <InspectorEventProvider>
        <EventInspectorPage />
      </InspectorEventProvider>
    </CentralWrapper>
  );
}
