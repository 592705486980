import React, { Component } from 'react';
import Block from 'components/presentation/block';
import Icon from 'components/fontAwesome/icon';

export default class WebsiteStatsForDomain extends Component {
  getIconForDelta = delta => {
    if (delta > 0) {
      return 'fas fa-caret-up';
    }

    if (delta < 0) {
      return 'fas fa-caret-down';
    }

    return 'fas fa-caret-left';
  };

  getColorForDelta = delta => {
    if (delta > 0) {
      return 'lightgreen';
    }

    if (delta < 0) {
      return 'lightred';
    }

    return '#999';
  };

  renderLineItems = filteredChange => {
    return filteredChange.map(line => {
      return (
        <tr key={line.name}>
          <td>{line.name}</td>
          <td>{line.value || 0}</td>
          <td>
            {line.delta === 0 && ' No change'}
            {line.delta === undefined && ' No change'}
            {line.delta > 0 && '+' + line.delta}
            {line.delta < 0 && line.delta}
            {line.delta !== 0 && line.delta !== undefined && (
              <Icon
                name={this.getIconForDelta(line.delta)}
                style={{
                  marginLeft: '10px',
                  color: this.getColorForDelta(line.delta)
                }}
              />
            )}
          </td>
        </tr>
      );
    });
  };

  render() {
    const { changeFrequencyData } = this.props;

    if (!changeFrequencyData) {
      return null;
    }

    let filteredChange = changeFrequencyData.filter(
      line => typeof line.delta !== undefined
    );

    return (
      <div className="row">
        <div className="col-md-12">
          <Block
            title={'Website change frequency'}
            help="Gain an understanding of how often your competitors are making changes to their website and compare those changes against the previous 30 days."
          >
            {filteredChange.length === 0 && (
              <p>Requires two months of data before reporting.</p>
            )}
            {filteredChange.length > 0 && (
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Changes this period</th>
                    <th>Delta</th>
                  </tr>
                </thead>
                <tbody>{this.renderLineItems(filteredChange)}</tbody>
              </table>
            )}
          </Block>
        </div>
      </div>
    );
  }
}
