import React from 'react';

export function NewsletterScreenshot({
  screenshotUrl,
  maxHeight,
  maxWidth,
  hideScroll,
  hideTitle
}) {
  return (
    <div>
      {hideTitle !== true && <strong>Newsletter: </strong>}

      <div
        style={{
          border: '1px solid #eee',
          overflow: hideScroll ? 'hidden' : 'scroll',
          maxHeight: maxHeight,
          maxWidth: maxWidth
        }}
      >
        <img src={screenshotUrl} alt="" style={{ width: '100%' }} />
      </div>
    </div>
  );
}

export default function NewsletterMeta({ from, subject, snippet }) {
  return (
    <div>
      <p>
        <strong>From: </strong>
        <br />
        {from}
      </p>
      <p>
        <strong>Subject: </strong>
        <br />
        {subject}
      </p>
      <p>
        <strong>Snippet: </strong>
        <br />
        {snippet}
      </p>
    </div>
  );
}
