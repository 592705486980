import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './accountSetupWizard.module.scss';
import { notOnMobile } from 'utils/appUtils';
import Form from 'react-bootstrap/Form';
import Button from 'components/button/button';
import { scoutUrl, clearScoutState } from 'actions/scoutUrlActions';
import { addProfile } from 'actions/profileActions';
import cx from 'classnames';
import { profileAddPageSelector } from 'selectors/profileSelectors';
import Spinner from 'components/spinner/spinner';
import { withScoutResponse } from 'utils/scout/scoutResponseFormBuilder';

const DEFAULT_FORM_DATA_STATE = {
  name: '',
  domain: '',
  facebookUrl: '',
  twitterUrl: '',
  youtubePageUrl: '',
  keywords: '',
  faviconUrl: ''
};

class AccountSetupWizardProfileStep extends Component {
  state = {
    formData: Object.assign({}, DEFAULT_FORM_DATA_STATE),
    step: 1
  };

  clearState = () => {
    this.setState({
      step: 1,
      formData: Object.assign({}, DEFAULT_FORM_DATA_STATE)
    });
    this.props.clearScoutState();
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.verifiedDomain && !this.props.verifiedDomain) {
      this.setState({
        step: 2,
        formData: withScoutResponse(
          this.state.formData,
          nextProps.scoutingResponse
        )
      });
    }
  }

  componentWillUnmount() {
    this.props.clearScoutState();
  }

  submitForm = e => {
    const { step } = this.state;
    e.preventDefault();
    e.stopPropagation();

    if (step === 1) {
      this.props.scoutUrl(this.state.formData.domain);
    }

    if (step === 2) {
      this.props.addProfile(this.state.formData, () => {
        this.props.loadNextStep();
      });
    }
  };

  setField = fieldName => e => {
    let newData = this.state;
    newData.formData[fieldName] = e.target.value;
    this.setState(newData);
  };

  renderTitleForStep = () => {
    const { step } = this.state;

    if (step === 2) {
      return (
        <div>
          <h1>
            Great. Now let's learn about your social profiles{' '}
            <span role="img" aria-label="">
              💬
            </span>
          </h1>
          <h2>These are optional. If you don't have one, leave it blank</h2>
        </div>
      );
    }

    return (
      <div>
        <h1>
          Thanks. Now tell us about your company{' '}
          <span role="img" aria-label="">
            🏠
          </span>
        </h1>
        <h2>We will set up your competitors after</h2>
      </div>
    );
  };

  renderStepOne = () => {
    const { formData } = this.state;
    const { verifiedDomain, scoutingDomain, scoutingError } = this.props;

    const isSubmitDisabled =
      formData.domain.length < 4 || formData.domain.indexOf('.') === -1;

    return (
      <div>
        <div className="row">
          <div className={'col-md-12'}>
            <div className={styles.websiteFields}>
              <Form.Group controlId="domain" className={'mrgBottom50'}>
                <Form.Label>
                  <strong>What's your company name?</strong>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="e.g. Pied Piper"
                  onChange={this.setField('name')}
                  autoFocus={notOnMobile(true)}
                  value={this.state.formData.name}
                  disabled={verifiedDomain}
                  className={styles.formControl}
                />
              </Form.Group>

              <Form.Group controlId="domain" className={'mrgBottom50'}>
                <Form.Label>
                  <strong>What's your website?</strong>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="e.g. piedpiper.net"
                  onChange={this.setField('domain')}
                  value={this.state.formData.domain}
                  disabled={verifiedDomain}
                  className={styles.formControl}
                />

                {scoutingError && (
                  <Form.Text
                    className={cx('text-danger', styles.multipleMessage)}
                  >
                    {scoutingError}
                  </Form.Text>
                )}
              </Form.Group>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="col-md-12"
            style={{
              textAlign: 'center',
              marginTop: '30px',
              marginBottom: '30px'
            }}
          >
            {!scoutingDomain && (
              <Button type="submit" rounded primary disabled={isSubmitDisabled}>
                Continue
              </Button>
            )}

            {scoutingDomain && (
              <span style={{ marginLeft: '10px' }}>
                <Spinner
                  style={{
                    height: '20px',
                    width: '20px',
                    fontSize: '8px',
                    marginRight: '10px'
                  }}
                />{' '}
                Verifying
              </span>
            )}
          </div>
        </div>
      </div>
    );
  };

  renderStepTwo = () => {
    return (
      <div>
        <div className="row">
          <div className={'col-md-12'}>
            <div className={styles.websiteFields}>
              <Form.Group controlId="facebookPageUrl" className={'mrgBottom50'}>
                <Form.Label>
                  <strong>What's your Facebook page?</strong>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="e.g. https://facebook.com/competito"
                  onChange={this.setField('facebookUrl')}
                  value={this.state.formData.facebookUrl}
                  className={styles.formControl}
                />
              </Form.Group>

              <Form.Group controlId="twitterPageUrl" className={'mrgBottom50'}>
                <Form.Label>
                  <strong>What's your Twitter page?</strong>
                </Form.Label>

                <Form.Control
                  type="text"
                  placeholder="e.g. https://twitter.com/competito"
                  onChange={this.setField('twitterUrl')}
                  value={this.state.formData.twitterUrl}
                  className={styles.formControl}
                />
              </Form.Group>

              <Form.Group controlId="youtubePageUrl" className={'mrgBottom50'}>
                <Form.Label>
                  <strong>What's your YouTube page?</strong>
                </Form.Label>

                <Form.Control
                  type="text"
                  placeholder="e.g. https://youtube.com/channel/dfsdfs"
                  onChange={this.setField('youtubePageUrl')}
                  value={this.state.formData.youtubePageUrl}
                  className={styles.formControl}
                />
              </Form.Group>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="col-md-12"
            style={{
              textAlign: 'center',
              marginTop: '30px',
              marginBottom: '30px'
            }}
          >
            <Button type="submit" rounded primary>
              Continue
            </Button>

            <Button
              style={{ marginLeft: '10px' }}
              rounded
              secondary
              onClick={this.clearState}
            >
              Go back
            </Button>
          </div>
        </div>
      </div>
    );
  };

  renderStepData = () => {
    const { step } = this.state;
    return (
      <div>
        <div className="row">
          <div className={cx('col-md-12', styles.titleText)}>
            {this.renderTitleForStep()}
          </div>
        </div>

        <Form onSubmit={e => this.submitForm(e)}>
          {step === 1 && this.renderStepOne()}
          {step === 2 && this.renderStepTwo()}
        </Form>
      </div>
    );
  };

  render() {
    return <div>{this.renderStepData()}</div>;
  }
}

const mapStateToProps = state => profileAddPageSelector(state);
export default connect(mapStateToProps, {
  scoutUrl,
  clearScoutState,
  addProfile
})(AccountSetupWizardProfileStep);
