import React, { Component } from 'react';
import SideContentWithGraphics from 'components/loggedOutWrappers/sideContentWithGraphics';
import Spinner from 'components/spinner/spinner';
import Alert from 'components/alert/alert';
import { createAxios } from 'utils/axios/axiosUtils';

class CheckoutConfirmContainer extends Component {
  state = { errorMessage: '', isConfirming: true };

  componentDidMount() {
    this.checkDetails();
  }

  async checkDetails() {
    const { match } = this.props;

    this.setState({ isConfirming: true });

    try {
      let response = await createAxios(false, true).post('checkout/confirm', {
        referenceId: match.params.ref
      });

      if (response && response.data && response.data.success) {
        window.location.href = '/';
      } else {
        this.setState({
          isConfirming: false,
          errorMessage:
            'Could not verify checkout right now, please try again later. If the issue persists, please contact support.'
        });
      }
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.errorMessage &&
        e.response.data.errorMessage.length
      ) {
        this.setState({
          errorMessage: e.response.data.errorMessage,
          isConfirming: false
        });
      } else {
        this.setState({
          isConfirming: false,
          errorMessage:
            'Could not verify checkout right now, please try again later. If the issue persists, please contact support.'
        });
      }
      return;
    }
  }

  render() {
    return (
      <SideContentWithGraphics>
        <br />
        {this.state.isConfirming && (
          <div>
            <Spinner /> Confirming checkout, please wait.
          </div>
        )}

        {this.state.errorMessage.length > 0 && (
          <Alert variant="danger">{this.state.errorMessage}</Alert>
        )}
      </SideContentWithGraphics>
    );
  }
}

export default CheckoutConfirmContainer;
