import React from 'react';
import { getEventTitle } from 'components/timeline/event/definition/newsletterUpdateEvent';
import InspectorEventWrapper from '../../inspectorEventWrapper';
import NewsletterMeta, {
  NewsletterScreenshot
} from 'components/newsletter/newsletterMeta';
import NewsletterLinks from 'components/newsletter/newsletterLinks';

export default function NewsletterUpdateInspector(props) {
  const name = props.eventData.referenceName;

  return (
    <InspectorEventWrapper {...props} title={getEventTitle(name)}>
      <div className="row">
        <div className="col-md-12">
          <NewsletterMeta {...props.eventProps} />

          <NewsletterScreenshot
            screenshotUrl={props.eventProps.screenshotUrl}
            maxHeight={'3000px'}
            maxWidth={'700px'}
          />
        </div>
      </div>
      <div className="row" style={{ marginTop: '20px' }}>
        <div className="col-md-12">
          <NewsletterLinks links={props.eventProps.extractedLinks} />
        </div>
      </div>
    </InspectorEventWrapper>
  );
}
