import React, { useState } from 'react';
import styles from './pageChangeViewer.module.scss';
import cx from 'classnames';
import Swal from 'sweetalert2';
import { createAxios } from 'utils/axios/axiosUtils';
import Block from 'components/presentation/block';
import { ucfirst } from 'utils/stringUtils';
import Dropdown from 'react-bootstrap/Dropdown';
import Gatekeeper from 'components/permissions/gatekeeper';
import Badge from 'react-bootstrap/Badge';
import { ROLE_USER } from 'utils/permissions/permissions';
import { useCurrentProfileId, useExampleMode } from 'providers/appProvider';
import { useHistory } from 'react-router-dom';
import Button from 'components/button/button';

function toFriendlyMetaName(metaTag) {
  switch (metaTag) {
    case 'og:title':
      return 'Open Graph title (og:title)';
    case 'og:url':
      return 'Open Graph image (og:url)';
    case 'og:description':
      return 'Open Graph description (og:description)';
    case 'description':
      return 'page description';
    case 'title':
      return 'page title';
    default:
      return metaTag;
  }
}

function getElementType(change) {
  if (
    change.elementData &&
    change.elementData.selector.indexOf('head') !== -1
  ) {
    return toFriendlyMetaName(change.elementData.itemType);
  }

  if (
    change.oldElementData &&
    change.oldElementData.selector.indexOf('head') !== -1
  ) {
    return toFriendlyMetaName(change.oldElementData.itemType);
  }

  if (!change.elementData.itemType) {
    return 'text';
  }

  return change.elementData.itemType;
}

function getParentSelector(selector) {
  let selectorParts = selector.split(' > ');
  selectorParts.pop();

  if (selectorParts.length === 1) {
    // This is likely only going to be the body, I am not ignoring the body!
    return null;
  }

  return selectorParts.join(' > ');
}

function styleToFriendlyString(style) {
  const nameMap = {
    backgroundColor: 'Background color',
    backgroundImage: 'Background image'
  };

  let str = [];
  Object.keys(style).forEach(item => {
    const friendlyName =
      typeof nameMap[item] === 'undefined' ? item : nameMap[item];
    str.push(friendlyName + ': ' + style[item]);
  });
  return str.join(' - ');
}

function getForItemType(elementData) {
  if (!elementData) {
    return null;
  }

  const itemType = elementData.itemType;

  if (
    itemType === 'text' ||
    itemType.indexOf('og:') === 0 ||
    itemType === 'title' ||
    itemType === 'description'
  ) {
    return elementData.text;
  } else if (itemType === 'image') {
    return elementData.src;
  } else if (itemType === 'styles') {
    return styleToFriendlyString(elementData.style);
  }

  return null;
}

function getItemChange(change) {
  const elementData = change.elementData;
  const oldElementData = change.oldElementData;

  let after = getForItemType(elementData, oldElementData);
  let before = getForItemType(oldElementData, elementData);

  return {
    before,
    after
  };
}

function Title({ change }) {
  const changeType = change.changeType;
  const elementType = getElementType(change);
  const isMeta = change.isMeta;

  return (
    <span>
      {ucfirst(changeType)} {elementType}{' '}
      {isMeta && (
        <span style={{ fontSize: '1.14rem', marginLeft: '5px' }}>
          <Badge variant={'success'}>Meta change</Badge>
        </span>
      )}
    </span>
  );
}

async function ignoreSelectorForEvent(
  eventId,
  profileId,
  exampleMode,
  selector,
  selectorType,
  history
) {
  if (exampleMode) {
    Swal.fire({
      type: 'error',
      title: 'Oops...',
      text: 'You cannot ignore anything on an example event.'
    });
    return;
  }

  const urlSearchParams = new URLSearchParams(history.location.search);
  urlSearchParams.set('resync', Date.now());

  const queryParts = window.location.href.split('?');
  const queryString = queryParts[1] !== undefined ? queryParts[1] : '';

  try {
    const response = await createAxios().post(
      'event-inspector/' +
        profileId +
        '/' +
        eventId +
        '/ignore-selector' +
        (queryString.length ? '?' + queryString : ''),
      {
        selector,
        selectorType
      }
    );

    if (
      response &&
      response.data &&
      response.data.changeStatus === 'event-deleted'
    ) {
      window.location.href = '/timeline';
    } else if (
      response &&
      response.data &&
      response.data.changeStatus === 'page-deleted'
    ) {
      urlSearchParams.delete('pageId');
      history.push({
        search: urlSearchParams.toString()
      });
    } else {
      // This triggers a refetch of data.
      history.push({
        search: urlSearchParams.toString()
      });
    }
  } catch (e) {
    console.warn(e);
    Swal.fire({
      type: 'error',
      title: 'Oops...',
      text:
        'Something went wrong trying to ignore this selector. Try again later.'
    });
  }
}

function Change({ change, idx, eventId }) {
  const elementData = change.elementData;
  const itemChange = getItemChange(change);
  const selector = elementData.selector;
  const parentSelector = getParentSelector(selector);

  const exampleMode = useExampleMode();
  const profileId = useCurrentProfileId();
  const history = useHistory();
  console.log(eventId);

  const ignoreSelector = (selector, selectorType) => {
    if (selectorType === 'custom') {
      Swal.fire({
        title: 'Choose custom CSS selector to ignore',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Ignore selector'
      }).then(result => {
        if (result.value) {
          ignoreSelectorForEvent(
            eventId,
            profileId,
            exampleMode,
            result.value,
            'custom',
            history
          );
        }
      });
      return;
    }

    Swal.fire({
      title: 'Are you sure?',
      text:
        "This will ignore selector '" +
        selector +
        "' forever until you remove this rule.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, I am sure!'
    }).then(result => {
      if (result.value) {
        ignoreSelectorForEvent(
          eventId,
          profileId,
          exampleMode,
          selector,
          selectorType,
          history
        );
      }
    });
  };

  let blockItems = [
    {
      key: 'i_' + idx,
      isJsx: true,
      value: (
        <Gatekeeper role={ROLE_USER}>
          <Dropdown>
            <Dropdown.Toggle variant="danger" id={'dropdown-' + idx}>
              Ignore
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => ignoreSelector(selector, 'single')}>
                Ignore selector
              </Dropdown.Item>
              {parentSelector && parentSelector.length && (
                <Dropdown.Item
                  onClick={() => ignoreSelector(parentSelector, 'parent')}
                >
                  Ignore parent selector
                </Dropdown.Item>
              )}
              <Dropdown.Divider />
              <Dropdown.Item onClick={() => ignoreSelector('', 'custom')}>
                Ignore custom selector
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Gatekeeper>
      )
    }
  ];

  if (exampleMode) {
    // Remove this when in example
    blockItems = [];
  }

  return (
    <Block
      title={<Title change={change} />}
      key={idx}
      strong
      blockItems={blockItems}
    >
      <div>
        {itemChange.before && (
          <span>
            <strong>Before</strong>
            <div>
              <code>{itemChange.before}</code>
            </div>
            <hr />
          </span>
        )}

        {itemChange.before && <strong>After</strong>}
        <div>
          <code>{itemChange.after}</code>
        </div>

        <br />

        <p className={styles.cssSelector}>{elementData.selector}</p>
      </div>
    </Block>
  );
}

const MAX_CHANGES_PER_PAGE = 10;

export default function PageChangeViewer(props) {
  const [showMore, setShowMore] = useState(false);

  const { changes, eventId } = props;
  if (!changes) {
    return null;
  }

  let idx = 0;
  let itemsToRender = [];

  let toShow = changes;
  if (!showMore) {
    toShow = changes.slice(0, MAX_CHANGES_PER_PAGE);
  }

  toShow.forEach(change => {
    itemsToRender.push(
      <Change change={change} key={idx} idx={idx} eventId={eventId} />
    );
    idx++;
  });

  return (
    <div>
      <div className={cx('row', styles.wrapper)}>
        <div className="col-md-12">{itemsToRender}</div>
      </div>
      {changes.length > MAX_CHANGES_PER_PAGE && (
        <div className={'row'} style={{ marginBottom: '40px' }}>
          <div className="col-md-12" style={{ textAlign: 'center' }}>
            <Button link onClick={() => setShowMore(!showMore)}>
              {showMore ? 'Show less' : 'Show all'}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
