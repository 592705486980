import React, { Component } from 'react';
import styles from './accountSetupWizardBreadcrumb.module.scss';
import cx from 'classnames';
import {
  REGISTER_STEP,
  PROFILE_STEP,
  COMPETITOR_STEP
} from 'components/accountSetup/steps';

class AccountSetupWizardBreadcrumb extends Component {
  render() {
    const { step } = this.props;

    return (
      <div className={styles.wrapper}>
        <ul>
          <li className={cx({ [styles.selected]: step === REGISTER_STEP })}>
            1) About you
          </li>
          <li className={cx({ [styles.selected]: step === PROFILE_STEP })}>
            2) Set up profile
          </li>
          <li className={cx({ [styles.selected]: step === COMPETITOR_STEP })}>
            3) Add competitor
          </li>
        </ul>
      </div>
    );
  }
}

export default AccountSetupWizardBreadcrumb;
