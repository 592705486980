import { createSelector } from 'reselect';
import { currentProfileSelector } from 'selectors/profileSelectors';
import { simpleCompetitorSelector } from 'selectors/competitorSelectors';

export const overviewPageSelector = createSelector(
  state => state.overview,
  currentProfileSelector,
  simpleCompetitorSelector,
  (overview, profile, competitors) => {
    return {
      isLoading: overview.get('isLoading'),
      loadingError: overview.get('loadingError'),
      keyStatsOverview: overview.getIn(['data', 'keyStatsOverview']),
      socialMediaOverview: overview.getIn(['data', 'socialMediaOverview']),
      websiteOverview: overview.getIn(['data', 'websiteOverview']),
      keywordsOverview: overview.getIn(['data', 'keywordsOverview']),
      profile: profile ? profile.toJS() : null,
      competitors: competitors.toJS()
    };
  }
);
