import { call, takeLatest } from 'redux-saga/effects';
import { SWITCH_ACCOUNT } from 'actions/authActions';
import { createAxios } from 'utils/axios/axiosUtils';

export default function* switchAccountSaga() {
  yield takeLatest(SWITCH_ACCOUNT, switchAccount);
}

function* switchAccount({ accountId }) {
  try {
    const response = yield call(getResponse, accountId);
    if (response.data && response.data.success) {
      window.location.href = '/?reload=1';
      return;
    }
  } catch (e) {
    console.warn(e);
  }
}

async function getResponse(accountId) {
  return await createAxios(true, true).post('switch-account/' + accountId);
}
