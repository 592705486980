import React from 'react';
import TimelineEventWrapper from '../timelineEventWrapper';
import { pluralise } from 'utils/numberUtils';
import styles from './serpAdFoundEvent.module.scss';
import { RenderCompetitorInfo } from './competitorFoundEvent';

export function getEventTitle(name, competitors) {
  const title =
    name +
    pluralise(
      competitors.length,
      ' has a new competitor competing for keywords',
      ' has ' + competitors.length + ' new competitors competing for keywords'
    );

  return title;
}

export default function SerpCompetitorFoundEvent(props) {
  const competitors = props.event.props.competitors;
  const name = props.event.referenceName;

  let displayCompetitors = [];
  let displayCounter = 1;

  const filteredCompetitors = competitors.slice(0, props.isInspector ? 500 : 5);

  filteredCompetitors.forEach(competitor => {
    displayCompetitors.push(
      <li
        key={displayCounter}
        style={
          displayCounter !== filteredCompetitors.length
            ? { borderBottom: '1px solid #eee', marginBottom: '20px' }
            : {}
        }
      >
        <RenderCompetitorInfo
          competitor={competitor}
          name={name}
          isSingleEvent={competitors.length === 1}
        />
        <div style={{ marginBottom: '20px' }} />
      </li>
    );
    displayCounter++;
  });

  return (
    <TimelineEventWrapper
      title={getEventTitle(name, competitors)}
      buttonText={
        competitors.length > 5 ? 'Show all competitors' : 'View details'
      }
      {...props}
    >
      <div className="row">
        <div className="col-md-12">
          <div>
            <ul className={styles.ul}>{displayCompetitors}</ul>
          </div>
        </div>
      </div>
    </TimelineEventWrapper>
  );
}
