import React, { Component } from 'react';
import CentralWrapper from 'components/loginWrappers/centralWrapper';
import { accountIntegrationsPageSelector } from 'selectors/accountSelectors';
import { connect } from 'react-redux';
import AccountIntegrationsPage from 'components/page/accountIntegrationsPage';
import {
  fetchIntegrationsData,
  removeIntegration
} from 'actions/integrationActions';

const TITLE_DEFAULT = 'Integrations | Competito';

class AccountIntegrationsContainer extends Component {
  UNSAFE_componentWillMount() {
    document.title = TITLE_DEFAULT;

    this.props.fetchIntegrationsData();
  }

  render() {
    return (
      <CentralWrapper>
        <AccountIntegrationsPage {...this.props} />
      </CentralWrapper>
    );
  }
}

const mapStateToProps = state => accountIntegrationsPageSelector(state);
export default connect(mapStateToProps, {
  fetchIntegrationsData,
  removeIntegration
})(AccountIntegrationsContainer);
