import { put, call, takeLatest } from 'redux-saga/effects';
import {
  FETCH_INTEGRATIONS_DATA,
  receiveIntegrationsData
} from 'actions/integrationActions';
import { createAxios } from 'utils/axios/axiosUtils';

export default function* fetchIntegrationsDataSaga() {
  yield takeLatest(FETCH_INTEGRATIONS_DATA, fetchIntegrationsData);
}

function* fetchIntegrationsData() {
  try {
    const response = yield call(getResponse);
    if (response.data && response.data.integrations) {
      yield put(receiveIntegrationsData(response.data.integrations));
    }
  } catch (e) {
    console.warn(e);
  }
}

async function getResponse() {
  return await createAxios().get('account-integrations');
}
