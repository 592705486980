import React, { Component } from 'react';
import CentralWrapper from 'components/loginWrappers/centralWrapper';
import { editCompetitorPageSelector } from 'selectors/competitorSelectors';
import {
  requestCompetitorConfig,
  attemptUpdateCompetitorConfig
} from 'actions/competitorConfigActions';
import { requestCompetitorEmail } from 'actions/competitorEmailActions';
import { connect } from 'react-redux';
import EditCompetitorPage from 'components/page/editCompetitorPage';
import Alert from 'components/alert/alert';
import Spinner from 'components/spinner/spinner';

const TITLE_DEFAULT = 'Edit competitor | Competito';

class EditCompetitorContainer extends Component {
  UNSAFE_componentWillMount() {
    const { match } = this.props;

    document.title = TITLE_DEFAULT;

    this.props.requestCompetitorConfig(match.params.competitorId);
    this.props.requestCompetitorEmail(match.params.competitorId);
  }

  render() {
    const { competitors, match, competitorConfig, loadingConfig } = this.props;

    const competitorId = match.params.competitorId;
    const competitor = competitors.get(competitorId);

    if (!competitor) {
      return (
        <CentralWrapper>
          <Alert variant="danger">
            The specified competitor does not exist.
          </Alert>
        </CentralWrapper>
      );
    }

    if (loadingConfig) {
      return (
        <CentralWrapper>
          <Spinner center />
        </CentralWrapper>
      );
    }

    if (!competitorConfig || !competitorConfig.size) {
      return (
        <CentralWrapper>
          <Alert variant="danger">
            Their was an issue loading config for this competitor, please try
            again shortly.
          </Alert>
        </CentralWrapper>
      );
    }

    return (
      <CentralWrapper>
        <EditCompetitorPage
          {...this.props}
          competitor={competitor.toJS()}
          competitorConfig={competitorConfig.toJS()}
        />
      </CentralWrapper>
    );
  }
}

const mapStateToProps = (state, props) =>
  editCompetitorPageSelector(state, props);
export default connect(mapStateToProps, {
  requestCompetitorConfig,
  attemptUpdateCompetitorConfig,
  requestCompetitorEmail
})(EditCompetitorContainer);
