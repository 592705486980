import React, { Component } from 'react';
import CentralWrapper from 'components/loginWrappers/centralWrapper';
import { connect } from 'react-redux';
import PlanPage from 'components/page/planPage';
import { planPageSelector } from 'selectors/planSelectors';
import { checkoutPlan } from 'actions/planActions';
import {
  attemptSwitchPlan,
  attemptCancelSubscription,
  attemptUpdatePaymentDetails
} from 'actions/subscriptionActions';
import { ROLE_ACCOUNT_ADMIN } from 'utils/permissions/permissions';
import Gatekeeper from 'components/permissions/gatekeeper';

const TITLE_DEFAULT = 'Edit plan | Competito';

class PlanContainer extends Component {
  UNSAFE_componentWillMount() {
    document.title = TITLE_DEFAULT;
  }

  render() {
    return (
      <Gatekeeper role={ROLE_ACCOUNT_ADMIN} showWarning>
        <CentralWrapper>
          <PlanPage {...this.props} />
        </CentralWrapper>
      </Gatekeeper>
    );
  }
}

const mapStateToProps = (state, props) => planPageSelector(state, props);
export default connect(mapStateToProps, {
  checkoutPlan,
  attemptSwitchPlan,
  attemptCancelSubscription,
  attemptUpdatePaymentDetails
})(PlanContainer);
