import React, { Component } from 'react';
import Block from 'components/presentation/block';
import Alert from 'components/alert/alert';
import styles from './planPage.module.scss';
import Icon from 'components/fontAwesome/icon';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Swal from 'sweetalert2';
import Button from 'components/button/button';

class PlanPage extends Component {
  state = { checkingOut: false };

  renderUpdatePaymentDetails = () => {
    const { subscription } = this.props;

    if (!subscription || !subscription.planId) {
      return null;
    }

    return (
      <div style={{ marginBottom: '30px' }}>
        <Alert variant="secondary">
          Need to update your card details?{' '}
          <Button link onClick={this.updatePaymentDetails} className="link">
            Click here to update them.
          </Button>
        </Alert>
      </div>
    );
  };

  renderTrialNotices = () => {
    if (this.props.paymentStatus !== 'trial') {
      return null;
    }

    if (this.hasTrialExpired()) {
      return (
        <div style={{ marginBottom: '30px' }}>
          <Alert variant="danger">
            <strong>Your trial has expired.</strong> Please choose a plan below
            to proceed.
          </Alert>
        </div>
      );
    }

    return (
      <div style={{ marginBottom: '30px' }}>
        <Alert variant="info">
          Enter payment details now and only pay after your trial ends.
        </Alert>
      </div>
    );
  };

  hasTrialExpired = () => {
    const { paymentStatus, servicesSummary } = this.props;
    const activeService = servicesSummary.filter(service => service.isActive);
    return paymentStatus === 'trial' && !activeService.length;
  };

  renderPriceHelp = (totalPrice, planPrice, competitorCount) => {
    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id={`tooltip-help`}>
            Total cost is ${planPrice} per month. This is calculated by $
            {totalPrice} plan price multipled by {competitorCount} competitors
            that you currently have enabled on this account.
          </Tooltip>
        }
      >
        <Icon
          name="fas fa-question-circle"
          style={{ color: '#999', marginLeft: '5px' }}
        />
      </OverlayTrigger>
    );
  };

  updatePaymentDetails = () => {
    const { subscription } = this.props;

    this.setState({ checkingOut: true });

    this.props.attemptUpdatePaymentDetails(subscription.subscriptionId, () => {
      this.setState({ checkingOut: false });
    });
  };

  selectPlan = planId => {
    this.setState({ checkingOut: true });

    this.props.checkoutPlan(planId, () => {
      this.setState({ checkingOut: false });
    });
  };

  cancelSubscription = subscriptionId => {
    this.setState({ checkingOut: true });

    const that = this;

    Swal.fire({
      title: 'Are you sure?',
      text:
        'You will be able to continue using the tool until the end of the current subscription period.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, I am sure!'
    }).then(result => {
      if (result.value) {
        that.props.attemptCancelSubscription(subscriptionId, () => {
          that.setState({ checkingOut: false });
        });
      }
    });
  };

  updateSubscription = (subscriptionId, newPlanId) => {
    this.setState({ checkingOut: true });

    const that = this;

    const text =
      newPlanId === 'starter-v1'
        ? 'Downgrading to this plan will issue a credit for your next invoice for any payment that has been made (pro-rated)'
        : 'Upgrading to this plan will incur additional charges that will be applied during the next invoice period.';

    Swal.fire({
      title: 'Are you sure?',
      text: text,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, I am sure!'
    }).then(result => {
      if (result.value) {
        that.props.attemptSwitchPlan(subscriptionId, newPlanId, () => {
          that.setState({ checkingOut: false });
        });
      }
    });
  };

  render() {
    const { subscription } = this.props;

    return (
      <div>
        <div className="row" style={{ marginBottom: '10px' }}>
          <div className="col-xl-12" style={{ textAlign: 'center' }}>
            <h1 className={'h4'} style={{ marginBottom: 0 }}>
              Plans
            </h1>
            <h2 className={'h5 textLighter thin'}>
              Affordable plans, only pay for what you use.
            </h2>
          </div>
        </div>

        {this.renderUpdatePaymentDetails()}

        {this.renderTrialNotices()}

        <div className="row">
          <div className="col-md-6">
            <Block title={'Starter'}>
              <p className={styles.planTagLine}>For small businesses</p>

              <div className={styles.pricingBlock}>
                <div className={styles.price}>
                  <strong>$60</strong> / month
                </div>

                <div className={styles.subscribeButton}>
                  {subscription && subscription.planId === 'starter-v1' && (
                    <Button
                      disabled={this.state.checkingOut}
                      onClick={() =>
                        this.cancelSubscription(subscription.subscriptionId)
                      }
                      danger
                    >
                      Cancel subscription
                    </Button>
                  )}

                  {subscription && subscription.planId !== 'starter-v1' && (
                    <Button
                      disabled={this.state.checkingOut}
                      onClick={() =>
                        this.updateSubscription(
                          subscription.subscriptionId,
                          'starter-v1'
                        )
                      }
                      primary
                    >
                      Switch to plan
                    </Button>
                  )}

                  {!subscription && (
                    <Button
                      disabled={this.state.checkingOut}
                      onClick={() => this.selectPlan('starter-v1')}
                      primary
                    >
                      Select plan
                    </Button>
                  )}
                </div>
              </div>

              <hr style={{ marginTop: '40px', marginBottom: '40px' }} />

              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" /> Track 5
                competitors
              </div>
              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" /> 150
                webpages per competitor
              </div>
              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" /> Monitor
                100 keywords
              </div>
              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" /> 12-month
                history retention
              </div>
              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" /> Unlimmited
                users
              </div>
              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" /> Basic
                support
              </div>
              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-info-circle" />{' '}
                <strong>30 day money back guarantee</strong>
              </div>
              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-info-circle" />{' '}
                <strong>No long term commitment, cancel anytime!</strong>
              </div>
            </Block>
          </div>
          <div className="col-md-6">
            <Block title={'Premium (recommended)'}>
              <p className={styles.planTagLine}>For fast growing businesses</p>

              <div className={styles.pricingBlock}>
                <div className={styles.price}>
                  <strong>$90</strong> / month
                </div>

                <div className={styles.subscribeButton}>
                  {subscription && subscription.planId === 'business-v2' && (
                    <Button
                      disabled={this.state.checkingOut}
                      onClick={() =>
                        this.cancelSubscription(subscription.subscriptionId)
                      }
                      danger
                    >
                      Cancel subscription
                    </Button>
                  )}

                  {subscription && subscription.planId !== 'business-v2' && (
                    <Button
                      disabled={this.state.checkingOut}
                      onClick={() =>
                        this.updateSubscription(
                          subscription.subscriptionId,
                          'business-v2'
                        )
                      }
                      primary
                    >
                      Switch to plan
                    </Button>
                  )}

                  {!subscription && (
                    <Button
                      disabled={this.state.checkingOut}
                      onClick={() => this.selectPlan('business-v2')}
                      primary
                    >
                      Select plan
                    </Button>
                  )}
                </div>
              </div>

              <hr style={{ marginTop: '40px', marginBottom: '40px' }} />

              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" /> Track 10
                competitors
              </div>
              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" /> 350
                webpages per competitor
              </div>
              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" /> Monitor
                250 keywords
              </div>
              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" /> 12-month
                history retention
              </div>
              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" /> Unlimmited
                users
              </div>
              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" /> Premium
                support
              </div>
              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" /> Data
                exports
              </div>
              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" /> Review
                monitoring
              </div>
            </Block>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Block title={'Elite'}>
              <p className={styles.planTagLine}>
                Competitors with large websites
              </p>

              <div className={styles.pricingBlock}>
                <div className={styles.price}>
                  <strong>$150</strong> / month
                </div>

                <div className={styles.subscribeButton}>
                  {subscription && subscription.planId === 'elite-v2' && (
                    <Button
                      disabled={this.state.checkingOut}
                      onClick={() =>
                        this.cancelSubscription(subscription.subscriptionId)
                      }
                      danger
                    >
                      Cancel subscription
                    </Button>
                  )}

                  {subscription && subscription.planId !== 'elite-v2' && (
                    <Button
                      disabled={this.state.checkingOut}
                      onClick={() =>
                        this.updateSubscription(
                          subscription.subscriptionId,
                          'elite-v2'
                        )
                      }
                      primary
                    >
                      Switch to plan
                    </Button>
                  )}

                  {!subscription && (
                    <Button
                      disabled={this.state.checkingOut}
                      onClick={() => this.selectPlan('elite-v2')}
                      primary
                    >
                      Select plan
                    </Button>
                  )}
                </div>
              </div>

              <hr style={{ marginTop: '40px', marginBottom: '40px' }} />

              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" />{' '}
                <strong>All of Business</strong>
              </div>
              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" /> Track 15
                competitors
              </div>
              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" /> 1,000
                webpages per competitor
              </div>
              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" /> Monitor
                350 keywords
              </div>
              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" /> 12-month
                history retention
              </div>
              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" /> Unlimmited
                users
              </div>
              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" /> Priorty
                support
              </div>
              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" />{' '}
                White-labelling (PDFs & Email)
              </div>
            </Block>
          </div>
          <div className="col-md-6">
            <Block title={'Ultimate'}>
              <p className={styles.planTagLine}>Enterprise</p>

              <div className={styles.pricingBlock}>
                <div className={styles.price}>
                  <strong>$200</strong> / month
                </div>

                <div className={styles.subscribeButton}>
                  {subscription && subscription.planId === 'ultimate-v1' && (
                    <Button
                      disabled={this.state.checkingOut}
                      onClick={() =>
                        this.cancelSubscription(subscription.subscriptionId)
                      }
                      danger
                    >
                      Cancel subscription
                    </Button>
                  )}

                  {subscription && subscription.planId !== 'ultiate-v1' && (
                    <Button
                      disabled={this.state.checkingOut}
                      onClick={() =>
                        this.updateSubscription(
                          subscription.subscriptionId,
                          'ultimate-v1'
                        )
                      }
                      primary
                    >
                      Switch to plan
                    </Button>
                  )}

                  {!subscription && (
                    <Button
                      disabled={this.state.checkingOut}
                      onClick={() => this.selectPlan('ultimate-v1')}
                      primary
                    >
                      Select plan
                    </Button>
                  )}
                </div>
              </div>

              <hr style={{ marginTop: '40px', marginBottom: '40px' }} />

              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" />{' '}
                <strong>All of Elite</strong>
              </div>
              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" /> Track 20
                competitors
              </div>
              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" /> 10,000
                webpages per competitor
              </div>
              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" /> Monitor
                750 keywords
              </div>
              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" /> 12-month
                history retention
              </div>
              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" /> Unlimmited
                users
              </div>
              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" /> Dedicated
                support
              </div>
              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" /> Dedicated
                Account Manager
              </div>
              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" /> Assisted
                setup
              </div>
            </Block>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Block title={'Enterprise'}>
              <p style={{ marginBottom: 0 }}>
                <strong>Want to talk to us for a custom plan?</strong> Send us
                an email (
                <a href="mailto:lee@competito.app">lee@competito.app</a>) or use
                the chat widget in the bottom right hand corner. We will get
                back to you as soon as we can.
              </p>

              <hr />

              <p>We can do: </p>
              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" />{' '}
                <strong>All of Ultimate</strong>
              </div>
              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" /> Up to
                unlimited competitors
              </div>
              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" /> Up to
                unlimited web pages
              </div>
              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" /> Up to
                unlimited keywords
              </div>
              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" /> Dedicated
                customer success manager
              </div>
              <div className={styles.feature}>
                <Icon className={styles.check} name="fas fa-check" />{' '}
                On-boarding
              </div>
            </Block>
          </div>
        </div>
      </div>
    );
  }
}

export default PlanPage;
