import React from 'react';
import moment from 'moment';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import styles from './timelineEventWrapper.module.scss';

export default function EventTime({ time }) {
  const momentTime = moment.unix(time);
  const startOfEventTimeDay = momentTime.startOf('day');
  const startOfToday = moment().startOf('day');
  const daysDiff = startOfEventTimeDay.diff(startOfToday, 'days');

  const daysMessage = {
    '0': 'Today',
    '-1': 'Yesterday'
  };

  let responseText = momentTime.fromNow();
  if (Math.abs(daysDiff) <= 0) {
    responseText = daysMessage[daysDiff];
  }

  return (
    <div className={styles.eventTime}>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id={`tooltip-time`}>
            {momentTime.format('MMMM Do YYYY')}
          </Tooltip>
        }
      >
        <span>{responseText}</span>
      </OverlayTrigger>
    </div>
  );
}
