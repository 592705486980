import React, { Component } from 'react';
import Block from 'components/presentation/block';
import Icon from 'components/fontAwesome/icon';

export default class TopKeywordsWidget extends Component {
  renderKeywordRows = () => {
    const { keywords } = this.props;

    return keywords.map(keyword => {
      return (
        <tr key={keyword.keyword}>
          <td>
            {keyword.keyword}
            {keyword.host && (
              <div style={{ color: '#999', fontSize: '0.8rem' }}>
                {keyword.host}
              </div>
            )}
          </td>
          <td style={{ textAlign: 'right', color: '#999' }}>
            #{keyword.bestRank}
          </td>
        </tr>
      );
    });
  };

  renderContent = () => {
    const { keywords } = this.props;

    if (!keywords.length) {
      return (
        <div>
          <Icon
            name="fas fa-exclamation-triangle"
            style={{ color: 'orange', marginRight: '5px' }}
          />{' '}
          No ranking keywords.
        </div>
      );
    }

    return (
      <table className="table table-striped">
        <tbody>{this.renderKeywordRows()}</tbody>
      </table>
    );
  };

  render() {
    const title = this.props.title;

    return (
      <Block
        title={title || 'Top keywords'}
        help="View who is ranking highest out of you and your competitors for the terms specified when adding your domain."
      >
        {this.renderContent()}
      </Block>
    );
  }
}
