import React from 'react';
import TimelineEventWrapper from '../timelineEventWrapper';
import { pluralise } from 'utils/numberUtils';
import styles from './tweetAddedEvent.module.scss';
import Icon from 'components/fontAwesome/icon';
import cx from 'classnames';

export function getEventTitle(name, tweets) {
  const title =
    name +
    ' added ' +
    pluralise(tweets.length, 'a tweet', tweets.length + ' tweets');

  return title;
}

function RenderStats({ favorites, retweets }) {
  return (
    <p style={{ marginLeft: '20px', color: '#777' }}>
      <span style={{ marginRight: '15px' }}>
        <Icon name={'fal fa-redo'} /> {retweets} retweets
      </span>
      <span>
        <Icon name={'fal fa-heart'} /> {favorites} favorited
      </span>
    </p>
  );
}

function RenderTweetText({ tweet }) {
  let { text, full_text, entities } = tweet;

  if (text === undefined || text.length === 0) {
    text = full_text;
  }

  entities.user_mentions.forEach(mention => {
    text = text.replaceAll(
      '@' + mention.screen_name,
      '<a target="_blank" href="https://twitter.com/' +
        mention.screen_name +
        '">@' +
        mention.screen_name +
        '</a>'
    );
  });

  entities.hashtags.forEach(hashtag => {
    text = text.replaceAll(
      '#' + hashtag.text,
      '<a target="_blank" href="https://twitter.com/hashtag/' +
        hashtag.text +
        '">#' +
        hashtag.text +
        '</a>'
    );
  });

  entities.urls.forEach(url => {
    text = text.replaceAll(
      url.url,
      '<a target="_blank" href="' + url.url + '">' + url.expanded_url + '</a>'
    );
  });

  if (entities.media) {
    entities.media.forEach(media => {
      text = text.replaceAll(media.url, '');
    });
  }

  text = text.replaceAll('\n', '<br />');

  return <span dangerouslySetInnerHTML={{ __html: text }} />;
}

function VideoPlayIcon({ tweet }) {
  return (
    <a
      href={
        'https://twitter.com/' +
        tweet.user.screen_name +
        '/status/' +
        tweet.twitterTweetId
      }
      className={styles.playIconWrapper}
      target="_blank"
      rel="noopener noreferrer"
    >
      <span className={styles.playIcon}>
        <Icon name={'far fa-play-circle'} size="5x" />
      </span>
    </a>
  );
}

function RenderTweetResponse({ tweet }) {
  return (
    <div className={styles.responseTweetWrapper}>
      <p>
        <RenderTweetText tweet={tweet.tweetForResponse} />
      </p>
    </div>
  );
}

function RenderTweet({ tweet, isSingleEvent, metaForTweet }) {
  const hasMedia = tweet.media_img && tweet.media_img;

  return (
    <div className={styles.twitterPostWrapper}>
      <div className={styles.handleLine}>
        <div className={styles.handle}>
          <img src={tweet.user.user_image} alt="" className={styles.avatar} />{' '}
          <a
            className={styles.screenName}
            href={'https://twitter.com/' + tweet.user.screen_name}
            target="_blank"
            rel="noopener noreferrer"
          >
            @{tweet.user.screen_name}
          </a>
        </div>
        <div className={styles.viewLink}>
          <a
            href={
              'https://twitter.com/' +
              tweet.user.screen_name +
              '/status/' +
              tweet.twitterTweetId
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            View on twitter{' '}
            <Icon
              name="far fa-external-link-alt"
              style={{ marginLeft: '5px' }}
            />
          </a>
        </div>
      </div>

      <div
        className={cx(styles.postWrapper, {
          [styles.singlePostWrapper]: isSingleEvent
        })}
      >
        <div className={styles.postData}>
          <div className={styles.text}>
            {tweet.tweetType === 'reply' && (
              <div className={styles.replyHeader}>Tweet reply</div>
            )}
            <RenderTweetText tweet={tweet} />
            {Object.keys(tweet.tweetForResponse).length > 0 && (
              <RenderTweetResponse tweet={tweet} />
            )}
          </div>
        </div>
        {hasMedia && (
          <div
            className={cx(styles.postMedia, {
              [styles.postMediaMargin]: isSingleEvent
            })}
          >
            <div style={{ position: 'relative' }}>
              <a
                href={
                  'https://twitter.com/' +
                  tweet.user.screen_name +
                  '/status/' +
                  tweet.twitterTweetId
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={cx(styles.postImageMedia, {
                    [styles.imgBlur]: tweet.media_img.indexOf('_video_') !== -1
                  })}
                  src={tweet.media_img}
                  alt=""
                />
              </a>
              {tweet.media_img.indexOf('_video_') !== -1 && (
                <VideoPlayIcon tweet={tweet} />
              )}
            </div>
          </div>
        )}
      </div>

      <div>
        {metaForTweet !== undefined && (
          <RenderStats
            favorites={metaForTweet.favorites}
            retweets={metaForTweet.retweets}
          />
        )}
      </div>
    </div>
  );
}

export default function TweetAddedEvent(props) {
  const tweets = props.event.props.tweets;
  const name = props.event.referenceName;

  let displayTweets = [];
  let displayCounter = 1;

  const filteredTweets = tweets.slice(0, props.isInspector ? 500 : 3);

  filteredTweets.forEach(tweet => {
    const metaForTweet =
      props.eventMeta &&
      props.eventMeta.stats &&
      props.eventMeta.stats[tweet.twitterTweetId];

    displayTweets.push(
      <li
        key={displayCounter}
        style={
          displayCounter !== filteredTweets.length
            ? { borderBottom: '1px solid #eee', marginBottom: '20px' }
            : {}
        }
      >
        <RenderTweet
          tweet={tweet}
          isSingleEvent={tweets.length === 1}
          metaForTweet={metaForTweet}
        />
        {displayCounter !== tweets.length && (
          <div style={{ marginBottom: '20px' }} />
        )}
      </li>
    );
    displayCounter++;
  });

  return (
    <TimelineEventWrapper
      title={getEventTitle(name, tweets)}
      buttonText={tweets.length > 3 ? 'Show all tweets' : 'View details'}
      {...props}
    >
      <div className="row">
        <div className="col-md-12">
          <div className={styles.twitterPostsWrapper}>
            <ul className={styles.ul}>{displayTweets}</ul>
          </div>
        </div>
      </div>
    </TimelineEventWrapper>
  );
}
