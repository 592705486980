import React from 'react';
import TimelineEventWrapper from '../timelineEventWrapper';
import { NewsletterScreenshot } from 'components/newsletter/newsletterMeta';
import { Link } from 'react-router-dom';
import { renderInspectorPath } from 'utils/pathUtils';

export function getEventTitle(name) {
  return name + ' has sent a newsletter update';
}

export default function NewsletterUpdateEvent(props) {
  const name = props.event.referenceName;

  return (
    <TimelineEventWrapper
      title={getEventTitle(name)}
      buttonText={'View newsletter'}
      {...props}
    >
      <div className="row">
        <div className="col-md-12">
          <Link to={renderInspectorPath(props.event.eventId)}>
            <NewsletterScreenshot
              screenshotUrl={props.eventProps.screenshotUrl}
              maxHeight={'400px'}
              hideScroll
              hideTitle
            />
          </Link>
        </div>
      </div>
    </TimelineEventWrapper>
  );
}
