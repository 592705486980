import { Component } from 'react';
import { setAuthToken } from 'utils/localStorage/localStorage';

const LIVE_DEMO_ACCESS_TOKEN =
  'guest_a633e017fd8d5f74f57da4cc5a44b315c15bc9715c803143efe5b77d07';

class LiveDemoContainer extends Component {
  componentDidMount() {
    setAuthToken(LIVE_DEMO_ACCESS_TOKEN);
    window.location.href = '/';
  }

  render() {
    return null;
  }
}

export default LiveDemoContainer;
