import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import LoginForm from 'components/login/loginForm';
import { connect } from 'react-redux';
import { storeLogin } from 'actions/authActions';
import Spinner from 'components/spinner/spinner';
import Alert from 'components/alert/alert';
import cx from 'classnames';
import SideContentWithGraphics from 'components/loggedOutWrappers/sideContentWithGraphics';
import styles from 'components/loggedOutWrappers/sidebarContentWithGraphics.module.scss';

class LoginContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSpinner: false,
      failedLogin: false,
      lastKnownEmail: ''
    };
  }

  handleSuccessLogin = data => {
    this.props.storeLogin(data);
  };

  handleFailedLogin = () => {
    this.setState({ showSpinner: false, failedLogin: true });
  };

  handleLoggingIn = (email, password) => {
    this.setState({
      showSpinner: true,
      failedLogin: false,
      lastKnownEmail: email
    });
  };

  renderSpinner() {
    return (
      <Row>
        <Col xs="12">
          <Spinner center useColors={true} />
        </Col>
      </Row>
    );
  }

  renderFailReason = () => {
    return (
      <Alert variant={'danger'}>
        We did not recognise your email and password, please try again.
      </Alert>
    );
  };

  render() {
    const { failedLogin, showSpinner } = this.state;

    if (showSpinner) {
      return (
        <SideContentWithGraphics hideLogo={true}>
          {this.renderSpinner()}
        </SideContentWithGraphics>
      );
    }

    return (
      <SideContentWithGraphics>
        <h1 className={cx('h4', 'fw700', 'textLighter', styles.title)}>
          Log in to continue
        </h1>

        {failedLogin && this.renderFailReason()}

        <LoginForm
          onLoginSuccess={this.handleSuccessLogin}
          onLoggingIn={this.handleLoggingIn}
          onLoginFailed={this.handleFailedLogin}
          defaultEmail={this.state.lastKnownEmail}
        />
      </SideContentWithGraphics>
    );
  }
}

export default connect(
  null,
  { storeLogin }
)(LoginContainer);
