import React, { Component } from 'react';
import CentralWrapper from 'components/loginWrappers/centralWrapper';
import { accountUserManagementPageSelector } from 'selectors/accountSelectors';
import { connect } from 'react-redux';
import AccountUserManagementPage from 'components/page/accountUserManagementPage';
import { attemptDeleteUser, attemptCreateUser } from 'actions/userActions';

const TITLE_DEFAULT = 'User management | Competito';

class AccountUserManagementContainer extends Component {
  UNSAFE_componentWillMount() {
    document.title = TITLE_DEFAULT;
  }

  render() {
    return (
      <CentralWrapper>
        <AccountUserManagementPage {...this.props} />
      </CentralWrapper>
    );
  }
}

const mapStateToProps = state => accountUserManagementPageSelector(state);
export default connect(mapStateToProps, {
  attemptDeleteUser,
  attemptCreateUser
})(AccountUserManagementContainer);
