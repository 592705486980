import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Icon from 'components/fontAwesome/icon';
import Button from 'components/button/button';
import cx from 'classnames';
import styles from './websiteItem.module.scss';
import Screenshot from './screenshot';

class WebsiteItem extends Component {
  renderButton = () => {
    const { buttonLabel, buttonUrl } = this.props;

    if (!buttonLabel) {
      return null;
    }

    return (
      <Link to={buttonUrl}>
        <Button info rounded thin style={{ marginTop: '10px' }}>
          {buttonLabel}
        </Button>
      </Link>
    );
  };

  renderScreenshot = () => {
    const { buttonUrl, screenshotUrl, large, largest } = this.props;

    if (!screenshotUrl) {
      return null;
    }

    if (!buttonUrl) {
      return (
        <div
          className={cx(styles.screenshot, {
            [styles.screenshotLarge]: large,
            [styles.screenshotLargest]: largest
          })}
        >
          <Screenshot screenshotUrl={screenshotUrl} larger={large} />
        </div>
      );
    }

    return (
      <div
        className={cx(styles.screenshot, {
          [styles.screenshotLarge]: large,
          [styles.screenshotLargest]: largest
        })}
      >
        <Screenshot
          screenshotUrl={screenshotUrl}
          link={buttonUrl}
          larger={large}
        />
      </div>
    );
  };

  render() {
    const { url, pageName, largest, children } = this.props;

    return (
      <div className={styles.wrapper}>
        {this.renderScreenshot()}
        <div style={{ overflow: 'hidden' }}>
          <div
            href={url}
            className={cx(styles.pageName, {
              [styles.pageNameLargest]: largest
            })}
            target="_blank"
            rel="noopener noreferrer"
            title={pageName}
          >
            {pageName}
          </div>
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className={cx({ [styles.urlLargest]: largest })}
          >
            {url.substr(0, 100)}
            {url.length > 100 ? '...' : ''}{' '}
            <Icon name="fal fa-external-link-alt" />
          </a>
          {children}
          <div>{this.renderButton()}</div>
        </div>
      </div>
    );
  }
}

export default WebsiteItem;
