import React, {
  useReducer,
  useCallback,
  useContext,
  createContext,
  useEffect
} from 'react';
import Pusher from 'components/pusher/pusher';
import { createAxios } from 'utils/axios/axiosUtils';

export const AppContext = createContext();

const SET_EXAMPLE_MODE = 'SET_EXAMPLE_MODE';
const SET_USER = 'SET_USER';
const SET_CURRENT_PROFILE_ID = 'SET_CURRENT_PROFILE_ID';

const SET_IS_LOADING_NOTIFICATIONS = 'SET_IS_LOADING_NOTIFICATIONS';
const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
const SET_NOTIFICATIONS_PAGE = 'SET_NOTIFICATIONS_PAGE';
const SET_UNREAD_NOTIFICATIONS_COUNT = 'SET_UNREAD_NOTIFICATIONS_COUNT';
const SET_LOADING_NOTIFICATIONS_FAILED = 'SET_LOADING_NOTIFICATIONS_FAILED';

const reducer = (state, action) => {
  switch (action.type) {
    case SET_EXAMPLE_MODE:
      return {
        ...state,
        exampleMode: action.exampleMode
      };
    case SET_USER:
      return {
        ...state,
        user: action.user
      };
    case SET_CURRENT_PROFILE_ID:
      return {
        ...state,
        currentProfileId: action.currentProfileId
      };

    case SET_IS_LOADING_NOTIFICATIONS:
      return {
        ...state,
        isLoadingNotifications: action.isLoadingNotifications
      };
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.notifications,
        totalNotificationRows: action.totalNotificationRows,
        loadNotifications: false,
        loadingNotificationsFailed: false,
        unreadNotificationsCount: action.unreadNotificationsCount
      };
    case SET_NOTIFICATIONS_PAGE:
      return {
        ...state,
        notificationsPage: action.notificationsPage
      };
    case SET_UNREAD_NOTIFICATIONS_COUNT:
      return {
        ...state,
        unreadNotificationsCount: action.unreadNotificationsCount
      };
    case SET_LOADING_NOTIFICATIONS_FAILED:
      return {
        ...state,
        loadingNotificationsFailed: action.loadingNotificationsFailed
      };
    default:
      throw new Error('Should not have hit this.');
  }
};

async function loadNotifications(page, dispatch) {
  dispatch({
    type: SET_IS_LOADING_NOTIFICATIONS,
    isLoadingNotifications: true
  });

  dispatch({
    type: SET_NOTIFICATIONS_PAGE,
    notificationsPage: page
  });

  try {
    const response = await createAxios().get(
      'app-notifications?page=' + page + '&limit=10'
    );
    if (response.data && response.data.success) {
      dispatch({
        type: SET_NOTIFICATIONS,
        notifications: response.data.notifications,
        totalNotificationRows: response.data.totalRows,
        unreadNotificationsCount: response.data.unreadCount
      });
    }
  } catch (e) {
    dispatch({
      type: SET_LOADING_NOTIFICATIONS_FAILED,
      loadingNotificationsFailed: true
    });
    console.warn('Something went wrong trying to notifications.', e);
  }

  dispatch({
    type: SET_IS_LOADING_NOTIFICATIONS,
    isLoadingNotifications: false
  });
  return [];
}

export default function AppProvider(props) {
  const [state, dispatch] = useReducer(reducer, {
    user: props.user,
    exampleMode: props.exampleMode,
    currentProfileId: props.currentProfileId,
    notifications: [],
    unreadNotificationsCount: props.unreadNotificationsCount,
    isLoadingNotifications: false,
    notificationsPage: 1,
    loadingNotificationsFailed: false,
    totalNotificationRows: 0
  });

  useEffect(() => {
    dispatch({
      type: SET_EXAMPLE_MODE,
      exampleMode: props.exampleMode
    });
  }, [props.exampleMode]);

  useEffect(() => {
    dispatch({
      type: SET_CURRENT_PROFILE_ID,
      currentProfileId: props.currentProfileId
    });
  }, [props.currentProfileId]);

  const updateNotifications = useCallback(async () => {
    loadNotifications(state.notificationsPage, dispatch);
  }, [state.notificationsPage]);

  return (
    <AppContext.Provider value={[state, dispatch]}>
      <Pusher
        channel={state.user.accountId + `-channel-app-notifications`}
        event={state.user.userId + `-updateOutstandingCount`}
        onUpdate={updateNotifications}
      />

      {props.children}
    </AppContext.Provider>
  );
}

export const useExampleMode = () => {
  const [state] = useContext(AppContext);
  return state.exampleMode;
};

export const useAuthUser = () => {
  const [state] = useContext(AppContext);
  return state.user;
};

export const useCurrentProfileId = () => {
  const [state] = useContext(AppContext);
  return state.currentProfileId;
};

export const useGetNotificationState = () => {
  const [state, dispatch] = useContext(AppContext);
  return {
    notifications: state.notifications,
    loadingNotificationsFailed: state.loadingNotificationsFailed,
    unreadNotificationsCount: state.unreadNotificationsCount,
    isLoadingNotifications: state.isLoadingNotifications,
    notificationsPage: state.notificationsPage,
    totalNotificationRows: state.totalNotificationRows,
    loadNotifications: useCallback(
      page =>
        loadNotifications(
          page === undefined ? state.notificationsPage : page,
          dispatch
        ),
      [state.notificationsPage, dispatch]
    )
  };
};
