import React from 'react';
import TimelineEventWrapper from '../timelineEventWrapper';
import { pluralise } from 'utils/numberUtils';

export function getEventTitle(name, ads) {
  const title =
    name +
    pluralise(
      ads.length,
      ' is ranking for a new keyword',
      ' is ranking for ' + ads.length + ' new keywords'
    );

  return title;
}

function RenderKeyword({ keyword }) {
  return (
    <tr>
      <td>{keyword.keyword}</td>
      <td style={{ textAlign: 'center' }}>
        <a href={keyword.link} target="_blank" rel="noopener noreferrer">
          View page
        </a>
      </td>
      <td style={{ textAlign: 'right' }}>{keyword.rank}</td>
    </tr>
  );
}

export default function KeywordNewRankingEvent(props) {
  const keywords = props.event.props.keywords;
  const name = props.event.referenceName;

  let displayKeywords = [];
  let displayCounter = 1;

  const filteredKeywords = keywords
    .sort((a, b) => (a.rank > b.rank ? 1 : -1))
    .slice(0, props.isInspector ? 500 : 10);

  filteredKeywords.forEach(keyword => {
    displayKeywords.push(
      <RenderKeyword
        key={displayCounter}
        keyword={keyword}
        name={name}
        isSingleEvent={keywords.length === 1}
      />
    );
    displayCounter++;
  });

  return (
    <TimelineEventWrapper
      title={getEventTitle(name, keywords)}
      buttonText={keywords.length > 10 ? 'Show all keywords' : 'View details'}
      {...props}
    >
      <div className="row">
        <div className="col-md-12">
          <div style={{ padding: '10px 20px' }}>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Keyword</th>
                  <th style={{ textAlign: 'center' }}> </th>
                  <th style={{ textAlign: 'right' }}>Position</th>
                </tr>
              </thead>
              <tbody>{displayKeywords}</tbody>
            </table>
          </div>
        </div>
      </div>
    </TimelineEventWrapper>
  );
}
