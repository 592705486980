import { put, call, select, takeLatest } from 'redux-saga/effects';
import {
  receiveInventoryTechData,
  handleInventoryTechDataError,
  FETCH_INVENTORY_TECH_DATA
} from 'actions/inventoryActions';
import { createAxios } from 'utils/axios/axiosUtils';
import { currentProfileIdSelector } from 'selectors/profileSelectors';

export default function* fetchInventoryTechnologiesSaga() {
  yield takeLatest(FETCH_INVENTORY_TECH_DATA, fetchTechnologies);
}

function* fetchTechnologies({ competitorId }) {
  const profileId = yield select(currentProfileIdSelector);

  try {
    const response = yield call(getApiResponse, profileId, competitorId);
    if (response.data) {
      yield put(receiveInventoryTechData(response.data));
    } else {
      yield put(handleInventoryTechDataError('No data returned by API.'));
    }
  } catch (e) {
    console.warn(e);

    yield put(handleInventoryTechDataError(e.message));
  }
}

async function getApiResponse(profileId, competitorId) {
  return await createAxios().get(
    'inventory/technologies/' + competitorId + '?profileId=' + profileId
  );
}
