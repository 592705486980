import React from 'react';
import TimelineEventWrapper from '../timelineEventWrapper';
import { pluralise } from 'utils/numberUtils';
import styles from './serpAdFoundEvent.module.scss';
import Icon from 'components/fontAwesome/icon';

export function getEventTitle(name, ads) {
  const title =
    name +
    pluralise(
      ads.length,
      ' has a new Google Ad detected',
      ' has ' + ads.length + ' new Google Ads detected'
    );

  return title;
}

function RenderAd({ ad }) {
  return (
    <div
      key={ad.link + ad.keyword + ad.competitorId}
      className={styles.adWrapper}
    >
      <div className={styles.keyword}>
        <Icon name="fas fa-search" style={{ color: '#bbb' }} />{' '}
        <span style={{ marginRight: '5px' }}>{ad.keyword}</span>
      </div>
      <div className={styles.title}>
        <a href={ad.link} target="_blank" rel="noopener noreferrer">
          {ad.title}
        </a>
      </div>
      <div className={styles.url}>
        <Icon name="fal fa-ad" style={{ marginRight: '5px' }} /> {ad.link}
      </div>
      <div className={styles.description}>{ad.description}</div>
    </div>
  );
}

export default function SerpAdFoundEvent(props) {
  const ads = props.event.props.ads;
  const name = props.event.referenceName;

  let displayAds = [];
  let displayCounter = 1;

  const filteredAds = ads.slice(0, props.isInspector ? 500 : 5);

  filteredAds.forEach(ad => {
    displayAds.push(
      <li
        key={displayCounter}
        style={
          displayCounter !== filteredAds.length
            ? { borderBottom: '1px solid #eee', marginBottom: '20px' }
            : {}
        }
      >
        <RenderAd ad={ad} name={name} isSingleEvent={ads.length === 1} />
        <div style={{ marginBottom: '20px' }} />
      </li>
    );
    displayCounter++;
  });

  return (
    <TimelineEventWrapper
      title={getEventTitle(name, ads)}
      buttonText={ads.length > 5 ? 'Show all ads' : 'View details'}
      {...props}
    >
      <div className="row">
        <div className="col-md-12">
          <div>
            <ul className={styles.ul}>{displayAds}</ul>
          </div>
        </div>
      </div>
    </TimelineEventWrapper>
  );
}
