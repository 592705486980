import React, { Component } from 'react';
import styles from './topIconStat.module.scss';
import Tooltip from 'react-bootstrap/Tooltip';
import Icon from 'components/fontAwesome/icon';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

class TopIconStat extends Component {
  getIconForDelta = delta => {
    if (delta > 0) {
      return 'fas fa-caret-up';
    }

    if (delta < 0) {
      return 'fas fa-caret-down';
    }

    return 'fas fa-caret-left';
  };

  getColorForDelta = delta => {
    if (delta > 0) {
      return 'lightgreen';
    }

    if (delta < 0) {
      return 'lightred';
    }

    return '#999';
  };

  renderStat = () => {
    const { iconName, iconSize, iconColor, stat, label } = this.props;
    return (
      <div className={styles.statBoxContent}>
        <div className={styles.statBoxIcon}>
          <Icon
            style={{ color: iconColor || '#e4e7ed' }}
            name={iconName}
            size={iconSize || '4x'}
          />
        </div>
        <div className={styles.statBoxStat}>
          {stat} {label}
        </div>
      </div>
    );
  };

  render() {
    const { tooltip, label } = this.props;

    if (!tooltip) {
      return <div className={styles.statBoxWrapper}>{this.renderStat()}</div>;
    }

    return (
      <div className={styles.statBoxWrapper}>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`tooltip-` + label}>{tooltip}</Tooltip>}
        >
          {this.renderStat()}
        </OverlayTrigger>
      </div>
    );
  }
}

export default TopIconStat;
