import { createSelector } from 'reselect';
import { Map } from 'immutable';

export const competitorEmailSelector = createSelector(
  state => state.competitorEmail,
  (state, props) => props.match.params.competitorId,
  (competitorEmail, competitorId) => {
    const competitorEmailData = competitorEmail.get('data');

    if (!competitorEmailData || !competitorEmailData.size) {
      return Map({});
    }

    return {
      email: competitorEmailData.get(competitorId)
    };
  }
);
