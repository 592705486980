import React from 'react';
import TimelineEventWrapper from '../timelineEventWrapper';
import Icon from 'components/fontAwesome/icon';
import Alert from 'components/alert/alert';

export default function CompetitorAddedEvent(props) {
  const { referenceName, props: eventProps } = props.event;

  return (
    <TimelineEventWrapper
      title={'Competitor ' + referenceName + ' added'}
      {...props}
    >
      <div className="row">
        <div
          className="col-md-12"
          style={{ padding: '10px 20px 10px 20px', fontSize: '15px' }}
        >
          <div>
            <h1 style={{ fontSize: '18px' }}>
              <span role="img" aria-label="">
                🔎
              </span>{' '}
              Searching for results
            </h1>
            <p>
              It may take a few days to start seeing updates after first adding
              your competitor.
            </p>

            <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
              <li>
                <span role="img" aria-label="" style={{ marginRight: '3px' }}>
                  👉
                </span>{' '}
                Up to <strong>an hour</strong> for social media, keywords, and
                ads updates
              </li>
              <li>
                <span role="img" aria-label="" style={{ marginRight: '3px' }}>
                  👉
                </span>{' '}
                Up to <strong>7 days</strong> for any web page changes to be
                detected
              </li>
            </ul>

            <p style={{ marginTop: '12px' }}>
              We will notify you via email when changes are discovered.
            </p>

            <p style={{ marginTop: '10px', marginBottom: '20px' }}>
              You can learn more by reading the{' '}
              <a
                href="https://competito.app/getting-started-with-competito/"
                target="_blank"
                rel="noopener noreferrer"
              >
                getting started guide <Icon name="fal fa-external-link-alt" />
              </a>
              .
            </p>
          </div>

          <hr />

          <Alert
            type="tw1"
            variant="info"
            style={{ marginBottom: '10px', marginTop: '20px' }}
          >
            <div>
              <p style={{ fontSize: '15px' }}>
                Your email address for this competitor is{' '}
                <strong>
                  <a href={'mailto:' + eventProps.assignedEmail}>
                    {eventProps.assignedEmail}
                  </a>
                </strong>
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '15px',
                  fontWeight: 'bold'
                }}
              >
                <a
                  href="https://competito.app/competitor-newsletter-and-account-email-monitoring/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  &raquo; Learn how to track your competitors emails{' '}
                  <Icon name="fal fa-external-link-alt" />
                </a>
              </p>
            </div>
          </Alert>
        </div>
      </div>
    </TimelineEventWrapper>
  );
}
