import React, { useState } from 'react';
import TimelineEventWrapper from '../timelineEventWrapper';
import { pluralise } from 'utils/numberUtils';
import styles from './youTubeVideoAddedEvent.module.scss';
import YouTube from 'react-youtube';
import Spinner from 'components/spinner/spinner';

export function getEventTitle(name, videos) {
  const title =
    name +
    ' added ' +
    pluralise(
      videos.length,
      'a video on YouTube',
      videos.length + ' videos on YouTube'
    );

  return title;
}

function RenderVideo({ video, isInspector }) {
  const [hasLoaded, setHasLoaded] = useState(false);

  const opts = {
    // height: '390',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      loop: 1
    }
  };

  const onReady = event => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
    setHasLoaded(true);
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          {isInspector && (
            <p>
              <strong>Title:</strong> <br />
              {video.title}
            </p>
          )}
          {isInspector && (
            <p>
              <strong>Description:</strong> <br />
              {video.description}
            </p>
          )}
          <p>
            {isInspector && (
              <span>
                <strong>Link:</strong> <br />
              </span>
            )}
            {isInspector && (
              <a
                target="_blank"
                rel="noreferrer noopener"
                href={video.videoUrl}
              >
                {video.videoUrl}
              </a>
            )}
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {!hasLoaded && <Spinner center />}
          <div style={{ maxWidth: '800px', opacity: hasLoaded ? 1 : 0 }}>
            <YouTube
              videoId={video.youtubeVideoId}
              opts={opts}
              onReady={onReady}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default function YouTubeVideoAddedEvent(props) {
  const videos = props.event.props.videos;
  const name = props.event.referenceName;

  let displayVideos = [];
  let displayCounter = 1;

  const filteredVideos = videos.slice(0, props.isInspector ? 500 : 2);

  filteredVideos.forEach(video => {
    displayVideos.push(
      <li
        key={displayCounter}
        style={
          displayCounter !== filteredVideos.length
            ? { borderBottom: '1px solid #eee', marginBottom: '20px' }
            : {}
        }
      >
        <RenderVideo
          video={video}
          favicon={props.event.favicon}
          isInspector={props.isInspector}
        />
        <div style={{ marginBottom: '20px' }} />
      </li>
    );
    displayCounter++;
  });

  return (
    <TimelineEventWrapper
      title={getEventTitle(name, videos)}
      buttonText={videos.length > 2 ? 'Show all videos' : 'View details'}
      {...props}
    >
      <div className="row">
        <div className="col-md-12">
          <div className={styles.wrapper}>
            <ul className={styles.ul}>{displayVideos}</ul>
          </div>
        </div>
      </div>
    </TimelineEventWrapper>
  );
}
