import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { createAxios } from 'utils/axios/axiosUtils';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import Icon from 'components/fontAwesome/icon';
import styles from './loginForm.module.scss';
import { notOnMobile } from 'utils/appUtils';

export default class LoginForm extends Component {
  submitForm = event => {
    event.preventDefault();

    const form = event.currentTarget;

    const email = form.email.value;
    const password = form.password.value;

    this.props.onLoggingIn(email, password);

    this.attemptLogin(email, password);
  };

  async attemptLogin(email, password) {
    let response = null;
    try {
      response = await createAxios(false).post('login', {
        username: email,
        password
      });
    } catch (e) {}

    if (!response || !response.data || !response.data.token) {
      if (this.inputRef) {
        this.inputRef.value = '';
      }

      this.props.onLoginFailed();
      return;
    }

    this.props.onLoginSuccess(response.data);
  }

  renderForm() {
    return (
      <div className={styles.loginForm}>
        <Form onSubmit={e => this.submitForm(e)}>
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email"
              defaultValue={this.props.defaultEmail || ''}
              autoFocus={
                this.props.defaultEmail.length === 0 && notOnMobile(true)
              }
            />
          </Form.Group>

          <Form.Group controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter your password"
              ref={input => (this.inputRef = input)}
              autoFocus={
                this.props.defaultEmail.length > 0 && notOnMobile(true)
              }
            />
          </Form.Group>
          <Button variant="btnAlt" type="submit" className="btnFlat">
            <Icon name="fal fa-sign-in-alt" className="mgr10" /> Sign in
          </Button>

          <div className={'mrgTop30'}>
            <Link
              to="/setup-wizard"
              className={cx('linkEffect', 'textLighter', 'mgr10')}
            >
              <Icon name="fal fa-user-plus" className="mgr10" /> Create account
            </Link>
            <Link
              to="/forgotten-password"
              className={cx('linkEffect', 'textLighter')}
            >
              <Icon name="fal fa-unlock-alt" className="mgr10" /> Forgot
              password
            </Link>
          </div>
        </Form>
      </div>
    );
  }

  render() {
    return <div>{this.renderForm()}</div>;
  }
}
