import { call, put, takeLatest } from 'redux-saga/effects';
import { TOGGLE_NOTIFICATION_SUBS_FOR_PROFILE } from 'actions/eventNotificationSubscriptionActions';
import { fetchUserChannelData } from 'actions/authActions';
import { createAxios } from 'utils/axios/axiosUtils';
import Swal from 'sweetalert2';

export default function* toggleNotificationSubscriptionsForProfileSaga() {
  yield takeLatest(
    TOGGLE_NOTIFICATION_SUBS_FOR_PROFILE,
    toggleNotificationSubscriptionsForProfile
  );
}

function* toggleNotificationSubscriptionsForProfile({ profileId, status }) {
  try {
    yield call(apiCall, profileId, status);
    yield put(fetchUserChannelData());
  } catch (e) {
    Swal.fire({
      type: 'error',
      title: 'Oops...',
      text:
        'Something went wrong trying to enable/disbale this Profile notifications. Try again later.'
    });
  }
}

async function apiCall(profileId, status) {
  if (status === 'enable') {
    return await createAxios().delete(
      'self-notification-channel/subscriptions/' + profileId + '/enable'
    );
  }
  return await createAxios().put(
    'self-notification-channel/subscriptions/' + profileId + '/disable'
  );
}
