import React, { useState } from 'react';
import ScreenshotModal from './screenshotModal';
import InspectorEventWrapper from '../../inspectorEventWrapper';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Spinner from 'components/spinner/spinner';
import Diff from 'components/diff/diff';
import Swal from 'sweetalert2';
import { createAxios } from 'utils/axios/axiosUtils';
import PageChangeViewer from 'components/inspector/pageChange/pageChangeViewer';
import Icon from 'components/fontAwesome/icon';
import ChangeSeverityBadge from 'components/website/changeSeverityBadge';
import Alert from 'components/alert/alert';
import Gatekeeper from 'components/permissions/gatekeeper';
import moment from 'moment';
import { ROLE_JESSLE } from 'utils/permissions/permissions';
import CopyButton from 'components/button/copyButton';
import { useParams } from 'react-router-dom';

async function fetchDiff(originalSourceUrl, newSourceUrl) {
  let response = null;
  try {
    response = await createAxios().get(
      'source-diff?originalSourceUrl=' +
        originalSourceUrl +
        '&newSourceUrl=' +
        newSourceUrl
    );
  } catch (e) {}

  if (!response || !response.data || !response.data.diff) {
    Swal.fire({
      type: 'error',
      title: 'Oops...',
      text: 'Something went wrong trying to compare the diff. Try again later.'
    });
    return null;
  }
  return response.data.diff;
}

function buildDebugProps(props) {
  let str = '';
  Object.keys(props).forEach(key => {
    str += key + '=' + encodeURIComponent(props[key]) + '&';
  });
  return str;
}

export default function WebPageUpdated(props) {
  const [modalScreenshotUrl, setModalScreenshotUrl] = useState(null);
  const [sourceDiff, setSourceDiff] = useState(null);
  const [loadingDiff, setLoadingDiff] = useState(false);

  const { tab } = useParams();

  const currentPageId = props.pageId;
  const eventProps = props.eventProps.pages.find(
    page => page.pageId.toString() === currentPageId
  );

  const {
    originalSourceCodeLocation,
    sourceCodeLocation,
    changeSeverity,
    changeImpact,
    pageName,
    url,
    fullPageDiffScreenshotLocation,
    originalPageTestedAt,
    fullPageScreenshotLocation,
    viewportPageScreenshotLocation,
    originalFullPageScreenshotLocation
  } = eventProps;

  const eventMeta = props.eventMeta || {};

  const debugParams = {
    url: eventProps.url,
    competitorConfig: JSON.stringify(
      eventMeta.competitorConfig ? eventMeta.competitorConfig : {}
    ),
    originalChangeDetectElementTreePath: eventMeta.originalChangeDetectElementTreePath
      ? eventMeta.originalChangeDetectElementTreePath
      : ''
  };
  const localParliamentUrl =
    'http://api.parliament.test/adminhub/tests/mi6-request?' +
    buildDebugProps(debugParams);
  const prodParliamentUrl =
    'https://parliament.competito.app/adminhub/tests/mi6-request?' +
    buildDebugProps(debugParams);

  const onSelectTab = async tab => {
    if (tab === 'source-diff' && sourceDiff === null) {
      setLoadingDiff(true);
      const diff = await fetchDiff(
        originalSourceCodeLocation,
        sourceCodeLocation
      );
      setSourceDiff(diff);
      setLoadingDiff(false);
    }
  };

  const title = (
    <span style={{ verticalAlign: 'middle' }}>
      {props.eventData.referenceName} has updated this webpage{' '}
      <ChangeSeverityBadge severity={changeSeverity} pill={true} />
    </span>
  );

  const afterImage =
    fullPageDiffScreenshotLocation && fullPageDiffScreenshotLocation.length
      ? fullPageDiffScreenshotLocation
      : fullPageScreenshotLocation;

  return (
    <InspectorEventWrapper {...props} title={title} isIndividual={true}>
      <ScreenshotModal
        url={modalScreenshotUrl}
        onClose={() => setModalScreenshotUrl(null)}
      />

      <div>
        <div style={{ display: 'flex', marginBottom: '25px' }}>
          <div style={{ maxWidth: '150px', marginRight: '20px' }}>
            <a href={url} target="_blank" rel="noopener noreferrer">
              <img
                src={viewportPageScreenshotLocation}
                alt=""
                style={{
                  width: '100%',
                  border: '1px solid #eee',
                  marginBottom: '5px',
                  boxShadow: 'rgb(0 0 0 / 14%) 0px 1px 2px'
                }}
              />
            </a>
          </div>
          <div className="my-auto">
            <p style={{ marginBottom: 0, fontSize: '16px' }}>
              <strong>{pageName}</strong>
            </p>
            <a href={url} target="_blank" rel="noopener noreferrer">
              {url} <Icon name="fal fa-external-link-alt" />
            </a>
          </div>
        </div>
      </div>

      <Tabs
        defaultActiveKey={tab === 'default' ? 'changes' : tab}
        transition={false}
        id="noanim-tab"
        onSelect={onSelectTab}
      >
        <Tab
          eventKey="changes"
          title={
            'Changes (' +
            (eventMeta.changes ? eventMeta.changes.length : 1) +
            ')'
          }
        >
          <div className="row">
            <div
              className="col-md-12"
              style={{
                marginTop: '30px',
                marginBottom: '10px'
              }}
            >
              <h3 style={{ fontSize: '20px' }}>
                <span role="img" aria-label="" style={{ marginRight: '5px' }}>
                  📺
                </span>
                Visual diff
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <p
                style={{
                  textAlign: 'center',
                  color: '#555',
                  marginBottom: '15px'
                }}
              >
                <strong>Current</strong> (
                {moment
                  .unix(props.eventData.representativeTime)
                  .format('MMMM Do YYYY, h:mm a')}
                )
              </p>
              <img
                src={afterImage}
                onClick={() => setModalScreenshotUrl(afterImage)}
                alt=""
                style={{
                  width: '100%',
                  border: '1px solid #eee',
                  cursor: 'pointer',
                  boxShadow: 'rgba(0, 0, 0, 0.14) 0px 1px 2px'
                }}
              />
            </div>
            <div className="col-md-6">
              <p
                style={{
                  textAlign: 'center',
                  color: '#555',
                  marginBottom: '15px'
                }}
              >
                <strong>Original</strong> (
                {moment
                  .unix(originalPageTestedAt)
                  .format('MMMM Do YYYY, h:mm a')}
                )
              </p>
              <img
                src={originalFullPageScreenshotLocation}
                alt=""
                onClick={() =>
                  setModalScreenshotUrl(originalFullPageScreenshotLocation)
                }
                style={{
                  width: '100%',
                  border: '1px solid #eee',
                  cursor: 'pointer',
                  boxShadow: 'rgba(0, 0, 0, 0.14) 0px 1px 2px'
                }}
              />
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-12"
              style={{
                marginTop: '40px',
                marginBottom: '5px'
              }}
            >
              <h3 style={{ fontSize: '20px' }}>
                <span role="img" aria-label="" style={{ marginRight: '5px' }}>
                  📋
                </span>
                Changelog
              </h3>
            </div>
          </div>
          <PageChangeViewer
            changes={eventMeta.changes}
            eventId={props.eventData.eventId}
          />
        </Tab>
        <Tab eventKey="source-diff" title="Diff (Source)">
          <div className="row" style={{ marginTop: '20px', padding: '25px' }}>
            <div className="col-md-12">
              {loadingDiff && <Spinner center />}
              {!loadingDiff && sourceDiff !== null && (
                <Diff diff={sourceDiff} />
              )}
            </div>
          </div>
        </Tab>
      </Tabs>

      <Gatekeeper role={ROLE_JESSLE}>
        <div className="row" style={{ marginTop: '20px' }}>
          <div className="col-md-12">
            <Alert variant="info" type="tw1">
              <div style={{ marginRight: '20px' }}>
                <p style={{ marginBottom: 0, paddingBottom: 0 }}>
                  <strong>Staff debug tools</strong>
                </p>
                <p style={{ marginTop: 0, paddingTop: 0, marginBottom: 0 }}>
                  <a
                    href={localParliamentUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Debug in Local Parliament
                  </a>{' '}
                  |{' '}
                  <a
                    href={prodParliamentUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Debug in Production Parliament
                  </a>{' '}
                  |{' '}
                  <CopyButton
                    text={eventMeta.debugCommand}
                    label="Copy CLI Command"
                  />
                </p>
                <p>Impact: {changeImpact}</p>
              </div>
            </Alert>
          </div>
        </div>
      </Gatekeeper>
    </InspectorEventWrapper>
  );
}
