import { createSelector } from 'reselect';
import { authSelector } from 'selectors/authSelectors';

export const planPageSelector = createSelector(
  authSelector,
  auth => {
    const subscription = auth.get('subscription');
    return {
      paymentStatus: auth.get('account').get('paymentStatus'),
      servicesSummary: auth.get('servicesSummary').toJS(),
      subscription: subscription ? subscription.toJS() : null
    };
  }
);
