import React, { Component } from 'react';
import styles from './noticeBar.module.scss';
import cx from 'classnames';
import { connect } from 'react-redux';
import { setExampleMode } from 'actions/authActions';
import { topBarSelector } from 'selectors/loggedInSelectors';
import { Link } from 'react-router-dom';
import Icon from 'components/fontAwesome/icon';
import moment from 'moment';
import { ROLE_ACCOUNT_ADMIN } from 'utils/permissions/permissions';
import Gatekeeper from 'components/permissions/gatekeeper';

class NoticeBar extends Component {
  constructor() {
    super();
    this.state = { count: 0 };
    this.resizer = window.addEventListener('resize', this.incrementForReload);
  }

  componentWillUnmount() {
    this.resizer = null;
  }

  incrementForReload = () => {
    this.setState({ count: this.state.count + 1 });
  };

  renderExpirationWarning = () => {
    return (
      <Link to={'/plan'} className={styles.dangerLink}>
        <div>
          <Icon
            name="fal fa-exclamation-triangle"
            style={{ marginRight: '5px' }}
          />{' '}
          <strong>Trial expired</strong>
          <Gatekeeper role={ROLE_ACCOUNT_ADMIN}>
            : View plans{' '}
            <Icon
              name="fal fa-chevron-right"
              style={{ marginLeft: '5px', fontSize: '12px' }}
            />
          </Gatekeeper>
        </div>
      </Link>
    );
  };

  renderTrialExpiresInWarning = () => {
    const { activeService } = this.props;

    const relativeMsg =
      moment
        .unix(activeService.get('expirationDate'))
        .fromNow()
        .replace('in ', '') + ' remaining';

    return (
      <Link to={'/plan'} className={styles.warningLink}>
        <div>
          <strong>Trial ({relativeMsg})</strong>
          <Gatekeeper role={ROLE_ACCOUNT_ADMIN}>
            : Upgrade now{' '}
            <Icon
              name="fal fa-chevron-right"
              style={{ marginLeft: '5px', fontSize: '12px' }}
            />
          </Gatekeeper>
        </div>
      </Link>
    );
  };

  renderTryJessleNotice = () => {
    return (
      <a href={'/setup-wizard'} className={styles.warningLink}>
        <div>
          <strong>Begin your free 30-day trial now</strong>
          <Icon
            name="fal fa-chevron-right"
            style={{ marginLeft: '5px', fontSize: '12px' }}
          />
        </div>
      </a>
    );
  };

  renderAccountExpiredWarning = () => {
    return (
      <Link to={'/plan'} className={styles.dangerLink}>
        <div>
          <Icon
            name="fal fa-exclamation-triangle"
            style={{ marginRight: '5px' }}
          />{' '}
          <strong>Account expired</strong>{' '}
          <Gatekeeper role={ROLE_ACCOUNT_ADMIN}>
            : Reactive now{' '}
            <Icon
              name="fal fa-chevron-right"
              style={{ marginLeft: '5px', fontSize: '12px' }}
            />
          </Gatekeeper>
        </div>
      </Link>
    );
  };

  renderAccountCancelledWarning = () => {
    return (
      <Link to={'/plan'} className={styles.dangerLink}>
        <div>
          <Icon
            name="fal fa-exclamation-triangle"
            style={{ marginRight: '5px' }}
          />{' '}
          <strong>Account cancelled</strong>
          <Gatekeeper role={ROLE_ACCOUNT_ADMIN}>
            : Reactive now{' '}
            <Icon
              name="fal fa-chevron-right"
              style={{ marginLeft: '5px', fontSize: '12px' }}
            />
          </Gatekeeper>
        </div>
      </Link>
    );
  };

  renderNotice = () => {
    const { activeService, hasExpired, paymentStatus, user } = this.props;

    if (user.get('role') === 'ROLE_LIVE_DEMO') {
      return this.renderTryJessleNotice();
    }

    if (paymentStatus === 'trial') {
      if (hasExpired) {
        return this.renderExpirationWarning();
      }

      return this.renderTrialExpiresInWarning();
    }

    if (activeService === null) {
      return this.renderAccountExpiredWarning();
    }

    if (paymentStatus === 'cancelled') {
      return this.renderAccountCancelledWarning();
    }

    return null;
  };

  renderMobileHamburger = () => {
    return (
      <div className={styles.hamburgerButtonGroup} role="group">
        <button
          type="button"
          className={styles.contentBtn}
          onClick={this.props.toggleSidebar}
        >
          <Icon name="fa fa-bars" className={styles.fa} />
        </button>
      </div>
    );
  };

  shouldShowNoticeBar = notice => {
    if (notice) {
      // Show so we have the warning
      return true;
    }

    const screenWidth = window.innerWidth;

    if (screenWidth < 992) {
      // Show so we have the hamburger
      return true;
    }

    return false;
  };

  render() {
    const notice = this.renderNotice();
    if (!this.shouldShowNoticeBar(notice)) {
      return null;
    }

    return (
      <header className={cx(styles.header)}>
        {notice}

        {this.renderMobileHamburger()}
      </header>
    );
  }
}

export default connect(
  topBarSelector,
  {
    setExampleMode
  }
)(NoticeBar);
