import React, { Component } from 'react';
import Block from 'components/presentation/block';

export default class FacebookAdsWidget extends Component {
  renderLineItems = () => {
    const { data } = this.props;

    return Object.values(data).map(line => {
      return (
        <tr key={line.name}>
          <td>{line.name}</td>
          <td>{line.numberOfAds}</td>
        </tr>
      );
    });
  };

  render() {
    const { data } = this.props;

    if (!data) {
      return null;
    }

    return (
      <div className="row">
        <div className="col-md-6">
          <Block
            title={'Facebook Ads Running'}
            help="Here you can get an overview of how many Facebook Ads you and your competitors are currently running."
          >
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Number of Ads</th>
                </tr>
              </thead>
              <tbody>{this.renderLineItems()}</tbody>
            </table>
          </Block>
        </div>
      </div>
    );
  }
}
