import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Icon from 'components/fontAwesome/icon';

class Screenshot extends Component {
  state = { loaded: false, error: false, image: null };

  componentDidMount() {
    const { screenshotUrl } = this.props;

    if (screenshotUrl && screenshotUrl.length) {
      const image = new Image();

      image.onload = () => {
        this.setState({ image, loaded: true, error: false });
      };

      image.onerror = () => {
        this.setState({ image: null, error: true, loaded: true });
      };

      image.src = screenshotUrl;
    }
  }

  renderFailPlaceholder = () => {
    const { larger } = this.props;

    return (
      <div
        style={{
          backgroundColor: '#eee',
          display: 'flex',
          maxWidth: '100%',
          minWidth: larger ? '200px' : '150px',
          minHeight: larger ? '113px' : '84px',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={`tooltip-`}>
              This image has possibly been deleted or is out of date.
            </Tooltip>
          }
        >
          <Icon
            name="fas fa-exclamation-triangle"
            style={{ color: 'rgb(218, 139, 38)', fontSize: '18px' }}
          />
        </OverlayTrigger>
      </div>
    );
  };

  render = () => {
    const { link, className } = this.props;
    if (!this.state.loaded) {
      return null;
    }

    const { image, error } = this.state;

    const imageElement = error ? (
      this.renderFailPlaceholder()
    ) : (
      <img src={image.src} alt="Screenshot" className={className} />
    );

    if (!link) {
      return imageElement;
    }

    return <Link to={link}>{imageElement}</Link>;
  };
}

export default Screenshot;
