import { call, select, takeLatest } from 'redux-saga/effects';
import { ATTEMPT_DELETE_CURRENT_PROFILE } from 'actions/profileActions';
import { createAxios } from 'utils/axios/axiosUtils';
import Swal from 'sweetalert2';
import { currentProfileIdSelector } from 'selectors/profileSelectors';

export default function* deleteProfileSaga() {
  yield takeLatest(ATTEMPT_DELETE_CURRENT_PROFILE, deleteProfile);
}

function* deleteProfile({ cb }) {
  const profileId = yield select(currentProfileIdSelector);
  if (!profileId) {
    return;
  }

  let isOK = false;
  try {
    const response = yield call(attemptDeleteResponse, profileId);
    if (response.data && response.data.success) {
      isOK = true;

      window.location.href = '/';
    }
  } catch (e) {
    console.warn(e);
  }

  if (!isOK) {
    Swal.fire({
      type: 'error',
      title: 'Oops...',
      text:
        'Something went wrong trying to delete this Profile. Try again later.'
    });
  }

  cb();
}

async function attemptDeleteResponse(profileId) {
  return await createAxios().delete('profiles/' + profileId);
}
