import React, { Component } from 'react';
import Block from 'components/presentation/block';
import MarkAppNotificationButton from 'components/notification/markAppNotificationButton';
import Badge from 'react-bootstrap/Badge';
import moment from 'moment';
import EventCommentNotificationBlock from './notificationBlocks/eventCommentNotificationBlock';
import Gatekeeper from 'components/permissions/gatekeeper';

class NotificationBlock extends Component {
  renderPostTitle = () => {
    const { notification } = this.props;

    const postTime = moment
      .unix(notification.createdTime)
      .format('MMMM Do YYYY');
    const readTime = notification.readTime;

    return (
      <div>
        {postTime}{' '}
        {readTime === 0 && (
          <Badge variant="warning" style={{ marginLeft: '10px' }}>
            Unread
          </Badge>
        )}
      </div>
    );
  };

  renderBlockItems = () => {
    const { notification, refreshNotifications } = this.props;

    return [
      {
        isJsx: true,
        value: (
          <Gatekeeper noDemoAccount>
            <MarkAppNotificationButton
              notification={notification}
              refreshNotifications={refreshNotifications}
            />
          </Gatekeeper>
        )
      }
    ];
  };

  renderNotificationBlock = () => {
    const { notification, users } = this.props;

    switch (notification.type) {
      case 'EventComment':
        return (
          <EventCommentNotificationBlock
            notification={notification}
            users={users}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const title = this.renderPostTitle();
    const blockItems = this.renderBlockItems();

    return (
      <div className="row">
        <div className="col-md-12">
          <Block title={title} titleElement="div" blockItems={blockItems}>
            {this.renderNotificationBlock()}
          </Block>
        </div>
      </div>
    );
  }
}

export default NotificationBlock;
