import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

export default class Icon extends React.Component {
  static defaultProps = {
    name: '',
    Component: 'span'
  };

  static propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    size: PropTypes.oneOf(['lg', '2x', '3x', '4x', '5x']),
    rotate: PropTypes.oneOf(['45', '90', '135', '180', '225', '270', '315']),
    flip: PropTypes.oneOf(['horizontal', 'vertical']),
    fixedWidth: PropTypes.bool,
    spin: PropTypes.bool,
    pulse: PropTypes.bool,
    stack: PropTypes.oneOf(['1x', '2x']),
    inverse: PropTypes.bool,
    Component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    tooltip: PropTypes.string
  };

  render() {
    const {
      Component,
      name,
      size,
      rotate,
      flip,
      spin,
      fixedWidth,
      stack,
      inverse,
      tooltip,
      pulse,
      className,
      style = {},
      bgColor,
      fontColor,
      ...props
    } = this.props;

    // For backward compatibility, we support names like "foo", which
    // we map to "fas fa-foo". The preferred new format is the full
    // class name, e.g. "fab fa-google".
    let classNames = name.includes(' ') ? name : `fas fa-${name}`;
    if (size) {
      classNames = `${classNames} fa-${size}`;
    }
    if (rotate) {
      classNames = `${classNames} fa-rotate-${rotate}`;
    }
    if (flip) {
      classNames = `${classNames} fa-flip-${flip}`;
    }
    if (fixedWidth) {
      classNames = `${classNames} fa-fw`;
    }
    if (spin) {
      classNames = `${classNames} fa-spin`;
    }
    if (pulse) {
      classNames = `${classNames} fa-pulse`;
    }

    if (stack) {
      classNames = `${classNames} fa-stack-${stack}`;
    }
    if (inverse) {
      classNames = `${classNames} fa-inverse`;
    }

    if (className) {
      classNames = `${classNames} ${className}`;
    }

    if (bgColor) {
      style.backgroundColor = bgColor;
      style.borderRadius = '50%';
      style.padding = '7px';
      style.fontSize = '11px';
    }

    if (fontColor) {
      style.color = fontColor;
    }

    const comp = <Component {...props} className={classNames} style={style} />;

    if (tooltip) {
      return (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`tooltip-` + tooltip}>{tooltip}</Tooltip>}
        >
          {comp}
        </OverlayTrigger>
      );
    }

    return comp;
  }
}
