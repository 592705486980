import { List, Map, fromJS } from 'immutable';
import {
  RECEIVE_KEYWORD_TABLE_DATA,
  FETCH_KEYWORD_TABLE_DATA,
  HANDLE_KEYWORD_TABLE_DATA_ERROR
} from 'actions/keywordActions';

const initialState = fromJS({
  data: Map({
    keywordTable: List(),
    keywordStats: Map({
      firstPlaceRankings: 0,
      topTenRankings: 0,
      top100Rankings: 0,
      totalKeywords: 0
    })
  }),
  isLoading: false,
  loadingError: false
});

export default function keywordReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_KEYWORD_TABLE_DATA:
      return state
        .set('isLoading', true)
        .set('loadingError', false)
        .setIn(['data', 'keywordTable'], List());
    case RECEIVE_KEYWORD_TABLE_DATA:
      return state
        .set('isLoading', false)
        .set('loadingError', false)
        .setIn(['data', 'keywordTable'], fromJS(action.data.keywordTable))
        .setIn(['data', 'keywordStats'], fromJS(action.data.keywordStats));
    case HANDLE_KEYWORD_TABLE_DATA_ERROR:
      return state.set('isLoading', false).set('loadingError', true);
    default:
      return state;
  }
}
