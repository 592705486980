import axios from 'axios';
import config from 'config';
import { getAuthToken } from 'utils/localStorage/localStorage';

export function createAxios(includeToken = true, isLongRequest = false) {
  const token = getAuthToken();

  let headers = { 'Content-Type': 'application/json' };
  if (includeToken && token) {
    headers.Authorization = token;
  }

  return axios.create({
    baseURL: config.API_ROOT,
    timeout: isLongRequest ? 100000 : 10000,
    headers: headers
  });
}
