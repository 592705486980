import React, { Component } from 'react';
import { connect } from 'react-redux';
import { gatekeeperSelector } from 'selectors/authSelectors';
import { hasPermission } from 'utils/permissions/permissions';

/**
 * onlyRole - will only allow that specific role
 * role - will check if you have that role or higher level
 * noDemoAccount - will not show for the demo account
 */
class Gatekeeper extends Component {
  deny = () => {
    const { showWarning } = this.props;

    if (!showWarning) {
      return null;
    }

    return (
      <p>
        You do not have permission to view this resource. Please contact your
        account administrator.
      </p>
    );
  };

  render() {
    const {
      children,
      onlyRole,
      allowExampleMode,
      isExampleMode,
      noDemoAccount,
      isDemoAccount,
      user,
      role
    } = this.props;

    if (noDemoAccount && isDemoAccount) {
      return this.deny();
    }

    if (allowExampleMode && isExampleMode) {
      return children;
    }

    const yourRole = user.get('role');

    if (onlyRole && yourRole !== onlyRole) {
      return this.deny();
    }

    if (role && !hasPermission(yourRole, role)) {
      return this.deny();
    }

    return children;
  }
}

const mapStateToProps = state => gatekeeperSelector(state);
export default connect(mapStateToProps, {})(Gatekeeper);
