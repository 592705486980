var _extends =
  Object.assign ||
  function(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };

// Combines all reducers from modules into one file.
const modules = require.context('./', true, /.*Reducer\.js/);

const reducers = modules.keys().reduce((memo, key) => {
  let reducer = modules(key);
  if (reducer.reducer) reducer = reducer.reducer;
  if (reducer.default) reducer = reducer.default;

  // Only add if function.
  if (typeof reducer !== 'function') {
    return memo;
  }

  const items = key.split('/');
  let name = items[1];
  name = name.replace('Reducer.js', '');

  return _extends({}, memo, {
    [name]: reducer
  });
}, {});

export default reducers;
