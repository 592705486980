import React, { Component } from 'react';
import CentralWrapper from 'components/loginWrappers/centralWrapper';
import { manageCompetitorsPageSelector } from 'selectors/competitorSelectors';
import { connect } from 'react-redux';
import { attemptRemoveCompetitor } from 'actions/competitorActions';
import { attemptRemoveCurrentProfile } from 'actions/profileActions';
import ManageCompetitorsPage from 'components/page/manageCompetitorsPage';

const TITLE_DEFAULT = 'Manage competitors | Competito';

class ManageCompetitorsContainer extends Component {
  UNSAFE_componentWillMount() {
    document.title = TITLE_DEFAULT;
  }

  render() {
    return (
      <CentralWrapper>
        <ManageCompetitorsPage {...this.props} />
      </CentralWrapper>
    );
  }
}

const mapStateToProps = state => manageCompetitorsPageSelector(state);
export default connect(mapStateToProps, {
  attemptRemoveCompetitor,
  attemptRemoveCurrentProfile
})(ManageCompetitorsContainer);
