import { createSelector } from 'reselect';

export const competitorConfigSelector = createSelector(
  state => state.competitorConfig,
  (state, props) => props.match.params.competitorId,
  (competitorConfigState, competitorId) => {
    return competitorConfigState.getIn(['data', competitorId]);
  }
);

export const competitorConfigLoadingSelector = createSelector(
  state => state.competitorConfig,
  configState => {
    return configState.get('loading');
  }
);
