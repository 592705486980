export function timelinePath() {
  return `/timeline`;
}

export function renderInspectorPath(eventId, additionalQueryParams = '') {
  return (
    '/event-inspector/' +
    eventId +
    '/default' +
    (additionalQueryParams.length ? '?' + additionalQueryParams : '')
  );
}
