import { call, takeLatest } from 'redux-saga/effects';
import { ATTEMPT_UPDATE_PAYMENT_DETAILS } from 'actions/subscriptionActions';
import Swal from 'sweetalert2';
import { createAxios } from 'utils/axios/axiosUtils';
import { getStripeKey } from 'utils/stripe/stripeUtils';

export default function* updatePaymentDetailsCheckoutSaga() {
  yield takeLatest(ATTEMPT_UPDATE_PAYMENT_DETAILS, updateFn);
}

function* updateFn({ subscriptionId, cb }) {
  try {
    const response = yield call(getResponse, subscriptionId);
    if (response.data && response.data.sessionId) {
      const stripe = window.Stripe(getStripeKey());
      if (!stripe) {
        console.warn(
          'You are missing stripe import, this cannot be fulfilled.'
        );

        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text:
            'Something went wrong trying to update payment details. Try again shortly.'
        });

        cb();
        return;
      }

      yield call(callStripe, stripe, response.data.sessionId);

      // I guess if we got here, we must have a problem, we should have been redirected!
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text:
          'Something went wrong trying to update payment details. Try again shortly.'
      });

      cb();
    } else {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text:
          'Something went wrong trying to update payment details. Try again shortly.'
      });
      cb();
    }
  } catch (e) {
    console.warn(e);

    Swal.fire({
      type: 'error',
      title: 'Oops...',
      text:
        'Something went wrong trying to update payment details. Try again shortly.'
    });
    cb();
  }
}

function* callStripe(stripe, sessionId) {
  return yield call(stripe.redirectToCheckout, {
    sessionId: sessionId
  });
}

async function getResponse(subscriptionId) {
  return await createAxios().post(
    'checkout/' + subscriptionId + '/payment-details'
  );
}
