import Spinner from 'components/spinner/spinner';
import React, { useContext, useEffect, createContext, useReducer } from 'react';
import { createAxios } from 'utils/axios/axiosUtils';
import { useCurrentProfileId, useExampleMode } from './appProvider';
import Swal from 'sweetalert2';
import { useLocation, useParams } from 'react-router-dom';

export const InspectorEventContext = createContext();

async function fetchData(eventId, search, profileId, exampleMode, dispatch) {
  dispatch({
    type: SET_IS_LOADING,
    isLoading: true
  });

  let url = 'event-inspector/' + profileId + '/' + eventId + search;
  if (exampleMode) {
    url += search.length ? '&example=1' : '?example=1';
  }

  let error = false;
  try {
    const response = await createAxios().get(url);
    if (response.data && response.data.eventData) {
      dispatch({
        type: STORE_DATA,
        eventData: response.data.eventData,
        eventMeta: response.data.meta
      });
    } else {
      error = true;
    }
  } catch (e) {
    error = true;
  }

  if (error) {
    Swal.fire({
      type: 'error',
      title: 'Oops...',
      text:
        'Something went wrong trying to load the event inspector. Try again later.'
    });
    return;
  }

  dispatch({
    type: SET_IS_LOADING,
    isLoading: false
  });
}

const STORE_DATA = 'STORE_DATA';
const SET_IS_LOADING = 'SET_IS_LOADING';

const reducer = (state, action) => {
  switch (action.type) {
    case STORE_DATA:
      return {
        ...state,
        eventData: action.eventData,
        eventMeta: action.eventMeta
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      };
    default:
      throw new Error('Should not have hit this.');
  }
};

export default function InspectorEventProvider(props) {
  const [state, dispatch] = useReducer(reducer, {
    eventData: null,
    eventMeta: null,
    isLoading: true
  });

  const { search } = useLocation();
  const profileId = useCurrentProfileId();
  const exampleMode = useExampleMode();
  const { eventId } = useParams();

  useEffect(() => {
    fetchData(eventId, search, profileId, exampleMode, dispatch);
  }, [eventId, search, profileId, exampleMode]);

  return (
    <InspectorEventContext.Provider value={[state, dispatch]}>
      {state.isLoading ? <Spinner center /> : props.children}
    </InspectorEventContext.Provider>
  );
}

export const useGetInspectorEventData = () => {
  const [state] = useContext(InspectorEventContext);
  return state;
};
