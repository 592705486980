import React, { useState } from 'react';
import styles from './inspectorEventComments.module.scss';
import Button from 'components/button/button';
import Icon from 'react-fa';
import cx from 'classnames';
import { MentionsInput, Mention } from 'react-mentions';
import Spinner from 'components/spinner/spinner';
import GravatarWrapper from 'components/avatar/gravatarWrapper';
import { ROLE_LIVE_DEMO } from 'utils/permissions/permissions';
import Swal from 'sweetalert2';
import { createAxios } from 'utils/axios/axiosUtils';
import {
  useAuthUser,
  useCurrentProfileId,
  useExampleMode
} from 'providers/appProvider';
import { useGetUsers } from 'providers/usersProvider';

const defaultMentionsInputStyle = {
  input: {
    backgroundColor: '#f9fafb',
    color: '#333',
    width: '100%',
    border: '1px solid #ddd',
    outline: 'none',
    resize: 'none',
    padding: '6px 10px',
    borderRadius: '16px',
    fontSize: '13px',
    lineHeight: '18px'
  },
  control: {
    width: '100%'
  },
  width: '100%',
  wordBreak: 'break-all'
};

export default function EventCommentBox(props) {
  const [editableComment, setEditableComment] = useState('');
  const [mentions, setMentions] = useState([]);
  const [plainComment, setPlainComment] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const authUser = useAuthUser();
  const users = useGetUsers();
  const exampleMode = useExampleMode();
  const profileId = useCurrentProfileId();

  const handleChange = (event, newValue, newPlainTextValue, mentions) => {
    setEditableComment(newValue);
    setMentions(mentions);
    setPlainComment(newPlainTextValue);
  };

  const getFilteredUsers = (search, callback) => {
    return Object.values(users).reduce((carry, user) => {
      if (
        user.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 &&
        user.userId !== authUser.userId
      ) {
        carry.push({
          id: user.userId,
          display: user.name,
          email: user.email
        });
      }
      return carry;
    }, []);
  };

  const renderUserSuggestion = (
    suggestion,
    search,
    highlightedDisplay,
    index,
    focused
  ) => {
    return (
      <div
        className={cx(
          { [styles.suggestionFocused]: focused },
          styles.suggestion
        )}
      >
        <GravatarWrapper
          email={suggestion.email}
          default="mm"
          protocol="https://"
          className={styles.profileGraphicBox}
          size={20}
          style={{ marginRight: '10px' }}
        />{' '}
        {highlightedDisplay}
      </div>
    );
  };

  const submitComment = async e => {
    e.preventDefault();

    if (authUser.role === ROLE_LIVE_DEMO || exampleMode) {
      Swal.fire({
        title: 'Live demo is read-only.',
        text:
          'You cannot comment whilst using the Live Demo. This is for demonstration purposes only.',
        type: 'error'
      });
      return;
    }

    const mentionsForSubmit = mentions.map(mention => {
      return {
        display: mention.display,
        userId: mention.id
      };
    });

    setIsSubmitting(true);

    try {
      const response = await createAxios().post(
        'event/' + props.eventId + '/comment',
        {
          plainComment,
          editableComment,
          mentions: mentionsForSubmit,
          profileId
        }
      );

      if (response.data && response.data.comment) {
        setEditableComment('');
        setMentions([]);
        setPlainComment('');
      }
    } catch (e) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text:
          'Something went wrong trying to load the comments for this event. Try again later.'
      });
    }

    setIsSubmitting(false);
  };

  return (
    <div className={styles.commentBoxWrapper}>
      <div style={{ flex: 1 }}>
        <GravatarWrapper
          email={authUser.email}
          default="mm"
          protocol="https://"
          className={styles.profileGraphicBox}
          size={35}
        />
      </div>
      <div style={{ flex: 11 }}>
        <form className={styles.commentForm}>
          <MentionsInput
            value={editableComment}
            onChange={handleChange}
            allowSpaceInQuery={true}
            style={defaultMentionsInputStyle}
          >
            <Mention
              trigger="@"
              displayTransform={(id, name) => `@${name} `}
              data={getFilteredUsers}
              renderSuggestion={renderUserSuggestion}
            />
          </MentionsInput>

          <Button
            style={{ maxHeight: '40px', marginLeft: '10px' }}
            rounded
            thin
            info
            disabled={isSubmitting}
            title="Post"
            onClick={submitComment}
          >
            {!isSubmitting && <Icon name="far fa-paper-plane"></Icon>}
            {isSubmitting && (
              <Spinner style={{ width: '20px', height: '20px' }} center />
            )}
          </Button>
        </form>
      </div>
    </div>
  );
}
