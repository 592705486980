import React, { Component } from 'react';
import Block from 'components/presentation/block';
import BarChart from 'components/graph/barChart';

export default class SocialGraphWidgets extends Component {
  getSocialFollowerData = () => {
    const { socialFollowers } = this.props.socialMediaOverview;
    const labels = socialFollowers.map(item => item.name);

    const data = {
      labels: labels,
      datasets: [
        {
          label: 'Facebook',
          backgroundColor: 'rgb(76, 110, 245, 0.8)',
          borderColor: 'rgb(76, 110, 245, 1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgb(76, 110, 245, 1)',
          hoverBorderColor: 'rgb(76, 110, 245, 0.8)',
          data: socialFollowers.map(item => item.FacebookLikes)
        },
        {
          label: 'Twitter',
          backgroundColor: 'rgb(38, 198, 218, 0.8)',
          borderColor: 'rgb(38, 198, 218, 1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgb(38, 198, 218, 0.8)',
          hoverBorderColor: 'rgb(38, 198, 218, 1)',
          data: socialFollowers.map(item => item.TwitterFollowers)
        }
      ]
    };

    return data;
  };

  getSocialFollowerGrowthData = () => {
    const { socialFollowerGrowth } = this.props.socialMediaOverview;
    const labels = socialFollowerGrowth.map(item => item.name);

    const data = {
      labels: labels,
      datasets: [
        {
          label: 'Facebook',
          backgroundColor: 'rgb(76, 110, 245, 0.8)',
          borderColor: 'rgb(76, 110, 245, 1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgb(76, 110, 245, 1)',
          hoverBorderColor: 'rgb(76, 110, 245, 0.8)',
          data: socialFollowerGrowth.map(item => item.FacebookLikes)
        },
        {
          label: 'Twitter',
          backgroundColor: 'rgb(38, 198, 218, 0.8)',
          borderColor: 'rgb(38, 198, 218, 1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgb(38, 198, 218, 0.8)',
          hoverBorderColor: 'rgb(38, 198, 218, 1)',
          data: socialFollowerGrowth.map(item => item.TwitterFollowers)
        }
      ]
    };

    return data;
  };

  getPostActivityData = () => {
    const { socialPosts } = this.props.socialMediaOverview;
    const labels = socialPosts.map(item => item.name);

    const data = {
      labels: labels,
      datasets: [
        {
          label: 'Facebook',
          backgroundColor: 'rgb(76, 110, 245, 0.8)',
          borderColor: 'rgb(76, 110, 245, 1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgb(76, 110, 245, 1)',
          hoverBorderColor: 'rgb(76, 110, 245, 0.8)',
          data: socialPosts.map(item => item.FacebookPosts)
        },
        {
          label: 'Twitter',
          backgroundColor: 'rgb(38, 198, 218, 0.8)',
          borderColor: 'rgb(38, 198, 218, 1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgb(38, 198, 218, 0.8)',
          hoverBorderColor: 'rgb(38, 198, 218, 1)',
          data: socialPosts.map(item => item.TweetCount)
        },
        {
          label: 'YouTube',
          backgroundColor: '#F50000',
          borderColor: '#d30000',
          borderWidth: 1,
          hoverBackgroundColor: '#d30000',
          hoverBorderColor: '#F50000',
          data: socialPosts.map(item => item.YouTubeVideos)
        }
      ]
    };

    return data;
  };

  getEngagementData = () => {
    const { socialEngagement } = this.props.socialMediaOverview;
    const labels = socialEngagement.map(item => item.name);

    const data = {
      labels: labels,
      datasets: [
        {
          label: 'Facebook',
          backgroundColor: 'rgb(76, 110, 245, 0.8)',
          borderColor: 'rgb(76, 110, 245, 1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgb(76, 110, 245, 1)',
          hoverBorderColor: 'rgb(76, 110, 245, 0.8)',
          data: socialEngagement.map(item => item.facebook)
        },
        {
          label: 'Twitter',
          backgroundColor: 'rgb(38, 198, 218, 0.8)',
          borderColor: 'rgb(38, 198, 218, 1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgb(38, 198, 218, 0.8)',
          hoverBorderColor: 'rgb(38, 198, 218, 1)',
          data: socialEngagement.map(item => item.twitter)
        }
      ]
    };

    return data;
  };

  render() {
    const { socialMediaOverview } = this.props;
    if (!socialMediaOverview) {
      return null;
    }

    return (
      <div>
        <div className="row">
          <div className="col-md-6">
            <Block
              title={'Social followers overall'}
              help="Here you can review how many social followers you and your competitors have at the current moment in time."
            >
              <BarChart data={this.getSocialFollowerData()} />
            </Block>
          </div>
          <div className="col-md-6">
            <Block
              title={'Social post activity'}
              help="Here you can summarize how many posts have been made on social media, including videos on YouTube in the last 30 days for you and your competitors."
            >
              <BarChart data={this.getPostActivityData()} />
            </Block>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Block
              title={'Social follower growth'}
              help="Here you can summarize how many followers on social media has been gained or lost in the last 30 days for you and your competitors."
            >
              <BarChart data={this.getSocialFollowerGrowthData()} />
            </Block>
          </div>
          <div className="col-md-6">
            <Block
              title={'Social engagement'}
              help="Here you can summarize how much engagement from posts you and your competitors have received on social media in the last 30 days. Engagement is defined as the amount of comments, likes, shares, favorites and retweets earnt."
            >
              <BarChart data={this.getEngagementData()} />
            </Block>
          </div>
        </div>
      </div>
    );
  }
}
