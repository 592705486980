import Alert from 'components/alert/alert';
import React from 'react';
import DefaultInspector from './definition/defaultInspector';

const req = require.context('components/inspector/definition', true, /.*.js/);

export default function InspectorEvent(props) {
  try {
    let Component = req(
      './' +
        props.eventData.eventType +
        '/' +
        props.eventData.eventType +
        'Inspector.js'
    ).default;
    return <Component {...props} eventProps={props.eventData.props} />;
  } catch (e) {
    if (e.message.indexOf('Cannot find module') === -1) {
      return (
        <Alert type="tw1" variant="danger">
          There seems to have been an issue loading this event.
        </Alert>
      );
    }
  }

  return <DefaultInspector {...props} eventProps={props.eventData.props} />;
}
