import { createSelector } from 'reselect';
import { currentProfileSelector } from 'selectors/profileSelectors';
import { profileConfigSelector } from 'selectors/profileConfigSelectors';
import { simpleCompetitorSelector } from 'selectors/competitorSelectors';

export const keywordsPageSelector = createSelector(
  state => state.keyword,
  currentProfileSelector,
  simpleCompetitorSelector,
  (keywords, profile, competitors) => {
    return {
      isLoading: keywords.get('isLoading'),
      loadingError: keywords.get('loadingError'),
      tableData: keywords.getIn(['data', 'keywordTable']).toJS(),
      totalKeywords: keywords.getIn(['data', 'keywordStats', 'totalKeywords']),
      top100Rankings: keywords.getIn([
        'data',
        'keywordStats',
        'top100Rankings'
      ]),
      topTenRankings: keywords.getIn([
        'data',
        'keywordStats',
        'topTenRankings'
      ]),
      firstPlaceRankings: keywords.getIn([
        'data',
        'keywordStats',
        'firstPlaceRankings'
      ]),
      profile: profile ? profile.toJS() : null,
      competitors: competitors.toJS()
    };
  }
);

export const keywordsSelector = createSelector(
  profileConfigSelector,
  profileConfig => {
    return {
      keywords: profileConfig.get('keywords')
    };
  }
);
