export const EVENT_TYPE_MAP = {
  competitorAdded: {
    category: 'Competitor added',
    icon: 'fas fa-cogs',
    iconBgColor: '#343a40',
    textColor: '#fff'
  },
  webPageInit: {
    category: 'Website change',
    icon: 'fas fa-browser',
    iconBgColor: '#46c37b',
    textColor: '#fff'
  },
  webPageAdded: {
    category: 'Website change',
    icon: 'fas fa-browser',
    iconBgColor: '#46c37b',
    textColor: '#fff'
  },
  webPageUpdated: {
    category: 'Website change',
    icon: 'fas fa-globe',
    iconBgColor: '#00c1d4',
    textColor: '#fff'
  },
  webPageRemoved: {
    category: 'Website change',
    icon: 'fas fa-browser',
    iconBgColor: '#d26a5c',
    textColor: '#fff'
  },
  youTubeVideoAdded: {
    category: 'Social media',
    icon: 'fab fa-youtube',
    iconBgColor: '#F50000',
    textColor: '#fff'
  },
  facebookPostAdded: {
    category: 'Social media',
    icon: 'fab fa-facebook-f',
    iconBgColor: '#5c80d1',
    textColor: '#fff'
  },
  facebookAdAdded: {
    category: 'Ads',
    icon: 'fab fa-facebook-f',
    iconBgColor: '#2f80da',
    textColor: '#fff'
  },
  tweetAdded: {
    category: 'Social media',
    icon: 'fab fa-twitter',
    iconBgColor: '#70b9eb',
    textColor: '#fff'
  },
  newsletterUpdate: {
    category: 'Email',
    icon: 'fas fa-envelope-open-text',
    iconBgColor: '#da621f',
    textColor: '#fff'
  },
  keywordRankingUpdate: {
    category: 'Search rankings',
    icon: 'fab fa-google',
    iconBgColor: '#2f80da',
    textColor: '#fff'
  },
  keywordNewRanking: {
    category: 'Search rankings',
    icon: 'fab fa-google',
    iconBgColor: '#2f80da',
    textColor: '#fff'
  },
  keywordInitRanking: {
    category: 'Search rankings',
    icon: 'fab fa-google',
    iconBgColor: '#2f80da',
    textColor: '#fff'
  },
  serpAdFound: {
    category: 'Ads',
    icon: 'fab fa-google',
    iconBgColor: '#2f80da',
    textColor: '#fff'
  },
  serpCompetitorFound: {
    category: 'Competitors',
    icon: 'fas fa-project-diagram',
    iconBgColor: '#2f80da',
    textColor: '#fff'
  },
  competitorFound: {
    category: 'Competitors',
    icon: 'fas fa-project-diagram',
    iconBgColor: '#2f80da',
    textColor: '#fff'
  },
  webPageSeoUpdate: {
    category: 'SEO changes',
    icon: 'fas fa-browser',
    iconBgColor: '#2f80da',
    textColor: '#fff'
  }
};
