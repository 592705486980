import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './accountSetupWizard.module.scss';
import { notOnMobile } from 'utils/appUtils';
import Form from 'react-bootstrap/Form';
import Button from 'components/button/button';
import cx from 'classnames';
import Spinner from 'components/spinner/spinner';
import { clearGuestToken } from 'utils/localStorage/localStorage';
import { parse } from 'query-string';
import { registerAccount } from 'actions/authActions';

const DEFAULT_FORM_DATA_STATE = {
  name: '',
  email: '',
  password: '',
  confirmPassword: ''
};

const DEFAULT_VALIDATION_FIELDS = {
  invalidName: false,
  invalidEmailAddress: false,
  invalidPassword: false,
  invalidConfirmPassword: false
};

class AccountSetupWizardRegisterStep extends Component {
  constructor() {
    super();

    this.state = {
      formData: Object.assign({}, DEFAULT_FORM_DATA_STATE),
      validationFields: Object.assign({}, DEFAULT_VALIDATION_FIELDS),
      addingAccount: false,
      accountError: null
    };

    clearGuestToken();
  }

  onAccountCreated = () => {
    this.props.loadNextStep();
  };

  onAccountFailure = errorMessage => {
    this.setState({ accountError: errorMessage });
  };

  submitForm = e => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      validationFields: DEFAULT_VALIDATION_FIELDS,
      accountError: null
    });

    const {
      registerAccount,
      location: { search }
    } = this.props;
    const { formData, validationFields, addingAccount } = this.state;
    const { name, email, password, confirmPassword } = formData;

    let validationIssues = false;

    if (addingAccount) {
      return;
    }

    if (name.length < 5) {
      validationIssues = true;
      validationFields.invalidName = true;
    }

    if (email.indexOf('@') === -1 || email.indexOf('.') === -1) {
      validationIssues = true;
      validationFields.invalidEmailAddress = true;
    }

    if (password.length < 5) {
      validationIssues = true;
      validationFields.invalidPassword = true;
    }

    if (password !== confirmPassword) {
      validationIssues = true;
      validationFields.invalidConfirmPassword = true;
    }

    if (validationIssues) {
      this.setState({ validationFields: validationFields });
      return;
    }

    const params = parse(search);
    const dataToPass = { planId: params.planId, name, email, password };

    this.setState({ addingAccount: true });

    registerAccount(dataToPass, this.onAccountCreated, this.onAccountFailure);
  };

  setField = fieldName => e => {
    let newData = this.state;
    newData.validationFields = Object.values({}, DEFAULT_VALIDATION_FIELDS);
    newData.formData[fieldName] = e.target.value;
    this.setState(newData);
  };

  renderFormFields = () => {
    const {
      formData,
      addingAccount,
      validationFields,
      accountError
    } = this.state;
    const { name, email, password, confirmPassword } = formData;
    const {
      invalidName,
      invalidEmailAddress,
      invalidPassword,
      invalidConfirmPassword
    } = validationFields;

    return (
      <div>
        <div className="row">
          <div className={'col-md-12'}>
            <div className={styles.websiteFields}>
              <Form.Group controlId="name" className={'mrgBottom50'}>
                <Form.Label>
                  <strong>What's your full name?</strong>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="e.g. Richard Hendricks"
                  onChange={this.setField('name')}
                  autoFocus={notOnMobile(true)}
                  value={name}
                  className={styles.formControl}
                />

                {invalidName && (
                  <Form.Text className="text-danger">
                    Your name should be at least four characters
                  </Form.Text>
                )}
              </Form.Group>

              <Form.Group controlId="email" className={'mrgBottom50'}>
                <Form.Label>
                  <strong>What's your email address?</strong>
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="e.g. richard@piedpiper.net"
                  onChange={this.setField('email')}
                  value={email}
                  className={styles.formControl}
                />

                {invalidEmailAddress && (
                  <Form.Text className="text-danger">
                    You must provide a valid email address
                  </Form.Text>
                )}
              </Form.Group>

              <Form.Group controlId="password" className={'mrgBottom50'}>
                <Form.Label>
                  <strong>What password would you like?</strong>
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="********"
                  onChange={this.setField('password')}
                  value={password}
                  className={styles.formControl}
                />

                {invalidPassword && (
                  <Form.Text className="text-danger">
                    Your password must be longer than 5 characters
                  </Form.Text>
                )}
              </Form.Group>

              <Form.Group controlId="confirmPassword" className={'mrgBottom50'}>
                <Form.Label>
                  <strong>Let's confirm the password you gave above</strong>
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="********"
                  onChange={this.setField('confirmPassword')}
                  value={confirmPassword}
                  className={styles.formControl}
                />

                {invalidConfirmPassword && (
                  <Form.Text className="text-danger">
                    Your passwords do not match
                  </Form.Text>
                )}
              </Form.Group>

              {accountError && (
                <Form.Text className="text-danger">{accountError}</Form.Text>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="col-md-12"
            style={{
              textAlign: 'center',
              marginTop: '30px',
              marginBottom: '30px'
            }}
          >
            {!addingAccount && (
              <Button type="submit" rounded primary>
                Sign up
              </Button>
            )}

            {addingAccount && (
              <span style={{ marginLeft: '10px' }}>
                <Spinner
                  style={{
                    height: '20px',
                    width: '20px',
                    fontSize: '8px',
                    marginRight: '10px'
                  }}
                />{' '}
                Validating
              </span>
            )}
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        <div className="row">
          <div className={cx('col-md-12', styles.titleText)}>
            <div>
              <h1>
                Welcome. Let's set up your free trial{' '}
                <span role="img" aria-label="">
                  🥳
                </span>
              </h1>
              <h2>No credit card required</h2>
            </div>
          </div>
        </div>

        <div>
          <Form onSubmit={e => this.submitForm(e)}>
            {this.renderFormFields()}
          </Form>
        </div>
      </div>
    );
  }
}

// const mapStateToProps = state => competitorAddPageSelector(state);
export default connect(
  null,
  { registerAccount }
)(AccountSetupWizardRegisterStep);
