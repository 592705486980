import React, { Component } from 'react';
import Block from 'components/presentation/block';
import Button from 'components/button/button';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import {
  getFrequencyFromCoolOff,
  getCoolOffForFrequency
} from 'utils/notification/notificationUtils';

class EventNotificationsSettingsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      frequency: getFrequencyFromCoolOff(
        props.notificationChannel.get('coolOff')
      )
    };
  }

  changeFrequency = e => {
    this.setState({ frequency: e.target.value });

    this.props.updateUserChannel({
      coolOff: getCoolOffForFrequency(e.target.value)
    });
  };

  toggleProfileSubscription = (profileId, state) => {
    this.props.toggleNotificationSubscriptionsForProfile(profileId, state);
  };

  getNotificationSubscriptionForProfileId = profileId => {
    const { channelSubscriptions } = this.props;

    if (!channelSubscriptions || !channelSubscriptions.size) {
      return null;
    }

    return channelSubscriptions.find(
      item => item.get('profileId') === profileId
    );
  };

  getStatusForProfile = profileId => {
    if (this.state.frequency === 'never') {
      return 'disabled';
    }

    const notificationSubscription = this.getNotificationSubscriptionForProfileId(
      profileId
    );
    if (notificationSubscription) {
      const allowedEventTypes = notificationSubscription.get(
        'allowedEventTypes'
      );
      const deniedEventTypes = notificationSubscription.get('deniedEventTypes');
      const deniedCompetitors = notificationSubscription.get(
        'deniedCompetitors'
      );

      if (deniedEventTypes && deniedEventTypes.indexOf('all') !== -1) {
        return 'disabled';
      }

      if (
        allowedEventTypes.size ||
        deniedEventTypes.size ||
        deniedCompetitors.size
      ) {
        return 'custom';
      }
    }

    return 'everything';
  };

  renderStatusPill(status) {
    switch (status) {
      case 'never':
      case 'disabled':
        return <Badge variant="danger">Disabled</Badge>;
      case 'custom':
        return <Badge variant="warning">Custom</Badge>;
      default:
        return <Badge variant="success">Everything</Badge>;
    }
  }

  renderTableRows = () => {
    let data = [];

    this.props.profiles.forEach(profile => {
      const { profileName, profileId } = profile;

      const status = this.getStatusForProfile(profileId);

      data.push(
        <tr key={profile.profileId}>
          <td style={{ fontWeight: 'bold' }}>{profileName}</td>
          <td>{this.renderStatusPill(status)}</td>
          <td align="right">
            {status === 'everything' && this.state.frequency !== 'never' && (
              <Button
                icon="fas fa-trash"
                onClick={() =>
                  this.toggleProfileSubscription(profileId, 'disable')
                }
                danger
                thin
              >
                Disable
              </Button>
            )}

            {status !== 'everything' && this.state.frequency !== 'never' && (
              <Button
                icon="fas fa-check"
                onClick={() =>
                  this.toggleProfileSubscription(profileId, 'enable')
                }
                success
                thin
              >
                Enable
              </Button>
            )}
          </td>
        </tr>
      );
    });

    return data;
  };

  render() {
    return (
      <div>
        <div className="row" style={{ marginBottom: '30px' }}>
          <div className="col-xl-12" style={{ textAlign: 'center' }}>
            <h1 className={'h4'} style={{ marginBottom: 0 }}>
              Manage event notifications
            </h1>
            <h2 className={'h5 textLighter thin'}>
              Specify rules for which notifications you wish to receive.
            </h2>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <Block title={'General'}>
              <Form.Group controlId="name">
                <Form.Label>
                  <strong>
                    What is the minimum frequency you wish to receive
                    notifications?
                  </strong>
                </Form.Label>
                <Form.Control
                  as="select"
                  onChange={this.changeFrequency}
                  value={this.state.frequency}
                  style={{ maxWidth: '200px' }}
                >
                  <option key="never" value="never">
                    Never
                  </option>
                  <option key="instant" value="instant">
                    Instant
                  </option>
                  <option key="hourly" value="hourly">
                    Hourly
                  </option>
                  <option key="daily" value="daily">
                    Daily
                  </option>
                  <option key="weekly" value="weekly">
                    Weekly
                  </option>
                </Form.Control>
              </Form.Group>
            </Block>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <Block title={'Subscriptions'}>
              <p>
                <strong>
                  Configure which Profiles, Competitors and Events can send
                  notifications.
                </strong>
              </p>

              <table className="table table-striped table-vcenter">
                <thead>
                  <tr>
                    <th>Profile</th>
                    <th>Status</th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>{this.renderTableRows()}</tbody>
              </table>
            </Block>
          </div>
        </div>
      </div>
    );
  }
}

export default EventNotificationsSettingsPage;
