import React, { Component } from 'react';
import styles from './keywordsPage.module.scss';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Icon from 'components/fontAwesome/icon';
import { friendlyUrl } from 'utils/urlUtils';
import Block from 'components/presentation/block';
import TablePagination from 'components/table/tablePagination';
import Sparkline from 'components/sparkline/sparkline';
import { getOrdinal } from 'utils/numberUtils';
import Spinner from 'components/spinner/spinner';
import CompetitorSelectInput from 'components/competitor/competitorSelectInput';
import SimpleStat from 'components/stat/simpleStat';
import Alert from 'components/alert/alert';
import LiveDemoWarning from 'components/demo/liveDemoWarning';

class KeywordsPage extends Component {
  selectCompetitor = competitorId => {
    if (competitorId && competitorId.length > 0) {
      this.props.onSelectCompetitor(competitorId);
    }
  };

  selectPage = page => {
    if (this.props.page !== page) {
      this.props.onSelectPage(page);
    }
  };

  renderTableRows = () => {
    const { tableData } = this.props;

    return tableData.map(row => {
      const history = row.history.map(item => item.rank);
      if (history.length === 1) {
        history.push(history[0]);
      }

      return (
        <tr key={row.keyword}>
          <td>
            <div className={styles.keywordWrapper}>{row.keyword}</div>
            {!!row.link && (
              <div className={styles.keywordLink}>
                {friendlyUrl(row.link, false, 50)}{' '}
                <a href={row.link} target="_blank" rel="noopener noreferrer">
                  <Icon name="fal fa-external-link-alt" />
                </a>
              </div>
            )}
          </td>
          <td>{history.length > 0 ? <Sparkline data={history} /> : '-'}</td>
          <td>{row.ownTopRank ? getOrdinal(row.ownTopRank) : '-'}</td>
          <td>
            {row.competitorTopRank ? getOrdinal(row.competitorTopRank) : '-'}
          </td>
        </tr>
      );
    });
  };

  submitSearchForm = e => {
    e.preventDefault();
    this.props.onSearchTermChange(
      document.getElementById('search-keywords').value
    );
  };

  renderTableData = () => {
    const {
      totalKeywords,
      page,
      competitors,
      sortBy,
      searchTerm,
      chosenCompetitorId
    } = this.props;

    const competitor = competitors[chosenCompetitorId];

    if (!chosenCompetitorId.length) {
      return (
        <div className="row">
          <div className="col-md-12">
            <Alert variant="info">
              No competitors have been added, please add one to view this data.
            </Alert>
          </div>
        </div>
      );
    }

    if (!totalKeywords) {
      return (
        <div className="row">
          <div className="col-md-12">
            <Alert variant="info">
              No keywords have been added, please add some to view this data.
            </Alert>
          </div>
        </div>
      );
    }

    return (
      <div className="row">
        <Block
          title="Keyword rankings"
          blockItems={[
            {
              key: 'bi_1',
              value: '<span>' + totalKeywords + ' rankings</span>'
            }
          ]}
        >
          <div className="row" style={{ marginBottom: '10px' }}>
            <div className="col-md-6">
              <form onSubmit={this.submitSearchForm}>
                <input
                  style={{ maxWidth: '250px' }}
                  className="form-control"
                  type="text"
                  name="search"
                  id="search-keywords"
                  defaultValue={searchTerm}
                  placeholder="Search for a keyword"
                />
              </form>
            </div>

            <div className="col-md-6">
              <CompetitorSelectInput
                onChange={this.selectCompetitor}
                competitors={competitors}
                className={styles.keywordsSelector}
              />
              <div className="clear"></div>
            </div>
          </div>

          <table className="table table-striped table-vcenter">
            <thead>
              <tr>
                <th
                  onClick={() => sortBy('keyword')}
                  style={{ cursor: 'pointer' }}
                >
                  Keyword{' '}
                  <Icon className={styles.sortIcon} name="fas fa-sort" />
                </th>
                <th style={{ width: '100px' }}>Your history</th>
                <th
                  onClick={() => sortBy('ownTopRank')}
                  style={{ width: '125px', cursor: 'pointer' }}
                >
                  Your position{' '}
                  <Icon className={styles.sortIcon} name="fas fa-sort" />
                </th>
                <th
                  onClick={() => sortBy('competitorTopRank')}
                  style={{ width: '75px', cursor: 'pointer' }}
                >
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-2`}>
                        {competitor.name} - {competitor.normalisedDomain}
                      </Tooltip>
                    }
                  >
                    <span>
                      <img
                        src={competitor.faviconUrl}
                        alt=""
                        className={styles.favi}
                      />
                    </span>
                  </OverlayTrigger>
                  <Icon className={styles.sortIcon} name="fas fa-sort" />
                </th>
              </tr>
            </thead>
            <tbody>{this.renderTableRows()}</tbody>
          </table>

          <TablePagination
            style={{ marginTop: '20px' }}
            page={page}
            numRows={totalKeywords}
            rowsPerPage={25}
            onClickPage={this.selectPage}
          />
        </Block>
      </div>
    );
  };

  renderRankings = () => {
    const { top100Rankings, topTenRankings, firstPlaceRankings } = this.props;
    return (
      <div className="row">
        <div className="col-md-4">
          <SimpleStat
            iconName="fal fa-trophy"
            iconColor="gold"
            iconSize="3x"
            label="1st place rankings"
            stat={firstPlaceRankings}
          />
        </div>

        <div className="col-md-4">
          <SimpleStat
            iconName="fal fa-trophy"
            iconColor="silver"
            iconSize="3x"
            label="Top 10 rankings"
            stat={topTenRankings}
          />
        </div>

        <div className="col-md-4">
          <SimpleStat
            iconName="fal fa-trophy"
            iconColor="#cd7f32"
            iconSize="3x"
            label="Top 100 rankings"
            stat={top100Rankings}
          />
        </div>
      </div>
    );
  };

  render() {
    const { profile, isLoading, loadingError } = this.props;

    return (
      <div>
        <div className="row" style={{ marginBottom: '30px' }}>
          <div className="col-xl-12" style={{ textAlign: 'center' }}>
            <h1 className={'h4'} style={{ marginBottom: 0 }}>
              Keywords for {profile.name}
            </h1>
            <h2 className={'h5 textLighter thin'}>
              Monitor where you and your competitors rank for your given
              keywords
            </h2>
          </div>
        </div>

        <LiveDemoWarning style={{ marginBottom: '60px' }} />

        {isLoading && <Spinner center />}
        {!isLoading && loadingError && (
          <p>
            An issue occured trying to load this page. Please try again later.
          </p>
        )}
        {!isLoading && this.renderRankings()}
        {!isLoading && this.renderTableData()}
      </div>
    );
  }
}

export default KeywordsPage;
