import React, { Component } from 'react';
import CentralWrapper from 'components/loginWrappers/centralWrapper';
import { editProfilePageSelector } from 'selectors/profileSelectors';
import { connect } from 'react-redux';
import {
  attemptRemoveCurrentProfile,
  attemptUpdateCurrentProfile
} from 'actions/profileActions';
import EditProfilePage from 'components/page/editProfilePage';
import { createAxios } from 'utils/axios/axiosUtils';

const TITLE_DEFAULT = 'Edit Profile | Competito';

class EditProfileContainer extends Component {
  state = { keywordsLeft: 0, keywordsUsed: 0 };

  UNSAFE_componentWillMount() {
    document.title = TITLE_DEFAULT;
    this.loadLimitsForProfile();
  }

  async loadLimitsForProfile() {
    let response = await createAxios().get('service-limits/keywords');
    if (response && response.data) {
      this.setState({
        keywordsLeft: response.data.keywordsLeft,
        keywordsUsed: response.data.keywordsUsed
      });
    }
  }

  render() {
    return (
      <CentralWrapper>
        <EditProfilePage
          {...this.props}
          keywordsLeft={this.state.keywordsLeft}
          keywordsUsed={this.state.keywordsUsed}
        />
      </CentralWrapper>
    );
  }
}

const mapStateToProps = (state, props) => editProfilePageSelector(state, props);
export default connect(mapStateToProps, {
  attemptRemoveCurrentProfile,
  attemptUpdateCurrentProfile
})(EditProfileContainer);
