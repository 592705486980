import React, { Component } from 'react';
import modalStyles from './globalModal.module.scss';
import Icon from 'components/fontAwesome/icon';
import cx from 'classnames';
import { createAxios } from 'utils/axios/axiosUtils';
import { connect } from 'react-redux';
import Spinner from 'components/spinner/spinner';
import { manageCompetitorsPageSelector } from 'selectors/competitorSelectors';
import Button from 'components/button/button';
import { markChecklistItemComplete } from 'actions/accountSetupActions';
import CopyButton from 'components/button/copyButton';

class NewsletterSignupGuidanceModal extends Component {
  state = { emails: [], requestingData: true };

  UNSAFE_componentWillMount() {
    this.loadCompetitorEmailAddresses();
  }

  async loadCompetitorEmailAddresses() {
    let response = await createAxios().get('account-emails');
    if (response && response.data) {
      this.setState({ emails: response.data.emails, requestingData: false });
    }
  }

  markAsComplete = () => {
    this.props.markChecklistItemComplete('hasSetupNewsletter', () => {
      this.props.clearModal();
    });
  };

  renderEmailsTable = () => {
    const { competitors } = this.props;
    const { requestingData } = this.state;

    if (requestingData) {
      return <Spinner />;
    }

    let competitorsEmails = [];

    this.state.emails.forEach(email => {
      const competitor = competitors.get(email.competitorId.toString());

      competitorsEmails.push(
        <tr key={email.email}>
          <td>
            <img
              src={competitor.get('faviconUrl')}
              alt=""
              style={{
                height: '14px',
                width: '14px',
                marginRight: '3px'
              }}
            />{' '}
            {competitor.get('name')}
          </td>
          <td>
            <a href={'mailto:' + email.email}>{email.email}</a>{' '}
            <CopyButton text={email.email} />
          </td>
        </tr>
      );
    });

    return (
      <table className="table table-striped table-vcenter">
        <thead>
          <tr>
            <th>Competitor</th>
            <th>Email address</th>
          </tr>
        </thead>
        <tbody>{competitorsEmails}</tbody>
      </table>
    );
  };

  render() {
    return (
      <div className={cx(modalStyles.modalMain, modalStyles.modalMainSmaller)}>
        <div className={modalStyles.closeWrapper}>
          <Icon name="fas fa-times" onClick={this.props.clearModal} />
        </div>

        <h1 className={modalStyles.title}>
          Set up newsletter monitoring{' '}
          <span role="img" aria-hidden="true">
            📰
          </span>
        </h1>

        <p>
          Simply sign up to the newsletter or register for an account on your
          competitors website using the email address assigned below. Don't
          worry if they require you to confirm this email address, you'll see a
          copy of the email on your Timeline, you can confirm it there.
        </p>

        {this.renderEmailsTable()}

        <div className="row">
          <div className="cold-md-12">
            <Button
              size="sm"
              primary
              thin
              style={{ marginBottom: '20px', marginLeft: '15px' }}
              onClick={this.markAsComplete}
            >
              Mark as complete
            </Button>

            <Button
              size="sm"
              secondary
              thin
              style={{ marginBottom: '20px', marginLeft: '15px' }}
              onClick={this.props.clearModal}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => manageCompetitorsPageSelector(state);
export default connect(mapStateToProps, { markChecklistItemComplete })(
  NewsletterSignupGuidanceModal
);
