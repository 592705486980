import React from 'react';
import PusherJS from 'pusher-js';
import ReactPusher, { setPusherClient } from './reactPusher';

const pusherClient = new PusherJS('0fed44212b88df482fca', {
  cluster: 'eu',
  encrypted: true,
  disableStats: true
});
setPusherClient(pusherClient);

export class Pusher extends React.Component {
  render() {
    const { channel, event, onUpdate } = this.props;

    return <ReactPusher event={event} channel={channel} onUpdate={onUpdate} />;
  }
}

export default Pusher;
