import React from 'react';
import TimelineEventWrapper from '../timelineEventWrapper';
import { pluralise } from 'utils/numberUtils';
import cx from 'classnames';
import styles from './webPageUpdatedEvent.module.scss';
import { Link } from 'react-router-dom';
import { renderInspectorPath } from 'utils/pathUtils';

export function getEventTitle(name, pages) {
  const nonTrivialPage = pages.find(page => page.changeSeverity !== 't');

  const title =
    name +
    ' ' +
    (nonTrivialPage === undefined ? '' : 'trivially') +
    ' updated ' +
    pluralise(pages.length, 'a web page', pages.length + ' web pages');

  return title;
}

export default function WebPageUpdatedEvent(props) {
  const pages = props.event.props.pages;
  const name = props.event.referenceName;

  const inspectorAdditionalParams =
    pages.length === 1 ? 'pageId=' + pages[0].pageId : '';

  return (
    <TimelineEventWrapper
      title={getEventTitle(name, pages)}
      buttonText="Show me what changed"
      inspectorAdditionalParams={inspectorAdditionalParams}
      {...props}
    >
      <Link
        to={renderInspectorPath(props.event.eventId, inspectorAdditionalParams)}
      >
        <div className={styles.flex}>
          <div
            className={cx(styles.flexColMain, {
              [styles.marginRightImage]: pages[1] !== undefined
            })}
          >
            <img
              src={pages[0].viewportPageDiffScreenshotLocation}
              alt="Viewport page screenshot with diff highlighted"
              className={cx(
                styles.img,
                pages.length > 1 ? styles.halfPageImg : styles.fullPageImg
              )}
            />
          </div>
          {pages[1] !== undefined && (
            <div className={styles.flexColSub}>
              {pages[1] !== undefined && (
                <img
                  src={pages[1].viewportPageDiffScreenshotLocation}
                  alt="Viewport page screenshot with diff highlighted"
                  style={
                    pages.length > 2 ? { borderBottom: ' 2px solid #eee' } : {}
                  }
                  className={cx(
                    styles.img,
                    pages.length > 2 ? styles.thirdPageImg : styles.halfPageImg
                  )}
                />
              )}

              {pages[2] !== undefined && (
                <div style={{ position: 'relative' }}>
                  <img
                    src={pages[2].viewportPageDiffScreenshotLocation}
                    alt="Viewport page screenshot with diff highlighted"
                    style={{ marginTop: '15px' }}
                    className={cx(
                      styles.img,
                      pages.length > 3
                        ? styles.thirdPageImgWithOverlay
                        : styles.thirdPageImg
                    )}
                  />

                  {pages[3] !== undefined && (
                    <div className={styles.overlay}>
                      <span className={styles.overlayText}>
                        +{pages.length - 3}
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </Link>
    </TimelineEventWrapper>
  );
}
