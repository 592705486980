export const FETCH_INVENTORY_PAGE_DATA = 'app:FETCH_INVENTORY_PAGE_DATA';
export function fetchInventoryPageData(
  competitorId,
  pageId,
  limit,
  searchTerm
) {
  return {
    type: FETCH_INVENTORY_PAGE_DATA,
    competitorId,
    pageId,
    limit,
    searchTerm
  };
}

export const RECEIVE_INVENTORY_PAGE_DATA = 'app:RECEIVE_INVENTORY_PAGE_DATA';
export function receiveInventoryPageData(data) {
  return {
    type: RECEIVE_INVENTORY_PAGE_DATA,
    data
  };
}

export const HANDLE_INVENTORY_PAGE_DATA_ERROR =
  'app:HANDLE_INVENTORY_PAGE_DATA_ERROR';
export function handleInventoryPageDataError() {
  return {
    type: HANDLE_INVENTORY_PAGE_DATA_ERROR
  };
}

export const FETCH_INVENTORY_TECH_DATA = 'app:FETCH_INVENTORY_TECH_DATA';
export function fetchInventoryTechData(competitorId) {
  return {
    type: FETCH_INVENTORY_TECH_DATA,
    competitorId
  };
}

export const RECEIVE_INVENTORY_TECH_DATA = 'app:RECEIVE_INVENTORY_TECH_DATA';
export function receiveInventoryTechData(data) {
  return {
    type: RECEIVE_INVENTORY_TECH_DATA,
    data
  };
}

export const HANDLE_INVENTORY_TECH_DATA_ERROR =
  'app:HANDLE_INVENTORY_TECH_DATA_ERROR';
export function handleInventoryTechDataError() {
  return {
    type: HANDLE_INVENTORY_TECH_DATA_ERROR
  };
}

export const SET_PAGE_MONITORING_STATE = 'app:SET_PAGE_MONITORING_STATE';
export function setPageMonitoringState(
  competitorId,
  pageId,
  operation,
  successCallback,
  failCallback
) {
  return {
    type: SET_PAGE_MONITORING_STATE,
    competitorId,
    pageId,
    operation,
    successCallback,
    failCallback
  };
}

export const ADD_PAGE_FOR_MONITORING = 'app:ADD_PAGE_FOR_MONITORING';
export function addPageForMonitoring(competitorId, url, successCallbackFn) {
  return {
    type: ADD_PAGE_FOR_MONITORING,
    competitorId,
    url,
    successCallbackFn
  };
}
