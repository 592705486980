import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ATTEMPT_SWITCH_PLAN,
  updateSubscription
} from 'actions/subscriptionActions';
import { createAxios } from 'utils/axios/axiosUtils';
import Swal from 'sweetalert2';

export default function* switchPlanSaga() {
  yield takeLatest(ATTEMPT_SWITCH_PLAN, updateFn);
}

function* updateFn({ subscriptionId, newPlanId, cb }) {
  try {
    const response = yield call(attemptResponse, subscriptionId, newPlanId);
    if (response.data && response.data.subscription) {
      yield put(updateSubscription(response.data.subscription));
      cb();
      return;
    }
  } catch (e) {
    console.warn(e);
  }

  Swal.fire({
    type: 'error',
    title: 'Oops...',
    text:
      'Something went wrong trying to switch plan. Please try again shortly.'
  });

  cb();
}

async function attemptResponse(subscriptionId, newPlanId) {
  return await createAxios().put(
    'subscriptions/' + subscriptionId + '/switch-plan/' + newPlanId
  );
}
