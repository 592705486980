import { createSelector } from 'reselect';
import { currentProfileSelector } from 'selectors/profileSelectors';
import { simpleCompetitorSelector } from 'selectors/competitorSelectors';

export const keywordSuggestionsPageSelector = createSelector(
  state => state.keywordSuggestion,
  currentProfileSelector,
  simpleCompetitorSelector,
  (keywords, profile, competitors) => {
    return {
      data: keywords.get('data'),
      profile: profile ? profile.toJS() : null,
      competitors: competitors.toJS()
    };
  }
);

export const keywordSuggestionsForDomainSelector = createSelector(
  state => state.keywordSuggestion,
  (state, props = {}) => props.domain,
  (keywords, domain) => {
    const data = keywords.get('data');
    if (!data || !data.size) {
      return null;
    }

    const domainData = data.get(domain);
    if (!domainData) {
      return null;
    }

    return domainData;
  }
);
