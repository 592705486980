import React, { Component } from 'react';
import Block from 'components/presentation/block';
import Button from 'components/button/button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import Spinner from 'components/spinner/spinner';
import cx from 'classnames';
import Icon from 'components/fontAwesome/icon';

class EditCompetitorPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      showAdvancedWebsiteConfig: false,
      facebookUrl: props.competitorConfig.facebookPageUrl
        ? props.competitorConfig.facebookPageUrl
        : '',
      youtubePageUrl: props.competitorConfig.youtubePageUrl
        ? props.competitorConfig.youtubePageUrl
        : '',
      twitterUrl: props.competitorConfig.twitterPageUrl
        ? props.competitorConfig.twitterPageUrl
        : '',
      domain: props.competitor.url,
      name: props.competitor.name,
      defaultClickSelectors: props.competitorConfig.defaultClickSelectors
        ? props.competitorConfig.defaultClickSelectors
        : '',
      ignoreSelectors: props.competitorConfig.ignoreSelectors
        ? props.competitorConfig.ignoreSelectors
        : '',
      deniedPaths: props.competitorConfig.deniedPaths
        ? props.competitorConfig.deniedPaths
        : '',
      ignoredQueryParams: props.competitorConfig.ignoredQueryParams
        ? props.competitorConfig.ignoredQueryParams
        : '',
      shouldSkipQueryParams:
        props.competitorConfig.shouldSkipQueryParams === '1' ? true : false,
      shouldRewriteInnerHtml:
        props.competitorConfig.shouldRewriteInnerHtml === '1' ? true : false
    };
  }

  triggerConfirm = (text, callback) => {
    Swal.fire({
      title: 'Are you sure?',
      text: text,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, I am sure!'
    }).then(result => {
      if (result.value) {
        callback();
      }
    });
  };

  setTextField = fieldName => e => {
    this.setState({ [fieldName]: e.target.value });
  };

  setCheckboxField = fieldName => e => {
    this.setState({ [fieldName]: e.target.checked });
  };

  saveForm = () => {
    const that = this;
    this.triggerConfirm(
      'Changing competitor URLs and social accounts can cause data to be deleted for the old accounts. This data cannot be restored afterwards.',
      () => {
        that.setState({ saving: true });
        that.props.attemptUpdateCompetitorConfig(
          that.props.competitor.competitorId,
          {
            facebookUrl: that.state.facebookUrl,
            twitterUrl: that.state.twitterUrl,
            youtubePageUrl: that.state.youtubePageUrl,
            domain: that.state.domain,
            name: that.state.name,
            ignoreSelectors: that.state.ignoreSelectors,
            defaultClickSelectors: that.state.defaultClickSelectors,
            deniedPaths: that.state.deniedPaths,
            ignoredQueryParams: that.state.ignoredQueryParams,
            shouldSkipQueryParams: that.state.shouldSkipQueryParams,
            shouldRewriteInnerHtml: that.state.shouldRewriteInnerHtml
          },
          () => {
            that.setState({ saving: false });
          }
        );
      }
    );
  };

  renderAdvancedWebsiteFormFields() {
    if (!this.state.showAdvancedWebsiteConfig) {
      return (
        <p
          className={cx('link', 'mrgBottom30')}
          onClick={() =>
            this.setState({
              showAdvancedWebsiteConfig: !this.state.showAdvancedWebsiteConfig
            })
          }
        >
          <Icon name={'fas fa-chevron-right'} /> Show advanced options
        </p>
      );
    }

    return (
      <div>
        <p
          className={cx('link', 'mrgBottom30')}
          onClick={() =>
            this.setState({
              showAdvancedWebsiteConfig: !this.state.showAdvancedWebsiteConfig
            })
          }
        >
          <Icon name={'fas fa-chevron-down'} /> Hide advanced options
        </p>

        <Form.Group controlId="defaultClickSelectors" className={'mrgBottom30'}>
          <Form.Label>
            <strong>Click selectors (on load)</strong>
          </Form.Label>
          <Form.Control
            type="textarea"
            rows={5}
            as="textarea"
            placeholder="e.g. .cookie-consent-cc close"
            onChange={this.setTextField('defaultClickSelectors')}
            value={this.state.defaultClickSelectors}
          />
          <Form.Text className="text-muted">
            Place each selector on a new line. Selectors are clicked in order
            after the page has finished loading. These are mostly used for
            closing cookie consent widgets before capturing screenshots within
            the platform.
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="deniedPaths">
          <Form.Label>
            <strong>Denied URL paths</strong>
          </Form.Label>

          <Form.Control
            type="textarea"
            rows={5}
            as="textarea"
            placeholder="e.g. https://example.com/foo/ignore-path/"
            onChange={this.setTextField('deniedPaths')}
            value={this.state.deniedPaths}
          />
          <Form.Text className="text-muted">
            Place each path to ignore on a new line. Each path added here will
            ignore all pages that fall under that path in the URL. This is
            useful for ignoring blogs, search pages and more.
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="ignoredQueryParams">
          <Form.Label>
            <strong>Ignored query parameters</strong>
          </Form.Label>

          <Form.Control
            type="textarea"
            rows={5}
            as="textarea"
            placeholder="e.g. blogPage"
            onChange={this.setTextField('ignoredQueryParams')}
            value={this.state.ignoredQueryParams}
          />
          <Form.Text className="text-muted">
            Place each query parameter to ignore on a new line. Each parameter
            added will be removed from a URL prior to analyzing in the platform.
            If after removal of the query parameter the page already exists in
            the platform, this page will not be tested again. This is useful for
            preventing pagination, etc. <br /> <br />
            It is not needed to add symbols such as '?', '&', or '='. You only
            need the parameter name.
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="shouldRewriteInnerHtml">
          <Form.Label>
            <strong>Animation pausing (via JavaScript)</strong>
          </Form.Label>

          <Form.Check
            type="checkbox"
            label="Enable (not recommended)"
            onChange={this.setCheckboxField('shouldRewriteInnerHtml')}
            checked={this.state.shouldRewriteInnerHtml}
          />
        </Form.Group>
      </div>
    );
  }

  render() {
    const { competitor } = this.props;

    return (
      <div>
        <div className="row" style={{ marginBottom: '10px' }}>
          <div className="col-xl-12" style={{ textAlign: 'center' }}>
            <h1 className={'h4'} style={{ marginBottom: 0 }}>
              Editing {competitor.normalisedDomain}
            </h1>
            <h2 className={'h5 textLighter thin'}>
              Update cofiguration, social accounts and keyword search terms for
              this competitor.
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Block title={'General'}>
              <Form.Group controlId="name" className={'mrgBottom30'}>
                <Form.Label>
                  <strong>Name</strong>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="e.g. Competito main site"
                  onChange={this.setTextField('name')}
                  value={this.state.name}
                />
              </Form.Group>

              <Form.Group controlId="newsletterEmail">
                <Form.Label>
                  <strong>Newsletter email address</strong>
                </Form.Label>
                <Form.Control
                  type="text"
                  disabled={true}
                  onChange={() => {}}
                  defaultValue={this.props.email}
                />
                <Form.Text className="text-muted">
                  This email address can be used for tracking your competitors'
                  newsletter campaigns and account emails. Simply sign up on
                  your competitors websites with this address to start tracking
                  what they send.
                </Form.Text>
              </Form.Group>
            </Block>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Block title={'Social accounts'}>
              <Form.Group controlId="facebookPageUrl" className={'mrgBottom30'}>
                <Form.Label>
                  <strong>Facebook page</strong>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="e.g. https://facebook.com/competito"
                  onChange={this.setTextField('facebookUrl')}
                  value={this.state.facebookUrl}
                />
              </Form.Group>

              <Form.Group controlId="youtubePageUrl" className={'mrgBottom30'}>
                <Form.Label>
                  <strong>YouTube page</strong>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="e.g. https://youtube.com/channel/sdffs"
                  onChange={this.setTextField('youtubePageUrl')}
                  value={this.state.youtubePageUrl}
                />
              </Form.Group>

              <Form.Group controlId="twitterPageUrl">
                <Form.Label>
                  <strong>Twitter page</strong>
                </Form.Label>

                <Form.Control
                  type="text"
                  placeholder="e.g. https://twitter.com/competito"
                  onChange={this.setTextField('twitterUrl')}
                  value={this.state.twitterUrl}
                />
              </Form.Group>
            </Block>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Block title={'Website settings'}>
              <Form.Group controlId="domain" className={'mrgBottom30'}>
                <Form.Label>
                  <strong>Website address</strong>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="e.g. https://competito.app or competito.app"
                  onChange={this.setTextField('domain')}
                  value={this.state.domain}
                />
              </Form.Group>

              <Form.Group controlId="ignoreSelectors">
                <Form.Label>
                  <strong>Ignore selectors</strong>
                </Form.Label>

                <Form.Control
                  type="textarea"
                  rows={10}
                  as="textarea"
                  placeholder="e.g. #recent-websites"
                  onChange={this.setTextField('ignoreSelectors')}
                  value={this.state.ignoreSelectors}
                />
                <Form.Text className="text-muted">
                  Place each selector on a new line. Each selector added here
                  will ensure the item selected and all children do not get
                  reported in website monitoring events.
                </Form.Text>
              </Form.Group>

              <Form.Group controlId="shouldSkipQueryParams">
                <Form.Label>
                  <strong>Exclude query parameters</strong>
                </Form.Label>

                <Form.Check
                  type="checkbox"
                  label="Enable (recommended)"
                  onChange={this.setCheckboxField('shouldSkipQueryParams')}
                  checked={this.state.shouldSkipQueryParams}
                />
              </Form.Group>

              {this.renderAdvancedWebsiteFormFields()}
            </Block>
          </div>
        </div>
        <div className="row">
          <div className="cold-md-12">
            <Button
              size="sm"
              primary
              type="submit"
              style={{ marginBottom: '20px', marginLeft: '15px' }}
              disabled={this.state.saving || this.state.deleting}
              onClick={this.saveForm}
            >
              {this.state.saving ? <Spinner center /> : 'Update'}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default EditCompetitorPage;
