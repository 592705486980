import React from 'react';
import Badge from 'react-bootstrap/Badge';

const SEVERITY_TO_VARIANT_MAP = {
  t: { variant: 'secondary', label: 'Trivial' },
  mi: { variant: 'warning', label: 'Minor' },
  ma: { variant: 'danger', label: 'Major' }
};

export default function ChangeSeverityBadge({ severity, labelSuffix, pill }) {
  const map = SEVERITY_TO_VARIANT_MAP[severity];

  return (
    <span style={{ fontSize: '15px', verticalAlign: 'middle' }}>
      <Badge variant={map.variant} pill={pill}>
        {map.label} {labelSuffix}
      </Badge>
    </span>
  );
}
