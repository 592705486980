import { call, put, takeLatest } from 'redux-saga/effects';
import { createAxios } from 'utils/axios/axiosUtils';
import { REGISTER_ACCOUNT, storeLogin } from 'actions/authActions';

export default function* registerAccountSaga() {
  yield takeLatest(REGISTER_ACCOUNT, registerAccount);
}

function* registerAccount({ accountData, onSuccess, onFailure }) {
  try {
    const response = yield call(apiCall, accountData);

    if (response && response.data.token) {
      yield put(storeLogin(response.data, onSuccess));
      return;
    }

    onFailure(response.data.errorMessage);
  } catch (e) {
    console.warn(e);

    onFailure('Something went wrong trying to add this user. Try again later.');
  }
}

async function apiCall(accountData) {
  return await createAxios(false, true).post('signup', accountData);
}
