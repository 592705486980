import { put, call, select, takeLatest } from 'redux-saga/effects';
import {
  receiveOverviewData,
  handleOverviewLoadingError,
  FETCH_OVERVIEW_DATA
} from 'actions/overviewActions';
import { createAxios } from 'utils/axios/axiosUtils';
import { currentProfileIdSelector } from 'selectors/profileSelectors';

const KEYWORD_STATS_TO_GET = [
  'RankedKeywordsCount',
  'WebsiteChangesCount',
  'FacebookLikes',
  'FacebookPosts',
  'FacebookPostEngagement',
  'TweetEngagement',
  'TwitterFollowers',
  'TweetCount'
];

export default function* fetchOverviewDataSaga() {
  yield takeLatest(FETCH_OVERVIEW_DATA, fetchOverviewData);
}

function* fetchOverviewData({ timeFromStr }) {
  const profileId = yield select(currentProfileIdSelector);

  try {
    const keyStatsResponse = yield call(
      getKeyStatsResponse,
      profileId,
      timeFromStr
    );
    const socialMediaResponse = yield call(
      getSocialMediaResponse,
      profileId,
      timeFromStr
    );
    const websiteResponse = yield call(
      getWebsiteResponse,
      profileId,
      timeFromStr
    );
    const keywordsResponse = yield call(
      getKeywordsResponse,
      profileId,
      timeFromStr
    );

    const data = {
      keyStatsOverview: keyStatsResponse.data,
      socialMediaOverview: socialMediaResponse.data,
      websiteOverview: websiteResponse.data,
      keywordsOverview: keywordsResponse.data
    };

    yield put(receiveOverviewData(data));
  } catch (e) {
    console.warn(e);

    yield put(handleOverviewLoadingError(e.message));
  }
}

async function getKeyStatsResponse(profileId, timeFromStr) {
  return await createAxios().get(
    'key-stat-dashboard?profileId=' +
      profileId +
      '&timeFrom=' +
      timeFromStr +
      '&stats=' +
      KEYWORD_STATS_TO_GET.join(',')
  );
}

async function getSocialMediaResponse(profileId, timeFromStr) {
  return await createAxios().get(
    'social-dashboard?profileId=' + profileId + '&timeFrom=' + timeFromStr
  );
}

async function getWebsiteResponse(profileId, timeFromStr) {
  return await createAxios().get(
    'website-dashboard?profileId=' + profileId + '&timeFrom=' + timeFromStr
  );
}

async function getKeywordsResponse(profileId, timeFromStr) {
  return await createAxios().get(
    'keywords-dashboard?profileId=' + profileId + '&timeFrom=' + timeFromStr
  );
}
