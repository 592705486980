import React, { useEffect } from 'react';
import CentralWrapper from 'components/loginWrappers/centralWrapper';
import Spinner from 'components/spinner/spinner';
import { useGetNotificationState } from 'providers/appProvider';
import Alert from 'components/alert/alert';
import Gatekeeper from 'components/permissions/gatekeeper';
import LiveDemoWarning from 'components/demo/liveDemoWarning';
import NotificationBlock from 'components/notification/notificationBlock';
import TablePagination from 'components/table/tablePagination';
import Button from 'components/button/button';
import UsersProvider, { useGetUsers } from 'providers/usersProvider';
import { createAxios } from 'utils/axios/axiosUtils';
import Swal from 'sweetalert2';

const TITLE_DEFAULT = 'My notifications | Competito';

async function markAllAsRead(loadNotifications) {
  const response = await createAxios().put('app-notifications/mark-all');
  if (response.data && response.data.success) {
    loadNotifications();
  } else {
    Swal.fire({
      type: 'error',
      title: 'Oops...',
      text:
        'Something went wrong trying to load the event inspector. Try again later.'
    });
  }
}

function NotificationsHeader() {
  return (
    <div className="row" style={{ marginBottom: '30px' }}>
      <div className="col-xl-12" style={{ textAlign: 'center' }}>
        <h1 className={'h4'} style={{ marginBottom: 0 }}>
          My notifications
        </h1>
        <h2 className={'h5 textLighter thin'}>
          View outstanding notifications from Competito and your team
        </h2>
      </div>
    </div>
  );
}

function NotificationsBody() {
  const {
    isLoadingNotifications,
    unreadNotificationsCount,
    loadingNotificationsFailed,
    notificationsPage,
    notifications,
    totalNotificationRows,
    loadNotifications
  } = useGetNotificationState();
  const users = useGetUsers();

  useEffect(() => {
    loadNotifications();
  }, [loadNotifications]);

  if (isLoadingNotifications) {
    return <Spinner center />;
  }

  if (loadingNotificationsFailed) {
    return (
      <Alert variant="danger">
        Could not load notifications. Please try again shortly.
      </Alert>
    );
  }

  const renderNotifications = () => {
    return notifications.map(notification => (
      <NotificationBlock
        key={notification.notificationId}
        notification={notification}
        users={users}
        refreshNotifications={loadNotifications}
      />
    ));
  };

  return (
    <div>
      <LiveDemoWarning style={{ marginBottom: '40px' }} />

      <div className="row">
        <div className="col-md-9">
          <p
            style={{
              fontSize: '16px',
              paddingTop: '5px',
              marginBottom: '40px',
              marginLeft: '15px'
            }}
          >
            {unreadNotificationsCount} outstanding notifications
          </p>
        </div>
        <div className="col-md-3">
          <Gatekeeper noDemoAccount>
            <Button
              thin
              primary
              icon="fas fa-check"
              style={{ float: 'right', marginRight: '15px' }}
              disabled={unreadNotificationsCount === 0}
              onClick={() => markAllAsRead(loadNotifications)}
            >
              Mark all read
            </Button>
          </Gatekeeper>
        </div>
      </div>

      {renderNotifications()}

      <TablePagination
        page={notificationsPage}
        rowsPerPage={10}
        numRows={totalNotificationRows}
        onClickPage={loadNotifications}
      />
    </div>
  );
}

export default function MyNotifications() {
  useEffect(() => {
    document.title = TITLE_DEFAULT;
  }, []);

  return (
    <UsersProvider>
      <CentralWrapper>
        <NotificationsHeader />
        <NotificationsBody />
      </CentralWrapper>
    </UsersProvider>
  );
}
