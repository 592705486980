import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ATTEMPT_CANCEL_SUBSCRIPTION,
  cancelSubscription
} from 'actions/subscriptionActions';
import { createAxios } from 'utils/axios/axiosUtils';
import Swal from 'sweetalert2';

export default function* cancelSubscriptionSaga() {
  yield takeLatest(ATTEMPT_CANCEL_SUBSCRIPTION, cancelFn);
}

function* cancelFn({ subscriptionId, cb }) {
  try {
    const response = yield call(attemptResponse, subscriptionId);
    if (response.data && response.data.success) {
      yield put(cancelSubscription(subscriptionId));
      cb();

      Swal.fire({
        type: 'success',
        title: 'Subscription cancelled',
        text:
          'Your subscription has now been cancelled. You can continue to use the tool until the end of your current period. We are sorry to see you go!'
      });
      return;
    }
  } catch (e) {
    console.warn(e);
  }

  Swal.fire({
    type: 'error',
    title: 'Oops...',
    text: 'Something went wrong trying to cancel this subscription.'
  });
  cb();
}

async function attemptResponse(subscriptionId) {
  return await createAxios().delete(
    'subscriptions/' + subscriptionId + '/cancel'
  );
}
