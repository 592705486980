import React, { Component } from 'react';
import CentralWrapper from 'components/loginWrappers/centralWrapper';
import { overviewPageSelector } from 'selectors/overviewSelectors';
import { connect } from 'react-redux';
import Spinner from 'components/spinner/spinner';
import OverviewPage from '../components/page/overviewPage';
import { fetchOverviewData } from '../actions/overviewActions';

const TITLE_DEFAULT = 'Overview | Competito';

class OverviewContainer extends Component {
  UNSAFE_componentWillMount() {
    document.title = TITLE_DEFAULT;

    this.props.fetchOverviewData('-30 days');
  }

  render() {
    const { isLoading } = this.props;

    return (
      <CentralWrapper>
        {isLoading ? (
          <Spinner center useColors />
        ) : (
          <OverviewPage {...this.props} />
        )}
      </CentralWrapper>
    );
  }
}

const mapStateToProps = state => overviewPageSelector(state);
export default connect(mapStateToProps, { fetchOverviewData })(
  OverviewContainer
);
