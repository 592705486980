import { fromJS, Map } from 'immutable';
import { LOGOUT } from 'actions/authActions';
import { RECEIVE_NOTIFICATION_SUBSCRIPTIONS_FOR_CHANNEL } from 'actions/eventNotificationSubscriptionActions';

const initialState = fromJS({
  data: Map()
});

export default function eventNotificationSubscriptionReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case RECEIVE_NOTIFICATION_SUBSCRIPTIONS_FOR_CHANNEL:
      return state.setIn(
        ['data', action.channelId.toString()],
        fromJS(action.subscriptions)
      );
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
