import { call, put, takeLatest } from 'redux-saga/effects';
import { ATTEMPT_UPDATE_USER, updateUserResponse } from 'actions/userActions';
import { createAxios } from 'utils/axios/axiosUtils';
import Swal from 'sweetalert2';

export default function* updateUserSaga() {
  yield takeLatest(ATTEMPT_UPDATE_USER, updateUser);
}

function* updateUser({ userId, data, cb }) {
  try {
    const response = yield call(attemptUpdateResponse, userId, data);
    if (response.data && response.data.success) {
      yield put(updateUserResponse(userId, response.data.user));

      Swal.fire({
        type: 'success',
        title: 'Saved!',
        showConfirmButton: false,
        timer: 1500
      });
    }
  } catch (e) {
    console.warn(e);

    if (
      e.response &&
      e.response.data &&
      !e.response.data.success &&
      e.response.data.errorMessage
    ) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: e.response.data.errorMessage
      });
    } else {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text:
          'Something went wrong trying to update this user. Try again later.'
      });
    }
  }
  cb && cb();
}

async function attemptUpdateResponse(userId, data) {
  return await createAxios().put('users/' + userId, data);
}
