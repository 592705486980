import React, { Component } from 'react';
import Alert from 'components/alert/alert';
import Gatekeeper from 'components/permissions/gatekeeper';
import { ROLE_LIVE_DEMO } from 'utils/permissions/permissions';
import Button from 'components/button/button';
import { setExampleMode } from 'actions/authActions';
import { connect } from 'react-redux';

class LiveDemoWarning extends Component {
  disableTimeline = () => {
    this.props.setExampleMode(false);
  };

  render() {
    const { style } = this.props;

    return (
      <Gatekeeper allowExampleMode onlyRole={ROLE_LIVE_DEMO}>
        <Alert variant={'info'} type="tw1" style={style}>
          <div>
            <h1>Live demo</h1>
            <p>
              All results shown are for example purposes to show what the
              platform could look like for your organization.
            </p>
            <Gatekeeper noDemoAccount>
              <Button
                thin
                primary
                noBg
                style={{ marginTop: '10px' }}
                onClick={this.disableTimeline}
              >
                View my results
              </Button>
            </Gatekeeper>
          </div>
        </Alert>
      </Gatekeeper>
    );
  }
}

export default connect(null, { setExampleMode })(LiveDemoWarning);
