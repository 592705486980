import React from 'react';
import TimelineEventWrapper from '../timelineEventWrapper';
import { pluralise } from 'utils/numberUtils';
import styles from './facebookPostAddedEvent.module.scss';
import Icon from 'components/fontAwesome/icon';
import cx from 'classnames';

export function getEventTitle(name, posts) {
  const title =
    name +
    ' added ' +
    pluralise(
      posts.length,
      'a post on Facebook',
      posts.length + ' posts on Facebook'
    );

  return title;
}

function RenderStats({ likes, comments, shares }) {
  return (
    <p style={{ marginLeft: '20px', color: '#777' }}>
      <span style={{ marginRight: '15px' }}>
        <Icon name={'far fa-thumbs-up'} /> {likes} likes
      </span>
      <span style={{ marginRight: '15px' }}>
        <Icon name={'far fa-share'} /> {comments || 0} comments
      </span>
      <span>
        <Icon name={'far fa-share'} /> {shares} shares
      </span>
    </p>
  );
}

function RenderPostText({ post }) {
  let { postText } = post;

  if (!postText) {
    return null;
  }

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: postText
          .replace(/\n/g, '<br />')
          .replace(
            post.link,
            '<a href="' +
              post.link +
              '" target="_blank" rel="noopener noreferrer">' +
              post.link +
              '</a>'
          )
      }}
    />
  );
}

function VideoPlayIcon({ post }) {
  return (
    <a
      href={post.postUrl}
      className={styles.playIconWrapper}
      target="_blank"
      rel="noopener noreferrer"
    >
      <span className={styles.playIcon}>
        <Icon name={'far fa-play-circle'} size="5x" />
      </span>
    </a>
  );
}

function RenderSharedText({ post }) {
  const parts = post.sharedText.split('\n');
  return (
    <div className={styles.sharedText}>
      <a href={post.link} target="_blank" rel="noopener noreferrer">
        {parts[0]}
      </a>{' '}
      <br />
      {parts[1]}
    </div>
  );
}

function PostHeader({ post, favicon }) {
  const header = post.header || null;

  return (
    <div className={styles.handleLine}>
      <div>
        <img src={favicon} alt="" className={styles.favi} />{' '}
        {header && (
          <a href={post.postUrl} className={styles.header}>
            {header}
          </a>
        )}
        {header === null && (
          <a
            href={post.postUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.header}
          >
            {post.username}
          </a>
        )}
      </div>
      <div>
        <a href={post.postUrl} target="_blank" rel="noopener noreferrer">
          View on Facebook{' '}
          <Icon name="far fa-external-link-alt" style={{ marginLeft: '5px' }} />
        </a>
      </div>
    </div>
  );
}

function RenderPost({ post, isSingleEvent, favicon, metaForPost }) {
  const hasMedia = post.images.length || post.videoThumb;
  return (
    <div className={styles.facebookPostWrapper}>
      <PostHeader post={post} favicon={favicon} />

      <div
        className={cx(styles.postWrapper, {
          [styles.singlePostWrapper]: isSingleEvent
        })}
      >
        <div className={styles.postData}>
          <div className={styles.text}>
            <RenderPostText post={post} />
          </div>
        </div>
        {hasMedia && (
          <div
            className={cx(styles.postMedia, {
              [styles.postMediaMargin]: isSingleEvent
            })}
          >
            <div style={{ position: 'relative' }}>
              <a href={post.postUrl} target="_blank" rel="noopener noreferrer">
                <img
                  className={cx(styles.postImageMedia, {
                    [styles.imgBlur]:
                      post.videoThumb && post.videoThumb.length > 0
                  })}
                  src={post.images[0]}
                  alt=""
                />
              </a>
              {post.sharedText && post.sharedText.length && (
                <RenderSharedText post={post} />
              )}
              {post.videoThumb && post.videoThumb.length > 0 && (
                <VideoPlayIcon post={post} />
              )}
            </div>
          </div>
        )}
      </div>

      <div>
        {metaForPost !== undefined && (
          <RenderStats
            comments={metaForPost.comments}
            likes={metaForPost.likes}
            shares={metaForPost.shares}
          />
        )}
      </div>
    </div>
  );
}

export default function FacebookPostAddedEvent(props) {
  const posts = props.event.props.posts;
  const name = props.event.referenceName;

  let displayPosts = [];
  let displayCounter = 1;

  const filteredPosts = posts.slice(0, props.isInspector ? 500 : 3);

  filteredPosts.forEach(post => {
    const metaForPost =
      props.eventMeta &&
      props.eventMeta.stats &&
      props.eventMeta.stats[post.fbPostId];

    displayPosts.push(
      <li
        key={displayCounter}
        style={
          displayCounter !== filteredPosts.length
            ? { borderBottom: '1px solid #eee', marginBottom: '20px' }
            : {}
        }
      >
        <RenderPost
          post={post}
          favicon={props.event.favicon}
          isSingleEvent={posts.length === 1}
          metaForPost={metaForPost}
        />
        <div style={{ marginBottom: '20px' }} />
      </li>
    );
    displayCounter++;
  });

  return (
    <TimelineEventWrapper
      title={getEventTitle(name, posts)}
      buttonText={posts.length > 3 ? 'Show all posts' : 'View details'}
      {...props}
    >
      <div className="row">
        <div className="col-md-12">
          <div className={styles.faceebookPostsWrapper}>
            <ul className={styles.ul}>{displayPosts}</ul>
          </div>
        </div>
      </div>
    </TimelineEventWrapper>
  );
}
