import { Map, fromJS } from 'immutable';
import {
  REQUEST_COMPETITOR_EMAIL,
  RECEIVE_COMPETITOR_EMAIL,
  HANDLE_BAD_COMPETITOR_EMAIL_RESPONSE
} from 'actions/competitorEmailActions';

const initialState = fromJS({
  data: Map(),
  loading: false
});

export default function competitorEmailReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_COMPETITOR_EMAIL:
      return state.set('loading', true);
    case RECEIVE_COMPETITOR_EMAIL:
      return state
        .setIn(['data', action.competitorId], action.email)
        .set('loading', false);
    case HANDLE_BAD_COMPETITOR_EMAIL_RESPONSE:
      return state.set('loading', false);
    default:
      return state;
  }
}
