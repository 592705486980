export function getAuthToken() {
  return window.localStorage.getItem('jessle_token');
}

export function removeAuthToken() {
  window.localStorage.removeItem('jessle_token');
}

export function setAuthToken(token) {
  localStorage.setItem('jessle_token', token);
}

export function clearGuestToken() {
  const authToken = getAuthToken();
  if (authToken && authToken.indexOf('guest_') === 0) {
    removeAuthToken();
  }
}

export function getCurrentProfileId() {
  return window.localStorage.getItem('currentProfileId');
}

export function setCurrentProfileId(profileId) {
  window.localStorage.setItem('currentProfileId', profileId);
}
