import React, { Component } from 'react';
import TimelinePage from 'components/page/timelinePage';
import CentralWrapper from 'components/loginWrappers/centralWrapper';
import {
  fetchTimelineEvents,
  applyFilterToTimeline,
  resetTimelineState,
  checkForTimelineUpdates,
  incrementTimelinePage
} from 'actions/timelineActions';
import { timelineEventsSelector } from 'selectors/timelineSelectors';
import { connect } from 'react-redux';
import sortBy from 'lodash/sortBy';
import Spinner from 'components/spinner/spinner';

const TITLE_DEFAULT = 'Timeline | Competito';

class TimelineContainer extends Component {
  UNSAFE_componentWillMount() {
    this.props.fetchTimelineEvents(true);

    this.pollTimer = setInterval(this.updatePoll, 60000);

    document.title = TITLE_DEFAULT;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.exampleMode !== this.props.exampleMode) {
      this.props.resetTimelineState();
      this.props.fetchTimelineEvents(true);
    }

    if (
      nextProps.hasUpdatesCount > 0 &&
      nextProps.hasUpdatesCount !== this.props.hasUpdatesCount
    ) {
      document.title =
        '(' + nextProps.hasUpdatesCount + ') Timline | Competito';
    }

    if (nextProps.hasUpdatesCount === 0 && this.props.hasUpdatesCount > 0) {
      document.title = TITLE_DEFAULT;
    }
  }

  componentWillUnmount() {
    clearInterval(this.pollTimer);
  }

  updatePoll = () => {
    this.props.checkForTimelineUpdates();
  };

  loadMore = () => {
    const { incrementTimelinePage, fetchTimelineEvents, filters } = this.props;

    incrementTimelinePage(filters.get('page') + 1);
    fetchTimelineEvents();
  };

  loadNew = () => {
    const { applyFilterToTimeline, fetchTimelineEvents } = this.props;

    applyFilterToTimeline('page', 1);
    fetchTimelineEvents(true);
  };

  render() {
    const {
      showSpinner,
      events,
      accountAddedOn,
      canLoadMore,
      hasUpdatesCount,
      history,
      match,
      exampleMode,
      competitors,
      user,
      fetchTimelineEvents,
      applyFilterToTimeline,
      globalModalActive
    } = this.props;

    let sortedEvents = sortBy(events.toJS(), [
      'representativeTime',
      'eventId'
    ]).reverse();

    return (
      <CentralWrapper lockInPlace={globalModalActive}>
        {showSpinner ? (
          <Spinner center useColors />
        ) : (
          <TimelinePage
            events={sortedEvents}
            hasUpdatesCount={hasUpdatesCount}
            competitors={competitors}
            loadMore={this.loadMore}
            history={history}
            match={match}
            loadNew={this.loadNew}
            canLoadMore={canLoadMore}
            accountAddedOn={accountAddedOn}
            exampleMode={exampleMode}
            user={user}
            fetchTimelineEvents={fetchTimelineEvents}
            applyFilterToTimeline={applyFilterToTimeline}
          />
        )}
      </CentralWrapper>
    );
  }
}

const mapStateToProps = state => timelineEventsSelector(state);
export default connect(mapStateToProps, {
  fetchTimelineEvents,
  applyFilterToTimeline,
  incrementTimelinePage,
  resetTimelineState,
  checkForTimelineUpdates
})(TimelineContainer);
