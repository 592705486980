import React from 'react';
import Icon from 'components/fontAwesome/icon';

function RenderIcon({ link }) {
  if (link.indexOf('mailto:') !== -1) {
    return <Icon name="far fa-envelope-open" />;
  }
  return <Icon name="far fa-external-link-alt" />;
}

function RenderLink({ link, text }) {
  return (
    <li key={link} style={{ marginBottom: '10px' }}>
      <a href={link} target="_blank" rel="noreferrer noopener">
        {text} <RenderIcon link={link} />
      </a>
    </li>
  );
}

function RenderLinks({ links }) {
  return Object.keys(links).map(link => (
    <RenderLink key={link} link={link} text={links[link]} />
  ));
}

export default function NewsletterLinks(props) {
  return (
    <div>
      <p>
        <strong>Links inside email: </strong>
      </p>
      <RenderLinks {...props} />
    </div>
  );
}
