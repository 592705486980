import { createSelector } from 'reselect';
import { simpleProfilesSelector } from 'selectors/profileSelectors';
import {
  authAccountSelector,
  isLoggedInSelector,
  authUserSelector
} from 'selectors/authSelectors';
import { usersSelector } from 'selectors/userSelectors';
import { simpleCompetitorSelector } from './competitorSelectors';

export const setupWizardSelector = createSelector(
  simpleProfilesSelector,
  simpleCompetitorSelector,
  authAccountSelector,
  usersSelector,
  isLoggedInSelector,
  (profiles, competitors, account, users, isLoggedIn) => {
    return {
      profiles: profiles,
      competitors: competitors,
      setupComplete: account ? account.get('setupComplete') : false,
      users,
      isLoggedIn
    };
  }
);

export const onboardingChecklistContainerSelector = createSelector(
  state => state.auth,
  authUserSelector,
  simpleCompetitorSelector,
  (auth, user, competitors) => {
    return {
      accountId: auth.get('user').get('accountId'),
      user: user,
      competitors: competitors
    };
  }
);
