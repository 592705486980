import { Map, fromJS } from 'immutable';
import {
  SCOUT_URL,
  HANDLE_SCOUT_RESPONSE,
  HANDLE_BAD_SCOUT_RESPONSE,
  CLEAR_SCOUT_STATE
} from 'actions/scoutUrlActions';

const initialState = fromJS({
  verifying: false,
  error: null,
  data: Map(),
  verified: false
});

export default function scoutUrlReducer(state = initialState, action) {
  switch (action.type) {
    case SCOUT_URL:
      return state
        .set('verifying', true)
        .set('verified', false)
        .set('error', '')
        .set('data', Map());
    case HANDLE_SCOUT_RESPONSE:
      return state
        .set('verifying', false)
        .set('verified', true)
        .set('error', '')
        .set('data', fromJS(action.response));
    case HANDLE_BAD_SCOUT_RESPONSE:
      return state
        .set('verifying', false)
        .set('verified', false)
        .set('error', action.errorMessage)
        .set('data', Map());
    case CLEAR_SCOUT_STATE:
      return initialState;
    default:
      return state;
  }
}
