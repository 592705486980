export const ATTEMPT_UPDATE_USER = 'app:ATTEMPT_UPDATE_USER';
export function attemptUpdateUser(userId, data, cb) {
  return {
    type: ATTEMPT_UPDATE_USER,
    userId,
    data,
    cb
  };
}

export const UPDATE_USER_RESPONSE = 'app:UPDATE_USER_RESPONSE';
export function updateUserResponse(userId, data) {
  return {
    type: UPDATE_USER_RESPONSE,
    userId,
    data
  };
}

export const FETCH_ALL_USERS = 'app:FETCH_ALL_USERS';
export function fetchAllUsers() {
  return {
    type: FETCH_ALL_USERS
  };
}

export const RECEIVE_USERS = 'app:RECEIVE_USERS';
export function receiveUsers(users) {
  return {
    type: RECEIVE_USERS,
    users
  };
}

export const HANDLE_FETCH_ALL_USERS_ERROR = 'app:HANDLE_FETCH_ALL_USERS_ERROR';
export function handleFetchAllUsersError() {
  return {
    type: HANDLE_FETCH_ALL_USERS_ERROR
  };
}

export const ATTEMPT_DELETE_USER = 'app:ATTEMPT_DELETE_USER';
export function attemptDeleteUser(userId) {
  return {
    type: ATTEMPT_DELETE_USER,
    userId
  };
}

export const DELETE_USER = 'app:DELETE_USER';
export function deleteUser(userId) {
  return {
    type: DELETE_USER,
    userId
  };
}

export const ATTEMPT_CREATE_USER = 'app:ATTEMPT_CREATE_USER';
export function attemptCreateUser(data, cb) {
  return {
    type: ATTEMPT_CREATE_USER,
    data,
    cb
  };
}

export const HANDLE_CREATE_USER_RESPONSE = 'app:HANDLE_CREATE_USER_RESPONSE';
export function handleCreateUserResponse(user) {
  return {
    type: HANDLE_CREATE_USER_RESPONSE,
    user
  };
}
