export function getFrequencyFromCoolOff(coolOff) {
  if (coolOff === 86400) {
    return 'daily';
  } else if (coolOff === 3600) {
    return 'hourly';
  } else if (coolOff === 0) {
    return 'instant';
  } else if (coolOff === 604800) {
    return 'weekly';
  }

  return 'never';
}

export function getCoolOffForFrequency(frequency) {
  switch (frequency) {
    case 'never':
      return -1;
    case 'daily':
      return 86400;
    case 'hourly':
      return 3600;
    case 'instant':
      return 0;
    default:
      return 604800;
  }
}
