import { call, put, takeLatest } from 'redux-saga/effects';
import { createAxios } from 'utils/axios/axiosUtils';
import Swal from 'sweetalert2';
import { SKIP_ONBOARDING } from 'actions/accountSetupActions';
import { push } from 'connected-react-router';

export default function* skipOnboardingSaga() {
  yield takeLatest(SKIP_ONBOARDING, skipOnboarding);
}

function* skipOnboarding({ successRoute }) {
  try {
    yield call(attemptUpdateResponse);

    yield put(push(successRoute));
  } catch (e) {
    console.warn(e);

    Swal.fire({
      type: 'error',
      title: 'Oops...',
      text: 'Something went wrong trying to skip onboarding. Try again later.'
    });
  }
}

async function attemptUpdateResponse() {
  return await createAxios().put('account-onboarding/skip');
}
