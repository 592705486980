import React, { Component } from 'react';
import CentralWrapper from 'components/loginWrappers/centralWrapper';
import { inventoryPagesContainerSelector } from 'selectors/inventorySelectors';
import {
  fetchInventoryPageData,
  setPageMonitoringState,
  addPageForMonitoring
} from 'actions/inventoryActions';
import { connect } from 'react-redux';
import Alert from 'components/alert/alert';
import InventoryPagesPage from 'components/page/inventoryPagesPage';

const TITLE_DEFAULT = 'Page inventory | Competito';

const PAGE_LIMIT = 50;

class InventoryPagesContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      competitorId: this.getFirstAvailableCompetitorId(props),
      page: 1,
      searchTerm: ''
    };

    document.title = TITLE_DEFAULT;
  }

  UNSAFE_componentWillMount() {
    const { competitorId } = this.state;
    if (competitorId) {
      this.props.fetchInventoryPageData(
        competitorId,
        this.state.page,
        PAGE_LIMIT,
        ''
      );
    }
  }

  getFirstAvailableCompetitorId(props) {
    if (!props.competitors || !Object.keys(props.competitors).length) {
      return null;
    }

    return Object.keys(props.competitors)[0];
  }

  selectCompetitor = competitorId => {
    this.setState({ competitorId: competitorId, page: 1 });
    this.props.fetchInventoryPageData(
      competitorId,
      1,
      PAGE_LIMIT,
      this.state.searchTerm
    );
  };

  selectPage = page => {
    this.setState({ page });
    this.props.fetchInventoryPageData(
      this.state.competitorId,
      page,
      PAGE_LIMIT,
      this.state.searchTerm
    );
  };

  setSearchTerm = searchTerm => {
    this.setState({ searchTerm, page: 1 });
    this.props.fetchInventoryPageData(
      this.state.competitorId,
      1,
      PAGE_LIMIT,
      searchTerm
    );
  };

  setPageMonitoringState = (pageId, operation) => {
    this.setState({ isPerformingDecision: true });

    this.props.setPageMonitoringState(
      this.state.competitorId,
      pageId,
      operation,
      () => {
        this.setState({ isPerformingDecision: false });

        this.refetchWithExistingData();
      },
      () => {
        this.setState({ isPerformingDecision: false });
      }
    );
  };

  addPageForMonitoring = (competitorId, url) => {
    this.props.addPageForMonitoring(competitorId, url, () => {
      this.refetchWithExistingData();
    });
  };

  refetchWithExistingData = () => {
    this.props.fetchInventoryPageData(
      this.state.competitorId,
      this.state.page,
      PAGE_LIMIT,
      this.state.searchTerm
    );
  };

  renderInWrapper() {
    const { competitorId, page, searchTerm, isPerformingDecision } = this.state;
    const {
      pages,
      stats,
      competitors,
      isLoadingPages,
      pagesLoadingError
    } = this.props;

    if (!competitorId) {
      return (
        <div className="row">
          <div className="col-md-12">
            <Alert variant="info">
              No competitors have been added, please add one to view this data.
            </Alert>
          </div>
        </div>
      );
    }

    return (
      <InventoryPagesPage
        competitorId={competitorId}
        pages={pages}
        pagination={{
          page,
          searchTerm,
          rowsPerPage: PAGE_LIMIT,
          totalRows: stats.get('FilteredTotalRows'),
          selectPage: this.selectPage,
          setSearchTerm: this.setSearchTerm
        }}
        stats={stats}
        addPageForMonitoring={this.addPageForMonitoring}
        competitors={competitors}
        setPageMonitoringState={this.setPageMonitoringState}
        isLoadingPages={isLoadingPages}
        isPerformingDecision={isPerformingDecision}
        pagesLoadingError={pagesLoadingError}
        onSelectCompetitor={this.selectCompetitor}
      />
    );
  }

  render() {
    return <CentralWrapper>{this.renderInWrapper()}</CentralWrapper>;
  }
}

const mapStateToProps = state => inventoryPagesContainerSelector(state);
export default connect(mapStateToProps, {
  fetchInventoryPageData,
  setPageMonitoringState,
  addPageForMonitoring
})(InventoryPagesContainer);
