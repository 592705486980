import { put, call, select, takeLatest } from 'redux-saga/effects';
import {
  storeHasUpdateCount,
  CHECK_FOR_TIMELINE_UPDATES
} from 'actions/timelineActions';
import { createAxios } from 'utils/axios/axiosUtils';
import {
  getLastFetchTimeSelector,
  currentFiltersSelector
} from 'selectors/timelineSelectors';
import { currentProfileIdSelector } from 'selectors/profileSelectors';
import { authUserExampleModeSelector } from 'selectors/authSelectors';

export default function* fetchTimelineUpdateCountSaga() {
  yield takeLatest(CHECK_FOR_TIMELINE_UPDATES, fetchTimelineUpdateCount);
}

function* fetchTimelineUpdateCount() {
  const since = yield select(getLastFetchTimeSelector);
  const filters = yield select(currentFiltersSelector);
  const profileId = yield select(currentProfileIdSelector);
  const isExampleMode = yield select(authUserExampleModeSelector);

  try {
    const response = yield call(
      getUpdateCountResponse,
      since,
      filters,
      profileId,
      isExampleMode
    );
    if (response.data && response.data.count) {
      yield put(storeHasUpdateCount(response.data.count));
    }
  } catch (e) {
    console.log('Failed to request poll update', e);
  }
}

async function getUpdateCountResponse(
  since,
  filters,
  profileId,
  isExampleMode
) {
  let url = 'timeline-events/since/' + since + '?profileId=' + profileId;

  if (isExampleMode) {
    url += '&example=1';
  }
  if (filters.competitorId) {
    url += '&competitorId=' + filters.competitorId;
  }
  if (filters.changeType) {
    url += '&changeType=' + filters.changeType;
  }

  return await createAxios().get(url);
}
