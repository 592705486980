import { call, takeLatest } from 'redux-saga/effects';
import { SET_PAGE_MONITORING_STATE } from 'actions/inventoryActions';
import { createAxios } from 'utils/axios/axiosUtils';
import Swal from 'sweetalert2';

export default function* setPageMMonitoringStateSaga() {
  yield takeLatest(SET_PAGE_MONITORING_STATE, setPageMonitoringState);
}

function* setPageMonitoringState({
  competitorId,
  pageId,
  operation,
  successCallback,
  failCallback
}) {
  let operationWord = 'undo';
  if (operation === 'f') {
    operationWord = 'force';
  } else if (operation === 'e') {
    operationWord = 'exclude';
  }

  try {
    yield call(apiCall, competitorId, pageId, operation);
  } catch (e) {
    Swal.fire({
      type: 'error',
      title: 'Oops...',
      text:
        'Something went wrong trying to ' +
        operationWord +
        ' this page. Try again later.'
    });

    failCallback && failCallback();
    return;
  }

  successCallback && successCallback();
}

async function apiCall(competitorId, pageId, operation) {
  let finalPath = 'reset';
  if (operation === 'f') {
    finalPath = 'force';
  } else if (operation === 'e') {
    finalPath = 'exclude';
  }

  return await createAxios().post(
    'inventory/pages/' + competitorId + '/' + pageId + '/' + finalPath
  );
}
