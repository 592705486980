import React, { Component } from 'react';
import styles from './block.module.scss';
import Icon from 'components/fontAwesome/icon';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import cx from 'classnames';
import Tooltip from 'react-bootstrap/Tooltip';

export default class Block extends Component {
  renderBlockItem = blockItem => {
    if (blockItem.isJsx) {
      return (
        <div className={styles.blockOptionsItem} key={blockItem.key || 'bi_1'}>
          {blockItem.value}
        </div>
      );
    }

    return (
      <div
        className={styles.blockOptionsItem}
        key={blockItem.key || 'bi_1'}
        dangerouslySetInnerHTML={{
          __html: blockItem.value
        }}
      />
    );
  };

  renderHelp = () => {
    return (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={`tooltip-followers`}>{this.props.help}</Tooltip>}
      >
        <Icon
          name="fas fa-question-circle"
          style={{ color: '#999', marginLeft: '5px' }}
        />
      </OverlayTrigger>
    );
  };

  renderTitle = () => {
    const { title, titleElement, help } = this.props;

    const TitleElement = titleElement || `h3`;

    return (
      <TitleElement className={styles.blockTitle}>
        {title}
        {help && this.renderHelp()}
      </TitleElement>
    );
  };

  render() {
    const { blockItems, children, strong } = this.props;

    return (
      <div className={cx(styles.block, { [styles.strong]: strong })}>
        <div className={styles.blockHeader}>
          {this.renderTitle()}
          <div className={styles.blockOptions}>
            {blockItems && blockItems.map(this.renderBlockItem)}
          </div>
        </div>
        <div className={styles.blockContent}>{children}</div>
      </div>
    );
  }
}
