import React, { Component } from 'react';
import Gravatar from 'react-gravatar';

class GravatarWrapper extends Component {
  render() {
    let { email, size } = this.props;
    const { fakeClassName, ...otherProps } = this.props;

    if (
      email === 'lee+livedemo@jessle.com' ||
      email === 'lee+livedemo2@jessle.com'
    ) {
      const src = {
        'lee+livedemo2@jessle.com':
          'https://static.wikia.nocookie.net/silicon-valley/images/2/20/Bertram_Gilfoyle.jpg',
        'lee+livedemo@jessle.com':
          'https://static.wikia.nocookie.net/silicon-valley/images/3/33/Richard_Hendricks.jpg'
      };

      return (
        <img
          src={src[email]}
          alt=""
          className={fakeClassName}
          style={{
            width: size + 'px',
            height: size + 'px',
            borderRadius: '50%',
            verticalAlign: 'middle',
            borderStyle: 'none'
          }}
        />
      );
    }

    return <Gravatar {...otherProps} email={email} />;
  }
}

export default GravatarWrapper;
