export const ROLE_GUEST = 'ROLE_GUEST';
export const ROLE_LIVE_DEMO = 'ROLE_LIVE_DEMO';
export const ROLE_USER = 'ROLE_USER';
export const ROLE_ACCOUNT_ADMIN = 'ROLE_ACCOUNT_ADMIN';
export const ROLE_ACCOUNT_OWNER = 'ROLE_ACCOUNT_OWNER';
export const ROLE_JESSLE = 'ROLE_JESSLE';

export const ROLES = {
  ROLE_GUEST: 0,
  ROLE_LIVE_DEMO: 1,
  ROLE_USER: 2,
  ROLE_ACCOUNT_ADMIN: 3,
  ROLE_ACCOUNT_OWNER: 4,
  ROLE_JESSLE: 5
};

export function hasPermission(yourRole, targetRole) {
  const yourRoleScore = ROLES[yourRole];
  const targetRoleScore = ROLES[targetRole];
  return targetRoleScore <= yourRoleScore;
}

export function getRoleRank(role) {
  switch (role) {
    case 'ROLE_JESSLE':
      return 999;
    case 'ROLE_ACCOUNT_OWNER':
      return 3;
    case 'ROLE_ACCOUNT_ADMIN':
      return 2;
    default:
      return 1;
  }
}
