import { put, call, select, takeLatest } from 'redux-saga/effects';
import {
  storeCompetitors,
  REQUEST_COMPETITORS
} from 'actions/competitorActions';
import { createAxios } from 'utils/axios/axiosUtils';
import { currentProfileIdSelector } from 'selectors/profileSelectors';

export default function* fetchCompetitorsSaga() {
  yield takeLatest(REQUEST_COMPETITORS, fetchCompetitors);
}

function* fetchCompetitors() {
  const profileId = yield select(currentProfileIdSelector);

  try {
    const competitorResponse = yield call(getCompetitorsResponse, profileId);
    if (competitorResponse.data && competitorResponse.data.competitors) {
      yield put(storeCompetitors(competitorResponse.data.competitors));
    }
  } catch (e) {
    console.warn(e);
  }
}

async function getCompetitorsResponse(profileId) {
  return await createAxios().get('competitors?profileId=' + profileId);
}
