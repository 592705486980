// Combines all sagas into one file.
import { call, all } from 'redux-saga/effects';

const requireSaga = require.context('./', true, /.*Saga\.js/);

const sagas = requireSaga.keys().reduce((memo, path) => {
  let saga = requireSaga(path);
  if (saga.default) saga = saga.default;

  // Only add if function.
  if (typeof saga === 'function') {
    memo.push(saga);
  }

  return memo;
}, []);

export default function* rootSaga() {
  yield all(sagas.map(saga => forkSafely(saga)));
}

function* forkSafely(saga, ...args) {
  try {
    yield call(saga, ...args);
  } catch (e) {
    console.warn(`Exception below thrown in saga "${saga.name}". Restarting.`);
    console.error(e);

    // restart
    yield* forkSafely(...arguments);
  }
}
