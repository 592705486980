export const REQUEST_COMPETITOR_EMAIL = 'app:REQUEST_COMPETITOR_EMAIL';
export function requestCompetitorEmail(competitorId) {
  return {
    type: REQUEST_COMPETITOR_EMAIL,
    competitorId
  };
}

export const RECEIVE_COMPETITOR_EMAIL = 'app:RECEIVE_COMPETITOR_EMAIL';
export function receiveCompetitorEmail(competitorId, email) {
  return {
    type: RECEIVE_COMPETITOR_EMAIL,
    competitorId,
    email
  };
}

export const HANDLE_BAD_COMPETITOR_EMAIL_RESPONSE =
  'app:HANDLE_BAD_COMPETITOR_EMAIL_RESPONSE';
export function handleBadCompetitorEmailResponse() {
  return {
    type: HANDLE_BAD_COMPETITOR_EMAIL_RESPONSE
  };
}
