export const MARK_CHECKLIST_ITEM_COMPLETE = 'app:MARK_CHECKLIST_ITEM_COMPLETE';
export function markChecklistItemComplete(itemName, successCb) {
  return {
    type: MARK_CHECKLIST_ITEM_COMPLETE,
    itemName,
    successCb
  };
}

export const SET_ACCOUNT_SETUP_COMPLETE = 'app:SET_ACCOUNT_SETUP_COMPLETE';
export function setAccountSetupComplete() {
  return {
    type: SET_ACCOUNT_SETUP_COMPLETE
  };
}

export const SKIP_ONBOARDING = 'app:SKIP_ONBOARDING';
export function skipOnboarding(successRoute) {
  return {
    type: SKIP_ONBOARDING,
    successRoute
  };
}
