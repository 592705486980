import React, { Component } from 'react';
import Alert from 'components/alert/alert';
import { connect } from 'react-redux';
import Button from 'components/button/button';
import { setExampleMode } from 'actions/authActions';

class ExampleModeWarning extends Component {
  render() {
    return (
      <Alert variant={'success'} type="tw1" style={{ marginBottom: '30px' }}>
        <div>
          <p style={{ fontSize: '15px' }}>
            <strong>Live demo enabled</strong>
          </p>

          <p>
            These results demonstrate what an account may look like after a few
            weeks of monitoring.
          </p>

          <Button
            thin
            danger
            onClick={() => this.props.setExampleMode(false)}
            style={{ marginTop: '10px' }}
          >
            See my own competitors
          </Button>
        </div>
      </Alert>
    );
  }
}

export default connect(
  null,
  {
    setExampleMode
  }
)(ExampleModeWarning);
