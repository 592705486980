import { call, select, takeLatest } from 'redux-saga/effects';
import { ATTEMPT_UPDATE_COMPETITOR_CONFIG } from 'actions/competitorConfigActions';
import { createAxios } from 'utils/axios/axiosUtils';
import Swal from 'sweetalert2';
import { currentProfileSelector } from 'selectors/profileSelectors';

export default function* updateCompetitorSaga() {
  yield takeLatest(ATTEMPT_UPDATE_COMPETITOR_CONFIG, updateCompetitor);
}

function* updateCompetitor({ competitorId, data, cb }) {
  const profile = yield select(currentProfileSelector);
  if (!profile || !profile.size) {
    return;
  }

  try {
    const response = yield call(attemptUpdateResponse, competitorId, data);
    if (response.data && response.data.success) {
      window.location.href = '/competitors/manage';
    }
  } catch (e) {
    console.warn(e);
    if (
      e.response &&
      e.response.data &&
      !e.response.data.success &&
      e.response.data.errorMessage
    ) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: e.response.data.errorMessage
      });
    } else {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text:
          'Something went wrong trying to update this competitor. Try again later.'
      });
    }
  }
  cb();
}

async function attemptUpdateResponse(competitorId, data) {
  return await createAxios().put('competitors-config/' + competitorId, data);
}
