import { useGetNotificationState } from 'providers/appProvider';
import React from 'react';
import Badge from 'react-bootstrap/Badge';

export default function OutstandingAppNotificationsPill() {
  const { unreadNotificationsCount } = useGetNotificationState();

  if (unreadNotificationsCount < 1) {
    return null;
  }

  return (
    <Badge pill variant="danger" style={{ marginLeft: '10px' }}>
      {unreadNotificationsCount}
    </Badge>
  );
}
