import React, { Component } from 'react';
import CentralWrapper from 'components/loginWrappers/centralWrapper';
import { authUserSettingsPageSelector } from 'selectors/authSelectors';
import { connect } from 'react-redux';
import UserSettingsPage from 'components/page/userSettingsPage';
import { attemptUpdateUser } from 'actions/userActions';

const TITLE_DEFAULT = 'My settings | Competito';

class AuthUserSettingsContainer extends Component {
  UNSAFE_componentWillMount() {
    document.title = TITLE_DEFAULT;
  }

  render() {
    return (
      <CentralWrapper>
        <UserSettingsPage {...this.props} editingSelf={true} />
      </CentralWrapper>
    );
  }
}

const mapStateToProps = state => authUserSettingsPageSelector(state);
export default connect(mapStateToProps, { attemptUpdateUser })(
  AuthUserSettingsContainer
);
