import React, { Component } from 'react';
import styles from './liveDemoIntroModal.module.scss';
import modalStyles from './globalModal.module.scss';
import Icon from 'components/fontAwesome/icon';
import Button from 'components/button/button';
import cx from 'classnames';

class LiveDemoIntroModal extends Component {
  constructor() {
    super();
    this.state = { isFullScreenMode: this.isFullScreenMode() };
    this.resizer = window.addEventListener('resize', this.checkSize);
  }

  componentWillUnmount() {
    this.resizer = null;
  }

  isFullScreenMode = () => {
    return window.innerWidth < 1200 ? false : true;
  };

  checkSize = () => {
    this.setState({ isFullScreenMode: this.isFullScreenMode() });
  };

  renderVideo = isFullScreen => {
    if (
      (isFullScreen && !this.state.isFullScreenMode) ||
      (!isFullScreen && this.state.isFullScreenMode)
    ) {
      return null;
    }

    return (
      <div
        className={cx(styles.videoWrapper, {
          [styles.videoWrapperInline]: !isFullScreen
        })}
      >
        <div className={styles.videoContainer}>
          <iframe
            className={styles.video}
            src="https://www.youtube.com/embed/aACObBTWq38"
            title="YouTube video player"
            frameBorder="0"
            height="352"
            width="640"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className={modalStyles.modalMain}>
        <div className={modalStyles.closeWrapper}>
          <Icon name="fas fa-times" onClick={this.props.clearModal} />
        </div>
        <div
          className={cx(styles.flexWrapper, {
            [styles.flexWrapperInline]: !this.state.isFullScreenMode
          })}
        >
          {this.renderVideo(true)}
          <div>
            <div>
              <span className={styles.liveDemoPill}>🎙 Live demo</span>
            </div>
            <h1 className={styles.title}>
              Welcome to Competito{' '}
              <span role="img" aria-hidden="true">
                👍
              </span>
            </h1>

            <div className={styles.description}>
              <p>
                Here is a video tour of the platfom in less than 90 seconds!{' '}
                <span role="img" aria-hidden="true">
                  🔥
                </span>
              </p>

              {this.renderVideo(false)}

              <p>If you enjoy the demo, you can try Competito for free</p>

              <ul className={styles.list}>
                <li className={styles.listItem}>
                  <Icon
                    name="fas fa-check"
                    bgColor={'#c6e0f6'}
                    fontColor={'#134977'}
                    style={{ marginRight: '10px' }}
                  />{' '}
                  30-day free trial
                </li>
                <li className={styles.listItem}>
                  <Icon
                    name="fas fa-check"
                    bgColor={'#c6e0f6'}
                    fontColor={'#134977'}
                    style={{ marginRight: '10px' }}
                  />{' '}
                  No credit card required
                </li>
                <li className={styles.listItem}>
                  <Icon
                    name="fas fa-check"
                    bgColor={'#c6e0f6'}
                    fontColor={'#134977'}
                    style={{ marginRight: '10px' }}
                  />{' '}
                  See if Competito is right for you
                </li>
              </ul>

              <p>Enjoy the demo</p>
            </div>
          </div>
        </div>

        <div className={styles.demoBtnHolder}>
          <Button
            rounded
            primary
            icon={'fas fa-flag-checkered'}
            onClick={this.props.clearModal}
          >
            Continue to demo
          </Button>
        </div>
      </div>
    );
  }
}

export default LiveDemoIntroModal;
