import React, { Component } from 'react';
import cx from 'classnames';

export default class CompetitorSelectInput extends Component {
  renderCompetitors = () => {
    const { competitors } = this.props;

    return Object.values(competitors).map(competitor => {
      return (
        <option key={competitor.name} value={competitor.competitorId}>
          {competitor.name}
        </option>
      );
    });
  };

  render() {
    const { style, competitors, onChange, className } = this.props;

    if (Object.keys(competitors).length < 2) {
      return null;
    }

    return (
      <select
        style={style}
        className={cx('form-control', className)}
        onChange={e => onChange(e.target.value)}
      >
        <option value="">Select a different competitor</option>
        {this.renderCompetitors()}
      </select>
    );
  }
}
