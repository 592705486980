import { put, call, takeLatest } from 'redux-saga/effects';
import {
  FETCH_USER_CHANNEL_DATA,
  receiveNotificationChannel
} from 'actions/authActions';
import { receiveNotificationSubscriptionsForChannel } from 'actions/eventNotificationSubscriptionActions';
import { createAxios } from 'utils/axios/axiosUtils';

export default function* fetchUserChannelSaga() {
  yield takeLatest(FETCH_USER_CHANNEL_DATA, fetchUserChannel);
}

function* fetchUserChannel() {
  try {
    const response = yield call(getResponse);
    if (response.data && response.data.channel) {
      yield put(receiveNotificationChannel(response.data.channel));
      yield put(
        receiveNotificationSubscriptionsForChannel(
          response.data.channel.channelId,
          response.data.subscriptions
        )
      );
    }
  } catch (e) {
    console.warn(e);
  }
}

async function getResponse() {
  return await createAxios().get('self-notification-channel');
}
