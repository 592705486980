import React, { Component } from 'react';
import styles from './radialProgress.module.scss';
import cx from 'classnames';

export default class RadialProgress extends Component {
  render() {
    const { percentage, className } = this.props;

    return (
      <div
        className={cx(styles.progress, className)}
        style={{ '--progress': percentage + '%' }}
        role="progressbar"
        aria-valuenow={percentage}
        aria-valuemin="0"
        aria-valuemax="100"
      >
        {percentage}%
      </div>
    );
  }
}
