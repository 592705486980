import React, { Component } from 'react';
import CentralWrapper from 'components/loginWrappers/centralWrapper';
import { onboardingChecklistContainerSelector } from 'selectors/accountSetupSelectors';
import {
  markChecklistItemComplete,
  skipOnboarding
} from 'actions/accountSetupActions';
import { connect } from 'react-redux';
import Spinner from 'components/spinner/spinner';
import { createAxios } from 'utils/axios/axiosUtils';
import Swal from 'sweetalert2';
import { sendVerificationEmail, setExampleMode } from 'actions/authActions';
import OnboardingPage from 'components/page/onboardingPage';
import Pusher from 'components/pusher/pusher';
import { launchModal } from 'actions/globalModalActions';

const TITLE_DEFAULT = 'Account setup checklist | Competito';

const DEFAULT_STATE = {
  showSpinner: true,
  onboardingProgress: 0,
  onboardingEvents: {}
};

class OnboardingContainer extends Component {
  constructor() {
    super();
    this.state = DEFAULT_STATE;

    document.title = TITLE_DEFAULT;
  }

  componentDidMount() {
    this.requestOnboardingData();
  }

  async requestOnboardingData() {
    this.setState(DEFAULT_STATE);

    let response = null;
    try {
      response = await createAxios().get('account-onboarding');
    } catch (e) {
      console.error(e);
    }

    if (!response) {
      Swal.fire({
        title: 'An unexpected error has occurred.',
        text:
          'Please try reloading the page. If the error persists, please contact our support team via live chat.',
        type: 'error'
      });
      return;
    }

    const { progress, events } = response.data;

    this.setState({
      onboardingProgress: progress,
      onboardingEvents: events,
      showSpinner: false
    });
  }

  render() {
    const { showSpinner, onboardingProgress, onboardingEvents } = this.state;
    const {
      accountId,
      history,
      user,
      launchModal,
      sendVerificationEmail,
      markChecklistItemComplete,
      setExampleMode,
      competitors,
      skipOnboarding
    } = this.props;

    return (
      <CentralWrapper wide={true} noTopPadding={true}>
        {showSpinner ? (
          <Spinner center useColors />
        ) : (
          <OnboardingPage
            onboardingProgress={onboardingProgress}
            onboardingEvents={onboardingEvents}
            launchModal={launchModal}
            sendVerificationEmail={sendVerificationEmail}
            markChecklistItemComplete={markChecklistItemComplete}
            setExampleMode={setExampleMode}
            history={history}
            user={user}
            firstCompetitor={competitors.first()}
            skipOnboarding={skipOnboarding}
          />
        )}

        <Pusher
          channel={accountId + `-channel-onboarding`}
          event={`progressUpdate`}
          onUpdate={() => this.requestOnboardingData()}
        />
      </CentralWrapper>
    );
  }
}

const mapStateToProps = state => onboardingChecklistContainerSelector(state);
export default connect(
  mapStateToProps,
  {
    launchModal,
    sendVerificationEmail,
    markChecklistItemComplete,
    setExampleMode,
    skipOnboarding
  }
)(OnboardingContainer);
