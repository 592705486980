import { call, takeLatest } from 'redux-saga/effects';
import { VERIFY_CAN_ADD_LIMIT_TYPE } from 'actions/competitorActions';
import { createAxios } from 'utils/axios/axiosUtils';

export default function* verifyCanAddForServiceSaga() {
  yield takeLatest(VERIFY_CAN_ADD_LIMIT_TYPE, verifyCanAddForService);
}

function* verifyCanAddForService({ successCb, failCb, limitType }) {
  try {
    const response = yield call(getResponse, limitType);
    if (response.data && response.data.isAllowed) {
      successCb();
      return;
    }
  } catch (e) {
    console.warn(e);
  }

  failCb();
}

async function getResponse(limitType) {
  return await createAxios(true, true).post('verify-can-add', {
    limitType
  });
}
