import React from 'react';
import { getEventTitle } from 'components/timeline/event/definition/webPageUpdatedEvent';
import InspectorEventWrapper from '../../inspectorEventWrapper';
import WebPageUpdated from './webPageUpdated';
import { renderInspectorPath } from 'utils/pathUtils';
import WebsiteItem from 'components/website/websiteItem';
import ChangeSeverityBadge from 'components/website/changeSeverityBadge';
import { useLocation } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';

export default function WebPageUpdatedInspector(props) {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const pageId = queryParams.get('pageId');

  if (pageId !== null) {
    return <WebPageUpdated {...props} pageId={pageId} />;
  }

  const pages = props.eventData.props.pages;
  const name = props.eventData.referenceName;

  let currentKey = 0;

  return (
    <InspectorEventWrapper {...props} title={getEventTitle(name, pages)}>
      <div className="row">
        <div className="col-md-12">
          <ul
            style={{
              listStyle: 'none',
              paddingLeft: 0,
              marginLeft: 0,
              marginBottom: '0 !important'
            }}
          >
            {pages
              .sort((a, b) => a.changeImpact < b.changeImpact)
              .map(ev => {
                const isLast = currentKey === pages.length - 1;
                currentKey++;

                return (
                  <li key={ev.url}>
                    <WebsiteItem
                      screenshotUrl={ev.viewportPageScreenshotLocation}
                      url={ev.url}
                      largest={true}
                      pageName={ev.pageName}
                      buttonLabel="View all changes"
                      buttonUrl={renderInspectorPath(
                        props.eventData.eventId,
                        'pageId=' + ev.pageId
                      )}
                    >
                      <div style={{ marginTop: '5px', marginBottom: '5px' }}>
                        <ChangeSeverityBadge
                          severity={ev.changeSeverity}
                          labelSuffix=" changes"
                        />

                        {ev.hasMetaChanges && (
                          <span
                            style={{
                              fontSize: '15px',
                              marginLeft: '10px',
                              verticalAlign: 'middle'
                            }}
                          >
                            <Badge variant={'success'}>meta changes</Badge>
                          </span>
                        )}
                      </div>
                    </WebsiteItem>

                    {isLast === false && (
                      <hr style={{ marginBottom: '20px' }} />
                    )}
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </InspectorEventWrapper>
  );
}
