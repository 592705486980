import { takeLatest, put } from 'redux-saga/effects';
import { STORE_LOGIN } from 'actions/authActions';
import { replace } from 'connected-react-router';
import { fetchAllUsers } from 'actions/userActions';
import { setAuthToken } from 'utils/localStorage/localStorage';
import { getQueryStringParams } from 'utils/urlUtils';

export default function* storeAuthTokenSaga() {
  yield takeLatest(STORE_LOGIN, handleStoreAuthToken);
}

function* handleStoreAuthToken({ data, onSuccess }) {
  yield setAuthToken(data.token);
  yield put(fetchAllUsers());

  if (!onSuccess) {
    const queryStringParams = getQueryStringParams();
    if (
      queryStringParams.returnTo !== undefined &&
      queryStringParams.returnTo.length > 0
    ) {
      window.location.href = decodeURIComponent(queryStringParams.returnTo);
      return;
    }

    yield put(replace('/'));
    return;
  }

  onSuccess();
}
