import React, { Component } from 'react';
import styles from './centralWrapper.module.scss';
import cx from 'classnames';

class CentralWrapper extends Component {
  render() {
    const { children, wide, noTopPadding, lockInPlace } = this.props;

    return (
      <main className={cx(styles.main, { [styles.mainLock]: lockInPlace })}>
        <div
          className={cx({
            [styles.container]: !wide,
            [styles.containerWide]: wide,
            [styles.noTopPadding]: noTopPadding
          })}
        >
          {children}
        </div>
      </main>
    );
  }
}

export default CentralWrapper;
