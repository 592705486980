import React, { Component } from 'react';
import modalStyles from './globalModal.module.scss';
import Icon from 'components/fontAwesome/icon';
import cx from 'classnames';
import { createAxios } from 'utils/axios/axiosUtils';
import Form from 'react-bootstrap/Form';
import { keywordsSelector } from 'selectors/keywordSelectors';
import { connect } from 'react-redux';
import Button from 'components/button/button';
import Spinner from 'components/spinner/spinner';
import { updateKeywords } from 'actions/keywordActions';

class ConfirmKeywordsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      keywordsLeft: 0,
      keywordsUsed: 0,
      saving: false,
      keywords: props.keywords
        ? props.keywords
            .get('value')
            .replaceAll(', ', '\n')
            .replaceAll(',', '\n')
        : ''
    };
  }

  UNSAFE_componentWillMount() {
    this.loadLimitsForProfile();
  }

  async loadLimitsForProfile() {
    let response = await createAxios().get('service-limits/keywords');
    if (response && response.data) {
      this.setState({
        keywordsLeft: response.data.keywordsLeft,
        keywordsUsed: response.data.keywordsUsed
      });
    }
  }

  saveForm = () => {
    const { keywords } = this.state;

    this.setState({ saving: true });

    this.props.updateKeywords(
      keywords,
      () => {
        this.setState({ saving: false });
        this.props.clearModal();
      },
      () => {
        this.setState({ saving: false });
      }
    );
  };

  render() {
    const { keywordsLeft, keywordsUsed, keywords } = this.state;

    return (
      <div className={cx(modalStyles.modalMain, modalStyles.modalMainSmaller)}>
        <div className={modalStyles.closeWrapper}>
          <Icon name="fas fa-times" onClick={this.props.clearModal} />
        </div>

        <h1 className={modalStyles.title}>
          Confirm your keywords{' '}
          <span role="img" aria-hidden="true">
            👍
          </span>
        </h1>

        <p>
          Competito has provided some suggestions below based on your website
          content. You can adjust these as you see fit.
        </p>

        <Form.Group controlId="keywords">
          <Form.Control
            type="textarea"
            rows={10}
            as="textarea"
            placeholder="e.g. competitor monitoring, watch my competition"
            onChange={e => this.setState({ keywords: e.target.value })}
            value={keywords}
          />

          <Form.Text className="text-muted">
            Keywords are seperated by a new line or a comma. <br />
            You are able to monitor {keywordsLeft} keywords for this profile (
            {keywordsUsed} already used as part of your service plan).
          </Form.Text>
        </Form.Group>

        <div className="row">
          <div className="cold-md-12">
            <Button
              size="sm"
              primary
              thin
              type="submit"
              style={{ marginBottom: '20px', marginLeft: '15px' }}
              disabled={this.state.saving}
              onClick={this.saveForm}
            >
              {this.state.saving ? <Spinner center /> : 'Mark as complete'}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => keywordsSelector(state);
export default connect(mapStateToProps, { updateKeywords })(
  ConfirmKeywordsModal
);
