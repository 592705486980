export function notOnMobile(value) {
  if (window.innerWidth <= 700) {
    return false;
  }

  if (window.innerHeight <= 500) {
    return false;
  }

  return value;
}
