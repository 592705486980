import { createSelector } from 'reselect';
import { currentProfileSelector } from 'selectors/profileSelectors';
import { simpleCompetitorSelector } from 'selectors/competitorSelectors';

export const technologiesSelector = createSelector(
  state => state.inventory,
  inventory => {
    const techData = inventory.get('techData');
    return techData && techData.get('technologies');
  }
);

export const technologyStatsSelector = createSelector(
  state => state.inventory,
  inventory => {
    const techData = inventory.get('techData');
    return techData && techData.get('stats');
  }
);

export const pagesSelector = createSelector(
  state => state.inventory,
  inventory => {
    const pagesData = inventory.get('pageData');
    return pagesData && pagesData.get('pages');
  }
);

export const pagesStatsSelector = createSelector(
  state => state.inventory,
  inventory => {
    const pagesData = inventory.get('pageData');
    return pagesData && pagesData.get('stats');
  }
);

export const inventoryPagesContainerSelector = createSelector(
  state => state.inventory,
  pagesSelector,
  pagesStatsSelector,
  currentProfileSelector,
  simpleCompetitorSelector,
  (inventoryState, pages, stats, profile, competitors) => {
    return {
      profile: profile ? profile.toJS() : null,
      competitors: competitors.toJS(),
      pages,
      stats,
      isLoadingPages: inventoryState.get('isLoadingPages'),
      pagesLoadingError: inventoryState.get('pagesLoadingError')
    };
  }
);

export const inventoryTechContainerSelector = createSelector(
  state => state.inventory,
  technologiesSelector,
  technologyStatsSelector,
  currentProfileSelector,
  simpleCompetitorSelector,
  (inventoryState, technologies, stats, profile, competitors) => {
    return {
      profile: profile ? profile.toJS() : null,
      competitors: competitors.toJS(),
      stats,
      technologies,
      isLoadingTech: inventoryState.get('isLoadingTech'),
      techLoadingError: inventoryState.get('techLoadingError')
    };
  }
);
