import React from 'react';
import styles from './timelineEventWrapper.module.scss';
import cx from 'classnames';
import Icon from 'components/fontAwesome/icon';
import TimelineEventTime from './timelineEventTime';
import { Link } from 'react-router-dom';
import { renderInspectorPath } from 'utils/pathUtils';
import { EVENT_TYPE_MAP } from 'utils/timelineUtils';

export default function TimelineEventWrapper(props) {
  const { title, event, children, buttonText } = props;

  const eventMap = EVENT_TYPE_MAP[event.eventType];

  const comments = event.comments;

  const commentsBlock = (
    <Link to={renderInspectorPath(event.eventId)}>
      <div className={styles.commentsIcon}>
        <Icon
          name="fas fa-comment-alt"
          size="2x"
          style={
            comments.length > 0 ? { color: '#f8485e' } : { color: '#392647' }
          }
        />
      </div>
      <p className={styles.commentText}>
        {comments.length > 99 ? '99+' : comments.length} comment
        {comments.length === 1 ? '' : 's'}
      </p>
    </Link>
  );

  return (
    <li className={styles.timelineEvent}>
      <div
        className={cx(styles.timelineEventIcon)}
        style={{ backgroundColor: eventMap.iconBgColor }}
      >
        <Icon name={eventMap.icon} size="2x" />
      </div>

      <div className={cx(styles.timelineEventBlock, 'animated fadeIn')}>
        <div className={styles.headingFlex}>
          <div>
            <TimelineEventTime time={event.representativeTime} />
            <h3 className={styles.title}>
              {event.favicon && (
                <img src={event.favicon} alt="" className={styles.favi} />
              )}{' '}
              <Link
                to={renderInspectorPath(
                  event.eventId,
                  props.inspectorAdditionalParams
                )}
              >
                {title}
              </Link>
            </h3>
          </div>
          {props.isInspector !== true && (
            <div className={styles.commentsBlock}>{commentsBlock}</div>
          )}
        </div>

        <div className={styles.eventBody}>
          <div className={styles.eventChildren}>{children}</div>
          <div
            className={cx(styles.eventButtonBar, {
              [styles.eventButtonInspector]: props.isInspector
            })}
          >
            {props.isInspector !== true && (
              <Link
                to={renderInspectorPath(
                  event.eventId,
                  props.inspectorAdditionalParams
                )}
              >
                <button>{buttonText || 'View details'}</button>
              </Link>
            )}
          </div>
        </div>

        <div className={styles.commentsMobile}>{commentsBlock}</div>
      </div>
    </li>
  );
}
