import React, { Component } from 'react';
import CentralWrapper from 'components/loginWrappers/centralWrapper';
import { inventoryTechContainerSelector } from 'selectors/inventorySelectors';
import { fetchInventoryTechData } from 'actions/inventoryActions';
import { connect } from 'react-redux';
import Alert from 'components/alert/alert';
import InventoryTechPage from 'components/page/inventoryTechPage';

const TITLE_DEFAULT = 'Technology inventory | Competito';

class InventoryTechContainer extends Component {
  constructor(props) {
    super(props);

    this.state = { competitorId: this.getFirstAvailableCompetitorId(props) };

    document.title = TITLE_DEFAULT;
  }

  UNSAFE_componentWillMount() {
    const { competitorId } = this.state;
    if (competitorId) {
      this.props.fetchInventoryTechData(competitorId);
    }
  }

  getFirstAvailableCompetitorId(props) {
    if (!props.competitors || !Object.keys(props.competitors).length) {
      return null;
    }

    return Object.keys(props.competitors)[0];
  }

  selectCompetitor = competitorId => {
    this.setState({ competitorId: competitorId });
    this.props.fetchInventoryTechData(competitorId);
  };

  renderInWrapper() {
    const { competitorId } = this.state;
    const {
      technologies,
      stats,
      competitors,
      isLoadingTech,
      techLoadingError
    } = this.props;

    if (!competitorId) {
      return (
        <div className="row">
          <div className="col-md-12">
            <Alert variant="info">
              No competitors have been added, please add one to view this data.
            </Alert>
          </div>
        </div>
      );
    }

    return (
      <InventoryTechPage
        competitorId={competitorId}
        technologies={technologies}
        stats={stats}
        competitors={competitors}
        isLoadingTech={isLoadingTech}
        techLoadingError={techLoadingError}
        onSelectCompetitor={this.selectCompetitor}
      />
    );
  }

  render() {
    return <CentralWrapper>{this.renderInWrapper()}</CentralWrapper>;
  }
}

const mapStateToProps = state => inventoryTechContainerSelector(state);
export default connect(mapStateToProps, { fetchInventoryTechData })(
  InventoryTechContainer
);
