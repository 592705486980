import { createSelector } from 'reselect';
import { Map } from 'immutable';
import { scoutUrlInformationSelector } from 'selectors/scoutUrlSelectors';
import { profileConfigSelector } from 'selectors/profileConfigSelectors';

export const simpleProfilesSelector = createSelector(
  state => state.profile,
  profiles => {
    const profileData = profiles.get('data');

    if (!profileData || !profileData.size) {
      return Map();
    }

    return profileData.map(profile => {
      return {
        profileId: profile.get('profileId'),
        profileName: profile.get('name')
      };
    });
  }
);

export const currentProfileSelector = createSelector(
  state => state.profile,
  profiles => {
    const profileData = profiles.get('data');
    if (!profileData || !profileData.size) {
      return null;
    }

    const currentProfileId = profiles.get('currentProfileId');
    if (!currentProfileId) {
      return null;
    }

    const profile = profileData.get(currentProfileId);
    if (!profile || !profile.size) {
      return null;
    }

    return profile;
  }
);

export const currentProfileIdSelector = createSelector(
  state => state.profile,
  profiles => {
    const profileData = profiles.get('data');
    if (!profileData || !profileData.size) {
      return null;
    }

    const currentProfileId = profiles.get('currentProfileId');
    if (!currentProfileId) {
      return null;
    }

    return currentProfileId;
  }
);

export const profileAddPageSelector = createSelector(
  state => state.profile,
  scoutUrlInformationSelector,
  (profileData, scoutData) => {
    return {
      ...scoutData,
      addingProfile: profileData.get('addingProfile')
    };
  }
);

export const editProfilePageSelector = createSelector(
  state => state.profile,
  currentProfileSelector,
  profileConfigSelector,
  (profileData, currentProfile, profileConfig) => {
    return {
      profile: currentProfile,
      profileConfig: profileConfig
    };
  }
);
