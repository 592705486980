export const ATTEMPT_SWITCH_PLAN = 'app:ATTEMPT_SWITCH_PLAN';
export function attemptSwitchPlan(subscriptionId, newPlanId, cb) {
  return {
    type: ATTEMPT_SWITCH_PLAN,
    subscriptionId,
    newPlanId,
    cb
  };
}

export const UPDATE_SUBSCRIPTION = 'app:UPDATE_SUBSCRIPTION';
export function updateSubscription(subscription) {
  return {
    type: UPDATE_SUBSCRIPTION,
    subscription
  };
}

export const ATTEMPT_CANCEL_SUBSCRIPTION = 'app:ATTEMPT_CANCEL_SUBSCRIPTION';
export function attemptCancelSubscription(subscriptionId, cb) {
  return {
    type: ATTEMPT_CANCEL_SUBSCRIPTION,
    subscriptionId,
    cb
  };
}

export const CANCEL_SUBSCRIPTION = 'app:CANCEL_SUBSCRIPTION';
export function cancelSubscription(subscriptionId) {
  return {
    type: CANCEL_SUBSCRIPTION,
    subscriptionId
  };
}

export const ATTEMPT_UPDATE_PAYMENT_DETAILS =
  'app:ATTEMPT_UPDATE_PAYMENT_DETAILS';
export function attemptUpdatePaymentDetails(subscriptionId, cb) {
  return {
    type: ATTEMPT_UPDATE_PAYMENT_DETAILS,
    subscriptionId,
    cb
  };
}
