import React, { Component } from 'react';
import { loggedInProfileSelector } from 'selectors/loggedInSelectors';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import ErrorPage from 'components/page/errorPage';
import TimelineContainer from './timelineContainer';
import KeywordsContainer from './keywordsContainer';
import InventoryPagesContainer from './inventoryPagesContainer';
import InventoryTechContainer from './inventoryTechContainer';
import KeywordSuggestionsContainer from './keywordSuggestionsContainer';
import OverviewContainer from './overviewContainer';
import CompetitorAddContainer from 'containers/competitorAddContainer';
import ManageCompetitorsContainer from 'containers/manageCompetitorsContainer';
import EditProfileContainer from 'containers/editProfileContainer';
import EditCompetitorContainer from 'containers/editCompetitorContainer';
import EventInspectorContainer from 'containers/eventInspectorContainer';
import OnboardingContainer from 'containers/onboardingContainer';

class LoggedInProfileContainer extends Component {
  render() {
    const { profiles, currentProfileId, isLoadingCompetitors } = this.props;

    if (!currentProfileId || !profiles.get(currentProfileId)) {
      return <Route render={props => <ErrorPage {...props} code={404} />} />;
    }

    if (isLoadingCompetitors) {
      return null;
    }

    return (
      <Switch>
        <Route exact path="/onboarding" component={OnboardingContainer} />
        <Route exact path="/overview" component={OverviewContainer} />
        <Route exact path="/timeline" component={TimelineContainer} />
        <Route
          exact
          path="/event-inspector/:eventId/:tab"
          component={EventInspectorContainer}
        />
        <Route exact path="/keywords" component={KeywordsContainer} />
        <Route
          exact
          path="/keyword-suggestions"
          component={KeywordSuggestionsContainer}
        />
        <Route
          exact
          path="/inventory/pages"
          component={InventoryPagesContainer}
        />
        <Route
          exact
          path="/inventory/technologies"
          component={InventoryTechContainer}
        />
        <Route
          exact
          path="/competitors/add"
          component={CompetitorAddContainer}
        />
        <Route
          exact
          path="/competitors/manage"
          component={ManageCompetitorsContainer}
        />
        <Route
          exact
          path="/competitors/:competitorId/edit"
          component={EditCompetitorContainer}
        />
        <Route exact path="/edit-profile" component={EditProfileContainer} />
        <Route render={props => <ErrorPage {...props} code={404} />} />
      </Switch>
    );
  }
}

const mapStateToProps = state => loggedInProfileSelector(state);
export default connect(
  mapStateToProps,
  {}
)(LoggedInProfileContainer);
