import { call, put, takeLatest } from 'redux-saga/effects';
import { createAxios } from 'utils/axios/axiosUtils';
import {
  ATTEMPT_CREATE_USER,
  handleCreateUserResponse
} from 'actions/userActions';
import Swal from 'sweetalert2';

export default function* createUserSaga() {
  yield takeLatest(ATTEMPT_CREATE_USER, createUserS);
}

function* createUserS({ data, cb }) {
  try {
    const response = yield call(apiCall, data);

    if (response && response.data.user) {
      yield put(handleCreateUserResponse(response.data.user));

      cb();
      Swal.fire({
        type: 'success',
        title: 'User created!',
        showConfirmButton: false,
        timer: 1500
      });
      return;
    }

    Swal.fire({
      type: 'error',
      title: 'Oops...',
      text: 'Something went wrong trying to add this user. Try again later.'
    });
  } catch (e) {
    console.warn(e);
    Swal.fire({
      type: 'error',
      title: 'Oops...',
      text: 'Something went wrong trying to add this user. Try again later.'
    });
  }
}

async function apiCall(data) {
  return await createAxios().post('users', data);
}
