import React from 'react';

const req = require.context(
  'components/timeline/event/definition',
  true,
  /.*.js/
);

export default function TimelineEventComponent(props) {
  try {
    let Component = req('./' + props.event.eventType + 'Event.js').default;
    return <Component {...props} eventProps={props.event.props} />;
  } catch (e) {
    console.log(e);
    return null;
  }
}
