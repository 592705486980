import { call, takeLatest } from 'redux-saga/effects';
import { createAxios } from 'utils/axios/axiosUtils';
import Swal from 'sweetalert2';
import { SEND_VERIFICATION_EMAIL } from 'actions/authActions';

export default function* sendVerificationEmailSaga() {
  yield takeLatest(SEND_VERIFICATION_EMAIL, sendVerificationEmail);
}

function* sendVerificationEmail() {
  try {
    yield call(apiCall);

    Swal.fire({
      type: 'success',
      title: 'Email verification sent',
      text:
        'Please check your inbox for the verification email. This can sometimes end up in your spam folder.'
    });
  } catch (e) {
    Swal.fire({
      type: 'error',
      title: 'Oops...',
      text:
        'Something went wrong trying to send the verification email. Try again later.'
    });
  }
}

async function apiCall() {
  return await createAxios().post('self/send-verification-email');
}
