import { Map, fromJS } from 'immutable';
import {
  RECEIVE_INVENTORY_PAGE_DATA,
  RECEIVE_INVENTORY_TECH_DATA,
  FETCH_INVENTORY_PAGE_DATA,
  FETCH_INVENTORY_TECH_DATA,
  HANDLE_INVENTORY_PAGE_DATA_ERROR,
  HANDLE_INVENTORY_TECH_DATA_ERROR
} from 'actions/inventoryActions';

const initialPageData = Map({
  stats: Map({}),
  pages: Map({})
});

const initialTechData = Map({
  stats: Map({}),
  technologies: Map({})
});

const initialState = fromJS({
  pageData: initialPageData,
  techData: initialTechData,
  isLoadingPages: false,
  pagesLoadingError: false,
  isLoadingTech: false,
  techLoadingError: false
});

export default function inventoryReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_INVENTORY_PAGE_DATA:
      return state
        .set('isLoadingPages', true)
        .set('pagesLoadingError', false)
        .set('pages', initialPageData);
    case FETCH_INVENTORY_TECH_DATA:
      return state
        .set('isLoadingTech', true)
        .set('techLoadingError', false)
        .set('tech', initialTechData);
    case RECEIVE_INVENTORY_PAGE_DATA:
      return state
        .set('isLoadingPages', false)
        .set('pagesLoadingError', false)
        .setIn(['pageData', 'pages'], fromJS(action.data.pages))
        .setIn(['pageData', 'stats'], fromJS(action.data.stats));
    case RECEIVE_INVENTORY_TECH_DATA:
      return state
        .set('isLoadingTech', false)
        .set('techLoadingError', false)
        .setIn(['techData', 'technologies'], fromJS(action.data.technologies))
        .setIn(['techData', 'stats'], fromJS(action.data.stats));
    case HANDLE_INVENTORY_PAGE_DATA_ERROR:
      return state.set('isLoadingPages', false).set('pagesLoadingError', true);
    case HANDLE_INVENTORY_TECH_DATA_ERROR:
      return state.set('isLoadingTech', false).set('techLoadingError', true);
    default:
      return state;
  }
}
