import { createSelector } from 'reselect';
import { authUserSelector } from 'selectors/authSelectors';

export const editUserPageSelector = createSelector(
  state => state.user,
  (state, props) => props.match.params.userId,
  authUserSelector,
  (userState, userId, authUser) => {
    return {
      user: userState.getIn(['data', userId]),
      usersLoading: userState.get('loadingAll'),
      usersLoadingError: userState.get('loadingAllError'),
      authUserRole: authUser.get('role')
    };
  }
);

export const usersSelector = createSelector(
  state => state.user,
  userState => {
    return userState.get('data');
  }
);

export const usersWizardSelector = createSelector(
  state => state.user,
  authUserSelector,
  (userState, authUser) => {
    return {
      users: userState.get('data'),
      authUserRole: authUser.get('role')
    };
  }
);
