import { createSelector } from 'reselect';

export const notificationSubscriptionSelector = state =>
  state.eventNotificationSubscription;

export const notificationSubscriptionDatapSelector = createSelector(
  notificationSubscriptionSelector,
  subs => {
    return {
      notificationSubscriptionData: subs.get('data')
    };
  }
);
