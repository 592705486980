import React from 'react';
import TimelineEventWrapper from '../timelineEventWrapper';
import { pluralise } from 'utils/numberUtils';
import styles from './facebookAdAddedEvent.module.scss';
import Icon from 'components/fontAwesome/icon';

const BASE_FB_AD_URL =
  'https://www.facebook.com/ads/library/?active_status=all&ad_type=all&country=ALL&impression_search_field=has_impressions_lifetime&view_all_page_id=';

export function getEventTitle(name, posts) {
  const title =
    name +
    ' has ' +
    pluralise(
      posts.length,
      'a new Facebook ad',
      posts.length + ' new Facebook ads'
    );

  return title;
}

function getAdvertUrl(facebookPageId) {
  return BASE_FB_AD_URL + facebookPageId;
}

function RenderBottomBlock({ ad }) {
  if (!ad.actionItems.length) {
    return null;
  }

  let hasItems = false;
  ad.actionItems.forEach(item => {
    if (item.length) {
      hasItems = true;
    }
  });

  if (!hasItems) {
    return null;
  }

  return (
    <div className="row">
      <div className="col-sm-9">
        <div className={styles.actionItems}>
          {ad.actionItems.map(ai => {
            return (
              <p key={ai}>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  className={styles.link}
                  href={getAdvertUrl(ad.facebookPageId)}
                >
                  {ai}
                </a>
              </p>
            );
          })}
        </div>
      </div>
      <div className="col-sm-3">
        {ad.buttonText && ad.buttonText.length > 0 && (
          <a
            rel="noopener noreferrer"
            target="_blank"
            className={styles.link}
            href={getAdvertUrl(ad.facebookPageId)}
          >
            <button>{ad.buttonText}</button>
          </a>
        )}
      </div>
    </div>
  );
}

function RenderImage({ ad }) {
  const isVideo = ad.video && ad.video.poster && ad.video.poster.length;
  if (isVideo) {
    return (
      <div>
        <p
          onClick={() => window.open(getAdvertUrl(ad.facebookPageId), '_blank')}
          className={styles.mediaImageWrapper}
          style={{
            position: 'relative',
            maxWidth: '500px',
            margin: '0 auto',
            marginBottom: '0'
          }}
        >
          <span
            style={{
              zIndex: 3,
              position: 'absolute',
              color: '#fff',
              top: '38%',
              textAlign: 'center',
              width: '100%',
              cursor: 'pointer'
            }}
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#fff', display: 'block' }}
              href={getAdvertUrl(ad.facebookPageId)}
            >
              <Icon name={'far fa-play-circle'} size="5x" />
            </a>
          </span>

          <span
            style={{
              zIndex: 2,
              position: 'absolute',
              backgroundColor: '#999',
              opacity: 0.4,
              top: 0,
              bottom: 0,
              width: '100%',
              cursor: 'pointer'
            }}
          ></span>
          <img src={ad.video.poster} style={{ width: '100%' }} alt="" />
        </p>
      </div>
    );
  }

  return (
    <div>
      <a
        rel="noopener noreferrer"
        target="_blank"
        href={getAdvertUrl(ad.facebookPageId)}
      >
        <img src={ad.image} alt="" style={{ width: '100%' }} />
      </a>
    </div>
  );
}

function AdHeader({ ad }) {
  return (
    <div className={styles.handleLine}>
      <div>
        <Icon
          name="fab fa-facebook"
          style={{ marginLeft: '5px' }}
          tooltip="Advertising on Facebook"
        />
        <Icon
          name="fab fa-facebook-messenger"
          style={{ marginLeft: '10px' }}
          tooltip="Advertising on Messenger"
        />
        <Icon
          name="fab fa-instagram"
          style={{ marginLeft: '10px' }}
          tooltip="Advertising on Instagram"
        />
        <Icon
          name="fab fa-globe"
          style={{ marginLeft: '10px' }}
          tooltip="Advertising on Audience Network"
        />
      </div>
      <div>
        <a
          href={getAdvertUrl(ad.facebookPageId)}
          target="_blank"
          rel="noopener noreferrer"
        >
          View on Facebook{' '}
          <Icon name="far fa-external-link-alt" style={{ marginLeft: '5px' }} />
        </a>
      </div>
    </div>
  );
}

function RenderAd({ ad, name, favicon }) {
  let text = ad.text;
  text = text.replace(/ {2}/g, '<br /><br />');
  text = text.replace(/... {2}.../g, '<br /><br />');

  return (
    <div className={styles.postWrapper}>
      <AdHeader ad={ad} />
      <div className={styles.post}>
        <div style={{ padding: '10px 10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              <img src={favicon} alt="" style={{ maxWidth: '25px' }} />
            </div>
            <div style={{ marginLeft: '10px' }}>
              <div>
                <a
                  target="_blank"
                  href={getAdvertUrl(ad.facebookPageId)}
                  rel="noopener noreferrer"
                  style={{ color: '#385898', fontWeight: 'bold' }}
                >
                  {name}
                </a>
              </div>
              <strong style={{ fontSize: '12px' }}>Sponsored</strong>
            </div>
          </div>
        </div>
        <p
          className={styles.postText}
          dangerouslySetInnerHTML={{
            __html: text
          }}
        />
        <div>
          <RenderImage ad={ad} />
        </div>
        <div>
          <RenderBottomBlock ad={ad} />
        </div>
      </div>
    </div>
  );
}

export default function FacebookAdAddedEvent(props) {
  const ads = props.event.props.ads;
  const name = props.event.referenceName;

  let displayAds = [];
  let displayCounter = 1;

  const filteredAds = ads.slice(0, props.isInspector ? 500 : 2);

  filteredAds.forEach(ad => {
    displayAds.push(
      <li
        key={displayCounter}
        style={
          displayCounter !== filteredAds.length
            ? { borderBottom: '1px solid #eee', marginBottom: '20px' }
            : {}
        }
      >
        <RenderAd
          ad={ad}
          favicon={props.event.favicon}
          name={name}
          isSingleEvent={ads.length === 1}
        />
        <div style={{ marginBottom: '20px' }} />
      </li>
    );
    displayCounter++;
  });

  return (
    <TimelineEventWrapper
      title={getEventTitle(name, ads)}
      buttonText={ads.length > 3 ? 'Show all ads' : 'View details'}
      {...props}
    >
      <div className="row">
        <div className="col-md-12">
          <div className={styles.faceebookAdsWrapper}>
            <ul className={styles.ul}>{displayAds}</ul>
          </div>
        </div>
      </div>
    </TimelineEventWrapper>
  );
}
