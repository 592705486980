import { put, call, takeLatest } from 'redux-saga/effects';
import {
  SCOUT_URL,
  handleBadScoutResponse,
  handleScoutResponse
} from 'actions/scoutUrlActions';
import { createAxios } from 'utils/axios/axiosUtils';

export default function* scoutUrlSaga() {
  yield takeLatest(SCOUT_URL, scoutUrl);
}

function* scoutUrl({ url, isCompetitorCheck }) {
  try {
    const response = yield call(getScoutResponse, url, isCompetitorCheck);
    if (response.data && response.data.verified) {
      yield put(handleScoutResponse(response.data));
      return;
    }

    if (response.data.requiresAuth) {
      yield put(
        handleBadScoutResponse(
          'This website requires authentication, please contact Competito support desk.'
        )
      );
      return;
    }

    yield put(
      handleBadScoutResponse(
        'The domain given could not be accessed, try again later.'
      )
    );
  } catch (e) {
    console.warn(e);
    yield put(
      handleBadScoutResponse(
        'There was a problem adding this domain, try again shortly.'
      )
    );
  }
}

async function getScoutResponse(url, isCompetitorCheck) {
  return await createAxios(true, true).post('scout-url', {
    url,
    isCompetitorCheck
  });
}
