import React, { Component } from 'react';
import Block from 'components/presentation/block';
import { getOrdinal } from 'utils/numberUtils';

export default class KeywordsBreakdownWidget extends Component {
  renderLineItems = () => {
    const { keywordsBreakdown } = this.props;

    return Object.values(keywordsBreakdown).map(line => {
      let rankingAverage = Math.floor(line.rankingAverage);

      return (
        <tr key={line.normalisedHost}>
          <td>{line.name}</td>
          <td>{rankingAverage > 0 ? getOrdinal(rankingAverage) : 0}</td>
          <td>{line.amountOfRankings}</td>
        </tr>
      );
    });
  };

  render() {
    const { keywordsBreakdown } = this.props;

    if (!keywordsBreakdown) {
      return null;
    }

    return (
      <div className="row">
        <div className="col-md-12">
          <Block
            title={'Google rankings summary'}
            help="Here you can get an overview of you and your competitors performance in Google Search Results for the keywords you specified."
          >
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Average rank</th>
                  <th>Keywords ranked</th>
                </tr>
              </thead>
              <tbody>{this.renderLineItems()}</tbody>
            </table>
          </Block>
        </div>
      </div>
    );
  }
}
