import React, { Component } from 'react';
import RadialProgress from 'components/progress/radialProgress';
import styles from './onboardingPage.module.scss';
import cx from 'classnames';
import Block from 'components/presentation/block';
import Button from 'components/button/button';
import TASKS from 'components/accountSetup/taskBlocks';
import Icon from 'components/fontAwesome/icon';
import Alert from 'components/alert/alert';

class OverviewPage extends Component {
  renderTitle = () => {
    const { user, firstCompetitor } = this.props;
    const userParts = user.get('name').split(' ');

    return (
      <div className="row" style={{ marginBottom: '30px', marginTop: '30px' }}>
        <div className="col-xl-12" style={{ textAlign: 'center' }}>
          <h1 className={'h4'} style={{ marginBottom: 0 }}>
            Congratulations, {userParts[0]}! 🎉
          </h1>
          <h2 className={'h5 textLighter thin'}>
            We will let you know as soon as{' '}
            <strong>{firstCompetitor.name}</strong> makes an update
          </h2>
        </div>
      </div>
    );
  };

  skipOnboarding = () => {
    const { skipOnboarding } = this.props;

    skipOnboarding('/timeline');
  };

  renderProgress = () => {
    const { onboardingProgress } = this.props;

    return (
      <div>
        <Block title={'Progress to perfection'}>
          <RadialProgress
            percentage={onboardingProgress}
            className={styles.progressBar}
          />
        </Block>

        {onboardingProgress !== 100 && (
          <div style={{ textAlign: 'center' }}>
            <Button
              primary
              rounded
              thin
              icon="fal fa-flag-checkered"
              onClick={this.skipOnboarding}
            >
              Skip setup
            </Button>
          </div>
        )}
      </div>
    );
  };

  renderTaskButton = (task, isDone) => {
    let onClick = null;

    if (task.button.action === 'sendVerificationEmail') {
      onClick = () => {
        this.props.sendVerificationEmail();
      };
    }

    if (task.button.action === 'viewDemoTimeline') {
      onClick = () => {
        this.props.setExampleMode(true);
        this.props.history.push('/timeline');
      };
    }

    if (task.button.modal) {
      onClick = () => {
        this.props.launchModal(task.button.modal);
      };
    }

    return (
      <Button
        thin
        primary
        disabled={isDone}
        to={task.button.to}
        onClick={onClick}
      >
        {task.button.label}
      </Button>
    );
  };

  renderTaskBlock = (task, idx) => {
    const { onboardingEvents, markChecklistItemComplete } = this.props;
    const isDone = onboardingEvents[task.key] === true;
    const isLast = TASKS.length === idx;

    return (
      <div key={task.key}>
        <h2 className={cx({ [styles.isDone]: isDone }, styles.taskHeading)}>
          <Icon
            name={isDone ? 'fal fa-check' : 'fal fa-times'}
            style={{
              marginRight: '10px',
              fontWeight: 'bold',
              color: isDone ? 'lightgreen' : '#c8e2f8'
            }}
          />{' '}
          {task.title}
        </h2>
        {isDone === false && (
          <p
            className={cx({ [styles.isDone]: isDone }, styles.taskDescription)}
          >
            {task.description}
          </p>
        )}

        {isDone === false && task.button && this.renderTaskButton(task, isDone)}
        {isDone === false &&
          (task.skippable === undefined || task.skippable === true) && (
            <Button
              rounded
              link
              disabled={isDone}
              style={{ marginLeft: task.button ? '20px' : '0px' }}
              onClick={() => markChecklistItemComplete(task.key)}
            >
              Mark complete
            </Button>
          )}
        {isLast === false && (
          <hr style={{ marginBottom: '25px', marginTop: '25px' }} />
        )}
      </div>
    );
  };

  renderTasks = () => {
    const { onboardingProgress } = this.props;

    let tasks = [];
    let count = 1;
    TASKS.forEach(task => {
      tasks.push(this.renderTaskBlock(task, count++));
    });

    return (
      <Block title={'Get the most out of Competito'}>
        {onboardingProgress !== 100 && tasks}
        {onboardingProgress === 100 && (
          <Alert variant="success">
            <strong>Congratulations!</strong> Your account is completely setup.
          </Alert>
        )}
      </Block>
    );
  };

  renderBody = () => {
    return (
      <div className={cx('row', styles.wrapper)}>
        <div className="col-md-8">{this.renderTasks()}</div>
        <div className="col-md-4">{this.renderProgress()}</div>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderTitle()}
        {this.renderBody()}
      </div>
    );
  }
}

export default OverviewPage;
