import React from 'react';
import TimelineEventWrapper from '../timelineEventWrapper';
import { pluralise } from 'utils/numberUtils';
import styles from './webPageAddedEvent.module.scss';
import Icon from 'components/fontAwesome/icon';
import cx from 'classnames';

export function getEventTitle(name, pages) {
  const title =
    name +
    ' removed ' +
    pluralise(pages.length, 'a web page', pages.length + ' web pages');

  return title;
}

export default function WebPageRemovedEvent(props) {
  const pages = props.event.props.pages;
  const name = props.event.referenceName;

  const slicedPages = props.isInspector ? pages : pages.slice(0, 30);

  return (
    <TimelineEventWrapper
      title={getEventTitle(name, pages)}
      buttonText={pages.length > 30 ? 'Show all removals' : 'View details'}
      {...props}
    >
      <div className="row">
        <div className="col-md-12">
          <div className={cx({ [styles.scroll]: props.isInspector })}>
            <ul
              style={{
                paddingLeft: 0,
                marginLeft: '20px',
                marginBottom: props.isInspector ? '0' : '10px'
              }}
            >
              {slicedPages.map(page => (
                <li key={page.url}>
                  <a
                    href={page.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ fontSize: '16px' }}
                  >
                    {page.url} <Icon name="fal fa-external-link-alt" />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </TimelineEventWrapper>
  );
}
