import { call, takeLatest } from 'redux-saga/effects';
import { createAxios } from 'utils/axios/axiosUtils';
import Swal from 'sweetalert2';
import { MARK_CHECKLIST_ITEM_COMPLETE } from 'actions/accountSetupActions';

export default function* markChecklistItemCompleteSaga() {
  yield takeLatest(MARK_CHECKLIST_ITEM_COMPLETE, markChecklistItemComplete);
}

function* markChecklistItemComplete({ itemName, successCb }) {
  try {
    yield call(attemptMarkResponse, itemName);
  } catch (e) {
    console.warn(e);

    Swal.fire({
      type: 'error',
      title: 'Oops...',
      text:
        'Something went wrong trying to mark this item as complete. Try again later.'
    });
    return;
  }

  successCb && successCb();
}

async function attemptMarkResponse(itemName) {
  return await createAxios().put('account-onboarding/mark-complete', {
    event: itemName
  });
}
