import React, { Component } from 'react';
import CentralWrapper from 'components/loginWrappers/centralWrapper';
import { connect } from 'react-redux';
import KeywordSuggestionsPage from 'components/page/keywordSuggestionsPage';
import { keywordSuggestionsPageSelector } from 'selectors/keywordSuggestionSelectors';
import { fetchKeywordSuggestionsForDomainIfRequired } from 'actions/keywordSuggestionActions';

const TITLE_DEFAULT = 'Keyword suggestions | Competito';

class KeywordSuggestionsContainer extends Component {
  UNSAFE_componentWillMount() {
    document.title = TITLE_DEFAULT;
  }

  render() {
    return (
      <CentralWrapper>
        <KeywordSuggestionsPage {...this.props} />
      </CentralWrapper>
    );
  }
}

const mapStateToProps = state => keywordSuggestionsPageSelector(state);
export default connect(mapStateToProps, {
  fetchKeywordSuggestionsForDomainIfRequired
})(KeywordSuggestionsContainer);
