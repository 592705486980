import React from 'react';
import cx from 'classnames';
import Icon from 'components/fontAwesome/icon';
import { Link } from 'react-router-dom';
import styles from './button.module.scss';

export default function Button(props) {
  const {
    children,
    className,
    style,
    link,
    icon,
    noOutline,
    rounded,
    noBg,
    secondary,
    primary,
    thin,
    danger,
    warning,
    success,
    info,
    disabled,
    title,
    type,
    onClick,
    to
  } = props;

  const btn = (
    <button
      className={cx(
        'btn',
        styles.btn,
        { [styles.noBg]: noBg },
        { [styles.rounded]: rounded },
        { [styles.primary]: primary },
        { [styles.info]: info },
        { [styles.secondary]: secondary },
        { [styles.danger]: danger },
        { [styles.warning]: warning },
        { [styles.success]: success },
        { [styles.link]: link },
        { [styles.thin]: thin },
        { [styles.noOutline]: noOutline },
        className
      )}
      title={title}
      disabled={disabled}
      style={style}
      onClick={onClick}
      type={type || 'button'}
    >
      {icon && <Icon name={icon} style={{ marginRight: '10px' }} />}
      {children}
    </button>
  );

  if (to) {
    return <Link to={to}>{btn}</Link>;
  }

  return btn;
}
