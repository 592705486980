import { fromJS, Map } from 'immutable';
import {
  FETCH_ALL_USERS,
  HANDLE_FETCH_ALL_USERS_ERROR,
  RECEIVE_USERS,
  UPDATE_USER_RESPONSE,
  DELETE_USER,
  HANDLE_CREATE_USER_RESPONSE
} from 'actions/userActions';

const initialState = fromJS({
  data: Map({}),
  loadingAll: false,
  loadingAllError: false
});

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_USERS:
      return state
        .set('loadingAll', true)
        .set('loadingAllError', false)
        .set('data', Map({}));
    case HANDLE_FETCH_ALL_USERS_ERROR:
      return state
        .set('loadingAll', false)
        .set('loadingAllError', true)
        .set('data', Map({}));
    case RECEIVE_USERS:
      return state
        .set('loadingAll', false)
        .set('loadingAllError', false)
        .set('data', fromJS(action.users));
    case UPDATE_USER_RESPONSE:
      const oldUserState = state.getIn(['data', action.userId.toString()]);

      if (!oldUserState) {
        return state;
      }

      const newUserState = oldUserState
        .set('name', action.data.name)
        .set('role', action.data.role)
        .set('email', action.data.email);

      return state.setIn(['data', action.userId.toString()], newUserState);
    case DELETE_USER:
      return state.deleteIn(['data', action.userId.toString()]);
    case HANDLE_CREATE_USER_RESPONSE:
      return state.setIn(
        ['data', action.user.userId.toString()],
        fromJS(action.user)
      );
    default:
      return state;
  }
}
