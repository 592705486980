import { createSelector } from 'reselect';
import { authAccountSelector, authUserSelector } from 'selectors/authSelectors';
import { isGlobalModalActive } from 'selectors/globalModalSelectors';
import { competitorsForProfileSelector } from 'selectors/competitorSelectors';

export const timelineEventsSelector = createSelector(
  state => state.timeline,
  authAccountSelector,
  authUserSelector,
  isGlobalModalActive,
  competitorsForProfileSelector,
  (timeline, account, user, isGlobalModalActive, competitors) => {
    return {
      events: timeline.get('events'),
      filters: timeline.get('filters'),
      showSpinner: timeline.get('showSpinner'),
      canLoadMore: timeline.get('canLoadMore'),
      hasUpdatesCount: timeline.get('hasUpdatesCount'),
      accountAddedOn: account.get('addedOn'),
      exampleMode: user.get('exampleMode'),
      user: user,
      competitors: competitors,
      globalModalActive: isGlobalModalActive
    };
  }
);

export const currentFiltersSelector = createSelector(
  state => state.timeline,
  timeline => {
    return timeline.get('filters').toJS();
  }
);

export const getLastFetchTimeSelector = createSelector(
  state => state.timeline,
  timeline => {
    return timeline.get('lastFetchTime');
  }
);
