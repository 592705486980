import { put, call, takeLatest } from 'redux-saga/effects';
import {
  fetchIntegrationsData,
  REMOVE_INTEGRATION
} from 'actions/integrationActions';
import { createAxios } from 'utils/axios/axiosUtils';
import Swal from 'sweetalert2';

export default function* removeIntegrationSaga() {
  yield takeLatest(REMOVE_INTEGRATION, removeIntegration);
}

function* removeIntegration({ integrationType }) {
  try {
    yield call(getResponse, integrationType);
    yield put(fetchIntegrationsData());
  } catch (e) {
    console.warn(e);

    Swal.fire({
      type: 'error',
      title: 'Oops...',
      text:
        'Something went wrong trying to remove this integration. Try again later.'
    });
  }
}

async function getResponse(integrationType) {
  return await createAxios().delete('account-integrations/' + integrationType);
}
