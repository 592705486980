import { put, call, select, takeLatest } from 'redux-saga/effects';
import {
  receiveInventoryPageData,
  handleInventoryPageDataError,
  FETCH_INVENTORY_PAGE_DATA
} from 'actions/inventoryActions';
import { createAxios } from 'utils/axios/axiosUtils';
import { currentProfileIdSelector } from 'selectors/profileSelectors';

export default function* fetchInventoryPagesSaga() {
  yield takeLatest(FETCH_INVENTORY_PAGE_DATA, fetchPages);
}

function* fetchPages({ competitorId, pageId, limit, searchTerm }) {
  const profileId = yield select(currentProfileIdSelector);

  try {
    const response = yield call(
      getApiResponse,
      profileId,
      competitorId,
      pageId,
      limit,
      searchTerm
    );
    if (response.data) {
      yield put(receiveInventoryPageData(response.data));
    } else {
      yield put(handleInventoryPageDataError('No data returned by API.'));
    }
  } catch (e) {
    console.warn(e);

    yield put(handleInventoryPageDataError(e.message));
  }
}

async function getApiResponse(
  profileId,
  competitorId,
  pageId,
  limit,
  searchTerm
) {
  return await createAxios().get(
    'inventory/pages/' +
      competitorId +
      '?profileId=' +
      profileId +
      '&page=' +
      pageId +
      '&limit=' +
      limit +
      '&searchTerm=' +
      searchTerm
  );
}
