import React, { Component } from 'react';
import Alert from 'components/alert/alert';
import ProgressBar from 'react-bootstrap/ProgressBar';
import styles from './keywordSuggestionTable.module.scss';
import Spinner from 'components/spinner/spinner';
import { formatGivenNumber } from 'utils/numberUtils';

export default class KeywordSuggestionTable extends Component {
  renderCompetitionProgressBar = percent => {
    let variant = 'success';

    if (percent > 80) {
      variant = 'danger';
    } else if (percent > 30) {
      variant = 'warning';
    }

    return (
      <ProgressBar
        className={styles.progress}
        striped
        variant={variant}
        now={percent}
      />
    );
  };

  renderCostPerClickProgressBar = percent => {
    let variant = 'success';

    if (percent > 5) {
      variant = 'danger';
    } else if (percent >= 2) {
      variant = 'warning';
    }

    return (
      <ProgressBar
        className={styles.progress}
        variant={variant}
        now={percent}
      />
    );
  };

  renderSuggestion = suggestion => {
    const searchVolume = suggestion.get('searchVolume');
    const searchVolumeProgress = Math.max(
      Math.min(Math.round(searchVolume * 0.005), 100),
      0
    );
    const averageCpc = suggestion.get('averageCpc');
    let cpcProgress = Math.min(100, Math.round(parseFloat(averageCpc) * 3));

    return (
      <tr key={suggestion}>
        <td style={{ verticalAlign: 'middle', fontWeight: 'bold' }}>
          {suggestion.get('keyword')}
        </td>
        <td>
          <div className={styles.flexTd}>
            <div className={styles.tableMetric}>
              {formatGivenNumber(searchVolume)}
            </div>{' '}
            <ProgressBar
              className={styles.progress}
              variant={'info'}
              now={searchVolumeProgress}
            />
          </div>
        </td>
        <td>
          <div className={styles.flexTd}>
            <div className={styles.tableMetric}>${averageCpc}</div>{' '}
            {this.renderCostPerClickProgressBar(cpcProgress)}
          </div>
        </td>
      </tr>
    );
  };

  renderTableRows = suggestions => {
    return suggestions
      .sort((a, b) => b.get('searchVolume') - a.get('searchVolume'))
      .map(suggestion => this.renderSuggestion(suggestion));
  };

  render() {
    const { dataForDomain } = this.props;

    if (!dataForDomain || dataForDomain.get('isLoading')) {
      return <Spinner center />;
    }

    if (dataForDomain.get('error')) {
      return (
        <Alert variant="danger">
          Their was an error trying to load suggestions, please try again
          shortly.
        </Alert>
      );
    }

    const suggestions = dataForDomain.get('suggestions');
    if (!suggestions.size) {
      return (
        <Alert variant="info">
          No keyword suggestions available for this website.
        </Alert>
      );
    }

    return (
      <table className="table table-striped">
        <thead>
          <tr>
            <th style={{ minWidth: '225px' }}>Keyword</th>
            <th>Search volume</th>
            <th>Cost per click</th>
          </tr>
        </thead>
        <tbody>{this.renderTableRows(suggestions)}</tbody>
      </table>
    );
  }
}
